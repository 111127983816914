const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const ReviewCorrectiveActionsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCcwDcCWYDuBaAxgPbKr4AuGaYuAhuRoQHawB0GEANmAMQDKAKgEEASvwDaABgC6iUAAdCsDBSayQAD0S4ATAGYWARgBsAVgnaA7AA5d2g7pNGHAGhABPRAYAsJlheteFgYAnOY+2lbaAL5RrqiYOATEpBRUtPRMrHSpPAByAKIAGuLSagpKKoxqmgjadn4WwUESIcHaJtperh4IJsFWfrq6bbpWFroGJhYxcehYeEQkYPRp2QzMLGtU3AAKwvkAagCSAPIAqrySMkgg5crr1VqW+nrBjhZ2EsEGEiZd7og+gNxsMvLoLCZwd4jDMQPF5kklitqGtMptkdwAMInYT7TH8I4HfIAfUE+NOuRYABF8gAZfL8fJXMqKe6qG41XC6LzAmyOKxWCTgsHdRAWIzBFhWMFffpeCRGIxeAyw+GJRYpSgojIbLY8bG4-Lkomk8knSlnHZUwSM5k3O6VR4ILlmFh6SFtb5Clr2UUIN6+Iz+AxWAwGTqWCGqubq5LLHLpSpZDGY2knXhM0r21mOjmISyS7RtCGjLw8iQSCx+iYDCv8qwmBv+cYw2JwmMLOPIxPrZM5bg0+m2rPyHMPPO1ENupXefq2NpGcx+oIWFhvToWFo+b6jaMJTtIhOo3UYy3WxnEgBC+RE+WExP4JwA0vlcnbRxVx6AanYvFLXkYRYfGYDhWH6AYsEGQShuGXiRq2sz7oimqrDqfZatwADiJzvrcY7st+WjGIYnS2PKbzDEK1h+j8DYsBW4pBNoEoVl8e4Ihq8Zaj2aJ6twZ42pm1wfmyVQTiGq6dNKujmLogFgsEfqREYLDKjYgRhmWpHsbGh7cce6HbPs-DCAAmrhDpfho+b1HJBibj8jZWMEgRVgCCBeN8fhhuCXoKiYfQ6QeKHakm6L9pigi5JidIWfhYmEb09hSsqAWbnByq6H6jarhWoxho0ja2DEbaMIQEBwGoarBVxqFJiyn4EdZzrDAM7rDEWPwyT5fq4OG9FNE0Xwhn8ClBchtWhb2bCcGADWiU6cFrnUnx5Qqlb-D0EJup0TSQr5YbfONnHdgZ4VavNuaJQ4UpBGGIa-P5IbZXoKVFm8hVWIBwQlVEQA */
    predictableActionArguments: true,

    id: "review-corrective-actions",

    states: {
      idle: {
        on: {
          START: {
            target: "active",
            cond: "validRequest",
            actions: [
              "saveAuthentication",
              "saveCorrectiveActions",
              "saveReportId",
              "setIndex",
              "saveApiUrl",
              "unflagChanges",
            ],
          },
        },
      },

      active: {
        on: {
          NEXT: {
            target: "active",
            internal: true,
            actions: "incrementIndex",
            cond: "notOnLastPage",
          },

          PREVIOUS: {
            target: "active",
            internal: true,
            actions: "decrementIndex",
            cond: "notOnFirstPage",
          },

          "CORRECTIVE_ACTION.DELETE": {
            target: "active",
            internal: true,
            actions: ["adaptIndex", "flagChanges", "removeCorrectiveAction"],
          },

          "CORRECTIVE_ACTION.UPDATE": {
            target: "active",
            internal: true,
            actions: ["updateCorrectiveAction", "flagChanges"],
          },

          CLOSE: {
            target: "idle",
            actions: ["resetCorrectiveActions", "resetPageIndex"],
          },

          DELETE: {
            target: "active",
            internal: true,
            actions: "sendDeleteToCurrentActor",
            cond: "currentActorCanDelete",
          },

          UPDATE_BEARER_TOKEN: {
            target: "active",
            internal: true,
            cond: "validToken",
            actions: ["saveToken", "sendTokenToSpawnedActors"],
          },

          GO: {
            target: "active",
            internal: true,
            actions: "setIndex",
            cond: "correctiveActionIdExists",
          },

          UPDATE: {
            target: "active",
            internal: true,
            actions: "sendUpdateToCurrentActor",
            cond: "currentActorCanUpdate",
          },

          RETRY: {
            target: "active",
            internal: true,
            actions: "sendRetryToCurrentActor",
          },

          CANCEL: {
            target: "active",
            internal: true,
            actions: "sendCancelToCurrentActor",
          },
        },
      },
    },

    initial: "idle",
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { ReviewCorrectiveActionsMachine };
