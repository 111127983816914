import StepsContainer from "../container";
import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation();
  return (
    <StepsContainer>
      <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
        <h1 className="text-center text-xl font-medium text-slate-600">
          {t("requalifyEvent.steps.loading.header")}
        </h1>
        <div className="mt-4">
          <p className="text-center text-sm text-gray-500">
            <span className="text-blue-600">
              {t("requalifyEvent.steps.loading.subHeader")}
            </span>
          </p>
          {/* Todo : Add a bit of animation over here */}
        </div>
        <div className="mt-12"></div>
      </div>
    </StepsContainer>
  );
}
