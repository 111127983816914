import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { EditCorrectiveActionsMachine as parentMachine } from "../machines/edit-corrective-actions/edit-corrective-actions-machine";

const EditCorrectiveActionsMachineContext = createContext();

export function useEditCorrectiveActionsMachine() {
  const context = useContext(EditCorrectiveActionsMachineContext);

  if (!context) {
    throw new Error(
      "useEditCorrectiveActionsMachine must be used within a EditSeqOfEventsMachineProvider",
    );
  }

  return context;
}

export function EditCorrectiveActionsMachineProvider({ children }) {
  const editCorrectiveActionsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <EditCorrectiveActionsMachineContext.Provider
      value={editCorrectiveActionsMachine}
    >
      {children}
    </EditCorrectiveActionsMachineContext.Provider>
  );
}
