import StepsContainer from "../../../container";
import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import { useCreateCorrectiveActionMachine } from "../../../../../context/action-plans/CreateCorrectiveActionMachineContext";
import { useTranslation } from "react-i18next";
import GenericSelectMenu from "../../../GenericSelectMenu";
import GenericText from "../../../GenericText";
import DateTimePicker from "../../../DateTimePicker";
import { TextArea } from "../../../TextArea";

export default function Step1() {
  const [state, send] = useCreateCorrectiveActionMachine();
  const [createReportState, sendToCreateReport] = useCreateReportMachine();
  const { t } = useTranslation();

  return (
    <StepsContainer>
      {/* Source */}
      <div>
        <GenericSelectMenu
          data={createReportState.context?.correctiveActionSources || []}
          selectedItem={state.context?.changeSet?.correctiveActionSource}
          onItemSelected={(element) => {
            send("CORRECTIVE_ACTION_SOURCE_SELECTED", { data: element });
          }}
          label={t("createCorrectiveAction.step1.label.source")}
          noSelectionText={t("createCorrectiveAction.step1.placeholder.source")}
          useNameForKey={true}
          useEntireItemForValue={true}
          i18nPrefixKey="shared.type.correctiveAction.source."
        />
      </div>
      {/* Priority */}
      <div>
        <GenericSelectMenu
          data={createReportState.context?.correctiveActionPriorities}
          selectedItem={state.context?.changeSet?.correctiveActionPriority}
          onItemSelected={(element) => {
            send("CORRECTIVE_ACTION_PRIORITY_SELECTED", { data: element });
          }}
          label={t("createCorrectiveAction.step1.label.priority")}
          noSelectionText={t(
            "createCorrectiveAction.step1.placeholder.priority",
          )}
          useNameForKey={true}
          useEntireItemForValue={true}
          i18nPrefixKey="shared.type.correctiveAction.priority."
        />
      </div>

      {/* Domain */}
      <div>
        <GenericSelectMenu
          //data={createReportState.context?.domains}
          data={createReportState.context?.restrictedDomains}
          selectedItem={state.context?.changeSet?.domain}
          onItemSelected={(element) => {
            send("DOMAIN_SELECTED", { data: element });
          }}
          label={t("createCorrectiveAction.step1.label.domain")}
          noSelectionText={t("createCorrectiveAction.step1.placeholder.domain")}
          useNameForKey={true}
          useEntireItemForValue={true}
          i18nPrefixKey="shared.type.domain."
        />
      </div>
      {/* Problem statement */}
      <GenericText
        label={t("createCorrectiveAction.step1.label.problemStatement")}
        // placeholder={t("createCorrectiveAction.step1.placeholder.problemStatement")}
        onChange={(value) => {
          send("PROBLEM_STATEMENT_CHANGED", {
            data: value,
          });
        }}
      />
      {/* Root cause */}
      <GenericText
        label={t("createCorrectiveAction.step1.label.rootCause")}
        // placeholder={t("createCorrectiveAction.step1.placeholder.rootCause")}
        onChange={(value) => {
          send("ROOT_CAUSE_CHANGED", {
            data: value,
          });
        }}
      />
      {/* Description */}
      {/* <GenericText
        label={t("createCorrectiveAction.step1.label.description")}
        // placeholder={t("createCorrectiveAction.step1.placeholder.description")}
        onChange={(value) => {
          send("DESCRIPTION_CHANGED", {
            data: value,
          });
        }}
      /> */}
      <TextArea
        id="action-description"
        name="action-description"
        rows={5}
        label={t("createCorrectiveAction.step1.label.description")}
        onChange={(event) => {
          send("DESCRIPTION_CHANGED", {
            data: event.target.value,
          });
        }}
      />
      {/* Handled by
      <GenericText
        label={t("createCorrectiveAction.step1.label.handledBy")}
        // placeholder={t("createCorrectiveAction.step1.placeholder.handledBy")}
        onChange={(value) => {
          send("HANDLED_BY_CHANGED", {
            data: value,
          });
        }}
      /> */}
      <div>
        <GenericSelectMenu
          data={createReportState.context?.correctiveActionManagerTypes}
          selectedItem={state.context?.changeSet?.handler_type}
          onItemSelected={(value) => {
            send("HANDLER_TYPE_CHANGED", {
              data: value,
            });
          }}
          label={t("createCorrectiveAction.step1.label.handledBy")}
          noSelectionText={t(
            "createCorrectiveAction.step1.placeholder.handledBy",
          )}
          useNameForKey={true}
          useEntireItemForValue={true}
          i18nPrefixKey="shared.type.correctiveAction.managerTypes."
        />
      </div>

      {/* Deadline */}
      <DateTimePicker
        label={t("createCorrectiveAction.step1.label.expiredAt")}
        initialDate={null}
        onChange={(iso861DateStr) => {
          send("EXPIRATION_DATE_SELECTED", {
            data: iso861DateStr,
          });
        }}
        min={new Date()}
      />
    </StepsContainer>
  );
}
