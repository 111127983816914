const {
  validToken,
  validSelectedConsequenceTypeObject,
  accessDeniedError,
  badRequestError,
} = require("../../commons/guards");

const validRequest = (context, event) => {
  return (
    event.reportId &&
    event.authentication &&
    event.authentication.token &&
    event.qmsToolApiUrl &&
    event.changeSet &&
    event.consequenceTypes
  );
};

const validReason = (context, event) => {
  return (
    context.changeSet.requalification_reason &&
    context.changeSet.requalification_reason.length > 0
  );
};

const forcedCriticity = (context, event) => {
  if (context?.changeSet?.consequence) {
    const consequenceType = context.consequenceTypes.find(
      (consequenceType) =>
        consequenceType.name === context.changeSet.consequence.name,
    );
    if (
      consequenceType?.forced_severity &&
      consequenceType?.forced_probability
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const computableCrititicy = (context, event) => {
  console.log("here");
  if (context?.changeSet?.consequence) {
    const consequenceType = context.consequenceTypes.find(
      (consequenceType) =>
        consequenceType.name === context.changeSet.consequence.name,
    );
    if (
      consequenceType?.forced_severity == null &&
      consequenceType?.forced_probability == null
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const validSelectedSeverity = (context, event) => {
  return true;
};

const validSelectedProbability = (context, event) => {
  return true;
};

export {
  validToken,
  validRequest,
  forcedCriticity,
  computableCrititicy,
  validSelectedConsequenceTypeObject,
  validSelectedSeverity,
  validSelectedProbability,
  validReason,
  accessDeniedError,
  badRequestError,
};
