import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SectionLock({ children, locked = false }) {
  const { t } = useTranslation();
  return (
    <>
      {locked && (
        <div className={classNames("relative", locked ? "print:hidden" : "")}>
          <div class="  blur-sm">{children}</div>
          <div class="absolute inset-0 flex cursor-not-allowed items-center justify-center text-center text-xl">
            <div className="overflow-hidden rounded-lg  bg-gray-50 shadow">
              <div className="px-6 py-6">
                <div className="text-center text-sm ">
                  <ExclamationCircleIcon
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto h-6 w-6 text-gray-400"
                  />
                  <p className="mt-4 font-semibold text-gray-900">
                    {t("shared.section.requalified.title")}
                  </p>
                  <p className="mt-2 text-gray-500">
                    {t("shared.section.requalified.subtitle")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!locked && children}
    </>
  );
}
