import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { FlagSectionForReviewMachine as parentMachine } from "../machines/flag-section-for-review/flag-section-for-review-machine";

const FlagSectionForReviewMachineContext = createContext();

export function useFlagSectionForReviewMachine() {
  const context = useContext(FlagSectionForReviewMachineContext);

  if (!context) {
    throw new Error(
      "useFlagSectionForReviewMachine must be used within a EditSeqOfEventsMachineProvider",
    );
  }

  return context;
}

export function FlagSectionForReviewMachineProvider({ children }) {
  const flagSectionForReviewMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <FlagSectionForReviewMachineContext.Provider
      value={flagSectionForReviewMachine}
    >
      {children}
    </FlagSectionForReviewMachineContext.Provider>
  );
}
