import { HomeIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Breadcrumb({ reference, reportId, loading }) {
  const { t } = useTranslation();
  const [state, send] = useViewEditReportMachine();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li key="dashboard">
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Dashboard</span>
            </Link>
          </div>
        </li>

        <li key="reports">
          <div className="flex items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <Link
              to="/reports"
              className={classNames(
                "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700",
              )}
            >
              {t("homepage.navigation.reports")}
            </Link>
          </div>
        </li>

        <li key="report.event.reference">
          <div className="flex items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <Link
              to={`reports/${reference}`}
              className={classNames(
                "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700",
                "pointer-events-none",
              )}
              aria-current="page"
              aria-disabled="true"
            >
              {reference}
            </Link>
          </div>
        </li>

        <li key="refresh-link">
          <div className="flex items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <a
              className={classNames(
                "ml-4 cursor-pointer  text-sm text-blue-500 hover:underline",
              )}
              aria-current="page"
              onClick={() =>
                send("LOAD_REPORT", {
                  data: reportId,
                })
              }
            >
              <ArrowPathIcon
                className={classNames(
                  "inline-block h-5 w-5",
                  loading ? "animate-spin" : "",
                )}
              />
              <span className="invisible ml-2 sm:visible">
                {t("editReport.view.breadcrumb.refresh")}
              </span>
            </a>
          </div>
        </li>
      </ol>
    </nav>
  );
}
