import Info from "../../Info";
import StepsContainer from "../../container";
import { useTranslation } from "react-i18next";
import { useReviewFiveWhysMachine } from "../../../../context/ReviewFiveWhysMachineContext";

export default function ProblemDefinition() {
  const { t } = useTranslation();
  const [state, send] = useReviewFiveWhysMachine();
  return (
    <StepsContainer>
      <div>
        <Info>
          <h2 className="font-medium">
            {t("editFiveWhys.steps.problem.info.header")}
          </h2>
          <div className="mt-3">
            {t("editFiveWhys.steps.problem.info.subHeader")}
          </div>
        </Info>
        <div className="space-y-6 pb-5 pt-6">
          {/* Problem statement */}
          <div>
            <label
              htmlFor="problem_statement"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("editFiveWhys.steps.form.problem.label")}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="problem_statement"
                id="problem_statement"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  send("PROBLEM_CHANGED", {
                    data: e.target.value,
                  });
                }}
                value={state?.context?.changeSet?.problem_statement}
              />
            </div>
          </div>
        </div>
      </div>
    </StepsContainer>
  );
}
