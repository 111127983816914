const { actions, sendParent } = require("xstate");
const { assign } = actions;

const saveMonth = assign({
  month: (context, event) => {
    return event.data;
  },
});

const saveYear = assign({
  year: (context, event) => {
    return event.data;
  },
});

const setPeriodOptionsAsActiveTab = assign({
  activeTab: "period",
});

const setRangeOptionsAsActiveTab = assign({
  activeTab: "range",
});

const saveStartDate = assign({
  startDate: (context, event) => {
    return event.data;
  },
  endDate: (context, event) => {
    const result = context?.endDate;
    const startDate = new Date(event.data);
    if (result && startDate > new Date(result)) {
      return "";
    } else {
      return result;
    }
  },
});

const saveEndDate = assign({
  endDate: (context, event) => {
    console.log("sss");
    return event.data;
  },
});

const resetPeriodOptions = assign({
  month: (c, e) => null,
  year: (c, e) => null,
});

const resetRangeOptions = assign({
  startDate: (c, e) => null,
  endDate: (c, e) => null,
});

const sendClearedToParent = sendParent((context) => ({
  type: context.clearedEventType,
}));

const sendApplyEventToParent = sendParent((context) => ({
  type: context.applyEventType,
  data: {
    month: context.month,
    year: context.year,
    startDate: context.startDate,
    endDate: context.endDate,
  },
}));

const { flagChanges, unflagChanges } = require("../../commons/actions");

export {
  saveMonth,
  saveYear,
  resetPeriodOptions,
  resetRangeOptions,
  setPeriodOptionsAsActiveTab,
  setRangeOptionsAsActiveTab,
  saveStartDate,
  saveEndDate,
  flagChanges,
  unflagChanges,
  sendClearedToParent,
  sendApplyEventToParent,
};
