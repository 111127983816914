const validLoadRequest = require("../../commons/guards").validLoadRequest;
const validToken = require("../../commons/guards").validToken;
const allDataLoaded = (context, event) => {
  return (
    context?.activeReportsLoaded === true &&
    context?.inactiveReportsLoaded === true
  );
};

const activeView = (context, event) => {
  return context.view === "active";
};

const inactiveView = (context, event) => {
  return context.view === "inactive";
};

const reportsNotEmpty = (context, event) => {
  return true;
};

const advancedOptionsClosed = (context, event) => {
  return context?.advancedSearchAgentRef?.state?.matches("idle");
};

const advancedOptionsOpen = (context, event) => {
  return context?.advancedSearchAgentRef?.state?.matches("active");
};

export {
  validLoadRequest,
  validToken,
  allDataLoaded,
  activeView,
  inactiveView,
  reportsNotEmpty,
  advancedOptionsClosed,
  advancedOptionsOpen,
};
