const validDescription = (context, event) => {
  return context.description && context.description.length > 0;
};

const validStartRequest = (context, event) => {
  return (
    event.data.action_url &&
    event.data.action_url.length > 0 &&
    event.data.authentication &&
    event.data.authentication.token &&
    event.data.authentication.token.length > 0
  );
};

const validToken = require("../../commons/guards").validToken;

export { validDescription, validStartRequest, validToken };
