const correctiveActionsEmpty = (context, event) => {
  return context?.corrective_actions?.length === 0;
};

const validChangeSet = (context, event) => {
  return (
    context?.changeSet?.description &&
    context?.changeSet?.description.length &&
    context?.changeSet?.handler_type &&
    context?.changeSet?.expired_at &&
    Date.parse(context?.changeSet?.expired_at)
  );
};

const validToken = require("../../commons/guards/index").validToken;
const noChanges = (context, event) => {
  return (
    !context?.changeSet?.description &&
    !context?.changeSet?.handler_type &&
    !context?.changeSet?.expired_at
  );
};

export { validChangeSet, correctiveActionsEmpty, validToken, noChanges };
