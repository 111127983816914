const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const createReviewSeqOfEventsDetailMachine = ({
  seq_of_events_detail,
  token,
  qmsToolApiUrl,
  reportId,
  maxDate,
}) =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QCcwDcCWYDuBaWYAjrgPYBmu6YAdgC6y4Ri0CGGANgHSQa0bVQAxABEAogGUAwgCUAkgAUAKrIDyAOQD6kgBIBBNQHFRwgNoAGALqJQABxKxeGEtWsgAHolwBmABwBWTh8ggEYAFgAmADZo0J9wsz8AGhAAT0RggHYATk5sjMiM0Kzwn0jQryzggF8q5NRMHHwiUgoqOgYmVg5uCEcBEVEAGVFFUXMrJBA7Bz5nVw8EYMifTi9I4KyshNCwqMjktIRN3K9yjK8MzK3QyJq69Cw8AmJySjQaekZmNi4ePn6AKryYS6UbjVzTRxzSYLXChAJ+S4JSJmApmc5ZUIHRBeYLBThZZZ+TK+NbBC53ED1R5NF6td7tL5dX69f5CUQANVEakUGhUkkkAOk3Mkog0INGGnEQ1EklGpksEPsUJcMM8O0inD8fkia1ON2C8Qy2IQuPCnFR4Vx2T8XnCfnClOpjWeLTeHw6326f34Ql0ADF-bL5RpucpFABNLR6QzGcGTSGzVWgWGhMyhQJZfzrDJmYKo4Im+EBVFZbWhDLZsK3WpUh4u5qvNqfTo-Hp9ISKaS6UXiDT6YShjmyMRqXvR-RGBUTWzKpPzdVeMy5Qmbcr5sxBY2pRB+LOcFGblElPfLLxO+tPRv0j1MttMdjMMCcB-MX2CCDOZ-8NAkADWz7OledLuoyrbdK+tDPpBvoID+JAAMYsEm4zxrOMxOMm7i7pUqyhGmPheHaGR+GYvgmr4AThPk5KFBk1FROSF4NMBbrNp6zIvmAj5QVxPHvmAyDICQyCcDY7DIWQIkALacEBtJsQyLZelwkHQdxb4CHB1C-khKGWGhUxzphC4IPR5rXOExThOSuyFjuCA2cuYQhDcWREWWkSOrW8muk2SkcfeGm8YJwnIIIwpdhGhmJiZaoID4m4WpsfhBKcPgYiaeKalsOpmNRET+A63n3CxCn+be4FcAArjYEDIc+tX1WyH5fpw8EAXJl7lTeYEqZwTUNQNdXIbB8F6ZhqGKgmxnQimnjBCshSbIR6zkritomtEy7RKleb4Tq4QVsxNJ+b1ymcYNvFXQJQkiWJEm0FJyCyb516gRdbZXY1I1stpumjc4U0zkZGFzdhjkogS0QuSRcSVEkDnEs5XiHUsXl4lmJ0NiB7F3t033cHd4WRdI0XTehKqmfaGZeXRZZ4j4lSoyaqUZAe2VmFziXZHm2OsRVfWXb9kCCDFs1YbCRErA6kSYnuR1lEdWVJWWJERKiFbFIS-M9R9gXeqy75AhKYoAEKiLowrSBoigqAA0ty4tg5LnhHezCTakdS6Gn48L7A5cLs-hR1kbE5QR-hNS1tQJBMPAkxvbjAX4+wSou6ZQdLaumK4tzGUmrg5IZvktobBUCt7uePndWd+up+2bLp1T8Uoia+6xGW8Pa5uxK63XeNVXxT7N-O8V2is-gRHnGSVhcYQmlZWpxMsm70RsOrV6Vp3vYP-VqcPTczRn4-5IEftWvms+EZcWIOYtGZbGEz8JFk2Rv-3u8p0PB+hSJo9xXmosfKXgCR5gyrmfMcRESLySkuXUZR0xxFiNRT+ydKr72CpAAB4MFhrU4CUFEhQKxlAqPZQ44cCHTzfqUKyJQshoMUhg4WzUwA4NdggXAiUDzpmCKlGyywyhlgovuJYVl1iVCzNkUIjDBafQJiLYazVfTsMznaHhOx+FLFKEUG4RZ-CcFiGzOe1YNiyPOgbGqii-7IFUafdmlYSK5jlksOirM4gEJPD4IohE8THRrmVAe39+rfQgHYoB3hQEVG8WEVG0T0QmmyDLIxmRSLuQvtHKoQA */
      predictableActionArguments: true,

      id: "review-seq-of-events-detail",

      context: {
        seq_of_events_detail: { ...seq_of_events_detail },
        authentication: { token: token },
        reportId: reportId,
        qmsToolApiUrl: qmsToolApiUrl,
        maxDate: maxDate,
      },

      states: {
        editing: {
          on: {
            DESCRIPTION_CHANGED: {
              target: "editing",
              internal: true,
              actions: ["saveDescription", "flagChanges"],
            },

            DELETE: "delete",

            UPDATE: {
              target: "update",
              cond: "validChangedSeqOfEventsDetail",
            },

            EVENT_OCCURENCE_DATE_SELECTED: {
              target: "editing",
              internal: true,
              actions: ["flagChanges", "saveEventOccurenceDate"],
            },

            AFFECTED_ENTITY_CHANGED: {
              target: "editing",
              internal: true,
              actions: ["flagChanges", "saveAffectedEntity"],
            },

            TRACES_AND_EVIDENCES_CHANGED: {
              target: "editing",
              internal: true,
              actions: ["flagChanges", "saveTracesAndEvidences"],
            },

            UPDATE_BEARER_TOKEN: {
              target: "editing",
              internal: true,
              cond: "validToken",
              actions: "saveToken",
            },
          },
        },

        delete: {
          states: {
            deleting: {
              invoke: {
                src: "deleteSeqOfEventsDetail",
                onDone: "#review-seq-of-events-detail.deleted",
                onError: "error",
              },
            },

            error: {
              on: {
                RETRY: "deleting",
              },
            },
          },

          initial: "deleting",
        },

        deleted: {
          type: "final",
          entry: "sendDeleteEventToParent",
        },

        update: {
          states: {
            updating: {
              invoke: {
                src: "updateSeqOfEventsDetail",

                onDone: {
                  target: "#review-seq-of-events-detail.updated",
                  actions: "unflagChanges",
                },

                onError: "error",
              },
            },

            error: {
              on: {
                RETRY: "updating",
              },
            },
          },

          initial: "updating",
        },
        updated: {
          entry: "sendUpdateEventToParent",

          always: "editing",
        },
      },

      initial: "editing",
    },
    {
      actions: actions,
      guards: guards,
      services: services,
    },
  );

export { createReviewSeqOfEventsDetailMachine };
