import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Range = ({ isoStringStart, isoStringEnd }) => {
  const { t } = useTranslation();

  const range = (start, end) => {
    const diffInMilliseconds = end - start;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 7),
    );
    const diffInMonths = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 30),
    );
    const diffInYears = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 365),
    );
    if (diffInSeconds < 60) {
      return t("shared.component.time.sec", { count: diffInSeconds });
    }
    if (diffInMinutes < 60) {
      return t("shared.component.time.min", { count: diffInMinutes });
    }
    if (diffInHours < 24) {
      const roundedHours = Math.round(diffInMinutes / 60);
      return t("shared.component.time.hour", { count: roundedHours });
    }
    if (diffInDays < 7) {
      return t("shared.component.time.day", { count: diffInDays });
    }
    if (diffInWeeks < 4) {
      return t("shared.component.time.week", { count: diffInWeeks });
    }
    if (diffInMonths < 12) {
      return t("shared.component.time.month", { count: diffInMonths });
    }
    if (diffInYears >= 1) {
      return t("shared.component.time.year", { count: diffInYears });
    }

    return "-";
  };

  return <>{range(new Date(isoStringStart), new Date(isoStringEnd))}</>;
};

export default Range;
