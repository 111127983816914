import { MoonIcon, SunIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PeriodOfTheDayBadge({
  periodOfTheDay,
  obfuscated = false,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium uppercase text-gray-500 ring-1 ring-inset ring-gray-500/10">
          {periodOfTheDay === "NIGHT" ? (
            <MoonIcon className="h-4 w-3" />
          ) : periodOfTheDay === "DAY" ? (
            <SunIcon className="h-4 w-3" />
          ) : (
            <MinusIcon className="h-4 w-3" />
          )}
          <span className={classNames(obfuscated ? "blur-sm" : "ml-1")}>
            {periodOfTheDay
              ? t("shared.type.periodOfTheDay." + periodOfTheDay)
              : null}
          </span>
        </span>
      </div>
    </>
  );
}
