const validChangedSeqOfEventsDetail = (context, event) => {
  return (
    context?.seq_of_events_detail?.description &&
    context.seq_of_events_detail.description.length > 0 &&
    context.changesHappened === true
  );
};

const validToken = require("../../commons/guards/index").validToken;

export { validChangedSeqOfEventsDetail, validToken };
