import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ReviewSeqOfEventsMachine as parentMachine } from "../machines/review-seq-of-events/review-seq-of-events-machine";

const ReviewSeqOfEventsMachineContext = createContext();

export function useReviewSeqOfEventsMachine() {
  const context = useContext(ReviewSeqOfEventsMachineContext);

  if (!context) {
    throw new Error(
      "useReviewSeqOfEventsMachine must be used within a ReviewSeqOfEventsMachineProvider",
    );
  }

  return context;
}

export function ReviewSeqOfEventsMachineProvider({ children }) {
  const reviewSeqOfEventsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ReviewSeqOfEventsMachineContext.Provider value={reviewSeqOfEventsMachine}>
      {children}
    </ReviewSeqOfEventsMachineContext.Provider>
  );
}
