import CriticityBadge from "../CriticityBadge";
import DateTimeFormatter from "../DateTimeFormatter";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import TimeAgo from "../shared/TimeAgo";
import { useListReportsMachine } from "../../../context/ListReportsMachineContext";
import { useEventNavigatorMachine } from "../../../context/EventNavigatorMachineContext";
import { useAuth } from "../../../context/AuthContext";
import { useEffect } from "react";
import Tabs from "./Tabs";
import SortableTableHeader from "../shared/SortableTableHeader";
import Filters from "../shared/Filters";
import ActiveFilters from "../shared/ActiveFilters";
import SearchWidget from "./SearchWidget";

import NoResults from "./NoResults";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function List() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, send] = useListReportsMachine();
  const [eventNavigatorState, eventNavigatorSend] = useEventNavigatorMachine();
  const { keycloak } = useAuth();

  const compareReportByCriticity = (report1, report2, order = "asc") => {
    // Fonction pour extraire la valeur de la source selon la nouvelle logique spécifiée
    function getCriticityValue(report) {
      const criticity = report?.event.criticity?.name
        ? t(`shared.type.criticity.tag.${report?.event.criticity?.name}`)
        : null;
      return !criticity ? "\uFFFF" : criticity;
    }

    // Extraction des valeurs de source pour chaque action
    const sourceValue1 = getCriticityValue(report1);
    const sourceValue2 = getCriticityValue(report2);

    // Utilisation de localeCompare pour la comparaison des chaînes de caractères
    let comparison = sourceValue1.localeCompare(sourceValue2);

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const headers = [
    {
      key: "reference",
      textId: "reports.table.headers.reference",
      classes:
        "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
      comparator: (a, b, order) =>
        order === "asc" ? (a.id > b.id ? 1 : -1) : a.id > b.id ? -1 : 1,
    },
    {
      key: "status",
      textId: "reports.table.headers.status",
      classes:
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
    },
    {
      key: "event.type",
      textId: "reports.table.headers.type",
      classes:
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
    },
    {
      key: "event.criticity.name",
      textId: "reports.table.headers.criticity",
      classes:
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareReportByCriticity,
    },
    {
      key: "residence_one_key",
      textId: "reports.table.headers.residence",
      classes:
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
    },
    {
      key: "event.event_occured_at",
      textId: "reports.table.headers.date",
      classes: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
    },
  ];

  useEffect(() => {
    console.log(state);
    if (state.matches("idle")) {
      send("LOAD", {
        data: {
          authentication: {
            token: keycloak.token,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log("Token is refreshing : send token to list machine");
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            {t("reports.header")}
          </h1>
          <p className="mt-2 text-xs text-gray-500">
            {t("reports.label.last_refreshed_at")}{" "}
            <TimeAgo isoString={state?.context?.loaded_at} />
          </p>
        </div>
        <div className="mt-4 sm:ml-3 sm:mt-0 sm:flex-none">
          {(state.can("SEARCH") || true) && <SearchWidget />}
        </div>
        <div className="mt-4 sm:ml-3 sm:mt-0 sm:flex-none">
          {state.can("EXPORT") && (
            <button
              type="button"
              className={classNames(
                "relative mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white",
                state.matches("exporting") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("EXPORT");
              }}
              disabled={state.matches("exporting")}
            >
              {state.matches("exporting") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.export")}
            </button>
          )}
          {state.matches("exporting") && (
            <button
              type="button"
              className={classNames(
                "relative mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                "cursor-not-allowed",
              )}
              disabled={true}
            >
              {state.matches("exporting") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.status.exporting")}
            </button>
          )}
          {state.matches("loading") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                "cursor-not-allowed",
              )}
              disabled={true}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.status.loading")}
            </button>
          )}
          {state.can("RELOAD") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RELOAD");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.refresh")}
            </button>
          )}
          {state.can("RETRY") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RETRY");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.retry")}
            </button>
          )}
        </div>
      </div>
      {/* Put here the tabs to filter out active and inactive reports.  */}
      <div className="mt-10 flex justify-between">
        <Tabs />
        <div className="my-auto ml-2">
          <Filters state={state} send={send} />
        </div>
      </div>
      <div className="sm:mt-3">
        <ActiveFilters state={state} send={send} />
      </div>

      <div className="-mx-4 mt-6 bg-white ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <SortableTableHeader
            headers={headers}
            sortedBy={state?.context?.currentSortKey}
            sortOrder={state?.context?.currentSortDirection}
            onHeaderClick={(header) => {
              send({
                type: "SORT",
                sortKey: header.key,
                comparator: header.comparator,
              }); // Appelle la fonction de callback avec la clé de l'en-tête cliqué
            }}
          />
          <tbody>
            {state?.context?.reports.length === 0 && (
              <tr>
                <td colspan="7">
                  <NoResults />
                </td>
              </tr>
            )}
            {state?.context?.reports.map((report, planIdx) => (
              <tr key={report.id}>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-transparent",
                    "relative py-4 pl-4 pr-3 text-sm sm:pl-6",
                  )}
                >
                  <div className="font-medium text-gray-900">
                    {report.reference}
                  </div>
                  <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                    <span>
                      <span className="block sm:hidden">
                        {report.residence_one_key}
                      </span>
                      <span className="block uppercase sm:hidden">
                        {" "}
                        {t("shared.type.reportStatus." + report.status)}
                      </span>
                      {report.event.type}
                      <span className="ml-3 lg:ml-0">
                        <CriticityBadge
                          value={report.event.criticity.name}
                          obfuscated={report.obfuscated}
                        />
                      </span>
                    </span>
                    <span className="ml-3 hidden sm:inline lg:ml-0">
                      {report.residence_one_key}
                    </span>
                    <span className="ml-3 hidden uppercase sm:inline lg:ml-0">
                      {" "}
                      {t("shared.type.reportStatus." + report.status)}
                    </span>
                  </div>
                  {planIdx !== 0 ? (
                    <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                  ) : null}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm uppercase text-gray-500 lg:table-cell",
                  )}
                >
                  {/* <EventTypeBadge value={report.event.type} /> */}
                  {t("shared.type.reportStatus." + report.status)}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell",
                  )}
                >
                  {/* <EventTypeBadge value={report.event.type} /> */}
                  {report.event.type}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell",
                  )}
                >
                  <CriticityBadge
                    value={report.event.criticity.name}
                    obfuscated={report.obfuscated}
                  />
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell",
                  )}
                >
                  {report.residence_one_key}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-gray-200",
                    "px-3 py-3.5 text-sm text-gray-500",
                  )}
                >
                  <div className="sm:block">
                    <DateTimeFormatter
                      isoString={report.event.event_occured_at}
                      obfuscated={report.obfuscated}
                    />
                  </div>
                  {/* <div className="hidden sm:block">{report.price}/month</div> */}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? "" : "border-t border-transparent",
                    "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6",
                  )}
                >
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => {
                      navigate(`/reports/${report.id}`);
                    }}
                  >
                    {t("reports.table.actions.view")}
                    <span className="sr-only">, {report.reference}</span>
                  </button>
                  {planIdx !== 0 ? (
                    <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
