import StepsContainer from "../../container";
import Info from "../../Info";
import { useTranslation } from "react-i18next";
import { useEditFiveWhysMachine } from "../../../../context/EditFiveWhysMachineContext";

import Statement from "../../Statement";

export default function Answer5() {
  const { t } = useTranslation();
  const [state, send] = useEditFiveWhysMachine();
  return (
    <>
      <StepsContainer>
        <div>
          <label
            htmlFor="problem_statement"
            className="block  text-sm font-medium leading-6 text-gray-600"
          >
            {t("editFiveWhys.steps.form.problem.label")}
          </label>

          <div className="mt-2">
            <Statement value={state?.context?.changeSet?.problem_statement} />
          </div>
        </div>
        <div>
          {/* ANSWER 1 */}
          {state?.context?.changeSet?.answer_why_1 && (
            <>
              <label className="block text-sm font-medium leading-6 text-gray-600">
                {t("editFiveWhys.steps.form.why.label")}
              </label>
              <div className="mt-2">
                <Statement
                  active={state?.context?.changeSet?.final_why_index === 1}
                  value={state?.context?.changeSet?.answer_why_1}
                />
              </div>
            </>
          )}
        </div>
        {/* ANSWER 2 */}
        {state?.context?.changeSet?.answer_why_2 && (
          <div className="mt-2">
            <Statement
              active={state?.context?.changeSet?.final_why_index === 2}
              value={state?.context?.changeSet?.answer_why_2}
            />
          </div>
        )}
        {/* ANSWER 3 */}
        {state?.context?.changeSet?.answer_why_3 && (
          <div className="mt-2">
            <Statement
              active={state?.context?.changeSet?.final_why_index === 3}
              value={state?.context?.changeSet?.answer_why_3}
            />
          </div>
        )}
        {/* ANSWER 4 */}
        {state?.context?.changeSet?.answer_why_4 && (
          <div className="mt-2">
            <Statement
              active={state?.context?.changeSet?.final_why_index === 4}
              value={state?.context?.changeSet?.answer_why_4}
            />
          </div>
        )}
        {/* ANSWER 5 */}
        {state?.context?.changeSet?.answer_why_5 && (
          <div className="mt-2">
            <Statement
              active={state?.context?.changeSet?.final_why_index === 5}
              value={state?.context?.changeSet?.answer_why_5}
            />
          </div>
        )}
        {/* OTHER POSSIBLE CAUSES */}
        {state?.context?.changeSet?.other_possible_causes && (
          <div>
            {" "}
            <label className="block text-sm font-medium leading-6 text-gray-600">
              {t("editFiveWhys.steps.form.why.otherPossibleCauses.label")}
            </label>
            <div className="mt-2">
              <Statement
                value={state?.context?.changeSet?.other_possible_causes}
              />
            </div>
          </div>
        )}
      </StepsContainer>
    </>
  );
}
