const { actions } = require("xstate");
const { assign } = actions;
const compareValues = require("../../../commons/functions").compareValues;
const getNestedValue = require("../../../commons/functions").getNestedValue;

const saveToken = require("../../../commons/actions").saveToken;
const saveLoadRequest = require("../../../commons/actions").saveLoadRequest;
const updateLastRefreshedTimestamp =
  require("../../../commons/actions").updateLastRefreshedTimestamp;
const saveError = require("../../../commons/actions").saveError;

const saveActionPlans = assign({
  actionPlans: (context, event) => {
    return event.data;
  },
});

const sortActionPlans = assign((context, event) => {
  let sortKey = event.sortKey || context.currentSortKey;
  let comparator;
  let sortDirection;
  const defaultComparator = (a, b, sortDirection) =>
    compareValues(
      getNestedValue(a, sortKey),
      getNestedValue(b, sortKey),
      sortDirection,
    );

  if (event.sortKey && context.currentSortKey === event.sortKey) {
    sortDirection = context.currentSortDirection === "asc" ? "desc" : "asc";
  } else if (event.sortKey && context.currentSortKey !== event.sortKey) {
    sortDirection = "asc";
  } else if (!event.sortKey) {
    sortDirection = context.currentSortDirection;
  }

  if (event.comparator) {
    comparator = event.comparator;
  } else if (context.currentSortKey === event.sortKey || !event.sortKey) {
    comparator = context.currentComparator || defaultComparator;
  } else {
    comparator = defaultComparator;
  }

  const sortedActionPlans = [...context.actionPlans].sort((a, b) =>
    comparator(a, b, sortDirection),
  );

  console.log("sortedActionPlans", sortedActionPlans);

  return {
    actionPlans: sortedActionPlans,
    currentSortKey: sortKey,
    currentSortDirection: sortDirection,
    currentComparator: comparator,
  };
});

export {
  saveLoadRequest,
  saveActionPlans,
  updateLastRefreshedTimestamp,
  saveError,
  saveToken,
  sortActionPlans,
};
