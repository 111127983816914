import React, { useEffect, useRef } from "react";
import { useActor } from "@xstate/react";
import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Correction({ correction }) {
  const [state, send] = useActor(correction.ref);
  const { t } = useTranslation();

  return (
    <>
      <div className="space-y-6 pb-5 pt-6">
        {/* Description */}
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            {t("editCorrections.view.label.description")}
          </label>
          <div className="mt-2">
            <input
              autoFocus
              type="text"
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              onChange={(e) => {
                console.log(e.target.value);
                send({
                  type: "DESCRIPTION_CHANGED",
                  data: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  send("UPDATE");
                }
              }}
              value={state.context.correction.description}
            ></input>
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => send("UPDATE")}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0  sm:w-auto"
                disabled={!state.can("UPDATE")}
              >
                {!state.matches("update.updating") && (
                  <>{t("editReport.view.action.save")}</>
                )}
                {state.matches("update.updating") && (
                  <>
                    <svg
                      className={classNames(
                        "-ml-1 mr-3  h-5 w-5 text-white",
                        "animate-spin ",
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    {t("shared.button.saving")}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
