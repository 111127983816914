import axios from "axios";
import { read, utils, writeFile, writeFileAsync } from "xlsx";
import moment from "moment-timezone";
const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const fetchReports = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}reports?view=minimal`, options).then(
    (response) => response.data,
  );
};

const exportReports = async (context, event) => {
  return new Promise((resolve, reject) => {
    try {
      const transformedReports = context.reports.map((report) => {
        const {
          id,
          reference,
          status,
          submitter_username,
          residence_one_key,
          created_at,
          updated_at,
          obfuscated,
          event,
        } = report;
        return {
          report_id: id,
          report_obfuscated: obfuscated,
          report_reference: reference,
          report_status: status,
          report_submitter_username: submitter_username,
          report_residence_one_key: residence_one_key,
          report_created_at: new Date(created_at).toLocaleString(),
          report_updated_at: new Date(updated_at).toLocaleString(),
          event_occured_at: new Date(event.event_occured_at).toLocaleString(),
          event_type: event.type,
          event_consequence: event.consequence.name,
          event_criticity: event.criticity.name,
          event_period_of_the_day: event.period_of_the_day,
          event_description: event.description,
          event_category: event.category,
          event_domain: event.domain,
          event_location: event.location,
          event_target: event.target,
          event_quality_informed_at: event.quality_informed_at
            ? new Date(event.quality_informed_at).toLocaleString()
            : null,
          event_regional_direction_informed_at:
            event.regional_direction_informed_at
              ? new Date(event.regional_direction_informed_at).toLocaleString()
              : null,
          event_authority_informed_at: event.authority_informed_at
            ? new Date(event.authority_informed_at).toLocaleString()
            : null,
          event_family_informed_at: event.family_informed_at
            ? new Date(event.family_informed_at).toLocaleString()
            : null,
          event_general_practitioner_informed_at:
            event.general_practitioner_informed_at
              ? new Date(
                  event.general_practitioner_informed_at,
                ).toLocaleString()
              : null,
          event_requalification_reason: event.requalification_reason,
          event_requalification_requested_at: event.requalification_requested_at
            ? new Date(event.requalification_requested_at).toLocaleString()
            : null,
          event_requalification_requester_username:
            event.requalification_requester_username,
        };
      });

      const worksheet = utils.json_to_sheet(transformedReports, { UTC: true });
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Reports");

      const SUBMITTER_USERNAME_INDEX = 4;
      const RESIDENCE_ONE_KEY_INDEX = 5;
      const REPORT_CREATED_AT_INDEX = 6;
      const REPORT_UPDATED_AT_INDEX = 7;
      const EVENT_OCCURED_AT_INDEX = 8;
      const EVENT_CONSEQUENCE_INDEX = 10;
      const EVENT_CRITICITY_INDEX = 11;
      const EVENT_PERIOD_OF_THE_DAY = 12;
      const EVENT_DESCRIPTION = 13;
      const EVENT_QUALITY_INFORMED_AT_INDEX = 18;
      const EVENT_REGIONAL_DIRECTION_INFORMED_AT_INDEX = 19;
      const EVENT_AUTHORITY_INFORMED_AT_INDEX = 20;
      const EVENT_FAMILY_INFORMED_AT_INDEX = 21;
      const EVENT_GENERAL_PRACTITIONER_INFORMED_AT_INDEX = 22;
      const EVENT_REQUALIFICATION_REASON_INDEX = 23;
      const EVENT_REQUALIFICATION_REQUESTED_AT_INDEX = 24;
      const EVENT_REQUALIFICATION_REQUESTER_USERNAME_INDEX = 25;

      /* create !cols array if it does not exist */
      if (!worksheet["!cols"]) worksheet["!cols"] = [];

      /* create column metadata object if it does not exist */
      if (!worksheet["!cols"][RESIDENCE_ONE_KEY_INDEX])
        worksheet["!cols"][RESIDENCE_ONE_KEY_INDEX] = { wch: 15 };

      if (!worksheet["!cols"][SUBMITTER_USERNAME_INDEX])
        worksheet["!cols"][SUBMITTER_USERNAME_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][REPORT_CREATED_AT_INDEX])
        worksheet["!cols"][REPORT_CREATED_AT_INDEX] = {
          wch: 24,
        };

      if (!worksheet["!cols"][REPORT_UPDATED_AT_INDEX])
        worksheet["!cols"][REPORT_UPDATED_AT_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][EVENT_OCCURED_AT_INDEX])
        worksheet["!cols"][EVENT_OCCURED_AT_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][EVENT_CONSEQUENCE_INDEX])
        worksheet["!cols"][EVENT_CONSEQUENCE_INDEX] = { wch: 50 };

      if (!worksheet["!cols"][EVENT_CRITICITY_INDEX])
        worksheet["!cols"][EVENT_CRITICITY_INDEX] = { wch: 50 };

      if (!worksheet["!cols"][EVENT_PERIOD_OF_THE_DAY])
        worksheet["!cols"][EVENT_PERIOD_OF_THE_DAY] = { wch: 13 };

      if (!worksheet["!cols"][EVENT_DESCRIPTION])
        worksheet["!cols"][EVENT_DESCRIPTION] = { wch: 50 };

      if (!worksheet["!cols"][EVENT_QUALITY_INFORMED_AT_INDEX])
        worksheet["!cols"][EVENT_QUALITY_INFORMED_AT_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][EVENT_REGIONAL_DIRECTION_INFORMED_AT_INDEX])
        worksheet["!cols"][EVENT_REGIONAL_DIRECTION_INFORMED_AT_INDEX] = {
          wch: 24,
        };

      if (!worksheet["!cols"][EVENT_AUTHORITY_INFORMED_AT_INDEX])
        worksheet["!cols"][EVENT_AUTHORITY_INFORMED_AT_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][EVENT_FAMILY_INFORMED_AT_INDEX])
        worksheet["!cols"][EVENT_FAMILY_INFORMED_AT_INDEX] = { wch: 24 };

      if (!worksheet["!cols"][EVENT_GENERAL_PRACTITIONER_INFORMED_AT_INDEX])
        worksheet["!cols"][EVENT_GENERAL_PRACTITIONER_INFORMED_AT_INDEX] = {
          wch: 24,
        };

      if (!worksheet["!cols"][EVENT_REQUALIFICATION_REASON_INDEX])
        worksheet["!cols"][EVENT_REQUALIFICATION_REASON_INDEX] = { wch: 50 };

      if (!worksheet["!cols"][EVENT_REQUALIFICATION_REQUESTED_AT_INDEX])
        worksheet["!cols"][EVENT_REQUALIFICATION_REQUESTED_AT_INDEX] = {
          wch: 24,
        };

      if (!worksheet["!cols"][EVENT_REQUALIFICATION_REQUESTER_USERNAME_INDEX])
        worksheet["!cols"][EVENT_REQUALIFICATION_REQUESTER_USERNAME_INDEX] = {
          wch: 24,
        };

      const range = utils.decode_range(worksheet["!ref"]);

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const cellRef = utils.encode_cell({ c: 6, r: rowNum }); // Column 'H', change 6 to the index of your column
        if (worksheet[cellRef]) {
          worksheet[cellRef].z = "yyyy-mm-dd hh:mm:ss"; // Set format for the cell
        }
      }

      writeFile(
        workbook,
        `reports_${moment(context?.loaded_at).format(
          "YYYY_MM_DD_HH_mm_ss",
        )}.xlsx`,
        {
          compression: true,
        },
      );
      return resolve();
    } catch (error) {
      return reject(error);
    }
  });
};
export { fetchReports, exportReports };
