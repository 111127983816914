import { useViewEditActionPlanMachine } from "../../../context/ViewEditActionPlanMachineContext";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs() {
  const [state, send] = useViewEditActionPlanMachine();
  const { t } = useTranslation();

  return (
    <div>
      <div className="">
        <nav className="flex space-x-4" aria-label="Tabs">
          <a
            key={"todo"}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              send("VIEW_TODO_ACTIONS");
            }}
            className={classNames(
              state.matches("loaded.todo")
                ? " bg-indigo-100  text-indigo-700"
                : "text-gray-400 hover:text-gray-700",
              "my-auto rounded-md px-2 py-2 text-sm font-semibold sm:px-3",
            )}
            aria-current={state.matches("loaded.todo") ? "page" : undefined}
          >
            <span className="mr-1 sm:mr-2">
              {t("action-plan.corrective_actions.filter.todo.label")}
            </span>
            <span
              className={classNames(
                state.matches("loaded.todo")
                  ? "bg-indigo-200 text-indigo-400"
                  : "bg-gray-200 text-gray-400",
                " inline-block  rounded-md  px-1.5 py-0.5 text-xs font-medium tabular-nums sm:px-2.5 sm:py-1.5",
              )}
            >
              {state.context?.filteredTodoCorrectiveActions?.length || 0}
            </span>
          </a>
          <a
            key={"ongoing"}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              send("VIEW_ONGOING_ACTIONS");
            }}
            className={classNames(
              state.matches("loaded.ongoing")
                ? "bg-indigo-100  text-indigo-700"
                : "text-gray-400 hover:text-gray-700",
              "my-auto rounded-md px-2 py-2 text-sm font-semibold sm:px-3",
            )}
            aria-current={state.matches("loaded.ongoing") ? "page" : undefined}
          >
            <span className="mr-1 sm:mr-2">
              {t("action-plan.corrective_actions.filter.ongoing.label")}
            </span>
            <span
              className={classNames(
                state.matches("loaded.ongoing")
                  ? "bg-indigo-200 text-indigo-400"
                  : "bg-gray-200 text-gray-400",
                " inline-block rounded-md px-1.5 py-0.5 text-xs font-medium tabular-nums sm:px-2.5 sm:py-1.5",
              )}
            >
              {state.context?.filteredOngoingCorrectiveActions?.length || 0}
            </span>
          </a>
          <a
            key={"completed"}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              send("VIEW_COMPLETE_ACTIONS");
            }}
            className={classNames(
              state.matches("loaded.complete")
                ? "bg-indigo-100  text-indigo-700"
                : "text-gray-400 hover:text-gray-700",
              "my-auto rounded-md px-2 py-2 text-sm font-semibold sm:px-3",
            )}
            aria-current={state.matches("loaded.complete") ? "page" : undefined}
          >
            <span className="mr-1 sm:mr-2">
              {t("action-plan.corrective_actions.filter.completed.label")}
            </span>
            <span
              className={classNames(
                state.matches("loaded.complete")
                  ? "bg-indigo-200 text-indigo-400"
                  : "bg-gray-200 text-gray-400",
                "  inline-block rounded-md px-1.5 py-0.5 text-xs font-medium tabular-nums sm:px-2.5 sm:py-1.5",
              )}
            >
              {state.context?.filteredCompleteCorrectiveActions?.length || 0}
            </span>
          </a>
        </nav>
      </div>
    </div>
  );
}
