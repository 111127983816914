const { actions, spawn, send } = require("xstate");
const { assign, log } = actions;
const { saveAuthentication } = require("../../commons/actions");

const saveCorrectiveActions = assign({
  corrective_actions: (context, event) => {
    return event.corrective_actions.map((correctiveAction) => {
      return {
        ...correctiveAction,
        ref: spawn(
          event?.spawnActorFunction({
            correctiveAction: correctiveAction,
            token: event.authentication.token,
            qmsToolApiUrl: event.qmsToolApiUrl,
            reportId: event.reportId,
          }),
        ),
      };
    });
  },
});

const setIndexOnFirstPage =
  require("../../commons/actions").setIndexOnFirstPage;
const incrementIndex = require("../../commons/actions").incrementIndex;
const decrementIndex = require("../../commons/actions").decrementIndex;
const resetPageIndex = require("../../commons/actions").resetPageIndex;

const resetCorrectiveActions = assign({
  corrective_actions: (context, event) => [],
});

const adaptIndex = assign({
  index: (context, event) => {
    console.log("corrective_actions length", context.corrective_actions.length);
    console.log("index", context.index);
    return context.index >= context.corrective_actions.length - 1
      ? context.index - 1
      : context.index;
  },
});

const notifyCurrentIndex = log((context, event) => {
  console.log("current index", context.index);
});

const removeCorrectiveAction = assign({
  corrective_actions: (context, event) => {
    const correctiveAction = context.corrective_actions.find(
      (correctiveAction) => correctiveAction.id === event.id,
    );
    correctiveAction.ref.stop();
    return context.corrective_actions.filter(
      (correctiveAction) => correctiveAction.id !== event.id,
    );
  },
});

const updateCorrectiveAction = assign({
  corrective_actions: (context, event) => {
    return context.corrective_actions.map((correctiveAction) => {
      return correctiveAction.id === event.correctiveAction.id
        ? {
            ...correctiveAction,
            ...event.correctiveAction,
            ref: correctiveAction.ref,
          }
        : correctiveAction;
    });
  },
});

const sendDeleteToCurrentActor = send(
  { type: "DELETE" },
  { to: (context) => context.corrective_actions[context.index].ref },
);
const sendUpdateToCurrentActor = send(
  { type: "UPDATE" },
  { to: (context) => context.corrective_actions[context.index].ref },
);

const sendRetryToCurrentActor = send(
  { type: "RETRY" },
  { to: (context) => context.corrective_actions[context.index].ref },
);

const sendCancelToCurrentActor = send(
  { type: "CANCEL" },
  { to: (context) => context.corrective_actions[context.index].ref },
);

const sendTokenToSpawnedActors = (context, event) => {
  context.corrective_actions.forEach((correctiveAction) => {
    console.log("updating actor's token");
    correctiveAction.ref.send({
      type: "UPDATE_BEARER_TOKEN",
      data: event.data,
    });
  });
};

// const saveReportId = assign({
//   reportId: (context, event) => {
//     console.log("event reportid", event);
//     return event.reportId;
//   },
// });

// const saveApiUrl = assign({
//   qmsToolApiUrl: (context, event) => {
//     return event.qmsToolApiUrl;
//   },
// });

const setIndex = assign({
  index: (context, event) => {
    if (event?.correctiveAction?.id) {
      const caIdx = context.corrective_actions.findIndex(
        (ca) => ca.id === event.correctiveAction.id,
      );
      return caIdx > -1 ? caIdx : 0;
    } else return 0;
  },
});

const saveCurrentCorectiveAction = assign({
  currentCorrectiveAction: (context, event) => {
    return event?.correctiveAction;
  },
});

const flagChanges = require("../../commons/actions").flagChanges;
const unflagChanges = require("../../commons/actions").unflagChanges;
const saveToken = require("../../commons/actions").saveToken;
const { saveReportId, saveApiUrl } = require("../../commons/actions");

export {
  saveAuthentication,
  saveCorrectiveActions,
  setIndexOnFirstPage,
  incrementIndex,
  decrementIndex,
  resetCorrectiveActions,
  resetPageIndex,
  removeCorrectiveAction,
  updateCorrectiveAction,
  sendDeleteToCurrentActor,
  saveReportId,
  saveApiUrl,
  adaptIndex,
  flagChanges,
  unflagChanges,
  notifyCurrentIndex,
  sendTokenToSpawnedActors,
  saveToken,
  setIndex,
  saveCurrentCorectiveAction,
  sendUpdateToCurrentActor,
  sendRetryToCurrentActor,
  sendCancelToCurrentActor,
};
