import Breadcrumb from "./Breadcrumb";
import Activity from "./Activity";
import Tasks from "../shared/Tasks";
import ReportView from "./ReportView";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEventNavigatorMachine } from "../../../context/EventNavigatorMachineContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SequenceOfEvents from "./SequenceOfEVents";
import FiveWhys from "./FiveWhys";
import { EditSeqOfEventsMachineProvider } from "../../../context/EditSeqOfEventsMachineContext";
import { EditFiveWhysMachineProvider } from "../../../context/EditFiveWhysMachineContext";
import { EditCorrectionsMachineProvider } from "../../../context/EditCorrectionMachineContext";
import RootCauseAnalysis from "../../ui/edit-five-whys/FiveWhysAnalysis";
import ActionPlan from "./ActionPlan";
import ImmediateActions from "./ImmediateActions";
import { EditCorrectiveActionsMachineProvider } from "../../../context/EditCorrectiveActionsMachineContext";
import { FlagSectionForReviewMachineProvider } from "../../../context/FlagSectionForReviewMachineContext";
import { ReviewFiveWhysMachineProvider } from "../../../context/ReviewFiveWhysMachineContext";
import ReviewFiveWhysPanel from "../review-five-whys/ReviewFiveWhysPanel";
import { ReviewCorrectionsMachineProvider } from "../../../context/ReviewCorrectionsMachineContext";
import { ReviewSeqOfEventsMachineProvider } from "../../../context/ReviewSeqOfEventsMachineContext";
import { ReviewCorrectiveActionsMachineProvider } from "../../../context/ReviewCorrectiveActionsMachineContext";
import NotFound from "../shared/NotFound";
import AccessDenied from "../shared/AccessDenied";
import ServerError from "../shared/ServerError";
import { RequalifyReportMachineProvider } from "../../../context/RequalifyReportMachineContext";
import RequalifyReportPanel from "../requalify-report/RequalifyReportPanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ showBreadcrumb = true }) {
  const [state, send] = useViewEditReportMachine();
  const [eventNavigatorState, eventNavigatorSend] = useEventNavigatorMachine();

  const urlReportId = useParams()?.id;
  const { t } = useTranslation();

  useEffect(() => {
    if (state.matches("processing.initialized.idle") && urlReportId) {
      send("LOAD_REPORT", { data: urlReportId });
    } else if (
      (state.matches("processing.initialized.active") ||
        state.matches("processing.initialized.error")) &&
      urlReportId
    ) {
      if (state.context?.report?.id !== parseInt(urlReportId)) {
        send("LOAD_REPORT", { data: urlReportId });
        console.log("LOAD_REPORT");
      }
    }
  }, []);

  return (
    <>
      {/* {(state.matches("processing.initialized.active") ||
        state.matches("processing.initialized.loading")) && (     */}
      {state.matches("processing.initialized.active") && (
        <>
          <div
            className={classNames(
              "hidden items-center justify-center",
              state.context?.report?.obfuscated ? "print:flex" : "print:hidden",
            )}
          >
            <h1 className="text-center text-4xl font-bold">
              {t("print.report.forbidden")}
            </h1>
          </div>
          <div
            className={classNames(
              state.context?.report?.obfuscated ? "print:hidden" : "",
            )}
          >
            {showBreadcrumb && (
              <div className="mb-10 print:hidden">
                <Breadcrumb
                  reference={state.context?.report?.reference}
                  reportId={state.context?.report?.id}
                  loading={state.matches("processing.initialized.loading")}
                />
              </div>
            )}

            {!state.matches("processing.initialized.loading") && (
              <div className="flex flex-wrap">
                <div className="w-full xl:w-3/5">
                  <FlagSectionForReviewMachineProvider>
                    <RequalifyReportMachineProvider>
                      <ReportView report={state.context?.report} />
                      <RequalifyReportPanel></RequalifyReportPanel>
                    </RequalifyReportMachineProvider>
                    <EditCorrectionsMachineProvider>
                      <ReviewCorrectionsMachineProvider>
                        <ImmediateActions />
                      </ReviewCorrectionsMachineProvider>
                      {/* <DebugPanel /> */}
                    </EditCorrectionsMachineProvider>
                    <EditSeqOfEventsMachineProvider>
                      <ReviewSeqOfEventsMachineProvider>
                        <SequenceOfEvents />
                      </ReviewSeqOfEventsMachineProvider>
                    </EditSeqOfEventsMachineProvider>
                    <EditFiveWhysMachineProvider>
                      <ReviewFiveWhysMachineProvider>
                        <FiveWhys />
                        <RootCauseAnalysis />
                        <ReviewFiveWhysPanel />
                      </ReviewFiveWhysMachineProvider>
                    </EditFiveWhysMachineProvider>
                    <EditCorrectiveActionsMachineProvider>
                      <ReviewCorrectiveActionsMachineProvider>
                        <ActionPlan />
                      </ReviewCorrectiveActionsMachineProvider>
                    </EditCorrectiveActionsMachineProvider>
                  </FlagSectionForReviewMachineProvider>
                </div>
                <div className=" w-full   xl:w-2/5  xl:pl-9">
                  <div className="mt-24 max-w-xl xl:mt-0 ">
                    <Tasks data={state.context?.report?.tasks} />
                  </div>
                  <div className="mt-16 max-w-xl xl:mt-6">
                    <Activity
                      data={state.context?.report?.activities}
                      obfuscated={state.context?.report?.obfuscated}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <>
        {state.matches("processing.initialized.loading") && (
          <div className="flex h-screen">
            <div className="m-auto">
              <div className="flex flex-row gap-2">
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700"></div>
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.3s]"></div>
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.5s]"></div>
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {state.matches("processing.initialized.error.404 not found") && (
          <NotFound />
        )}
      </>
      <>
        {state.matches("processing.initialized.error.401 access denied") && (
          <AccessDenied status={state.context.error?.response?.status} />
        )}
      </>
      <>
        {state.matches("processing.initialized.error.500 server error") && (
          <ServerError />
        )}
      </>
    </>
  );
}
