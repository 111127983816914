import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { RequalifyReportMachine as parentMachine } from "../machines/requalify-event/requalify-event-machine";

const RequalifyReportMachineContext = createContext();

export function useRequalifyReportMachine() {
  const context = useContext(RequalifyReportMachineContext);

  if (!context) {
    throw new Error(
      "useRequalifyReportMachine must be used within a RequalifyReportMachineProvider",
    );
  }

  return context;
}

export function RequalifyReportMachineProvider({ children }) {
  const requalifyReportMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMS_TOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <RequalifyReportMachineContext.Provider value={requalifyReportMachine}>
      {children}
    </RequalifyReportMachineContext.Provider>
  );
}
