const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const ViewEfficiencyMetricsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDcCWYDuBaMAzXqAxugHaECeWAtmAC4BORsAdKhADZgDEAMgPIBBACIBtAAwBdRKAAOAe1ipaqOSWkgAHogBMARgCczMQBYAzGLH6AbAHYAHDavbTVgDQhyiXVcNXj2uysxAFZjYItjfWMAX2j3NEwcfCJSCmo6RkIWdjkAQwhUEiguCFUwVhJkOQBrctw6QgALJIJiMDJKGgYmcSkkEHlFZVV1LQRtEOYncO1nPR8xXXdPBGNbZmsbMVM7XWDtfV1jGLiQBOw8VtTOjKZmHPzC4rB6ejl6Zhl2XNpcd6pmPVaE0Wil2mkuplYL11IMlCo1P0xsFAsxnHZ9DZjEd9NpjqZlogXIYxD5TLo7MEfFsDLF4ugLsk2h10t0svc8hBIFwAEoAUX4whh-Thw0RoDGWF0AWY5N0uhci2CNmV20JCCchm8VgpwWVVlMB30dLODNBzIht3ZDy5EF4glEklhCnhIyRiClDg2uN0IWVZksYXVZm0zBx+kWYjsATsERN53N11ZUOYLze9F5fIAKjyAJrC2QusWjHR2YxonYBaXBdU2GyGfx2SsOUzBVvaeNmy5glmQu427kAVQACkIBFm+QB9ABCfIE-J5k6zfAA0nyAHIFgZFhEl1byqZYlU7LHaA3edVWXYbDF6rEK+XS2KnEhyLnwfoJ7sWm5sj+FoZd3dBAsGcYJvT0P0sXMKIaw8D1zGYPUxBseVtHrcwDh2TtEm-JM+3ZNhOGdQC3QlHR9HA4xtisHUFTlWi3HghAqWYOs9ECUlDjxSirBwxkrnBX8UxtJ4SNdcVNC8RDwgjGwJnrMtsW0dVTFMctDU0yizyOdT+MTITk37TlIHE4tgICcDgnlKMHCsFFWyYlY-HA+szD2BxjECXZ9LwwyCJYNN3jMoDyIQX0xFDFwJnQtszxcAlmPU0xmGjAw1LCWxAl0Z9oiAA */
    predictableActionArguments: true,
    context: {
      qmsToolApiUrl: undefined,
      authentication: {
        token: undefined,
      },
      loaded_at: undefined,
      efficiency_metrics: {
        urgent_reports: null,
        non_urgent_reports_by_status: null,
      },
    },

    id: "view-efficiency-metrics",

    states: {
      idle: {
        on: {
          LOAD: {
            target: "loading",
            cond: "validLoadRequest",
            actions: "saveLoadRequest",
          },
        },
      },

      loading: {
        invoke: {
          src: "fetchEfficiencyMetrics",
          id: "fetch-efficiency-metrics",

          onDone: {
            target: "loaded",
            actions: ["saveEfficiencyMetrics", "updateLastRefreshedTimestamp"],
          },

          onError: "error",
        },
      },

      loaded: {
        on: {
          RELOAD: "loading",

          LOAD: {
            target: "loading",
            cond: "validLoadRequest",
            actions: "saveLoadRequest",
          },

          UPDATE_BEARER_TOKEN: {
            target: "loaded",
            internal: true,
            cond: "validToken",
            actions: "saveToken",
          },
        },
      },

      error: {
        on: {
          RETRY: "loading",
        },

        entry: "saveError",
      },
    },

    initial: "idle",
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { ViewEfficiencyMetricsMachine };
