import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { Widget } from "./Widget";
import TimeAgo from "../../ui/shared/TimeAgo";
import { useViewEfficiencyMetricsMachine } from "../../../context/ViewEfficiencyMetricsMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEventNavigatorMachine } from "../../../context/EventNavigatorMachineContext";

import { useAuth } from "../../../context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const { t } = useTranslation();
  const [state, send] = useViewEfficiencyMetricsMachine();
  const [viewEditReportState, viewEditReportSend, viewEditReportService] =
    useViewEditReportMachine();
  const refViewEdit = useViewEditReportMachine();
  const [eventNavigatorState, eventNavigatorSend] = useEventNavigatorMachine();
  const { keycloak, profile } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(state);
    if (state.matches("idle")) {
      send("LOAD", {
        data: {
          authentication: {
            token: keycloak.token,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log(
      "Token is refreshing : send token to efficiency tracker machine",
    );
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);
  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            <Trans
              i18nKey="dashboard.greeting"
              values={{ username: profile?.firstName }}
            />
          </h1>

          <p className="mt-2 text-xs text-gray-500">
            {t("reports.label.last_refreshed_at")}{" "}
            <TimeAgo isoString={state?.context?.loaded_at} />
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {state.matches("loading") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                "cursor-not-allowed",
              )}
              disabled={true}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.status.loading")}
            </button>
          )}
          {state.can("RELOAD") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RELOAD");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.refresh")}
            </button>
          )}
          {state.can("RETRY") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RETRY");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.retry")}
            </button>
          )}
        </div>
      </div>
      <ul
        role="list"
        className="my-12 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
      >
        {state?.context?.efficiency_metrics?.urgent_reports?.count > 0 &&
          state?.context?.efficiency_metrics?.urgent_reports?.percentage !==
            100 && (
            <li className="overflow-hidden rounded-xl border border-gray-200  shadow-lg">
              <div
                className={classNames(
                  "flex items-center gap-x-4 border-b border-gray-900/5  px-6 py-3",
                  "bg-red-500 ",
                )}
              >
                <div
                  className={classNames(
                    "text-base font-medium leading-6",
                    "text-gray-50",
                  )}
                >
                  {t("dashboard.efficiency.widgets.reports.urgent")}
                </div>
                <div
                  className={classNames(
                    "ml-auto mt-1 rounded-xl px-4 py-1.5 text-right text-xl font-semibold tracking-tight",
                    "bg-red-800 text-gray-50",
                  )}
                >
                  {state?.context?.efficiency_metrics?.urgent_reports?.count}
                </div>
              </div>

              <dl className=" divide-y divide-gray-200 bg-slate-50 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4  py-3">
                  <dt className="text-gray-500">
                    <span className="mr-6">
                      {t("dashboard.efficiency.widgets.label.completedTasks")}
                    </span>
                    <span
                      className={classNames(
                        "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1 text-xs font-medium",
                        "bg-gray-400 text-white",
                      )}
                    >
                      {
                        state?.context?.efficiency_metrics?.urgent_reports
                          ?.percentage
                      }
                      %
                    </span>
                  </dt>
                  <dd className="font-semibold tracking-wide text-gray-900">
                    {
                      state?.context?.efficiency_metrics?.urgent_reports
                        ?.completed_tasks
                    }
                    /
                    {
                      state?.context?.efficiency_metrics?.urgent_reports
                        ?.total_tasks
                    }
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">
                    {t("dashboard.efficiency.widgets.label.daysOfDelay")}
                  </dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-semibold text-gray-900">
                      {t("shared.component.time.day", {
                        count:
                          state?.context?.efficiency_metrics?.urgent_reports
                            ?.days_since_oldest_expired,
                      })}
                    </div>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <div className="text-blue-400">
                    <a
                      className={classNames(
                        " cursor-pointer  text-sm text-blue-500 hover:underline",
                      )}
                      aria-current="page"
                      onClick={(e) => {
                        e.preventDefault();

                        const reportIdentifiers =
                          state?.context?.efficiency_metrics?.urgent_reports?.report_ids.map(
                            (elm) => elm.id,
                          );

                        eventNavigatorSend({
                          type: "START",
                          data: {
                            reportIdentifiers: reportIdentifiers,
                            status: "URGENT",
                            viewReportActorRef: refViewEdit,
                          },
                        });
                        navigate(`/reports/navigator`);
                      }}
                    >
                      <span className="max-w-fit">
                        {t("dashboard.efficiency.widgets.mainAction")}

                        <ChevronRightIcon
                          className={classNames(
                            "-mt-[0.1rem] inline-block h-5 w-5",
                          )}
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </dl>
            </li>
          )}

        {state?.context?.efficiency_metrics?.non_urgent_reports_by_status
          ?.DRAFT &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.DRAFT?.count > 0 &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.DRAFT?.percentage !== 100 && (
            <Widget
              header={t("dashboard.efficiency.widgets.reports.draft")}
              count={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT.count
              }
              percentage={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT.percentage
              }
              completedTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT.completed_tasks
              }
              totalTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT.total_tasks
              }
              delay={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT.days_before_oldest_expiry
              }
              mainActionCallback={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .DRAFT?.report_ids
                  ? (e) => {
                      e.preventDefault();

                      const reportIdentifiers =
                        state?.context?.efficiency_metrics?.non_urgent_reports_by_status?.DRAFT.report_ids.map(
                          (elm) => elm.id,
                        );

                      eventNavigatorSend({
                        type: "START",
                        data: {
                          reportIdentifiers: reportIdentifiers,
                          status: "DRAFT",
                          viewReportActorRef: refViewEdit,
                        },
                      });
                      navigate(`/reports/navigator`);
                    }
                  : null
              }
            />
          )}
        {state?.context?.efficiency_metrics?.non_urgent_reports_by_status
          ?.PENDING &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.PENDING?.count > 0 && (
            <Widget
              header={t("dashboard.efficiency.widgets.reports.pending")}
              count={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .PENDING.count
              }
              percentage={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .PENDING.percentage
              }
              completedTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .PENDING.completed_tasks
              }
              totalTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .PENDING.total_tasks
              }
              delay={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .PENDING.days_before_oldest_expiry
              }
              mainActionCallback={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  ?.PENDING?.report_ids
                  ? (e) => {
                      e.preventDefault();

                      const reportIdentifiers =
                        state?.context?.efficiency_metrics?.non_urgent_reports_by_status?.PENDING.report_ids.map(
                          (elm) => elm.id,
                        );

                      eventNavigatorSend({
                        type: "START",
                        data: {
                          reportIdentifiers: reportIdentifiers,
                          status: "PENDING",
                          viewReportActorRef: refViewEdit,
                        },
                      });
                      navigate(`/reports/navigator`);
                    }
                  : null
              }
            />
          )}
        {state?.context?.efficiency_metrics?.non_urgent_reports_by_status
          ?.SUBMITTED &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.SUBMITTED?.count > 0 &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.SUBMITTED?.percentage !== 100 && (
            <Widget
              header={t("dashboard.efficiency.widgets.reports.submitted")}
              count={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .SUBMITTED.count
              }
              percentage={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .SUBMITTED.percentage
              }
              completedTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .SUBMITTED.completed_tasks
              }
              totalTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .SUBMITTED.total_tasks
              }
              delay={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .SUBMITTED.days_before_oldest_expiry
              }
              mainActionCallback={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  ?.SUBMITTED?.report_ids
                  ? (e) => {
                      e.preventDefault();

                      const reportIdentifiers =
                        state?.context?.efficiency_metrics?.non_urgent_reports_by_status?.SUBMITTED.report_ids.map(
                          (elm) => elm.id,
                        );

                      eventNavigatorSend({
                        type: "START",
                        data: {
                          reportIdentifiers: reportIdentifiers,
                          status: "SUBMITTED",
                          viewReportActorRef: refViewEdit,
                        },
                      });
                      navigate(`/reports/navigator`);
                    }
                  : null
              }
            />
          )}
        {state?.context?.efficiency_metrics?.non_urgent_reports_by_status
          ?.VALIDATED &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.VALIDATED?.count > 0 && (
            <Widget
              header={t("dashboard.efficiency.widgets.reports.validated")}
              count={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .VALIDATED.count
              }
              percentage={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .VALIDATED.percentage
              }
              completedTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .VALIDATED.completed_tasks
              }
              totalTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .VALIDATED.total_tasks
              }
              delay={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .VALIDATED.days_before_oldest_expiry
              }
              mainActionCallback={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  ?.VALIDATED?.report_ids
                  ? (e) => {
                      e.preventDefault();

                      const reportIdentifiers =
                        state?.context?.efficiency_metrics?.non_urgent_reports_by_status?.VALIDATED.report_ids.map(
                          (elm) => elm.id,
                        );

                      eventNavigatorSend({
                        type: "START",
                        data: {
                          reportIdentifiers: reportIdentifiers,
                          status: "VALIDATED",
                          viewReportActorRef: refViewEdit,
                        },
                      });
                      navigate(`/reports/navigator`);
                    }
                  : null
              }
            />
          )}
        {state?.context?.efficiency_metrics?.non_urgent_reports_by_status
          ?.CLOSED &&
          state?.context?.efficiency_metrics?.non_urgent_reports_by_status
            ?.CLOSED?.count > 0 && (
            <Widget
              header={t("dashboard.efficiency.widgets.reports.closed")}
              count={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .CLOSED.count
              }
              percentage={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .CLOSED.percentage
              }
              completedTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .CLOSED.completed_tasks
              }
              totalTasks={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .CLOSED.total_tasks
              }
              delay={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  .CLOSED.days_before_oldest_expiry
              }
              mainActionCallback={
                state?.context?.efficiency_metrics.non_urgent_reports_by_status
                  ?.CLOSED?.report_ids
                  ? (e) => {
                      e.preventDefault();

                      const reportIdentifiers =
                        state?.context?.efficiency_metrics?.non_urgent_reports_by_status?.CLOSED.report_ids.map(
                          (elm) => elm.id,
                        );

                      eventNavigatorSend({
                        type: "START",
                        data: {
                          reportIdentifiers: reportIdentifiers,
                          status: "CLOSED",
                          viewReportActorRef: refViewEdit,
                        },
                      });
                      navigate(`/reports/navigator`);
                    }
                  : null
              }
            />
          )}
      </ul>
    </>
  );
}
