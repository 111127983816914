import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ReviewCorrectiveActionsMachine as parentMachine } from "../machines/review-corrective-actions/review-corrective-actions-machine";

const ReviewCorrectiveActionsMachineContext = createContext();

export function useReviewCorrectiveActionsMachine() {
  const context = useContext(ReviewCorrectiveActionsMachineContext);

  if (!context) {
    throw new Error(
      "useReviewCorrectiveActionsMachine must be used within a ReviewCorrectiveActionsMachineProvider",
    );
  }

  return context;
}

export function ReviewCorrectiveActionsMachineProvider({ children }) {
  const reviewCorrectiveActionsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ReviewCorrectiveActionsMachineContext.Provider
      value={reviewCorrectiveActionsMachine}
    >
      {children}
    </ReviewCorrectiveActionsMachineContext.Provider>
  );
}
