import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ViewEditActionPlanMachine as parentMachine } from "../machines/view-edit-action-plan/view-edit-action-plan-machine";

const filters = [
  {
    id: "domain",
    getter: (action) => {
      return action.domain?.name || action?.report?.event?.domain?.name || "";
    },
    i18nKey: "action-plan.corrective_actions.table.headers.domain",
    i18nValuePrefixKey: "shared.type.domain.",
    options: [],
  },
  {
    id: "source",
    getter: (action) => {
      return action.source?.name || "";
    },
    i18nKey: "action-plan.corrective_actions.table.headers.source",
    i18nValuePrefixKey: "shared.type.correctiveAction.source.",
    options: [],
  },
  {
    id: "priority",
    getter: (action) => {
      return action.priority?.name || "";
    },
    i18nKey: "action-plan.corrective_actions.table.headers.priority",
    i18nValuePrefixKey: "shared.type.correctiveAction.priority.",
    options: [],
  },
];

const ViewEditActionPlanMachineContext = createContext();

export function useViewEditActionPlanMachine() {
  const context = useContext(ViewEditActionPlanMachineContext);
  if (!context) {
    throw new Error(
      "useViewEditActionPlanMachine must be used within a ViewEditActionPlanMachineProvider",
    );
  }
  return context;
}

export function ViewEditActionPlanMachineProvider({ children }) {
  const viewEditActionPlanMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
      filters: filters,
    },
  });

  return (
    <ViewEditActionPlanMachineContext.Provider
      value={viewEditActionPlanMachine}
    >
      {children}
    </ViewEditActionPlanMachineContext.Provider>
  );
}
