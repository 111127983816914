const { actions } = require("xstate");
const { assign } = actions;
const {
  flagChanges,
  unflagChanges,
  saveToken,
} = require("../../commons/actions");

const saveDescription = assign({
  description: (context, event) => event.data,
});

const saveStartRequest = assign({
  action_url: (context, event) => {
    console.log("saveStartRequest");
    return event.data?.action_url;
  },
  authentication: (context, event) => {
    return event.data?.authentication;
  },
});

const resetDescription = assign({
  description: (context, event) => undefined,
});

export {
  saveDescription,
  saveStartRequest,
  resetDescription,
  flagChanges,
  unflagChanges,
  saveToken,
};
