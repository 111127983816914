const { actions } = require("xstate");
const { assign } = actions;
const {
  flagChanges,
  unflagChanges,
  saveToken,
} = require("../../../commons/actions");

const saveStartRequest = assign({
  authentication: (context, event) => {
    return event.data?.authentication;
  },
});

const saveResidenceOneKey = assign({
  residenceOneKey: (context, event) => {
    return event.data;
  },
});

const saveActionPlan = assign({
  actionPlan: (context, event) => {
    return event.data;
  },
});
const saveActionPlansToBeCreated = assign({
  actionPlansToBeCreated: (context, event) => {
    return event.data;
  },
});

const saveOneKey = assign({
  createRequest: (context, event) => {
    return {
      ...context?.createRequest,
      residenceOneKey: event.data,
    };
  },
});

const resetCreateRequest = assign({
  createRequest: (context, event) => {
    return {};
  },
});

export {
  flagChanges,
  unflagChanges,
  saveToken,
  saveStartRequest,
  saveResidenceOneKey,
  saveActionPlan,
  saveActionPlansToBeCreated,
  saveOneKey,
  resetCreateRequest,
};
