import { useListReportsMachine } from "../../../context/ListReportsMachineContext";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs() {
  const [state, send] = useListReportsMachine();
  const { t } = useTranslation();

  return (
    <div>
      <div className="">
        <nav className="flex space-x-4" aria-label="Tabs">
          <a
            key={"active"}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              send("VIEW_ACTIVE_REPORTS");
            }}
            className={classNames(
              state.matches("loaded.active")
                ? " bg-indigo-100  text-indigo-700"
                : "text-gray-400 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-semibold",
            )}
            aria-current={state.matches("loaded.active") ? "page" : undefined}
          >
            {t("reports.filter.active.label")}
            <span
              className={classNames(
                state.matches("loaded.active")
                  ? "bg-indigo-200 text-indigo-400"
                  : "bg-gray-200 text-gray-400",
                "ml-2  inline-block rounded-md px-2.5 py-1.5 text-xs font-medium",
              )}
            >
              {state.context.filteredActiveReports?.length}
            </span>
          </a>
          <a
            key={"inactive"}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              send("VIEW_INACTIVE_REPORTS");
            }}
            className={classNames(
              state.matches("loaded.inactive")
                ? "bg-indigo-100  text-indigo-700"
                : "text-gray-400 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-semibold",
            )}
            aria-current={state.matches("loaded.inactive") ? "page" : undefined}
          >
            {t("reports.filter.inactive.label")}
            <span
              className={classNames(
                state.matches("loaded.inactive")
                  ? "bg-indigo-200 text-indigo-400"
                  : "bg-gray-200 text-gray-400",
                "ml-2  inline-block rounded-md px-2.5 py-1.5 text-xs font-medium",
              )}
            >
              {state.context.filteredInactiveReports?.length}
            </span>
          </a>
        </nav>
      </div>
    </div>
  );
}
