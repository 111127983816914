const { actions } = require("xstate");
const { assign } = actions;
const { saveToken, saveError } = require("../../commons/actions");

const saveReport = assign({
  report: (context, event) => {
    return event.data;
  },
});

const savePeriodOfTheDay = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      period_of_the_day: event.data,
    };
  },
});

const saveDescription = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      description: event.data,
    };
  },
});

const saveTarget = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      target: event.data,
    };
  },
});

const saveCategory = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      category: event.data,
    };
  },
});

const saveDomain = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      domain: event.data,
    };
  },
});

const saveLocation = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      location: event.data,
    };
  },
});

const saveAuthorityInformedAt = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      authority_informed_at: event.data,
    };
  },
});

const saveFamilyInformedAt = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      family_informed_at: event.data,
    };
  },
});

const saveGeneralPractitionerInformedAt = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      general_practitioner_informed_at: event.data,
    };
  },
});

const saveRegionDirectionInformedAt = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      regional_direction_informed_at: event.data,
    };
  },
});

const resetChangeSet = assign({
  changeSet: (context, event) => {
    return undefined;
  },
});

export {
  saveReport,
  saveToken,
  savePeriodOfTheDay,
  resetChangeSet,
  saveDescription,
  saveTarget,
  saveCategory,
  saveDomain,
  saveLocation,
  saveAuthorityInformedAt,
  saveFamilyInformedAt,
  saveRegionDirectionInformedAt,
  saveGeneralPractitionerInformedAt,
  saveError,
};
