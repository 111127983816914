const { actions } = require("xstate");
const { assign } = actions;

const saveLoadRequest = assign({
  loadRequest: (context, event) => {
    return event.data;
  },
  corrections: (context, event) => {
    return event.data?.corrections;
  },
  authentication: (context, event) => {
    return event.data.authentication;
  },
});

const resetLoadRequest = assign({
  loadRequest: (context, event) => {
    return undefined;
  },
  corrections: (context, event) => {
    return [];
  },
});

const saveDescription = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      description: event.data,
    };
  },
});

const saveReturnedCorrections = assign({
  corrections: (context, event) => {
    return event.data;
  },
});

const resetChangeSet = require("../../commons/actions/index").resetChangeSet;

const flagChanges = require("../../commons/actions/index").flagChanges;
const unflagChanges = require("../../commons/actions/index").unflagChanges;
const saveToken = require("../../commons/actions/index").saveToken;

export {
  saveLoadRequest,
  resetChangeSet,
  resetLoadRequest,
  saveDescription,
  saveReturnedCorrections,
  flagChanges,
  unflagChanges,
  saveToken,
};
