const { actions, spawn, send } = require("xstate");
const { assign, log } = actions;
const {
  createReviewSeqOfEventsDetailMachine,
} = require("../../review-seq-of-events-detail/review-seq-of-events-detail-machine");

const saveAuthentication = assign({
  authentication: (context, event) => {
    console.log("context", context);
    console.log("event", event);
    return {
      ...context.authentication,
      ...event.authentication,
    };
  },
});
const saveSeqOfEvents = assign({
  seq_of_events: (context, event) => {
    return event.seq_of_events.map((seq_of_events_detail) => {
      return {
        ...seq_of_events_detail,
        ref: spawn(
          createReviewSeqOfEventsDetailMachine({
            seq_of_events_detail: seq_of_events_detail,
            token: event.authentication.token,
            qmsToolApiUrl: event.qmsToolApiUrl,
            reportId: event.reportId,
          }),
        ),
      };
    });
  },
});

const saveMaxDate = assign({
  maxDate: (context, event) => {
    return event.maxDate;
  },
});

const setIndexOnFirstPage = assign({
  index: (context, event) => 0,
});

const incrementIndex = assign({
  index: (context, event) => {
    return context.index + 1;
  },
});

const decrementIndex = assign({
  index: (context, event) => {
    return context.index - 1;
  },
});

const resetSeqOfEvents = assign({
  seq_of_events: (context, event) => [],
});

const resetPageIndex = assign({
  index: (context, event) => null,
});

const adaptIndex = assign({
  index: (context, event) => {
    return context.index >= context.seq_of_events.length - 1
      ? context.index - 1
      : context.index;
  },
});

const notifyCurrentIndex = log((context, event) => {
  console.log("current index", context.index);
});

const removeSeqOfEventsDetail = assign({
  seq_of_events: (context, event) => {
    const seq_of_events_detail = context.seq_of_events.find(
      (seq_of_events_detail) => seq_of_events_detail.id === event.id,
    );

    console.log("seq_of_events_detail", seq_of_events_detail);
    seq_of_events_detail.ref.stop();
    return context.seq_of_events.filter(
      (seq_of_events_detail) => seq_of_events_detail.id !== event.id,
    );
  },
});

const updateSeqOfEventsDetail = assign({
  seq_of_events: (context, event) => {
    return context.seq_of_events.map((seq_of_events_detail) => {
      return seq_of_events_detail.id === event.seq_of_events_detail.id
        ? {
            ...seq_of_events_detail,
            ...event.seq_of_events_detail,
            ref: seq_of_events_detail.ref,
          }
        : seq_of_events_detail;
    });
  },
});

const sendDeleteToCurrentActor = send(
  { type: "DELETE" },
  { to: (context) => context.seq_of_events[context.index].ref },
);

const sendUpdateToCurrentActor = send(
  { type: "UPDATE" },
  { to: (context) => context.seq_of_events[context.index].ref },
);

const sendTokenToSpawnedActors = (context, event) => {
  context.seq_of_events.forEach((actor) => {
    console.log("updating actor's token");
    actor.ref.send({
      type: "UPDATE_BEARER_TOKEN",
      data: event.data,
    });
  });
};

const saveReportId = assign({
  reportId: (context, event) => {
    console.log("event reportid", event);
    return event.reportId;
  },
});

const saveApiUrl = assign({
  qmsToolApiUrl: (context, event) => {
    return event.qmsToolApiUrl;
  },
});

const flagChanges = require("../../commons/actions").flagChanges;
const unflagChanges = require("../../commons/actions").unflagChanges;
const saveToken = require("../../commons/actions").saveToken;
export {
  saveAuthentication,
  saveSeqOfEvents,
  setIndexOnFirstPage,
  incrementIndex,
  decrementIndex,
  resetSeqOfEvents,
  resetPageIndex,
  removeSeqOfEventsDetail,
  updateSeqOfEventsDetail,
  sendDeleteToCurrentActor,
  saveReportId,
  saveApiUrl,
  adaptIndex,
  flagChanges,
  unflagChanges,
  notifyCurrentIndex,
  sendUpdateToCurrentActor,
  saveMaxDate,
  sendTokenToSpawnedActors,
  saveToken,
};
