import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import { useRequalifyReportMachine } from "../../../../../context/RequalifyReportMachineContext";
import GenericSelectMenu from "../../../GenericSelectMenu";
import StepsContainer from "../../../container";
import { useTranslation } from "react-i18next";

export default function Step1() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const [requalifyReportState, requalifyReportSend] =
    useRequalifyReportMachine();
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div>
          <GenericSelectMenu
            data={createReportState.context?.consequenceTypes}
            selectedItem={
              requalifyReportState.context?.changeSet?.consequence.name
            }
            noSelectionText={t(
              "requalifyEvent.steps.step1.form.placeholder.consequence",
            )}
            onItemSelected={(element) => {
              requalifyReportSend({
                type: "CONSEQUENCE_SELECTED",
                data: element,
              });
            }}
            label={t("requalifyEvent.steps.step1.form.label.consequence")}
            useNameForKey={true}
            useNameForValue={true}
            i18nPrefixKey="shared.type.consequence."
            autoFocus={true}
          />
        </div>
      </StepsContainer>
    </>
  );
}
