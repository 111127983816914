function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Statement({ active, value, obfuscated = false }) {
  return (
    <div
      className={classNames(
        " block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-sm text-gray-500",
        active ? "ring-2 ring-violet-500" : "",
      )}
    >
      <div className={classNames(obfuscated ? "blur-sm" : "")}>{value}</div>
    </div>
  );
}
