const { tokenReady, validToken } = require("../../commons/guards");

const validPeriodOfTheDay = (context, event) => {
  return event.data === "DAY" || event.data === "NIGHT";
};

const changeSetNotEmpty = (context, event) => {
  //return context.changeSet;
  console.log("changeSetNotEmpty", context?.changeSet);
  return context?.changeSet === undefined ? false : true;
};

const reportInDraftOrPendingAndNotObfuscated = (context, event) => {
  return (
    (context?.report?.status === "DRAFT" ||
      context?.report?.status === "PENDING") &&
    !context?.report?.obfuscated
  );
};

const reportValidForSubmission = (context, event) => {
  const validReportStatus = reportInDraftOrPendingAndNotObfuscated(
    context,
    event,
  );

  // Sections required for submission

  const immediateActionsRequired =
    context?.report?.event?.criticity?.immediate_actions_required;

  const seqOfEventsRequired =
    context?.report?.event?.criticity?.seq_of_events_required;

  const rootCauseAnalysisRequired =
    context?.report?.event?.criticity?.root_cause_analysis_required;

  const actionPlanRequired =
    context?.report?.event?.criticity?.action_plan_required;

  // Sections filled for submission

  const reportSeqOfEventsFilled =
    context?.report?.sequence_of_events !== undefined &&
    context?.report?.sequence_of_events !== null;
  const reportRootCauseAnalysisFilled =
    context?.report?.five_whys !== undefined &&
    context?.report?.five_whys !== null;
  const reportImmediateActionsFilled =
    context?.report?.corrections !== undefined &&
    context?.report?.corrections !== null &&
    context?.report?.corrections?.length > 0;
  const reportActionPlanFilled =
    context?.report?.corrective_actions !== undefined &&
    context?.report?.corrective_actions !== null &&
    context?.report?.corrective_actions?.length > 0;

  return (
    validReportStatus &&
    (seqOfEventsRequired ? reportSeqOfEventsFilled : true) &&
    (rootCauseAnalysisRequired ? reportRootCauseAnalysisFilled : true) &&
    (immediateActionsRequired ? reportImmediateActionsFilled : true) &&
    (actionPlanRequired ? reportActionPlanFilled : true)
  );
};

const accessDeniedError = require("../../commons/guards").accessDeniedError;
const notFoundError = require("../../commons/guards").notFoundError;

export {
  tokenReady,
  validToken,
  validPeriodOfTheDay,
  changeSetNotEmpty,
  reportInDraftOrPendingAndNotObfuscated,
  reportValidForSubmission,
  accessDeniedError,
  notFoundError,
};
