import { useTranslation } from "react-i18next";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import ShortDate from "../ShortDate";
import i18n from "../../../i18n";

const statuses = {
  COMPLETE: "text-green-700 bg-green-50 ring-green-600/20",
  PENDING: "text-purple-700 bg-purple-50 ring-purple-600/20",
  TODO: "text-yellow-700 bg-yellow-100  ring-yellow-700/10",
  LATE: "text-pink-800 bg-pink-50 ring-pink-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const taskStatus = (task) => {
  if (!task.completed_at) {
    if (task.pending_at) {
      return "PENDING";
    }
    return task.expired_at && new Date(task.expired_at) < new Date()
      ? "LATE"
      : "TODO";
  }
  return "COMPLETE";
};

export default function Tasks({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-6 text-2xl font-medium leading-6 text-gray-600  xl:text-lg ">
        {t("editReport.view.tasks.title")}
      </h2>
      <ul role="list" className="divide-y divide-gray-300 pl-7 pr-4">
        {data.map((task) => (
          <li key={task.id} className="flex items-center justify-between py-5">
            <div className="min-w-0 sm:w-full">
              <div className="flex items-start justify-between gap-x-3">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t("shared.type.task." + task.task_type)}
                </p>
                <p
                  className={classNames(
                    statuses[taskStatus(task)],
                    "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                  )}
                >
                  {t("shared.type.taskStatus." + taskStatus(task))}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  {t("editReport.view.task.label.dueOn")}{" "}
                  <time dateTime={task.expired_at}>
                    <ShortDate
                      isoString={task.expired_at}
                      locale={i18n.language}
                    />
                  </time>
                </p>
                {/* <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg> */}
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4"></div>
          </li>
        ))}
      </ul>
    </>
  );
}
