import axios from "axios";

const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const defaultPostOptions = {
  method: "POST",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const fetchConsequenceTypes = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}consequence-types`, options).then(
    (response) => response.data,
  );
};

const fetchCorrectiveActionSources = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(
    `${context.qmsToolApiUrl}corrective-action-sources`,
    options,
  ).then((response) => response.data);
};

const fetchCorrectiveActionPriorities = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(
    `${context.qmsToolApiUrl}corrective-action-priorities`,
    options,
  ).then((response) => response.data);
};

const fetchCorrectiveActionManagerTypes = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(
    `${context.qmsToolApiUrl}corrective-action-manager-types`,
    options,
  ).then((response) => response.data);
};

const fetchSeverityTypes = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}severity-types`, options).then(
    (response) => response.data,
  );
};

const fetchProbabilityTypes = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}probability-types`, options).then(
    (response) => response.data,
  );
};

const fetchLocations = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}locations`, options).then(
    (response) => response.data,
  );
};

const fetchTargetTypes = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}target-types`, options).then(
    (response) => response.data,
  );
};

const fetchCategories = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}categories`, options).then(
    (response) => response.data,
  );
};

const computeCriticityFromConsequence = (context, event) => {
  try {
    const currentConsequenceType = context?.form?.consequenceType;
    if (
      currentConsequenceType &&
      !isNaN(currentConsequenceType.forced_severity) &&
      !isNaN(currentConsequenceType.forced_probability)
    ) {
      const options = {
        ...defaultPostOptions,
        headers: {
          ...defaultPostOptions.headers,
          Authorization: `Bearer ${context.authentication.token}`,
        },
        data: JSON.stringify({
          severity: currentConsequenceType.forced_severity,
          probability: currentConsequenceType.forced_probability,
          consequence_type: currentConsequenceType.name,
        }),
      };
      return axios(
        `${context.qmsToolApiUrl}functions/computeCriticity`,
        options,
      ).then((response) => response.data);
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const computeCriticity = (context, event) => {
  try {
    const severity = context?.report?.event?.severity;
    const probability = context?.report?.event?.probability;
    const consequence_type = context?.report?.event?.consequence_type;

    if (severity && probability && consequence_type) {
      const options = {
        ...defaultPostOptions,
        headers: {
          ...defaultPostOptions.headers,
          Authorization: `Bearer ${context.authentication.token}`,
        },
        data: JSON.stringify({
          severity: severity,
          probability: probability,
          consequence_type: consequence_type,
        }),
      };
      return axios(
        `${context.qmsToolApiUrl}functions/computeCriticity`,
        options,
      ).then((response) => response.data);
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const createReport = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
      data: JSON.stringify(context.report),
    };
    return axios(`${context.qmsToolApiUrl}reports`, options).then(
      (response) => response.data,
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDomains = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}domains`, options).then(
    (response) => response.data,
  );
};

const fetchUserRestrictedDomains = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}restricted-domains`, options).then(
    (response) => response.data,
  );
};

const fetchResidences = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}restricted-residences`, options).then(
    (response) => response.data,
  );
};

export {
  fetchConsequenceTypes,
  fetchCorrectiveActionSources,
  fetchCorrectiveActionPriorities,
  fetchCorrectiveActionManagerTypes,
  fetchSeverityTypes,
  fetchProbabilityTypes,
  fetchLocations,
  fetchTargetTypes,
  fetchCategories,
  fetchDomains,
  fetchUserRestrictedDomains,
  computeCriticityFromConsequence,
  computeCriticity,
  createReport,
  fetchResidences,
};
