const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const FlagSectionForReviewMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDMA2BDKBaWYDGALgJYD2AdlsiQE5bVgBuRYA7gHRESpgDEAygBUAggCUBAbQAMAXUSgADiVhFi5OSAAeiLAGYAnDrZ6ArHoBs5nQCYAjABYAHHcnGANCACeiAOxtTOszM7YwcrQKsDAF9I9zRMHHxVCipaeiZWNnRCIgYwNkgVAAIIODxqInkkngARAFE+AGERAEkABQFmgHkAOQB9BoAJIW6AcVrqqVkkEEVlJPUtBCxjALZvFasdO3szK02Hdy8EHUlfb3MTPYi7HWszaNiMbFxs8koaOkZmdiziXPyIEUSrAyhUqgAxAAyQhGvXBnREvREtQAas1agB1SbqWYqUhkBbaGxWXx6Ox6JxmbxUiwRNyeRCbMxrOwRewOezBRxWB4gOLPRL496pL4ZX45PIFAjFUrlSr4ngNSGdPi1bHTXHzaaLLA2bw2NjbST2bwObzGkw6byHRB65neVl6dmc4zc3n8hKvZIfNLfTLZf78qBEMhQQopQq+1g8CDkPIhhgkADWeQ9LySws+6RY6oUSjxam12jNbCtZZsgTN5gcehtCHJBrNKxCNZudgs9xifKenozKSzfvFgaewdD4ZokdFLB4YGo1BobHkGAIKQAtmw04K3v2ozmZDj81rQDq7ME2JIzMZjUEa067NaGfXtkYdP4HVYHGZJLd3T300Kdynf0-klOcaB4ZEBBEABNXMZkPfFCSWKxjF8QJTGJFwThOII6y-BwjAbU49WNDDf3if9tx9ICh1A+dqEVYYGlqSE4M1RCiyWc0rDYXZjCsSRWVuewbBsPDJAIsknWI80bDIrtNy9TNd2AiU2FgABXPA8DgWBFWVVU2IQwtj0QW5fG-KlbC5YIDHEySiPNMk9kE8iBSUwDs1U3IeAAVVaaohAEWpegAIVqURakRAROgAaVqbojLmDjTOQyQ9F42xrgkslX1Qut9Qy2xULJFx0q-Yxoi7MgSGBdRFL7ajswPZKTM0bR1kkTL+MEzYdBEsTH11BwuvMK4Anvc50sqhS-y3b0RS8zhuBagsCU43QULWGwRocfqzDkp0DiGgxeP0E4XEdE4rDsNzewAprBwDMBVqPdqlhCOw1g2LYdj2HRjqOewMok85vA-MJiVNO7KIWgcxWegEgVlMEUvg1r1tS3QbkNQSDH0EInX0Ow6yZQ0bAkvbXTNfrvBh+blJoxGgxDMMI13V60Z1CseIvbxzXB81v1rR8rQNMxrFsT8abJGbHgohnPKekD8jA6hObanUAl8HaHROfmKz2kWjnw3iKYw0GUJremPMehGVc07TdI1zH3qwWxz0pW5JAhil+LMeyzYvOTfucc4qsiIA */ predictableActionArguments: true,
    id: "flag-section-for-review",

    states: {
      idle: {
        on: {
          START: {
            target: "active.edit description",
            actions: ["saveStartRequest", "unflagChanges"],
            cond: "validStartRequest",
          },
        },
      },

      active: {
        states: {
          "edit description": {
            on: {
              DESCRIPTION_CHANGED: {
                target: "edit description",
                internal: true,
                actions: "saveDescription",
              },

              FLAG_FOR_REVIEW: {
                target: "flagging for review",
                cond: "validDescription",
              },

              CLOSE: "#flag-section-for-review.idle",
            },
          },

          "flagging for review": {
            invoke: {
              src: "flagSectionForReview",
              id: "flag-section-for-review",
              onDone: {
                target: "success",
                actions: ["resetDescription", "flagChanges"],
              },
              onError: "error",
            },
          },

          error: {
            on: {
              RETRY: "flagging for review",
              CANCEL: "#flag-section-for-review.idle",
            },
          },

          success: {
            on: {
              CLOSE: "#flag-section-for-review.idle",
            },
          },
        },

        initial: "edit description",

        on: {
          UPDATE_BEARER_TOKEN: {
            target: "active",
            internal: true,
            cond: "validToken",
            actions: "saveToken",
          },
        },
      },
    },

    initial: "idle",
    context: {
      changesHappened: false,
    },
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { FlagSectionForReviewMachine };
