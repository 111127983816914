const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const ReviewSeqOfEventsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCcwDcCWYDuBaWYAjrgPYBmu6YAdgC6wB0GEANmAMQDKAKgIIBK3ANoAGALqJQABxKwMtDCWqSQAD0QBmAOwMRAJj0BOQwDYAjBpEBWKwA4zWgDQgAnoly2RDDdo0AWKyMTEz0RDQBfcOdUTBx8IlIKKjpGAEMAYwU0DgA5AFEADWFxFRk5BSUVdQR-K11bDTM9LVtbEz9mkw1nNwQrMzqNQL8NPQHbfts-SOj0LDwCYnJKbJSGDKyOAAV+PIA1AEkAeQBVTlEJJBAy+UVlK+rcPSsNBlstQ08zQystAY1DHoeoh+oNhqNxpM9DMQDF5vElklVvR1pkMNkuHkAIoAfSOADEcfs8jluJwcQARPJ8A4AGQYVNp1LyF1KslulQe7g0ngYk30JhEAy0Ig+JmBCDGenqlkFWmegqMESisLmcUWiRWNBRG3RHE42LxhOJpPJVJp9JOWwpvG4LJKVxuFXuoEePLMDFMPjGDTsDT8EpFDHMIhEfi07XMWishmVs1iCwSy2SOrRGIAwrSjgbWY72c6qog-OHPSYvSITJ57A0JR4vH5THoRqYWqETFYYXD1Umkdq0mmOIzmbnpPm7oWan4PVYp352oEI6YuhK-KLdOZDKK7KNDA3O2rE4itWtdRirTa7TiAEJ5AR5fg47hHADSJJH1zHnNdiD072DjUMeVjAGQwzFsCVglefxPjDLowxGZUVWoEgIDgFQu0PTUU3gPNynHLkEFwDpDFLb0wKGVp-FrZ5XjMDdQK0LRtF3X59wTBEsORRhmDYNk8K-NREDMMIGDMYSDFqLorC3Ws6LqBpNxaUNGN+Bw2PhDVky41FNj4jkXUEhAfjeEV2jnT4eVGANXE0JoZRFRTQPeRDwiAA */
    predictableActionArguments: true,
    id: "review-seq-of-events",
    context: {
      seq_of_events: [],
      index: null,
      authentication: { token: null },
      reportId: null,
      qmsToolApiUrl: null,
    },
    states: {
      idle: {
        on: {
          START: {
            target: "active",
            cond: "validRequest",
            actions: [
              "saveSeqOfEvents",
              "setIndexOnFirstPage",
              "saveAuthentication",
              "saveReportId",
              "saveApiUrl",
              "unflagChanges",
              "saveMaxDate",
            ],
          },
        },
      },

      active: {
        on: {
          NEXT: {
            target: "active",
            internal: true,
            actions: ["incrementIndex", "notifyCurrentIndex"],
            cond: "notOnLastPage",
          },

          PREVIOUS: {
            target: "active",
            internal: true,
            cond: "notOnFirstPage",
            actions: ["decrementIndex", "notifyCurrentIndex"],
          },

          "SEQ_OF_EVENTS_DETAIL.DELETE": {
            target: "active",
            internal: true,
            actions: ["adaptIndex", "flagChanges", "removeSeqOfEventsDetail"],
          },

          "SEQ_OF_EVENTS_DETAIL.UPDATE": {
            target: "active",
            internal: true,
            actions: ["flagChanges", "updateSeqOfEventsDetail"],
          },

          CLOSE: {
            target: "idle",
            actions: ["resetSeqOfEvents", "resetPageIndex"],
          },

          DELETE: {
            target: "active",

            actions: "sendDeleteToCurrentActor",
          },

          UPDATE_BEARER_TOKEN: {
            target: "active",
            internal: true,
            cond: "validToken",
            actions: ["saveToken", "sendTokenToSpawnedActors"],
          },
        },
      },
    },

    initial: "idle",
  },
  { actions: actions, guards: guards, services: services },
);

export { ReviewSeqOfEventsMachine };
