import Breadcrumb from "./Breadcrumb";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericBadge from "../shared/GenericBadge";
import TimeAgo from "../shared/TimeAgo";
import Tabs from "./Tabs";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import ShortDate from "../ShortDate";
import { MinusSmallIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useViewEditActionPlanMachine } from "../../../context/ViewEditActionPlanMachineContext";
import { useAuth } from "../../../context/AuthContext";
import { useSearchParams } from "react-router-dom";
import { useReviewCorrectiveActionsMachine } from "../../../context/ReviewCorrectiveActionsMachineContext";
import { createReviewCorrectiveActionMachine } from "../../../machines/action-plans/review-corrective-action/review-corrective-action-machine";
import ReviewCorrectiveActionsPanel from "../action-plans/review-corrective-actions/ReviewCorrectiveActionsPanel";
import SortableTableHeader from "../shared/SortableTableHeader";
import Filters from "../shared/Filters";
import ActiveFilters from "../shared/ActiveFilters";
const statuses = {
  COMPLETE: " text-green-700 bg-green-50 ring-green-600/20",
  ONGOING: " text-purple-700 bg-purple-50 ring-purple-600/20",
  TODO: " text-yellow-700 bg-yellow-100  ring-yellow-700/10",
  LATE: " text-pink-800 bg-pink-50 ring-pink-600/20",
};

const priorities = {
  HIGH_PRIORITY: " text-red-700 bg-red-50 ring-red-600/20",
  MEDIUM_PRIORITY: " text-yellow-700 bg-yellow-100 ring-yellow-700/10",
  LOW_PRIORITY: " text-green-700 bg-green-50 ring-green-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ActionPlan() {
  const { t } = useTranslation();
  const [state, send] = useViewEditActionPlanMachine();
  const [searchParams, setSearchParams] = useSearchParams();
  const { keycloak } = useAuth();
  const urlActionPlanId = useParams()?.id;
  //const { id } = useParams();
  const residence_one_key = searchParams.get("residence_one_key");
  const [reviewCorrectiveActionsState, reviewCorrectiveActionsSend] =
    useReviewCorrectiveActionsMachine();

  const compareProblemStatement = (action1, action2, order = "asc") => {
    // Fonction pour extraire la valeur à comparer selon la logique spécifiée
    function getValue(action) {
      return (
        action.problem_statement ||
        action?.report?.five_whys?.problem_statement ||
        action?.report?.event?.description ||
        ""
      );
    }

    // Extraction des valeurs pour chaque action
    const value1 = getValue(action1);
    const value2 = getValue(action2);

    // Comparaison des valeurs
    let comparison = value1.localeCompare(value2);

    // Gestion de l'ordre de tri
    if (order === "desc") {
      comparison = -comparison;
    }

    return comparison;
  };

  const compareCorrectiveActionsByDomain = (
    action1,
    action2,
    order = "asc",
  ) => {
    // Fonction pour extraire la valeur du domaine selon la nouvelle logique spécifiée
    function getDomainValue(action) {
      // Directement depuis l'objet "domain" ou via le chemin "report.event.domain.name"
      return action.domain?.name || action?.report?.event?.domain?.name || "";
    }

    // Extraction des valeurs de domaine pour chaque action
    // const domainValue1 = getDomainValue(action1);
    // const domainValue2 = getDomainValue(action2);

    const domainValue1 = t(`shared.type.domain.${getDomainValue(action1)}`);
    const domainValue2 = t(`shared.type.domain.${getDomainValue(action2)}`);

    // Utilisation de localeCompare pour la comparaison des chaînes de caractères
    let comparison = domainValue1.localeCompare(domainValue2);

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const compareCorrectiveActionsByCause = (action1, action2, order = "asc") => {
    // Fonction pour extraire la valeur de la cause racine selon la nouvelle logique spécifiée
    function getRootCauseValue(action) {
      // Directement depuis l'objet "root_cause" ou via le chemin "report.five_whys.final_answer"
      const rootCause =
        action.root_cause || action?.report?.five_whys?.final_answer;
      // Traite explicitement null comme une valeur valide mais spéciale
      return !rootCause ? "\uFFFF" : rootCause;
    }

    // Extraction des valeurs de cause racine pour chaque action
    const rootCauseValue1 = getRootCauseValue(action1);
    const rootCauseValue2 = getRootCauseValue(action2);

    // Utilisation de localeCompare pour la comparaison des chaînes de caractères
    let comparison = rootCauseValue1.localeCompare(rootCauseValue2, undefined, {
      numeric: true,
    });

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const compareCorrectiveActionsBySource = (
    action1,
    action2,
    order = "asc",
  ) => {
    // Fonction pour extraire la valeur de la source selon la nouvelle logique spécifiée
    function getSourceValue(action) {
      const source = action?.report_id
        ? String("" + action.report_id).padStart(10, "0")
        : t(`shared.type.correctiveAction.source.${action.source?.name}`);

      return !source ? "\uFFFF" : source;
    }

    // Extraction des valeurs de source pour chaque action
    const sourceValue1 = getSourceValue(action1);
    const sourceValue2 = getSourceValue(action2);

    let comparison = sourceValue1.localeCompare(sourceValue2);

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const compareCorrectiveActionsByHandler = (
    action1,
    action2,
    order = "asc",
  ) => {
    // Fonction pour extraire la valeur de l'agent responsable selon la nouvelle logique spécifiée
    function getHandlerValue(action) {
      // Traite explicitement null comme une valeur valide mais spéciale
      return !action.handler_type?.name
        ? "\uFFFF"
        : t(
            `shared.type.correctiveAction.managerTypes.${action.handler_type?.name}`,
          );
    }

    // Extraction des valeurs de l'agent responsable pour chaque action
    const handlerValue1 = getHandlerValue(action1);
    const handlerValue2 = getHandlerValue(action2);

    // Utilisation de localeCompare pour la comparaison des chaînes de caractères
    let comparison = handlerValue1.localeCompare(handlerValue2);

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const compareCorrectiveActionsByPriority = (
    action1,
    action2,
    order = "asc",
  ) => {
    function getPriorityValue(action) {
      // Utilise la valeur maximale pour les priorités non définies
      return !action?.priority?.display_index
        ? "\uFFFF"
        : t(
            `shared.type.correctiveAction.priority.${action?.priority?.display_index}`,
          );
    }

    const priorityValue1 = getPriorityValue(action1);
    const priorityValue2 = getPriorityValue(action2);

    // Comparaison initiale sur la priorité
    let comparison = priorityValue1.localeCompare(priorityValue2);

    // Si les priorités sont égales, comparer par la date de création
    if (comparison === 0 && action1.created_at && action2.created_at) {
      // Compare dates; note that we assume dates are in a format that localeCompare can handle
      comparison = action1.created_at.localeCompare(action2.created_at);
    }

    // Appliquer l'ordre de tri demandé
    if (order === "asc") {
      comparison = -comparison;
    }

    return comparison;
  };

  const getCorrectiveActionStatus = (correctiveAction) => {
    const currentDate = new Date();
    const expirationDate = new Date(correctiveAction.expired_at);

    if (expirationDate < currentDate) {
      if (
        correctiveAction.status !== "COMPLETE" &&
        correctiveAction.status !== "ONGOING"
      ) {
        return "LATE";
      }
    }
    return correctiveAction.status;
  };

  const compareCorrectiveActionsByStatus = (
    action1,
    action2,
    order = "asc",
  ) => {
    // Fonction pour extraire la valeur de statut selon la nouvelle logique spécifiée
    function getStatusValue(action) {
      // Utilisation de la fonction getCorrectiveActionStatus pour obtenir le statut correct
      return getCorrectiveActionStatus(action);
    }

    // Extraction des valeurs de statut pour chaque action
    const statusValue1 = getStatusValue(action1);
    const statusValue2 = getStatusValue(action2);

    // Utilisation de localeCompare pour la comparaison des chaînes de caractères
    let comparison = statusValue1.localeCompare(statusValue2);

    // Gestion de l'ordre de tri (inverse si demandé)
    if (order === "desc") {
      // Inversion du résultat de localeCompare pour le tri descendant
      comparison = -comparison;
    }

    return comparison;
  };

  const headers = [
    {
      key: "domain",
      textId: "action-plan.corrective_actions.table.headers.domain",
      classes:
        "hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-6 lg:table-cell",
      comparator: compareCorrectiveActionsByDomain,
    },
    {
      key: "priority",
      textId: "action-plan.corrective_actions.table.headers.short.priority",
      classes:
        //"px-4 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-3",
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareCorrectiveActionsByPriority,
    },
    {
      key: "priority-2",
      textId: "action-plan.corrective_actions.table.headers.priority",
      classes:
        "px-4 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-3 lg:hidden",
      //"hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareCorrectiveActionsByPriority,
    },
    {
      key: "report_id",
      textId: "action-plan.corrective_actions.table.headers.source",
      classes:
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareCorrectiveActionsBySource,
    },
    {
      key: "problem_statement",
      textId: "action-plan.corrective_actions.table.headers.problem_statement",
      classes:
        "hidden whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareProblemStatement,
    },
    {
      key: "root_cause",
      textId: "action-plan.corrective_actions.table.headers.root_cause",
      classes:
        "hidden  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareCorrectiveActionsByCause,
    },
    {
      key: "description",
      textId: "action-plan.corrective_actions.table.headers.action",
      classes:
        //"px-4 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-3",
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
    },
    {
      key: "handled_by",
      textId: "action-plan.corrective_actions.table.headers.handled_by",
      classes:
        "hidden whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
      comparator: compareCorrectiveActionsByHandler,
    },
    {
      key: "expired_at",
      textId: "action-plan.corrective_actions.table.headers.expired_at",
      classes:
        "hidden whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell",
    },
    {
      key: "status",
      textId: "action-plan.corrective_actions.table.headers.status",
      classes: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
      comparator: compareCorrectiveActionsByStatus,
    },
  ];

  useEffect(() => {
    send("LOAD", {
      data: {
        authentication: {
          token: keycloak.token,
        },
        id: urlActionPlanId,
        residence_one_key: residence_one_key,
      },
    });
  }, []);

  useEffect(() => {
    console.log("Token is refreshing : send token to list machine");
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);

  return (
    <>
      {/* <div className="xl:mt-3">
        <div className="mb-10 print:hidden">
          <Breadcrumb
            residence_one_key={residence_one_key}
            actionPlanId={state.context?.id}
            loading={state.matches("loading")}
          />
        </div>
      </div> */}
      <div className="sm:flex sm:items-center ">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            {t("editActionPlan.header")} {residence_one_key}
          </h1>
          <p className="mt-2 text-xs text-gray-500">
            {t("reports.label.last_refreshed_at")}{" "}
            <TimeAgo isoString={state?.context?.loaded_at} />
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {state.can("EXPORT") && (
            <button
              type="button"
              className={classNames(
                "relative mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white",
                state.matches("exporting") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("EXPORT");
              }}
              disabled={state.matches("exporting")}
            >
              {state.matches("exporting") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.export")}
            </button>
          )}
          {state.matches("exporting") && (
            <button
              type="button"
              className={classNames(
                "relative mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                "cursor-not-allowed",
              )}
              disabled={true}
            >
              {state.matches("exporting") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.status.exporting")}
            </button>
          )}
          {state.matches("loading") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                "cursor-not-allowed",
              )}
              disabled={true}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.status.loading")}
            </button>
          )}
          {state.can("RELOAD") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RELOAD");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.refresh")}
            </button>
          )}
          {state.can("RETRY") && (
            <button
              type="button"
              className={classNames(
                "relative inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                state.matches("loading") ? "cursor-not-allowed" : "",
              )}
              onClick={() => {
                send("RETRY");
              }}
              disabled={state.matches("loading")}
            >
              {state.matches("loading") && (
                <svg
                  className={classNames(
                    "-ml-1 mr-3 h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}

              {t("reports.actions.retry")}
            </button>
          )}
        </div>
      </div>
      <div className="mt-10 flex justify-between ">
        <Tabs />
        <div className="my-auto ml-2">
          <Filters state={state} send={send} />
        </div>
      </div>
      <div className="sm:mt-3">
        <ActiveFilters state={state} send={send} />
      </div>
      <div
        className={classNames(
          "-mx-4 mt-8 overflow-auto bg-white  ring-1 ring-gray-300 sm:mx-0 sm:mt-6 sm:rounded-lg",
        )}
      >
        <table className="min-w-full  divide-y divide-gray-300 ">
          <SortableTableHeader
            headers={headers}
            sortedBy={state?.context?.currentSortKey}
            sortOrder={state?.context?.currentSortDirection}
            onHeaderClick={(header) => {
              send({
                type: "SORT",
                sortKey: header.key,
                comparator: header.comparator,
              });
            }}
          />
          <tbody>
            <>
              {state?.context?.corrective_actions?.map(
                (corrective_action, planIdx) => (
                  <tr key={corrective_action.id}>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6",
                      )}
                    >
                      <div className="text-xs font-semibold text-gray-700">
                        <span className="mr-1.5 lg:hidden">
                          {t(
                            "action-plan.corrective_actions.table.headers.domain",
                          )}
                        </span>
                        {corrective_action.domain ? (
                          <GenericBadge
                            value={t(
                              "shared.type.domain." +
                                corrective_action.domain.name,
                            )}
                          />
                        ) : corrective_action.report?.event?.domain?.name ? (
                          <>
                            <GenericBadge
                              value={t(
                                "shared.type.domain." +
                                  corrective_action.report?.event?.domain?.name,
                              )}
                            />
                          </>
                        ) : (
                          <MinusSmallIcon className="inline h-6 w-6 text-gray-300 lg:hidden" />
                        )}
                      </div>
                      <div className="mt-2  text-xs text-gray-500 lg:hidden">
                        <span className="mr-1 font-semibold text-gray-700 ">
                          {t(
                            "action-plan.corrective_actions.table.headers.expired_at",
                          )}
                        </span>
                        <ShortDate
                          isoString={corrective_action.expired_at}
                          locale={i18n.language}
                        />
                      </div>
                      <div className="mt-2 text-xs text-gray-500 lg:hidden">
                        <span className="mr-1.5  text-gray-900 ">
                          {t(
                            "action-plan.corrective_actions.table.headers.source",
                          )}{" "}
                          :
                        </span>
                        {corrective_action.report_id && (
                          <span className=" lg:hidden">
                            <Link
                              to={"/reports/" + corrective_action.report_id}
                              className="cursor-pointer  text-xs text-blue-500 hover:underline"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className={classNames(
                                  "mr-1.5 inline-block h-4 w-4 ",
                                  corrective_action?.report?.event?.type
                                    ?.name === "SENTINEL"
                                    ? "text-red-500"
                                    : "",
                                  corrective_action?.report?.event?.type
                                    ?.name === "SAFETY"
                                    ? "text-orange-400"
                                    : "",
                                )}
                              >
                                <circle
                                  cx="6"
                                  cy="6"
                                  r="6"
                                  fill="currentColor"
                                />
                              </svg>
                              {corrective_action?.report?.reference}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="-mt-0.5 ml-1 inline-block h-4 w-4"
                              >
                                <path d="M6.22 8.72a.75.75 0 0 0 1.06 1.06l5.22-5.22v1.69a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0 0 1.5h1.69L6.22 8.72Z" />
                                <path d="M3.5 6.75c0-.69.56-1.25 1.25-1.25H7A.75.75 0 0 0 7 4H4.75A2.75 2.75 0 0 0 2 6.75v4.5A2.75 2.75 0 0 0 4.75 14h4.5A2.75 2.75 0 0 0 12 11.25V9a.75.75 0 0 0-1.5 0v2.25c0 .69-.56 1.25-1.25 1.25h-4.5c-.69 0-1.25-.56-1.25-1.25v-4.5Z" />
                              </svg>
                            </Link>
                          </span>
                        )}
                        {!corrective_action.report_id &&
                          corrective_action.source_id && (
                            <>
                              <span className=" lg:hidden">
                                <GenericBadge
                                  value={t(
                                    "shared.type.correctiveAction.source." +
                                      corrective_action.source.name,
                                  )}
                                />
                              </span>
                            </>
                          )}
                      </div>

                      <div className="mt-2 flex flex-col text-xs text-gray-500 sm:block lg:hidden">
                        <div>
                          <span className="mr-1.5 text-gray-900  lg:hidden">
                            {t(
                              "action-plan.corrective_actions.table.headers.problem_statement",
                            )}{" "}
                            :
                          </span>

                          {corrective_action.problem_statement ||
                            corrective_action?.report?.five_whys
                              ?.problem_statement ||
                            corrective_action?.report?.event?.description}
                        </div>
                        <div className="mt-1.5">
                          <span className="mr-1.5  text-gray-900 lg:hidden">
                            {t(
                              "action-plan.corrective_actions.table.headers.root_cause",
                            )}{" "}
                            :
                          </span>

                          {(corrective_action.root_cause ||
                            corrective_action?.report?.five_whys
                              ?.final_answer) && (
                            <>
                              {corrective_action.root_cause && (
                                <>{corrective_action.root_cause}</>
                              )}
                              {corrective_action?.report?.five_whys
                                ?.final_answer && (
                                <>
                                  {corrective_action.root_cause && <>{", "}</>}
                                  {
                                    corrective_action?.report?.five_whys
                                      ?.final_answer
                                  }
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div className="mt-1.5">
                          <span className="mr-1.5  text-gray-900 ">
                            {t(
                              "action-plan.corrective_actions.table.headers.description",
                            )}{" "}
                            :
                          </span>
                          <span className="">
                            {corrective_action.description}
                          </span>
                        </div>
                        <div className="mt-1.5">
                          {" "}
                          <span className="mr-1.5  text-gray-900 ">
                            {t(
                              "action-plan.corrective_actions.table.headers.handled_by",
                            )}{" "}
                            :
                          </span>
                          {corrective_action.handler_type?.name
                            ? t(
                                `shared.type.correctiveAction.managerTypes.${corrective_action.handler_type?.name}`,
                              )
                            : ""}
                        </div>
                      </div>
                      <div className="mt-2  text-xs text-gray-500 lg:hidden">
                        <span className="mr-1.5  text-gray-900 ">
                          {t(
                            "action-plan.corrective_actions.table.headers.priority",
                          )}{" "}
                          :
                        </span>
                        {corrective_action?.priority?.name && (
                          <span
                            className={classNames(
                              priorities[corrective_action?.priority?.name],
                              "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                            )}
                          >
                            {t(
                              `shared.type.correctiveAction.priority.short.${corrective_action?.priority?.name}`,
                            )}
                          </span>
                        )}
                      </div>
                      {planIdx !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        " hidden  px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      {corrective_action?.priority?.name && (
                        <span
                          className={classNames(
                            priorities[corrective_action?.priority?.name],
                            "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                          )}
                        >
                          {t(
                            `shared.type.correctiveAction.priority.short.${corrective_action?.priority?.name}`,
                          )}
                        </span>
                      )}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm  text-gray-500 lg:table-cell",
                      )}
                    >
                      {corrective_action.report_id && (
                        <Link
                          to={"/reports/" + corrective_action.report_id}
                          className="cursor-pointer whitespace-nowrap text-xs  font-semibold text-blue-500 hover:underline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className={classNames(
                              "mr-1 inline-block h-4 w-4 ",
                              corrective_action?.report?.event?.type?.name ===
                                "SENTINEL"
                                ? "text-red-500"
                                : "",
                              corrective_action?.report?.event?.type?.name ===
                                "SAFETY"
                                ? "text-orange-400"
                                : "",
                            )}
                          >
                            <circle cx="6" cy="6" r="6" fill="currentColor" />
                          </svg>

                          {corrective_action?.report?.reference}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="-mt-0.5 ml-1 inline-block h-4 w-4"
                          >
                            <path d="M6.22 8.72a.75.75 0 0 0 1.06 1.06l5.22-5.22v1.69a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0 0 1.5h1.69L6.22 8.72Z" />
                            <path d="M3.5 6.75c0-.69.56-1.25 1.25-1.25H7A.75.75 0 0 0 7 4H4.75A2.75 2.75 0 0 0 2 6.75v4.5A2.75 2.75 0 0 0 4.75 14h4.5A2.75 2.75 0 0 0 12 11.25V9a.75.75 0 0 0-1.5 0v2.25c0 .69-.56 1.25-1.25 1.25h-4.5c-.69 0-1.25-.56-1.25-1.25v-4.5Z" />
                          </svg>
                        </Link>
                      )}
                      {!corrective_action.report_id &&
                        corrective_action.source_id && (
                          <GenericBadge
                            value={t(
                              `shared.type.correctiveAction.source.${corrective_action.source.name}`,
                            )}
                          />
                        )}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        " hidden  px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      <div className="w-full">
                        {corrective_action.problem_statement ||
                          corrective_action?.report?.five_whys
                            ?.problem_statement ||
                          corrective_action?.report?.event?.description}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        " hidden px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      <div className="w-full">
                        {(corrective_action.root_cause ||
                          corrective_action?.report?.five_whys
                            ?.final_answer) && (
                          <>
                            {corrective_action.root_cause && (
                              <>{corrective_action.root_cause}</>
                            )}
                            {corrective_action?.report?.five_whys
                              ?.final_answer && (
                              <>
                                {corrective_action.root_cause && <>{", "}</>}
                                {
                                  corrective_action?.report?.five_whys
                                    ?.final_answer
                                }
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden  px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      {corrective_action.description}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden whitespace-nowrap px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      {corrective_action.handler_type?.name
                        ? t(
                            `shared.type.correctiveAction.managerTypes.${corrective_action.handler_type?.name}`,
                          )
                        : ""}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden whitespace-nowrap px-3 py-3.5 text-xs text-gray-500 lg:table-cell",
                      )}
                    >
                      <ShortDate
                        isoString={corrective_action.expired_at}
                        locale={i18n.language}
                      />
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "px-3 py-3.5 text-sm text-gray-500",
                      )}
                    >
                      <span
                        className={classNames(
                          statuses[
                            getCorrectiveActionStatus(corrective_action)
                          ],
                          "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                        )}
                      >
                        {t(
                          "shared.type.actionPlanStatus." +
                            getCorrectiveActionStatus(corrective_action),
                        )}
                      </span>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6",
                      )}
                    >
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={(e) => {
                          console.log("clicked");
                          reviewCorrectiveActionsSend("START", {
                            corrective_actions:
                              state?.context?.corrective_actions,
                            authentication: {
                              token: state?.context?.authentication?.token,
                            },
                            qmsToolApiUrl: state?.context?.qmsToolApiUrl,
                            correctiveAction: corrective_action,

                            spawnActorFunction:
                              createReviewCorrectiveActionMachine,
                          });
                        }}
                      >
                        <span className="lg:hidden">{"Edit"}</span>
                        <PencilSquareIcon className="hidden h-5 w-5 lg:inline" />
                        <span className="sr-only">, {"Edit"}</span>
                      </button>
                      {planIdx !== 0 ? (
                        <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                      ) : null}
                    </td>
                  </tr>
                ),
              )}
            </>
          </tbody>
        </table>
      </div>
      <ReviewCorrectiveActionsPanel />
    </>
  );
}
