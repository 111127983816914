const { actions, sendParent } = require("xstate");
const { assign } = actions;

const saveDescription = assign({
  seq_of_events_detail: (context, event) => {
    return {
      ...context.seq_of_events_detail,
      description: event.data,
    };
  },
});

const saveEventOccurenceDate = assign({
  seq_of_events_detail: (context, event) => {
    console.log("context", context);
    console.log("event", event);
    return {
      ...context.seq_of_events_detail,
      item_occured_at: event.data,
    };
  },
});

const saveAffectedEntity = assign({
  seq_of_events_detail: (context, event) => {
    return {
      ...context.seq_of_events_detail,
      affected_entity: event.data,
    };
  },
});

const saveTracesAndEvidences = assign({
  seq_of_events_detail: (context, event) => {
    return {
      ...context.seq_of_events_detail,
      traceability_and_evidences: event.data,
    };
  },
});

const sendDeleteEventToParent = sendParent((context) => ({
  type: "SEQ_OF_EVENTS_DETAIL.DELETE",
  id: context.seq_of_events_detail.id,
}));
const sendUpdateEventToParent = sendParent((context) => ({
  type: "SEQ_OF_EVENTS_DETAIL.UPDATE",
  seq_of_events_detail: context.seq_of_events_detail,
}));

const flagChanges = require("../../commons/actions").flagChanges;
const unflagChanges = require("../../commons/actions").unflagChanges;
const saveToken = require("../../commons/actions").saveToken;

export {
  saveDescription,
  saveEventOccurenceDate,
  sendDeleteEventToParent,
  sendUpdateEventToParent,
  flagChanges,
  unflagChanges,
  saveAffectedEntity,
  saveTracesAndEvidences,
  saveToken,
};
