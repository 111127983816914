const { actions, sendParent } = require("xstate");
const { assign } = actions;

const saveDescription = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      description: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "description"]);
  },
});

const saveStatus = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      status: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "status"]);
  },
});

const sendDeleteEventToParent = sendParent((context) => ({
  type: "CORRECTIVE_ACTION.DELETE",
  id: context.correctiveAction.id,
}));

const sendUpdateEventToParent = sendParent((context) => ({
  type: "CORRECTIVE_ACTION.UPDATE",
  correctiveAction: context.correctiveAction,
}));

const saveHandlerType = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      handler_type: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "handler_type"]);
  },
});

const saveExpirationDate = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      expired_at: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "expired_at"]);
  },
});

const saveDomain = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      domain: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "domain"]);
  },
});

const saveCorrectiveActionSource = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      source: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "source"]);
  },
});

const saveCorrectiveActionPriority = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      priority: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "priority"]);
  },
});

const saveProblemStatement = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      problem_statement: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "problem_statement"]);
  },
});

const saveRootCause = assign({
  correctiveAction: (context, event) => {
    return {
      ...context.correctiveAction,
      root_cause: event.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "root_cause"]);
  },
});

const updateCompletionDateIfNecessary = assign({
  correctiveAction: (context, event) => {
    console.log(event);
    return {
      ...context.correctiveAction,
      completed_at: event?.data?.completed_at,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "completed_at"]);
  },
});

const saveDeferralReason = assign({
  correctiveAction: (context, event) => {
    console.log(event);
    return {
      ...context.correctiveAction,
      deferral_reason: event?.data,
    };
  },
  changes: (context, event) => {
    return new Set([...context.changes, "deferral_reason"]);
  },
});

const emptyChanges = assign({
  changes: (context, event) => {
    return new Set();
  },
});

const flagChanges = require("../../../commons/actions").flagChanges;
const unflagChanges = require("../../../commons/actions").unflagChanges;
const saveToken = require("../../../commons/actions").saveToken;
const saveError = require("../../../commons/actions").saveError;
const flagDeferralReasonAsMandatory =
  require("../../../commons/actions").flagDeferralReasonAsMandatory;
const unflagDeferralReasonAsMandatory =
  require("../../../commons/actions").unflagDeferralReasonAsMandatory;

export {
  saveDescription,
  saveStatus,
  sendDeleteEventToParent,
  sendUpdateEventToParent,
  flagChanges,
  flagDeferralReasonAsMandatory,
  unflagChanges,
  saveToken,
  saveExpirationDate,
  saveDomain,
  saveCorrectiveActionSource,
  saveCorrectiveActionPriority,
  saveHandlerType,
  saveProblemStatement,
  saveRootCause,
  saveDeferralReason,
  updateCompletionDateIfNecessary,
  saveError,
  emptyChanges,
  unflagDeferralReasonAsMandatory,
};
