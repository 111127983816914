import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LanguageMenu() {
  const [language, setLanguage] = useState(i18next.language);
  return (
    <>
      {/* Language dropdown */}
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open language menu</span>
          <GlobeAltIcon
            className="h-6 w-6 font-normal text-gray-400"
            aria-hidden="true"
          />
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-medium leading-6 text-gray-900"
              aria-hidden="true"
            >
              {language}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={(evt) => {
                    evt.preventDefault();
                    i18next.changeLanguage("en");
                    setLanguage("en");
                  }}
                  className={classNames(
                    "cursor-pointer",
                    active ? "bg-gray-100" : "",
                    "block px-3 py-1 text-sm leading-6",
                  )}
                >
                  {"EN"}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={(evt) => {
                    evt.preventDefault();
                    i18next.changeLanguage("nl");
                    setLanguage("nl");
                  }}
                  className={classNames(
                    "cursor-pointer",
                    active ? "bg-gray-100" : "",
                    "block px-3 py-1 text-sm leading-6",
                  )}
                >
                  {"NL"}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={(evt) => {
                    evt.preventDefault();
                    i18next.changeLanguage("fr");
                    setLanguage("fr");
                  }}
                  className={classNames(
                    "cursor-pointer",
                    active ? "bg-gray-100" : "",
                    "block px-3 py-1 text-sm leading-6",
                  )}
                >
                  {"FR"}
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
