const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const createAdvancedSearchAgentMachine = ({
  periodSelectedEventType,
  rangeSelectedEventType,
  clearedEventType,
  applyEventType,
}) => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEMIDdkDsDGkAEsYyATtgBYDEAwgDICiAggEoDaADALqKgAOA9rACWAF0F9M3EAA9EAFgDMADgB0ANjYBODaoCsGgExKA7IsWqANCACeiALRG2y2Rtn7ZJgIwb5R+asUAvgGWqBg4+IQk5BQMAAqxNACa7FxIIPxCouKSMgj6OqrKbK7OivIKsrKaljYIGh6OFWw68l4KHh6BwSChWLgQBESkZMrkYNgA1njI2KJoYHjCyABGFCmSGSJiEmm5to71vjp6moZsqt4eNYj6HU5GHnoaOkb1rWVBIeh9EUPko2RxlMZnMFktViwPKleAIttldoglLJlM9-LJVPp9C4PEZrnk7u5HloXm9OvJPj1vuEBpFhsoeGBiGIIBQALIAeQAcgAVAASAH0AMp0ehUbl0AAi6zSmyyO1Ae30xWURhKhlUun0FmsiB0HmROLYD20igK+R0FN61MGURGDKZfBZiUYTCFIroYsl0phmW2OTsSp0Th88meZh0inOuJ1CH8HhVikqigerlD3ktVP6Nrp9uZFAAagBJOgAdX5TAYnIA4nR+ezYtzC1zBd70rC5f6EB5VMjVWwlKoQy0dPo8fJQyjuxpNOp3hcM2Es7T-sQsDAC8Wy7E6EwmxK6w2m5yW5wNu2-Qiuw81Oie5j5PpI89ZHiWhplGYMYop7ICnqFz8NJ-CMq6YOugrcsw3L8hKDDim6orilKp4yue8IKrqij6MoSgNCOsj6oc0a1H42GKN4lRGA8mIVAB1rLiBa5gBQdCcvusHwcKiFeihPpwvK0i6vUygdN4WLaGw3ZGq+GIfq434aKaWIal03SYI6cCSFaS7AWevroYJCC2Pk8jBr4Ya6JGqjEXYjxGCiLhKv2GJ+Im+h0TptoAkC0yzII8yLCsen8Z2tzKBGuiqDiBj5CYOgvjGHhKCibkFH4bDJhq5LdNpvxebmjrBR2l4vB+WjNC4fgKA42q1L+KjJs88hsEaI7WdZHl5XSoEwEVF4YQg6KOOOnTuEY+SEuOeL1R+rx6kqiaaPIBRBEEQA */
      id: "advanced search",

      predictableActionArguments: true,

      context: {
        activeTab: "period",
        month: null,
        year: null,
        startDate: null,
        endDate: null,
        periodSelectedEventType: periodSelectedEventType,
        rangeSelectedEventType: rangeSelectedEventType,
        clearedEventType: clearedEventType,
        applyEventType: applyEventType,
      },

      states: {
        "check active tab": {
          always: [
            {
              target: "period",
              cond: "periodOptionsActive",
            },
            {
              target: "range",
              cond: "rangeOptionsActive",
            },
          ],
        },

        period: {
          on: {
            MONTH_SELECTED: {
              target: "period",
              internal: true,
              actions: ["saveMonth", "resetRangeOptions", "flagChanges"],
              cond: "validMonth",
            },

            YEAR_SELECTED: {
              target: "period",
              internal: true,
              actions: ["saveYear", "resetRangeOptions", "flagChanges"],
              cond: "validYear",
            },

            VIEW_RANGE_OPTIONS: {
              target: "range",
              actions: "setRangeOptionsAsActiveTab",
            },
          },
        },

        range: {
          on: {
            VIEW_PERIOD_OPTIONS: {
              target: "#advanced search.period",
              actions: "setPeriodOptionsAsActiveTab",
            },

            START_DATE_SELECTED: {
              target: "range",
              internal: true,
              actions: ["saveStartDate", "resetPeriodOptions", "flagChanges"],
            },

            END_DATE_SELECTED: {
              target: "range",
              internal: true,
              actions: ["saveEndDate", "resetPeriodOptions", "flagChanges"],
            },
          },
        },
      },

      initial: "check active tab",

      on: {
        CLEAR: {
          target: ".check active tab",
          actions: [
            "resetPeriodOptions",
            "resetRangeOptions",
            "unflagChanges",
            "sendClearedToParent",
          ],
          cond: "changesHappened",
        },

        APPLY: {
          target: "#advanced search",
          internal: true,
          cond: "validPeriodOrRangeSelected",
          actions: "sendApplyEventToParent",
        },
      },
    },
    { actions, guards, services },
  );
};

export { createAdvancedSearchAgentMachine };
