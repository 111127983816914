const { actions } = require("xstate");
const { assign } = actions;

const saveToken = require("../../commons/actions").saveToken;
const saveError = require("../../commons/actions").saveError;
const updateLastRefreshedTimestamp =
  require("../../commons/actions").updateLastRefreshedTimestamp;

const saveEfficiencyMetrics = assign({
  efficiency_metrics: (context, event) => {
    return event.data;
  },
});

const saveLoadRequest = require("../../commons/actions").saveLoadRequest;
export {
  saveError,
  updateLastRefreshedTimestamp,
  saveEfficiencyMetrics,
  saveLoadRequest,
  saveToken,
};
