const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const EventNavigatorMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5RgG5gHYBcC06CGKAllHpgPYBOAdIRADZgDEAygCoCCASqwNoAMAXUSgADmViFMhMumEgAHomwAmABx8qAZgDsAVgBs+5X1UBOACyrzARgA0IAJ5LrBqte18+OzbvOnlyubaAL7B9qgYOPhEJOTUeADGUmiMAMIAMgDyzACi-EJIIGISUjJyigjm+lQBmj661qr62qam9k4I2NbW1YGqPXz6ptrmNtr6oeFoWLgExKSUVInJTAAKnDkAagCSmQCqzPlyxZLSsoUV2OZ85lRWxrqafKb6mqqB7YjGylTXyu56PymaymJqTcDTKJzWKLZaEFIAORyAA1eIJjuJTmULoh3NUGgFgVZNKY+LoXp8EN9fnx-tpAa0QapNOCIjNovM4kskvCmGwuGiCqJMaVzqBLvp3FpaZplNpNEzzJpKV1XO5PHVLLomtY3qEwiB0GQIHA5GyoTEFhQMSUzuUlIZdNLlLL5YrlY4lJpRlpmYN9O8hkM9KzIbNLVzaAwbVixQpnOo7gZ-u9lMMyU0VcpdNoqIM+AN-iZJapQ5Fw5zYTy0DHRfbOo0fiN-qDmvogkZdJTE8Zmj06t7tIT9cEgA */
    id: "event-navigator",

    predictableActionArguments: true,

    states: {
      idle: {
        on: {
          START: {
            target: "active",
            cond: "validRequest",
            actions: [
              "saveReportIdentifiers",
              "setIndexOnFirstPage",
              "saveStatus",
              "sendLoadEventToViewReportMachine",
            ],
          },
        },
      },

      active: {
        on: {
          CLOSE: {
            target: "idle",
            actions: "resetPageIndex",
          },

          PREVIOUS: {
            target: "active",
            actions: ["decrementIndex", "sendLoadEventToViewReportMachine"],
            cond: "notOnFirstPage",
            internal: true,
          },

          NEXT: {
            target: "active",
            actions: ["incrementIndex", "sendLoadEventToViewReportMachine"],
            cond: "notOnLastPage",
            internal: true,
          },

          START: {
            target: "active",
            internal: true,
            cond: "validRequest",
            actions: [
              "saveReportIdentifiers",
              "setIndexOnFirstPage",
              "saveStatus",
              "sendLoadEventToViewReportMachine",
            ],
          },
        },
      },
    },

    initial: "idle",
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { EventNavigatorMachine };
