import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import TimeAgo from "../shared/TimeAgo";
import { useListActionPlansMachine } from "../../../context/ListActionPlansMachineContext";
import { useViewEditActionPlanMachine } from "../../../context/ViewEditActionPlanMachineContext";

import { useAuth } from "../../../context/AuthContext";
import { useEffect } from "react";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import SortableTableHeader from "../shared/SortableTableHeader";
import AccessDenied from "../shared/AccessDenied";

const statuses = {
  COMPLETE: " text-green-700 bg-green-50 ring-green-600/20",
  ONGOING: " text-purple-700 bg-purple-50 ring-purple-600/20",
  TODO: " text-yellow-700 bg-yellow-100  ring-yellow-700/10",
  LATE: " text-pink-800 bg-pink-50 ring-pink-600/20",
};

const headers = [
  {
    key: "residence_one_key",
    textId: "action-plans.table.headers.residence_one_key",
    classes:
      "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
  },
  {
    key: "meta_data.global_status",
    textId: "action-plans.table.headers.status",
    classes:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell",
  },
  {
    key: "meta_data.completion_percentage",
    textId: "action-plans.table.headers.completion",
    classes:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell",
  },
  {
    key: "meta_data.total_corrective_actions",
    textId: "action-plans.table.headers.number_of_actions",
    classes:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell",
  },
  {
    key: "meta_data.late_actions",
    textId: "action-plans.table.headers.number_of_overdue_actions",
    classes:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell",
  },
  {
    key: "meta_data.updated_at",
    textId: "action-plans.table.headers.last_updated_at",
    classes:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function List() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, send] = useListActionPlansMachine();
  const [viewEditActionPlanState, viewEditActionPlanSend] =
    useViewEditActionPlanMachine();
  const { keycloak } = useAuth();

  useEffect(() => {
    if (state.matches("idle")) {
      send("LOAD", {
        data: {
          authentication: {
            token: keycloak.token,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log("Token is refreshing : send token to list machine");
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);

  return (
    <div>
      {!state.matches("error") && (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold leading-6 text-gray-900">
                {t("action-plans.header")}
              </h1>
              <p className="mt-2 text-xs text-gray-500">
                {t("action-plans.label.last_refreshed_at")}{" "}
                <TimeAgo isoString={state?.context?.loaded_at} />
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              {state.matches("loading") && (
                <button
                  type="button"
                  className={classNames(
                    "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                    "cursor-not-allowed",
                  )}
                  disabled={true}
                >
                  {state.matches("loading") && (
                    <svg
                      className={classNames(
                        "-ml-1 mr-3 h-5 w-5 text-white",
                        "animate-spin ",
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {t("reports.status.loading")}
                </button>
              )}
              {state.can("RELOAD") && (
                <button
                  type="button"
                  className={classNames(
                    "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                    state.matches("loading") ? "cursor-not-allowed" : "",
                  )}
                  onClick={() => {
                    send("RELOAD");
                  }}
                  disabled={state.matches("loading")}
                >
                  {state.matches("loading") && (
                    <svg
                      className={classNames(
                        "-ml-1 mr-3 h-5 w-5 text-white",
                        "animate-spin ",
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {t("reports.actions.refresh")}
                </button>
              )}
            </div>
          </div>
          <div className="-mx-4 mt-10 bg-white ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <SortableTableHeader
                headers={headers}
                sortedBy={state?.context?.currentSortKey}
                sortOrder={state?.context?.currentSortDirection}
                onHeaderClick={(header) => {
                  send({
                    type: "SORT",
                    sortKey: header.key,
                    comparator: header.comparator,
                  });
                }}
              />

              <tbody>
                {state?.context?.actionPlans?.map((actionPlan, planIdx) => (
                  <tr key={actionPlan.id}>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6",
                      )}
                    >
                      <div className="font-medium text-gray-900">
                        <div className="flex w-full flex-none gap-x-2   text-sm leading-6 text-gray-900">
                          <div>
                            <BuildingOffice2Icon
                              className="h-6 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="text-sm  leading-6 text-gray-500">
                            {actionPlan.residence_one_key}
                          </div>
                          <div className="mb-auto ml-0.5 mt-auto  text-xs text-gray-500 sm:hidden   md:ml-0">
                            {" "}
                            <span className="">
                              {t("action-plans.table.headers.last_updated_at")}
                            </span>
                            <span className="ml-1">
                              <TimeAgo
                                isoString={actionPlan.meta_data.updated_at}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1.5 flex  text-gray-500 sm:block md:hidden">
                        <span
                          className={classNames(
                            statuses[actionPlan.meta_data.global_status],
                            "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                          )}
                        >
                          {t(
                            "shared.type.actionPlanStatus." +
                              actionPlan.meta_data.global_status,
                          )}
                        </span>

                        <span
                          className={classNames(
                            planIdx === 0 ? "" : "border-t border-gray-200",
                            "ml-3 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium",
                            "bg-gray-100 text-gray-500",
                          )}
                        >
                          {actionPlan.meta_data.completion_percentage} %
                        </span>
                        <span className="mb-auto ml-3 mt-auto  text-xs md:ml-0">
                          <span className="font-semibold">
                            {actionPlan.meta_data.total_corrective_actions}
                          </span>{" "}
                          actions
                        </span>
                        <span className="mb-auto  ml-1.5 mt-auto border-l border-gray-200 pl-1.5 text-xs  md:ml-0">
                          <span className="font-semibold">
                            {actionPlan.meta_data.late_actions}
                          </span>{" "}
                          late actions
                        </span>
                        <span className="mb-auto ml-1.5 mt-auto  hidden pl-1.5 text-xs sm:inline sm:border-l sm:border-gray-200  md:ml-0">
                          <span className="font-semibold">
                            {t("action-plans.table.headers.last_updated_at")}
                          </span>
                          <span className="ml-1">
                            <TimeAgo
                              isoString={actionPlan.meta_data.updated_at}
                            />
                          </span>
                        </span>
                      </div>
                      {/* <div className="ml-0.5 mt-1.5  text-xs text-gray-500 sm:hidden   md:ml-0">
                    {" "}
                    <span className="">
                      {t("action-plans.table.headers.last_updated_at")}
                    </span>
                    <span className="ml-1">
                      <TimeAgo isoString={actionPlan.meta_data.updated_at} />
                    </span>
                  </div> */}
                      {planIdx !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "relative hidden py-3.5 pl-3 pr-4 text-sm font-medium sm:pr-6 md:table-cell",
                      )}
                    >
                      <span
                        className={classNames(
                          statuses[actionPlan.meta_data.global_status],
                          "inline-flex items-center gap-x-1.5  whitespace-nowrap rounded-md px-2  py-1 text-xs font-medium shadow-md ring-1 ring-inset",
                        )}
                      >
                        {t(
                          "shared.type.actionPlanStatus." +
                            actionPlan.meta_data.global_status,
                        )}
                      </span>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "relative hidden px-3 py-3.5 text-sm text-gray-500  md:table-cell ",
                      )}
                    >
                      <span
                        className={classNames(
                          planIdx === 0 ? "" : "border-t border-gray-200",
                          "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1 text-xs font-medium",
                          "bg-gray-100 text-gray-500",
                        )}
                      >
                        {actionPlan.meta_data.completion_percentage} %
                      </span>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "relative hidden py-3.5 pl-3 pr-4 text-sm text-gray-500 sm:pr-6 md:table-cell",
                      )}
                    >
                      {actionPlan.meta_data.total_corrective_actions}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "relative hidden py-3.5 pl-3 pr-4 text-sm text-gray-500 sm:pr-6 md:table-cell",
                      )}
                    >
                      {actionPlan.meta_data.late_actions}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "relative hidden py-3.5 pl-3 pr-4 text-sm text-gray-500 sm:pr-6 md:table-cell",
                      )}
                    >
                      <TimeAgo isoString={actionPlan.meta_data.updated_at} />
                    </td>

                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative  py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6",
                      )}
                    >
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={() => {
                          viewEditActionPlanSend("LOAD", {
                            data: {
                              authentication: {
                                token: keycloak.token,
                              },
                              id: actionPlan.id,
                              residence_one_key: actionPlan.residence_one_key,
                            },
                          });
                          navigate(
                            `/action-plans/${actionPlan.id}?residence_one_key=${actionPlan.residence_one_key}`,
                          );
                        }}
                      >
                        {t("reports.table.actions.view")}
                        <span className="sr-only">, </span>
                      </button>
                      {planIdx !== 0 ? (
                        <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {state.matches("error.access denied") && (
        <div className="-mt-10">
          <AccessDenied />
        </div>
      )}
    </div>
  );
}
