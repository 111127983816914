const { actions } = require("xstate");
const { assign } = actions;

const saveAuthentication = assign({
  authentication: (context, event) => {
    return {
      ...context.authentication,
      ...event.authentication,
    };
  },
});

const saveToken = assign({
  authentication: (context, event) => {
    return {
      ...context.authentication,
      token: event.data,
    };
  },
});

const saveError = assign({
  error: (context, event) => event.data,
});

const notifyError = (context, event) => {
  console.log("notifyError", context.error);
};

const resetChangeSet = assign({
  changeSet: (context, event) => {
    console.log("Current changest befor reset", context.changeSet);
    return undefined;
  },
});

const flagChanges = assign({
  changesHappened: (context, event) => {
    console.log("flagChanges", context.changesHappened);
    return true;
  },
});

const unflagChanges = assign({
  changesHappened: (context, event) => {
    return false;
  },
});

const saveLoadRequest = assign({
  authentication: (context, event) => {
    return {
      ...context.authentication,
      token: event.data.authentication.token,
    };
  },
});

const updateLastRefreshedTimestamp = assign({
  loaded_at: () => {
    return new Date().toISOString();
  },
});

const setIndexOnFirstPage = assign({
  index: (context, event) => 0,
});

const incrementIndex = assign({
  index: (context, event) => {
    console.log("incrementIndex", context.index);
    return context.index + 1;
  },
});

const decrementIndex = assign({
  index: (context, event) => {
    return context.index - 1;
  },
});

const resetPageIndex = assign({
  index: (context, event) => null,
});

const addValueToFilter = assign({
  filters: (context, event) => {
    const updatedFilters = context.filters.map((filter) => {
      if (filter.id === event.data.filterId) {
        return {
          ...filter,
          options: filter.options.map((option) => {
            if (option.value === event.data.value) {
              return {
                ...option,
                checked: true,
              };
            }
            return option;
          }),
        };
      }
      return filter;
    });
    return updatedFilters;
  },
});

const removeValueFromFilter = assign({
  filters: (context, event) => {
    const updatedFilters = context.filters.map((filter) => {
      if (filter.id === event.data.filterId) {
        return {
          ...filter,
          options: filter.options.map((option) => {
            if (option.value === event.data.value) {
              return {
                ...option,
                checked: false,
              };
            }
            return option;
          }),
        };
      }
      return filter;
    });
    return updatedFilters;
  },
});

const filterRecords = (filters, records) => {
  return records.filter((record) => {
    // Vérifier chaque record pour voir s'il satisfait toutes les conditions des filtres
    return filters.every((filter) => {
      // Vérifier si les options existent et récupérer seulement celles qui sont cochées
      const checkedOptions = filter.options
        ? filter.options.filter((option) => option.checked)
        : [];

      // Si aucune option n'est cochée ou si les options sont nulles ou un tableau vide, le filtre est valide
      if (!filter.options || checkedOptions.length === 0) {
        return true;
      }

      // Obtenir la valeur du record pour le filtre actuel
      const recordValue = filter.getter(record);

      // Vérifier si la valeur du record correspond à l'une des options cochées
      return checkedOptions.some((option) => option.value === recordValue);
    });
  });
};

const saveReportId = assign({
  reportId: (context, event) => {
    console.log("event reportid", event);
    return event.reportId;
  },
});

const saveApiUrl = assign({
  qmsToolApiUrl: (context, event) => {
    return event.qmsToolApiUrl;
  },
});

const flagDeferralReasonAsMandatory = assign({
  deferralReasonMandatory: (context, event) => {
    return true;
  },
});

const unflagDeferralReasonAsMandatory = assign({
  deferralReasonMandatory: (context, event) => {
    return false;
  },
});

export {
  saveAuthentication,
  saveToken,
  saveError,
  notifyError,
  resetChangeSet,
  flagChanges,
  unflagChanges,
  saveLoadRequest,
  updateLastRefreshedTimestamp,
  setIndexOnFirstPage,
  incrementIndex,
  decrementIndex,
  resetPageIndex,
  addValueToFilter,
  removeValueFromFilter,
  filterRecords,
  saveReportId,
  saveApiUrl,
  flagDeferralReasonAsMandatory,
  unflagDeferralReasonAsMandatory,
};
