import axios from "axios";

const defaultPostOptions = {
  method: "Post",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const createFiveWhys = (context, event) => {
  try {
    console.log("createFiveWhys", context);
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify(context?.changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/five-whys`,
      options,
    ).then((response) => response?.data?.five_whys);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { createFiveWhys };
