import axios from "axios";

const defaultPostOptions = {
  method: "Post",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const createCorrection = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
      data: JSON.stringify(context?.changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.loadRequest.reportId}/corrections`,
      options,
    ).then((response) => response?.data?.corrections);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { createCorrection };
