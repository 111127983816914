import StepsContainer from "../../container";
import Info from "../../Info";
import Statement from "../../Statement";
import { useTranslation } from "react-i18next";
import { useReviewFiveWhysMachine } from "../../../../context/ReviewFiveWhysMachineContext";

export default function Answer1() {
  const { t } = useTranslation();
  const [state, send] = useReviewFiveWhysMachine();
  return (
    <>
      <StepsContainer>
        <Info>
          <h2 className="font-medium">
            {t("editFiveWhys.steps.answer1.info.header")}
          </h2>
          <div className="mt-3">
            {t("editFiveWhys.steps.answer1.info.subHeader")}
          </div>
          <div className="mt-3">
            {t("editFiveWhys.steps.answer1.info.guidance")}
          </div>
        </Info>
        <div className="space-y-6 pb-5 pt-6">
          <div>
            {/* PROBLEM STATEMENT */}
            <label
              htmlFor="problem_statement"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("editFiveWhys.steps.form.problem.label")}
            </label>
            {/* <div className="mt-2 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-sm text-gray-500">
              <div>{state?.context?.changeSet?.problem_statement}</div>
            </div> */}
            <Statement value={state?.context?.changeSet?.problem_statement} />
          </div>
          {/* Answer 1 */}
          <div>
            <label
              htmlFor="answer_why_1"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("editFiveWhys.steps.form.why.label")}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="answer_why_1"
                id="answer_why_1"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  send("ANSWER_CHANGED", {
                    data: e.target.value,
                  });
                }}
                value={state?.context?.changeSet?.answer_why_1}
              />
            </div>
          </div>
        </div>
      </StepsContainer>
    </>
  );
}
