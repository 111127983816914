import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { EditSeqOfEventsMachine as parentMachine } from "../machines/edit-seq-of-events/edit-seq-of-events-machine";

const EditSeqOfEventsMachineContext = createContext();

export function useEditSeqOfEventsMachine() {
  const context = useContext(EditSeqOfEventsMachineContext);

  if (!context) {
    throw new Error(
      "useEditSeqOfEventsMachine must be used within a EditSeqOfEventsMachineProvider",
    );
  }

  return context;
}

export function EditSeqOfEventsMachineProvider({ children }) {
  const editSeqOfEventsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <EditSeqOfEventsMachineContext.Provider value={editSeqOfEventsMachine}>
      {children}
    </EditSeqOfEventsMachineContext.Provider>
  );
}
