const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const createFetchAgentMachine = ({
  token,
  url,
  loadedEventType,
  errorEventType,
}) => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QDMwBcDGALAtAQxgDs0A6ASwgBswBiAGQHkBBAEQG0AGAXUVAAcA9rDJoyAwrxAAPRAGZZJACyKAnACYOAVk0aAbGo3KANCACeiAIwXFJABxrZHZYu2LdulQHYAvt5OpMXAIwYhJKATwIMkIoGghxMHJCADcBAGtEgOx8IlJwyOioBGjUjDxRcU4uKslBYQqJJGlEHEcSC1ldTwsOWxULXU0Lbs8TcwRZIZJPWTU+rrUVW01bYd9-dGzg0PyomJowACdDgUOSPkpy5FOAWxIsoNywiL2ikoEyhqqaprqRMUaoBkCDUFlsSkUak8KnUKlUg0UsjGll0CmUk0Ungcq08HDU6xADxyITyL0g9GY7G4tSE-3EkmBOAcCk8umWHk0KkGHBhumRE1RJE0shUwtsinsMNBBKJ21JkXJAFUAAosJgAFQAogB9ABCmqYACVNYbteqGABpTUAOR+-FpDQZLS6JA4VixtlsXRm1jU-N0HAUmkxskx-TFyhlm0eJJIRxOhwprDtID+jqawJ0HBIodkrM0+lkVkh-LUuhs9jxMM8wsmRd8fhAhAEEDgklluRp9QBToQTNW0zZBdFXM0PK5-NaahIXn0S26HFZFhWUcCxNCFGoXbpgOaCADrvcIpGK0hkM0-ODObcugsagLPSxWNXWyeu0K2-TQMQmjsiM8kJ5hwAbBh4paKBYOYaI4Y6giKKiyC+MY7GSECfj2GYtJyrpQrmNb8hKCjLN0vpLqCgxIeupDxqc6H0phCAQZ4rorLePQ9KKsz8nev7ohGXT9G6PgNkAA */
      id: "fetch-agent",

      predictableActionArguments: true,

      context: {
        authentication: { token: token },
        url: url,
        loadedEventType: loadedEventType,
        errorEventType: errorEventType,
      },

      states: {
        idle: {
          on: {
            LOAD: {
              target: "loading",
              actions: "saveLoadRequest",
            },
          },
        },

        loading: {
          invoke: {
            src: "fetchData",

            onDone: {
              target: "loaded",
              actions: ["saveData", "updateLastRefreshedTimestamp"],
            },

            onError: "error",
          },
        },

        loaded: {
          entry: "sendLoadedEventTypeToParent",

          on: {
            LOAD: {
              target: "loading",
              actions: "saveLoadRequest",
            },

            UPDATE_BEARER_TOKEN: {
              target: "loaded",
              internal: true,
              cond: "validToken",
              actions: "saveToken",
            },
          },
        },

        error: {
          entry: ["sendErrorEventTypeToParent", "saveError"],

          on: {
            LOAD: "loading",
          },
        },
      },

      initial: "idle",
    },
    {
      actions: actions,
      guards: guards,
      services: services,
    },
  );
};

export { createFetchAgentMachine };
