import { useTranslation } from "react-i18next";
import { useListReportsMachine } from "../../../context/ListReportsMachineContext";
import { useAuth } from "../../../context/AuthContext";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  useClose,
} from "@headlessui/react";
import SearchOptions from "./SearchOptions";
import Range from "../shared/Range";

import {
  MagnifyingGlassIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";

export default function SearchWidget() {
  const { t } = useTranslation();
  const [state, send] = useListReportsMachine();
  const advancedSearchAgentRef = state?.context?.advancedSearchAgentRef;
  const { keycloak } = useAuth();

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="w-5 text-gray-400"
                />
              </div>
              <input
                id="search"
                name="search"
                type="text"
                className="block w-full rounded-none rounded-l-md  border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={t("shared.searchWidget.placeholder")}
                value={state.context.searchValue || ""}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    send("LOAD", {
                      data: {
                        authentication: {
                          token: keycloak.token,
                        },
                        searchValue: e.target.value,
                      },
                    });
                  }
                }}
                onChange={(e) => {
                  send("SEARCH_VALUE_CHANGED", {
                    data: { searchValue: e.target.value },
                  });
                }}
              />
            </div>
            {/* Range */}
            {state?.context?.advancedSearch?.startDate &&
              state?.context?.advancedSearch?.endDate && (
                <div
                  className={
                    "relative -ml-px  inline-flex items-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 "
                  }
                >
                  <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium uppercase text-gray-500 ring-1 ring-inset ring-gray-500/10">
                    <Range
                      isoStringStart={state.context.advancedSearch.startDate}
                      isoStringEnd={state.context.advancedSearch.endDate}
                    />
                    <button
                      type="button"
                      className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400  hover:bg-gray-200 hover:text-gray-500"
                      onClick={(event) => {
                        advancedSearchAgentRef.send({
                          type: "CLEAR",
                        });
                      }}
                    >
                      {" "}
                      <span className="sr-only">
                        Clear advanced search options
                      </span>
                      <svg
                        className="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeWidth="1.5"
                          d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}

            {/* Period */}
            {((state?.context?.advancedSearch?.month &&
              state?.context?.advancedSearch?.year) ||
              (!state?.context?.advancedSearch?.month &&
                state?.context?.advancedSearch?.year)) && (
              <div
                className={
                  "relative -ml-px  inline-flex items-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 "
                }
              >
                <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium uppercase text-gray-500 ring-1 ring-inset ring-gray-500/10">
                  {state?.context?.advancedSearch?.year}
                  {state?.context?.advancedSearch?.month ? "-" : ""}
                  {state?.context?.advancedSearch?.month}
                  <button
                    type="button"
                    className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400  hover:bg-gray-200 hover:text-gray-500"
                    onClick={(event) => {
                      advancedSearchAgentRef.send({
                        type: "CLEAR",
                      });
                    }}
                  >
                    {" "}
                    <span className="sr-only">
                      Clear advanced search options
                    </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}

            <PopoverButton
              type="button"
              className="relative -ml-px  inline-flex items-center gap-x-1.5  rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <AdjustmentsHorizontalIcon
                aria-hidden="true"
                className="-ml-0.5 h-5 w-5 text-gray-400"
              />
              {t("shared.searchWidget.options.label")}
            </PopoverButton>
            <PopoverPanel
              anchor="bottom end"
              className=" !overflow-visible rounded-md bg-white text-sm/6 shadow-2xl ring-1 ring-inset ring-gray-300 transition duration-200 ease-in-out [--anchor-gap:0.5rem] data-[closed]:-translate-y-1 data-[closed]:opacity-0 "
            >
              <SearchOptions
                advancedSearchAgentRef={advancedSearchAgentRef}
                close={close}
              />
            </PopoverPanel>
          </div>
        </>
      )}
    </Popover>
  );
}
