import { TextArea } from "../../../TextArea";
import StepsContainer from "../../../container";
import GenericSelectMenu from "../../../GenericSelectMenu";
import DateTimePicker from "../../../DateTimePicker";
import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import { useTranslation } from "react-i18next";
import PeriodOfTheDayBadge from "../../../PeriodOfTheDayBadge";

export default function Step3() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { t } = useTranslation();

  return (
    <>
      <StepsContainer>
        <div>
          <div className="flex w-full items-end justify-between">
            <label className="block text-sm font-medium leading-6 text-gray-600">
              {t("createReport.steps.step3.form.label.eventDateTime")}
            </label>

            <PeriodOfTheDayBadge
              periodOfTheDay={
                createReportState.context?.report?.event?.period_of_the_day
              }
            />
          </div>
          <div className="mt-2">
            <DateTimePicker
              //label={t("createReport.steps.step3.form.label.eventDateTime")}
              initialDate={
                createReportState.context?.report?.event?.event_occured_at
              }
              onChange={(iso861DateStr) => {
                createReportSend("OCCURENCE_DATE_SELECTED", {
                  data: iso861DateStr,
                });
              }}
              max={new Date()}
            />
          </div>
        </div>
        <div>
          {/* <GenericSelectMenu
            data={["DAY", "NIGHT"]}
            selectedItem={
              createReportState.context?.report?.event?.period_of_the_day
            }
            onItemSelected={(element) => {
              console.log("element", element);
              createReportSend("PERIOD_OF_THE_DAY_SELECTED", {
                data: element,
              });
            }}
            noSelectionText={t(
              "createReport.steps.step3.form.placeholder.periodOfTheDay",
            )}
            label={t("createReport.steps.step3.form.label.periodOfTheDay")}
            useNameForKey={false}
            useNameForValue={false}
            i18nPrefixKey="shared.type.periodOfTheDay."
          /> */}
        </div>

        <div className="col-span-full">
          <TextArea
            id="event-description"
            name="event-description"
            rows={10}
            label={t("createReport.steps.step3.form.label.eventDescription")}
            //helpText="Veuillez décrire les circonstances de l'évènement"
            onChange={(event) => {
              createReportSend("DESCRIPTION_CHANGED", {
                data: event.target.value,
              });
            }}
            value={createReportState?.context?.report?.event?.description}
          />
        </div>
      </StepsContainer>
    </>
  );
}
