import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ViewEditReportMachine as parentMachine } from "../machines/view-edit-report/view-edit-report-machine";
const ViewEditReportMachineContext = createContext();

export function useViewEditReportMachine() {
  const context = useContext(ViewEditReportMachineContext);
  if (!context) {
    throw new Error(
      "useViewEditReportMachine must be used within a ViewEditReportMachineProvider",
    );
  }
  return context;
}

export function ViewEditReportMachineProvider({ children }) {
  const viewEditReportMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ViewEditReportMachineContext.Provider value={viewEditReportMachine}>
      {children}
    </ViewEditReportMachineContext.Provider>
  );
}
