import axios from "axios";

const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const defaultPostOptions = {
  method: "POST",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};
const fetchActionPlansToBeCreated = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(
    `${context.qmsToolApiUrl}action-plans/to-be-created`,
    options,
  ).then((response) => response.data);
};

const createActionPlan = (context, event) => {
  const options = {
    ...defaultPostOptions,
    headers: {
      ...defaultPostOptions.headers,
      Authorization: `Bearer ${context.authentication.token}`,
    },
    data: JSON.stringify({
      residence_one_key: context?.createRequest?.residenceOneKey,
    }),
  };
  return axios(`${context.qmsToolApiUrl}action-plans`, options).then(
    (response) => response.data,
  );
};

export { fetchActionPlansToBeCreated, createActionPlan };
