import React, { useEffect, useRef } from "react";
import { useActor } from "@xstate/react";
import { useTranslation } from "react-i18next";
import GenericSelectMenu from "../../GenericSelectMenu";
import GenericText from "../../GenericText";
import DateTimePicker from "../../DateTimePicker";
import { useCreateReportMachine } from "../../../../context/CreateReportMachineContext";
import { TextArea } from "../../TextArea";
import ShortDate from "../../ShortDate";
import i18n from "../../../../i18n";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function CorrectiveAction({
  correctiveAction,
  forceUpdateMethod,
}) {
  const [state, send] = useActor(correctiveAction.ref);
  const [createReportState] = useCreateReportMachine();
  const { t } = useTranslation();

  useEffect(() => {
    forceUpdateMethod();
  }, [state]);

  return (
    <>
      <div className="space-y-6  pb-96 pt-6">
        {state.context.correctiveAction.completed_at && (
          <div className="text-xs font-semibold text-gray-400">
            <span>{t("editCorrectiveActions.view.label.completedAt")}</span>
            &nbsp;
            <ShortDate
              isoString={state.context.correctiveAction.completed_at}
              locale={i18n.language}
            />
          </div>
        )}
        {/* Source */}
        <div>
          <GenericSelectMenu
            data={createReportState.context?.correctiveActionSources}
            selectedItem={state.context?.correctiveAction?.source}
            onItemSelected={(element) => {
              send({
                type: "CORRECTIVE_ACTION_SOURCE_SELECTED",
                data: element,
              });
            }}
            label={t("createCorrectiveAction.step1.label.source")}
            noSelectionText={t(
              "createCorrectiveAction.step1.placeholder.source",
            )}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.correctiveAction.source."
            disabled={state.context?.correctiveAction?.report}
            autoFocus={!state.context?.correctiveAction?.report}
          />
        </div>
        {/* Priority */}
        <div>
          <GenericSelectMenu
            data={createReportState.context?.correctiveActionPriorities}
            selectedItem={state.context?.correctiveAction?.priority}
            onItemSelected={(element) => {
              send({
                type: "CORRECTIVE_ACTION_PRIORITY_SELECTED",
                data: element,
              });
            }}
            label={t("createCorrectiveAction.step1.label.priority")}
            noSelectionText={t(
              "createCorrectiveAction.step1.placeholder.priority",
            )}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.correctiveAction.priority."
            autoFocus={state.context?.correctiveAction?.report}
          />
        </div>
        {/* Domain */}
        <div>
          <GenericSelectMenu
            //data={createReportState.context?.domains}
            data={createReportState.context?.restrictedDomains}
            selectedItem={
              state.context?.correctiveAction?.domain ||
              state.context?.correctiveAction?.report.event.domain
            }
            onItemSelected={(element) => {
              send({ type: "DOMAIN_SELECTED", data: element });
            }}
            label={t("createCorrectiveAction.step1.label.domain")}
            noSelectionText={t(
              "createCorrectiveAction.step1.placeholder.domain",
            )}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.domain."
            disabled={state.context?.correctiveAction?.report}
          />
        </div>
        {/* Problem statement - Report */}
        {state.context?.correctiveAction?.report?.five_whys
          ?.problem_statement && (
          <GenericText
            label={t("createCorrectiveAction.step1.label.problemStatement")}
            // placeholder={t("createCorrectiveAction.step1.placeholder.problemStatement")}
            value={
              state.context?.correctiveAction.report.five_whys.problem_statement
            }
            disabled={state.context?.correctiveAction?.report}
          />
        )}
        {/* Problem statement */}
        {!state.context?.correctiveAction?.report && (
          <GenericText
            label={t("createCorrectiveAction.step1.label.problemStatement")}
            // placeholder={t("createCorrectiveAction.step1.placeholder.problemStatement")}
            onChange={(value) => {
              send({
                type: "PROBLEM_STATEMENT_CHANGED",
                data: value,
              });
            }}
            value={state.context?.correctiveAction?.problem_statement || ""}
            // disabled={!state.context?.correctiveAction?.report}
          />
        )}
        {/* Root cause - Report*/}
        {state.context?.correctiveAction?.report?.five_whys?.final_answer && (
          <GenericText
            label={t("createCorrectiveAction.step1.label.rootCause")}
            // placeholder={t("createCorrectiveAction.step1.placeholder.rootCause")}
            onChange={(value) => {
              send({
                type: "ROOT_CAUSE_CHANGED",
                data: value,
              });
            }}
            value={
              state.context?.correctiveAction?.report?.five_whys?.final_answer
            }
            disabled={state.context?.correctiveAction?.report}
          />
        )}
        {/* Root cause */}
        {!state.context?.correctiveAction?.report && (
          <GenericText
            label={t("createCorrectiveAction.step1.label.rootCause")}
            // placeholder={t("createCorrectiveAction.step1.placeholder.rootCause")}
            onChange={(value) => {
              send({
                type: "ROOT_CAUSE_CHANGED",
                data: value,
              });
            }}
            value={state.context?.correctiveAction?.root_cause || ""}
            // disabled={!state.context?.correctiveAction?.report}
          />
        )}
        {/* Status */}
        <div>
          <GenericSelectMenu
            data={["TODO", "ONGOING", "COMPLETE"]}
            selectedItem={state.context?.correctiveAction.status}
            onItemSelected={(element) => {
              send({
                type: "STATUS_CHANGED",
                data: element,
              });
            }}
            noSelectionText={t(
              "reviewActionPlan.correctiveAction.placeholder.status",
            )}
            label={t("reviewActionPlan.correctiveAction.label.status")}
            useNameForKey={false}
            useNameForValue={false}
            i18nPrefixKey="shared.type.correctiveActionStatus."
            //autoFocus={state.context?.correctiveAction?.report}
          />
        </div>
        {/* Description */}
        <div>
          {/* <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            {t("editCorrectiveActions.view.label.description")}
          </label> */}
          <div className="mt-2">
            <TextArea
              label={t("editCorrectiveActions.view.label.description")}
              rows={5}
              onChange={(e) => {
                send({
                  type: "DESCRIPTION_CHANGED",
                  data: e.target.value,
                });
              }}
              value={state.context.correctiveAction.description || ""}
              // disabled={!state.context?.correctiveAction?.report}
            />
            {/* <input
              type="text"
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              onChange={(e) => {
                console.log(e.target.value);
                send({
                  type: "DESCRIPTION_CHANGED",
                  data: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  send("UPDATE");
                }
              }}
              value={state.context.correctiveAction.description}
            ></input> */}
          </div>
        </div>

        {/* Handled by
        <GenericText
          label={t("createCorrectiveAction.step1.label.handledBy")}
          // placeholder={t("createCorrectiveAction.step1.placeholder.handledBy")}
          onChange={(value) => {
            send({
              type: "HANDLED_BY_CHANGED",
              data: value,
            });
          }}
          value={state.context?.correctiveAction.handled_by}
        /> */}
        {/* Priority */}
        <div>
          <GenericSelectMenu
            data={createReportState.context?.correctiveActionManagerTypes}
            selectedItem={state.context?.correctiveAction?.handler_type}
            onItemSelected={(element) => {
              send({
                type: "HANDLER_TYPE_CHANGED",
                data: element,
              });
            }}
            label={t("createCorrectiveAction.step1.label.handledBy")}
            noSelectionText={t(
              "createCorrectiveAction.step1.placeholder.handledBy",
            )}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.correctiveAction.managerTypes."
          />
        </div>
        {/* Deadline */}
        <DateTimePicker
          label={t("createCorrectiveAction.step1.label.expiredAt")}
          initialDate={state.context?.correctiveAction.expired_at}
          onChange={(iso861DateStr) => {
            send({
              type: "EXPIRATION_DATE_SELECTED",
              data: iso861DateStr,
            });
          }}
          min={new Date()}
        />
        {/* 
        <div className=" flex justify-end">
          <button
            type="button"
            onClick={() => send("UPDATE")}
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0  sm:w-auto"
            disabled={!state.can("UPDATE")}
          >
            {!state.matches("update.updating") && (
              <>{t("editReport.view.action.save")}</>
            )}
            {state.matches("update.updating") && (
              <>
                <svg
                  className={classNames(
                    "-ml-1 mr-3  h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {t("shared.button.saving")}
              </>
            )}
          </button>
        </div> */}
        {state.context.deferralReasonMandatory && (
          <TextArea
            label={t("editCorrectiveActions.view.label.deferralReason")}
            rows={2}
            onChange={(e) => {
              send({
                type: "DEFERRAL_REASON_CHANGED",
                data: e.target.value,
              });
            }}
            disabled={!state.context.deferralReasonMandatory}
            required={state.context.deferralReasonMandatory}
          />
        )}
      </div>
    </>
  );
}
