// auth-context.js

import { createContext, useContext, useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import { useCreateReportMachine } from "./CreateReportMachineContext";

import { useViewEditReportMachine } from "./ViewEditReportMachineContext";
import { useListReportsMachine } from "./ListReportsMachineContext";
import { useMachine } from "@xstate/react";

import authMachine from "../stateMachine";

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const refreshToken = async (keycloak, send) => {
  console.log("refreshing token");
  try {
    const refreshed = await keycloak.updateToken(70);
    if (refreshed) {
      send("TOKEN_REFRESHED");
    }
  } catch (error) {
    send("FAILURE");
  }
};

export const AuthContextProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [profile, setProfile] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [state, send] = useMachine(authMachine);

  const [createReportMachine, sendCreate] = useCreateReportMachine();
  const [viewEditReportMachine, sendViewEdit] = useViewEditReportMachine();
  const refViewEdit = useViewEditReportMachine();

  const refreshToken = async () => {
    console.log("refreshing token");
    const refreshed = await keycloak.updateToken(70);
    try {
      if (refreshed) {
        send("TOKEN_REFRESHED");
        sendCreate("UPDATE_BEARER_TOKEN", { data: keycloak?.token });

        sendViewEdit("UPDATE_BEARER_TOKEN", { data: keycloak?.token });
      } else {
        send("FAILURE");
      }
    } catch (error) {
      send("FAILURE");
    }
  };
  function getTokenExpirationInSeconds(keycloak) {
    // Ensure that the token is parsed
    if (!keycloak.tokenParsed) {
      throw new Error("Token not parsed or user is not authenticated");
    }

    // Get the current time in seconds since the Unix epoch
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);

    // Calculate the time left in seconds
    const secondsLeft = keycloak.tokenParsed.exp - currentTimeInSeconds;

    return Math.max(secondsLeft, 0); // Return 0 if token is already expired
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      send("ACTIVATE");
    };

    window.addEventListener("mousemove", handleMouseMove);

    const kc = new Keycloak({
      url:
        process.env.REACT_APP_KC_URL || "https://auth.local.orpea-belgium.com/",
      realm: process.env.REACT_APP_KC_REALM || "leadtool-v3-local",
      clientId: process.env.REACT_APP_KC_CLIENT_ID || "qmstool-v1-frontend",
    });
    kc.onTokenExpired = () => {
      console.log("token expired");
      send("LOGOUT");
    };

    // Initialize Keycloak and check if user is authenticated
    kc.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    })
      .then(async (auth) => {
        if (auth) {
          const profile = await kc.loadUserProfile();
          setProfile(profile);
          setKeycloak(kc);
          setAuthenticated(true);
          send("AUTHENTICATED");
          sendCreate("UPDATE_BEARER_TOKEN", { data: kc?.token });
          sendViewEdit("UPDATE_BEARER_TOKEN", { data: kc?.token });
          //sendCreate("SET_EDIT_REPORT_MACHINE_REF", { data: editReportMachine });
          sendCreate("SET_EDIT_REPORT_MACHINE_REF", {
            data: refViewEdit,
          });

          setInterval(() => {
            if (kc.token && kc.isTokenExpired(30)) {
              // Check 60 seconds before expiry
              send("TOKEN_EXPIRING", { data: getTokenExpirationInSeconds(kc) });
              console.debug("sending token expiring");
            } else {
              console.debug("token not expired");
            }
          }, 10000); // Check every 60 seconds
        } else {
          setKeycloak(kc);
          setAuthenticated(false);
          setProfile(null);

          // Redirect to Keycloak login page immediately
          kc.login();
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // Cleanup: Logout from Keycloak when the component is unmounted to prevent memory leaks and ensure user session closure.
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (keycloak) {
        keycloak.logout();
      }
    };
  }, []);

  useEffect(() => {
    if (state.matches("logout")) {
      console.log("logout");
      if (keycloak) {
        keycloak.logout();
      }
    }
    if (state.matches({ dashboard: "refreshingToken" })) {
      refreshToken();
    }
  }, [state]);

  const value = {
    isAuthenticated: authenticated,
    keycloak: keycloak,
    profile: profile,
    state: state,
    send: send,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
