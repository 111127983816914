const { actions } = require("xstate");
const { assign } = actions;

const saveProblemStatement = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      problem_statement: event.data,
    };
  },
});

const saveAnswer1 = assign({
  changeSet: (context, event) => {
    console.log("saveAnswer1", event.data);
    return {
      ...context.changeSet,
      answer_why_1: event.data,
    };
  },
});

const saveAnswer2 = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_2: event.data,
    };
  },
});

const saveAnswer3 = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_3: event.data,
    };
  },
});

const saveAnswer4 = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_4: event.data,
    };
  },
});

const saveAnswer5 = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_5: event.data,
    };
  },
});

const saveOtherPossibleCauses = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      other_possible_causes: event.data,
    };
  },
});

const resetChangeSet = assign({
  changeSet: (context, event) => {
    return {};
  },
});

const resetFinalAnswer = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      final_answer: undefined,
    };
  },
});

const saveAnswer2AsFinal = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_3: undefined,
      answer_why_4: undefined,
      answer_why_5: undefined,
      final_why_index: 2,
      final_answer: context.changeSet.answer_why_2,
    };
  },
});

const saveAnswer3AsFinal = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_4: undefined,
      answer_why_5: undefined,
      final_why_index: 3,
      final_answer: context.changeSet.answer_why_3,
    };
  },
});

const saveAnswer4AsFinal = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      answer_why_5: undefined,
      final_why_index: 4,
      final_answer: context.changeSet.answer_why_4,
    };
  },
});

const saveAnswer5AsFinal = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      final_why_index: 5,
      final_answer: context.changeSet.answer_why_5,
    };
  },
});
const saveReportId = assign({
  reportId: (context, event) => {
    return event.data?.reportId;
  },
});

const saveAuthentication = assign({
  authentication: (context, event) => {
    return event.data?.authentication;
  },
});

const saveError = require("../../commons/actions").saveError;
const notifyError = require("../../commons/actions").notifyError;

const saveToken = require("../../commons/actions").saveToken;
const saveInitialChangeSet = assign({
  changeSet: (context, event) => {
    console.log("saveInitialChangeSet", event.data);
    return {
      ...event.data.changeSet,
    };
  },
});
export {
  saveProblemStatement,
  resetChangeSet,
  saveAnswer1,
  saveAnswer2,
  saveAnswer3,
  saveAnswer4,
  saveAnswer5,
  resetFinalAnswer,
  saveAnswer2AsFinal,
  saveAnswer3AsFinal,
  saveAnswer4AsFinal,
  saveAnswer5AsFinal,
  saveOtherPossibleCauses,
  saveError,
  notifyError,
  saveReportId,
  saveAuthentication,
  saveInitialChangeSet,
  saveToken,
};
