import React, { useEffect } from "react";
import { useActor } from "@xstate/react";
import { useTranslation } from "react-i18next";
import GenericSelectMenu from "../GenericSelectMenu";
import GenericText from "../GenericText";
import { TextArea } from "../TextArea";
import DateTimePicker from "../DateTimePicker";
import { useCreateReportMachine } from "../../../context/CreateReportMachineContext";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function CorrectiveAction({
  correctiveAction,
  forceUpdateMethod,
}) {
  const [state, send] = useActor(correctiveAction.ref);
  const { t } = useTranslation();
  const [createReportState, sendToCreateReport] = useCreateReportMachine();

  useEffect(() => {
    forceUpdateMethod();
  }, [state]);

  return (
    <>
      <div className="space-y-6 pb-5 pt-6">
        <div>
          <GenericSelectMenu
            data={["TODO", "ONGOING", "COMPLETE"]}
            selectedItem={state.context?.correctiveAction.status}
            onItemSelected={(element) => {
              send({
                type: "STATUS_CHANGED",
                data: element,
              });
            }}
            noSelectionText={t(
              "reviewActionPlan.correctiveAction.placeholder.status",
            )}
            label={t("reviewActionPlan.correctiveAction.label.status")}
            useNameForKey={false}
            useNameForValue={false}
            i18nPrefixKey="shared.type.correctiveActionStatus."
            autoFocus={true}
          />
        </div>
        {/* Description */}
        <div>
          {/* <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            {t("editCorrectiveActions.view.label.description")}
          </label> */}
          <div className="mt-2">
            <TextArea
              label={t("editCorrectiveActions.view.label.description")}
              onChange={(e) => {
                send({
                  type: "DESCRIPTION_CHANGED",
                  data: e.target.value,
                });
              }}
              value={state.context.correctiveAction.description || ""}
              // disabled={!state.context?.correctiveAction?.report}
            />
            {/* <input
              type="text"
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              onChange={(e) => {
                console.log(e.target.value);
                send({
                  type: "DESCRIPTION_CHANGED",
                  data: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  send("UPDATE");
                }
              }}
              value={state.context.correctiveAction.description}
            ></input> */}
          </div>
        </div>
        <div>
          <GenericSelectMenu
            data={createReportState.context?.correctiveActionManagerTypes}
            selectedItem={state.context?.correctiveAction?.handler_type}
            onItemSelected={(value) => {
              send({
                type: "HANDLER_TYPE_CHANGED",
                data: value,
              });
            }}
            label={t("createCorrectiveAction.step1.label.handledBy")}
            noSelectionText={t(
              "createCorrectiveAction.step1.placeholder.handledBy",
            )}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.correctiveAction.managerTypes."
          />
        </div>

        {/* Deadline */}
        <DateTimePicker
          label={t("createCorrectiveAction.step1.label.expiredAt")}
          initialDate={state.context?.correctiveAction.expired_at}
          onChange={(iso861DateStr) => {
            send({
              type: "EXPIRATION_DATE_SELECTED",
              data: iso861DateStr,
            });
          }}
          min={new Date()}
        />
        {state.context.deferralReasonMandatory && (
          <TextArea
            label={t("editCorrectiveActions.view.label.deferralReason")}
            rows={2}
            onChange={(e) => {
              send({
                type: "DEFERRAL_REASON_CHANGED",
                data: e.target.value,
              });
            }}
            disabled={!state.context.deferralReasonMandatory}
          />
        )}
      </div>
    </>
  );
}
