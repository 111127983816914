const { actions, spawn, send, sendTo } = require("xstate");
const { assign, log, pure } = actions;
const {
  createReviewCorrectionMachine,
} = require("../../review-correction/review-correction-machine");

const saveAuthentication = assign({
  authentication: (context, event) => {
    console.log("context", context);
    console.log("event", event);
    return {
      ...context.authentication,
      ...event.authentication,
    };
  },
});
const saveCorrections = assign({
  corrections: (context, event) => {
    return event.corrections.map((correction) => {
      return {
        ...correction,
        ref: spawn(
          createReviewCorrectionMachine({
            correction: correction,
            token: event.authentication.token,
            qmsToolApiUrl: event.qmsToolApiUrl,
            reportId: event.reportId,
          }),
        ),
      };
    });
  },
});

const setIndexOnFirstPage = assign({
  index: (context, event) => 0,
});

const incrementIndex = assign({
  index: (context, event) => {
    return context.index + 1;
  },
});

const decrementIndex = assign({
  index: (context, event) => {
    return context.index - 1;
  },
});

const resetCorrections = assign({
  corrections: (context, event) => [],
});

const resetPageIndex = assign({
  index: (context, event) => null,
});

const adaptIndex = assign({
  index: (context, event) => {
    console.log("corrections length", context.corrections.length);
    console.log("index", context.index);
    return context.index >= context.corrections.length - 1
      ? context.index - 1
      : context.index;
  },
});

const notifyCurrentIndex = log((context, event) => {
  console.log("current index", context.index);
});

const removeCorrection = assign({
  corrections: (context, event) => {
    const correction = context.corrections.find(
      (correction) => correction.id === event.id,
    );

    console.log("correction", correction);
    correction.ref.stop();
    return context.corrections.filter(
      (correction) => correction.id !== event.id,
    );
  },
});

const updateCorrection = assign({
  corrections: (context, event) => {
    return context.corrections.map((correction) => {
      return correction.id === event.correction.id
        ? { ...correction, ...event.correction, ref: correction.ref }
        : correction;
    });
  },
});

const sendDeleteToCurrentActor = send(
  { type: "DELETE" },
  { to: (context) => context.corrections[context.index].ref },
);

const sendUpdateToCurrentActor = send(
  { type: "UPDATE" },
  { to: (context) => context.corrections[context.index].ref },
);

const sendTokenToSpawnedActors = (context, event) => {
  context.corrections.forEach((correction) => {
    console.log("updating actor's token");
    correction.ref.send({
      type: "UPDATE_BEARER_TOKEN",
      data: event.data,
    });
  });
};

const saveReportId = assign({
  reportId: (context, event) => {
    console.log("event reportid", event);
    return event.reportId;
  },
});

const saveApiUrl = assign({
  qmsToolApiUrl: (context, event) => {
    return event.qmsToolApiUrl;
  },
});

const flagChanges = require("../../commons/actions").flagChanges;
const unflagChanges = require("../../commons/actions").unflagChanges;
const saveToken = require("../../commons/actions").saveToken;

export {
  saveAuthentication,
  saveCorrections,
  setIndexOnFirstPage,
  incrementIndex,
  decrementIndex,
  resetCorrections,
  resetPageIndex,
  removeCorrection,
  updateCorrection,
  sendDeleteToCurrentActor,
  saveReportId,
  saveApiUrl,
  adaptIndex,
  flagChanges,
  unflagChanges,
  notifyCurrentIndex,
  sendUpdateToCurrentActor,
  saveToken,
  sendTokenToSpawnedActors,
};
