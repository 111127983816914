import { Fragment, useEffect, useState } from "react";
import CorrectiveAction from "./CorrectiveAction";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { useReviewCorrectiveActionsMachine } from "../../../context/ReviewCorrectiveActionsMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { XMarkIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import StepsContainer from "../container";
import KeyboardShortcutsPanel from "../shared/KeyboardShortcutsPanel";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import AccessDenied from "./AccessDenied";
import Deleting from "./Deleting";
import Error from "./Error";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const shortcuts = [
  { keys: ["Ctrl", "Shift", ArrowLongLeftIcon], label: "shortcut.previous" },
  { keys: ["Ctrl", "Shift", ArrowLongRightIcon], label: "shortcut.next" },
  { keys: ["Ctrl", "Shift", "S"], label: "shortcut.save" },
  { keys: ["Ctrl", "Shift", "Delete"], label: "shortcut.delete" },
];

// Create your forceUpdate hook
function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

export default function ReviewCorrectiveActionsPanel() {
  const [state, send] = useReviewCorrectiveActionsMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();
  const handleForceupdateMethod = useForceUpdate();

  const { t } = useTranslation();
  const { keycloak } = useAuth();

  const handleClose = () => {
    send("CLOSE");
    if (state?.context?.changesHappened) {
      viewEditReportSend("LOAD_REPORT", {
        data: viewEditReportState.context?.report?.id,
      });
    }
  };

  useEffect(() => {
    console.log(
      "Token is refreshing : send token to ReviewCorrectiveActions machine",
    );
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.key === "Delete" || event.key === "Backspace")
      ) {
        send("DELETE");
      }
      if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.key === "ArrowRight" || event.key === "ArrowDown")
      ) {
        send("NEXT");
      }
      if (
        event.ctrlKey &&
        event.shiftKey &&
        (event.key === "ArrowLeft" || event.key === "ArrowUp")
      ) {
        send("PREVIOUS");
      }
      if (event.ctrlKey && event.shiftKey && event.key === "S") {
        send("UPDATE");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Transition.Root show={state.matches("active")} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={handleClose}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-medium leading-6 text-white">
                              {t("editCorrectiveActions.view.title")}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                tabIndex={-1}
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                                disabled={!state.can("CLOSE")}
                                onClick={handleClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {t("editCorrectiveActions.view.subtitle")}
                            </p>
                          </div>
                          <div className="mt-7"></div>
                        </div>

                        {/* Main content */}
                        {state.matches("active") &&
                          state.context?.corrective_actions.length > 0 && (
                            <>
                              <div className="sticky top-0 z-50 flex flex-shrink-0  flex-wrap justify-between border-b border-slate-200 bg-slate-50 ">
                                <div className=" -mt-14  flex w-full items-center justify-center px-6 ">
                                  <div className="flex w-10/12">
                                    <KeyboardShortcutsPanel
                                      shortcuts={shortcuts}
                                    />
                                  </div>
                                </div>
                                <div className="py-4 pl-3 sm:px-6">
                                  <div className="flex items-center">
                                    <button
                                      type="button"
                                      onClick={() => send("PREVIOUS")}
                                      className={classNames(
                                        "rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:text-gray-300",
                                      )}
                                      disabled={!state.can("PREVIOUS")}
                                    >
                                      <ChevronLeftIcon className="h-5 w-5" />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => send("NEXT")}
                                      className={classNames(
                                        "ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:text-gray-300",
                                      )}
                                      disabled={!state.can("NEXT")}
                                    >
                                      <ChevronRightIcon className="h-5 w-5" />
                                    </button>
                                    <span className="text-medium ml-4 text-xs text-gray-900 ">
                                      <div className="inline-block w-2 text-center">
                                        {state.context.index + 1}
                                      </div>
                                      <div className="ml-1 inline-block w-2 text-center text-gray-500">
                                        /
                                      </div>
                                      <div className="inline-block w-2 text-center">
                                        {
                                          state.context.corrective_actions
                                            .length
                                        }
                                      </div>
                                    </span>
                                  </div>
                                </div>

                                <div className=" py-4 pr-3 sm:px-6">
                                  <div className="flex">
                                    {!state.context.corrective_actions[
                                      state.context.index
                                    ].ref.state.matches("delete.error") && (
                                      <button
                                        type="button"
                                        onClick={() => send("DELETE")}
                                        className="ml-4 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-md hover:bg-red-500  disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0 sm:w-auto"
                                        disabled={!state.can("DELETE")}
                                      >
                                        {!state.context.corrective_actions[
                                          state.context.index
                                        ].ref.state.matches(
                                          "delete.deleting",
                                        ) && <TrashIcon className="h-5 w-5" />}
                                        {state.context.corrective_actions[
                                          state.context.index
                                        ].ref.state.matches(
                                          "delete.deleting",
                                        ) && (
                                          <svg
                                            className={classNames(
                                              " h-5 w-5 text-white",
                                              "animate-spin ",
                                            )}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <circle
                                              className="opacity-25"
                                              cx="12"
                                              cy="12"
                                              r="10"
                                              stroke="currentColor"
                                              stroke-width="4"
                                            ></circle>
                                            <path
                                              className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                          </svg>
                                        )}
                                      </button>
                                    )}
                                    {state.context.corrective_actions[
                                      state.context.index
                                    ].ref.state.can("CANCEL") && (
                                      <button
                                        type="button"
                                        onClick={() => send("CANCEL")}
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-md hover:bg-red-500 sm:ml-3 sm:w-auto"
                                      >
                                        {t("shared.button.cancel")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-1 flex-col justify-between ">
                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                  {(state.context.corrective_actions[
                                    state.context.index
                                  ].ref.state.matches("editing") ||
                                    state.context.corrective_actions[
                                      state.context.index
                                    ].ref.state.matches("update.updating")) && (
                                    <CorrectiveAction
                                      key={
                                        state.context.corrective_actions[
                                          state.context.index
                                        ].id
                                      }
                                      correctiveAction={
                                        state.context.corrective_actions[
                                          state.context.index
                                        ]
                                      }
                                      forceUpdateMethod={
                                        handleForceupdateMethod
                                      }
                                    ></CorrectiveAction>
                                  )}
                                  {state.context.corrective_actions[
                                    state.context.index
                                  ].ref.state.matches(
                                    "delete.error.server error",
                                  ) && <Error />}
                                  {state.context.corrective_actions[
                                    state.context.index
                                  ].ref.state.matches(
                                    "delete.error.access denied",
                                  ) && <AccessDenied />}
                                  {state.context.corrective_actions[
                                    state.context.index
                                  ].ref.state.matches("delete.deleting") && (
                                    <Deleting />
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                        {/* Empty content */}
                        {state.matches("active") &&
                          state.context?.corrective_actions.length === 0 && (
                            <StepsContainer>
                              <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
                                <h1 className="text-center text-xl font-medium text-slate-600">
                                  {t("shared.empty.collection.header")}
                                </h1>
                                <div className="mt-4">
                                  <p className="text-center text-sm text-gray-500">
                                    {t("shared.empty.collection.subHeader")}
                                  </p>
                                </div>
                              </div>
                            </StepsContainer>
                          )}

                        {/* Error content */}

                        {/* Loading content */}

                        {/* Abandon content */}
                        {/* {state.matches("confirm abandon") && <Cancel />} */}
                      </div>

                      {/* Bottom Action Bar */}
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {state.matches("active") &&
                          state.context?.corrective_actions.length > 0 && (
                            <button
                              type="button"
                              onClick={() => send("UPDATE")}
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0  sm:w-auto"
                              disabled={!state.can("UPDATE")}
                            >
                              {!state.context.corrective_actions[
                                state.context.index
                              ].ref.state.matches("update.updating") && (
                                <>{t("editReport.view.action.save")}</>
                              )}
                              {state.context.corrective_actions[
                                state.context.index
                              ].ref.state.matches("update.updating") && (
                                <>
                                  <svg
                                    className={classNames(
                                      "-ml-1  h-5 w-5 text-white",
                                      "animate-spin ",
                                    )}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      stroke-width="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </>
                              )}
                            </button>
                          )}
                        {state.can({ type: "CLOSE" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={handleClose}
                            tabIndex={3}
                          >
                            {t("shared.button.close")}
                          </button>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
