import { ListBulletIcon } from "@heroicons/react/24/outline";
import { useRequalifyReportMachine } from "../../../../../context/RequalifyReportMachineContext";
import { useAuth } from "../../../../../context/AuthContext";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import CriticityBadge from "../../../CriticityBadge";

import { Trans, useTranslation } from "react-i18next";

export default function Summary() {
  const [requalifyReportState, requalifyReportSend] =
    useRequalifyReportMachine();
  const { profile } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <div className="lg:col-start-3 lg:row-end-1">
        <div className="rounded-lg bg-gray-50 pb-6 shadow-sm ring-1 ring-gray-900/5">
          <dl className="flex flex-wrap">
            {/* Criticity section header */}
            <div className="w-full  px-6 pt-6">
              <div className="flex flex-auto justify-between">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t(
                    "createReport.steps.step6.summary.sectionHeader.criticity",
                  )}
                </p>
                <CriticityBadge
                  value={
                    requalifyReportState?.context?.changeSet?.criticity?.name
                  }
                />
              </div>
            </div>
            {/* Criticity type */}
            <div className="flex w-full flex-none gap-x-4 px-6 pt-6">
              <div className="flex w-full flex-none gap-x-4 text-sm leading-6 text-gray-600">
                <dt className="flex-none">
                  <ExclamationTriangleIcon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>

                <dd className="">
                  {requalifyReportState?.context?.changeSet?.criticity
                    .action_deadline_in_hours === 0 && (
                    <>
                      <Trans
                        i18nKey="createReport.steps.step6.summary.criticity.warn.actionDeadlineZeroHours"
                        components={{
                          span: <span className="font-semibold text-red-700" />,
                        }}
                      />
                    </>
                  )}
                  {requalifyReportState?.context?.changeSet?.criticity
                    ?.action_deadline_in_hours > 0 &&
                    requalifyReportState?.context?.changeSet?.criticity
                      ?.action_deadline_in_hours <= 24 && (
                      <>
                        <Trans
                          i18nKey="createReport.steps.step6.summary.criticity.warn.actionDeadlineWithin24Hours"
                          components={{
                            span: (
                              <span className="font-semibold text-purple-700" />
                            ),
                          }}
                          values={{
                            hours:
                              requalifyReportState?.context?.form
                                ?.computedCriticityType
                                ?.action_deadline_in_hours,
                          }}
                        />
                      </>
                    )}
                  {requalifyReportState?.context?.changeSet?.criticity
                    .action_deadline_in_hours > 24 && (
                    <>
                      <Trans
                        i18nKey="createReport.steps.step6.summary.criticity.warn.actionDealineOver24Hours"
                        components={{
                          span: (
                            <span className="font-semibold text-blue-700" />
                          ),
                        }}
                        values={{
                          days:
                            requalifyReportState?.context?.changeSet?.criticity
                              ?.action_deadline_in_hours / 24,
                        }}
                      />
                    </>
                  )}
                </dd>
              </div>
            </div>
            {/* Required actions */}
            {(requalifyReportState?.context?.changeSet?.criticity
              ?.action_plan_required ||
              requalifyReportState?.context?.changeSet?.criticity
                ?.seq_of_events_required ||
              requalifyReportState?.context?.changeSet?.criticity
                ?.root_cause_analysis_required) && (
              <div className="flex w-full flex-none gap-x-4 px-6 pt-6">
                <div className="flex w-full flex-none gap-x-4 pb-6 text-sm leading-6 text-gray-600">
                  <dt className="flex-none">
                    <ListBulletIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="">
                    <div>
                      <p className=" ">
                        {t(
                          "requalifyEvent.steps.step3.summary.criticity.warn.label.tasks",
                        )}
                      </p>
                      <ul className="mt-1.5 list-disc pl-4">
                        {requalifyReportState?.context?.changeSet?.criticity
                          ?.seq_of_events_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.sequenceOfEvents",
                            )}
                          </li>
                        )}
                        {requalifyReportState?.context?.changeSet?.criticity
                          ?.root_cause_analysis_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.rootCauseAnalysis",
                            )}
                          </li>
                        )}
                        {requalifyReportState?.context?.changeSet?.criticity
                          ?.action_plan_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.actionPlan",
                            )}
                          </li>
                        )}
                      </ul>
                    </div>
                  </dd>
                </div>
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  );
}
