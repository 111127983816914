const { validToken, noChanges } = require("../../../commons/guards");

const validStartRequest = (context, event) => {
  return event?.data?.authentication?.token && event?.data?.actionPlanId;
};

const validChangeSet = (context, event) => {
  return (
    context?.changeSet?.description &&
    context?.changeSet?.description.length &&
    context?.changeSet?.problemStatement &&
    context?.changeSet?.problemStatement.length &&
    context?.changeSet?.rootCause &&
    context?.changeSet?.rootCause.length &&
    // context?.changeSet?.handledBy &&
    // context?.changeSet?.handledBy.length &&
    context?.changeSet?.handlerType &&
    context?.changeSet?.expiredAt &&
    context?.changeSet?.domain &&
    context?.changeSet?.correctiveActionSource &&
    context?.changeSet?.correctiveActionPriority
    //&& ["TODO", "ONGOING", "COMPLETE"].includes(context?.changeSet?.status)
  );
};

export { validToken, validChangeSet, validStartRequest, noChanges };
