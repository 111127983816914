const validLoadRequest = require("../../../commons/guards").validLoadRequest;
const validToken = require("../../../commons/guards").validToken;

const actionPlansNotEmpty = (context, event) => {
  return context?.actionPlans?.length > 0;
};

const accessDeniedError = (context, event) => {
  return context.error?.response?.status === 403;
};

export { validLoadRequest, validToken, actionPlansNotEmpty, accessDeniedError };
