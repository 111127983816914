const { actions } = require("xstate");
const { assign, raise, sendTo } = actions;
const { saveError, saveToken, notifyError } = require("../../commons/actions");

const saveConsequenceTypes = assign({
  consequenceTypes: (context, event) => event.data,
});

const saveCorrectiveActionSources = assign({
  correctiveActionSources: (context, event) => event.data,
});

const saveCorrectiveActionPriorities = assign({
  correctiveActionPriorities: (context, event) => event.data,
});

const saveCorrectiveActionManagerTypes = assign({
  correctiveActionManagerTypes: (context, event) => event.data,
});

const saveSeverityTypes = assign({
  severityTypes: (context, event) => event.data,
});

const saveProbabilityTypes = assign({
  probabilityTypes: (context, event) => event.data,
});

const saveLocations = assign({
  locations: (context, event) => event.data,
});

const saveTargetTypes = assign({
  targetTypes: (context, event) => event.data,
});

const saveCategories = assign({
  categories: (context, event) => event.data,
});

const saveDomains = assign({
  domains: (context, event) => event.data,
});

const saveUserRestrictedDomains = assign({
  restrictedDomains: (context, event) =>
    event.data.length === 0 ? context.domains : event.data,
});

const raiseInitializationError = raise((context, event) => ({
  type: "INITIALIZATION_ERROR",
  data: event.data,
}));

const saveSelectedConsequenceTypeEventKey = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        consequence_type: event.data,
      },
    };
  },
});

const saveEventDescription = assign({
  report: (context, evt) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        description: evt.data,
      },
    };
  },
});

const saveEventOccurenceDate = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        event_occured_at: event.data,
      },
    };
  },
});

const resetCriticityType = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        criticity_type: null,
        severity: null,
        probability: null,
      },
    };
  },
});

const saveEventLocation = assign({
  form: (context, event) => {
    return {
      ...context.form,
      location: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        location: event.data.name,
      },
    };
  },
});

const saveEventTarget = assign({
  form: (context, event) => {
    return {
      ...context.form,
      target: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        target: event.data.name,
      },
    };
  },
});

const saveEventCategory = assign({
  form: (context, event) => {
    return {
      ...context.form,
      category: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        category: event.data.name,
      },
    };
  },
});

const saveEventDomain = assign({
  form: (context, event) => {
    return {
      ...context.form,
      domain: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        domain: event?.data?.name,
      },
    };
  },
});

const saveEventPeriodOfTheDay = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        period_of_the_day: event?.data,
      },
    };
  },
});

const computeEventPeriodOfTheDay = assign({
  report: (context, event) => {
    const hours = new Date(event.data).getHours();

    if (hours >= 7 && hours < 22) {
      return {
        ...context.report,
        event: {
          ...context.report.event,
          period_of_the_day: "DAY",
        },
      };
    } else {
      return {
        ...context.report,
        event: {
          ...context.report.event,
          period_of_the_day: "NIGHT",
        },
      };
    }
  },
});

const saveAuthorityInfoDate = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        authority_informed_at: event.data !== "" ? event.data : null,
      },
    };
  },
});

const saveFamilyInfoDate = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        family_informed_at: event.data !== "" ? event.data : null,
      },
    };
  },
});

const saveForcedValues = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        severity: context?.form?.consequenceType?.forced_severity,
        probability: context?.form?.consequenceType?.forced_probability,
      },
    };
  },
});

const saveSelectedConsequenceTypeFormObject = assign({
  form: (context, event) => {
    const consequenceType = context.consequenceTypes.find(
      (consequenceType) =>
        consequenceType.name === context?.report?.event?.consequence_type,
    );
    return {
      ...context.form,
      consequenceType: consequenceType,
    };
  },
});

const saveSelectedSeverity = assign({
  form: (context, event) => {
    return {
      ...context.form,
      severityType: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        severity: event.data.associated_value,
      },
    };
  },
});

const saveSelectedProbability = assign({
  form: (context, event) => {
    return {
      ...context.form,
      probabilityType: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        probability: event.data.associated_value,
      },
    };
  },
});

const saveComputedCriticityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      computedCriticityType: event.data,
    };
  },
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        criticity_type: event?.data?.name,
      },
    };
  },
});

const resetReportEventCriticity = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        criticity_type: null,
        severity: null,
        probability: null,
      },
    };
  },
});

const resetComputedCriticityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      computedCriticityType: null,
    };
  },
});

const resetSeverityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      severityType: null,
    };
  },
});

const resetProbabilityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      probabilityType: null,
    };
  },
});

const saveForcedSeverityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      severityType: context.severityTypes.find(
        (severityType) =>
          severityType.associated_value ===
          context?.form?.consequenceType?.forced_severity,
      ),
    };
  },
});

const saveForcedProbabilityType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      probabilityType: context.probabilityTypes.find(
        (probabilityType) =>
          probabilityType.associated_value ===
          context?.form?.consequenceType?.forced_probability,
      ),
    };
  },
});

const resetConsequenceType = assign({
  form: (context, event) => {
    return {
      ...context.form,
      consequenceType: null,
    };
  },
});

const resetReportEvent = assign({
  report: (context, event) => {
    return {
      ...context.report,
      residence_one_key: null,
      event: null,
    };
  },
});

const resetLocation = assign({
  form: (context, event) => {
    return {
      ...context.form,
      location: null,
    };
  },
});

const resetTarget = assign({
  form: (context, event) => {
    return {
      ...context.form,
      target: null,
    };
  },
});

const resetCategory = assign({
  form: (context, event) => {
    return {
      ...context.form,
      category: null,
    };
  },
});

const resetDomain = assign({
  form: (context, event) => {
    return {
      ...context.form,
      domain: null,
    };
  },
});

const saveResidences = assign({
  residences: (context, event) => event.data,
});

const saveSelectedResidenceEventKey = assign({
  report: (context, event) => {
    return {
      ...context.report,
      residence_one_key: event.data,
    };
  },
});

const saveResult = assign({
  result: (context, event) => event.data,
});

const saveGeneralPractionerInfoDate = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        general_practitioner_informed_at: event.data !== "" ? event.data : null,
      },
    };
  },
});
const saveRegionalDirectionInfoDate = assign({
  report: (context, event) => {
    return {
      ...context.report,
      event: {
        ...context.report.event,
        regional_direction_informed_at: event.data !== "" ? event.data : null,
      },
    };
  },
});

const saveEditReportMachineRef = assign({
  editReportMachineRef: (context, event) => {
    return event.data;
  },
});

const sendLoadReportEvent = (context, event) => {
  const [current, send] = context.editReportMachineRef;
  console.log(context.result);
  send("LOAD_REPORT", { data: context.result.id });
};

export {
  saveConsequenceTypes,
  saveCorrectiveActionSources,
  saveCorrectiveActionPriorities,
  saveCorrectiveActionManagerTypes,
  saveSeverityTypes,
  saveProbabilityTypes,
  saveLocations,
  saveTargetTypes,
  saveCategories,
  saveDomains,
  raiseInitializationError,
  saveError,
  saveToken,
  saveSelectedConsequenceTypeEventKey,
  saveEventDescription,
  saveEventOccurenceDate,
  resetCriticityType,
  saveEventLocation,
  saveEventTarget,
  saveEventDomain,
  saveEventPeriodOfTheDay,
  saveAuthorityInfoDate,
  saveFamilyInfoDate,
  notifyError,
  saveForcedValues,
  saveSelectedConsequenceTypeFormObject,
  saveSelectedSeverity,
  saveSelectedProbability,
  saveComputedCriticityType,
  resetReportEventCriticity,
  resetComputedCriticityType,
  resetSeverityType,
  saveForcedSeverityType,
  saveForcedProbabilityType,
  resetProbabilityType,
  resetConsequenceType,
  resetReportEvent,
  resetLocation,
  resetTarget,
  saveEventCategory,
  resetCategory,
  saveResidences,
  saveSelectedResidenceEventKey,
  saveResult,
  resetDomain,
  saveRegionalDirectionInfoDate,
  saveEditReportMachineRef,
  sendLoadReportEvent,
  saveGeneralPractionerInfoDate,
  saveUserRestrictedDomains,
  computeEventPeriodOfTheDay,
};
