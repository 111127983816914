import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ListActionPlansMachine as parentMachine } from "../machines/action-plans/list-action-plans/list-action-plans-machine";

const ListActionPlansMachineContext = createContext();

export function useListActionPlansMachine() {
  const context = useContext(ListActionPlansMachineContext);

  if (!context) {
    throw new Error(
      "useListActionPlansMachine must be used within a ListActionPlansMachineProvider",
    );
  }

  return context;
}

export function ListActionPlansMachineProvider({ children }) {
  const listActionPlansMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ListActionPlansMachineContext.Provider value={listActionPlansMachine}>
      {children}
    </ListActionPlansMachineContext.Provider>
  );
}
