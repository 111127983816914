function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function TextArea({
  id,
  name,
  rows,
  label,
  helpText,
  onChange,
  value,
  tabindex,
  disabled
}) {
  return (
    <>
      <div className="flex flex-col">
        <label
          htmlFor="about"
          className={classNames(
            "block text-sm font-medium leading-6 ",
            disabled
              ? "text-gray-300"
              : "text-gray-600",
          )}
        >
          {label}
        </label>
        <div className="mt-2">
          <textarea
            id={id}
            name={name}
            onChange={onChange}
            rows={rows || 3}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 disabled:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={value || ""}
            tabIndex={tabindex}
            disabled={disabled}
          />
        </div>
        <p className="mt-3 text-sm leading-6 text-gray-600">{helpText}</p>
      </div>
    </>
  );
}
