const validChangeSet = (context, event) => {
  return (
    ((context?.changes?.has("description") &&
      context?.correctiveAction?.description.length) ||
      context?.changes?.has("handler_type") ||
      (context?.changes?.has("expired_at") &&
        context?.changes?.has("deferral_reason") &&
        context?.correctiveAction?.deferral_reason) ||
      (context?.changes?.has("status") &&
        ["TODO", "ONGOING", "COMPLETE"].includes(
          context?.correctiveAction?.status,
        )) ||
      (context?.changes?.has("problem_statement") &&
        context?.correctiveAction?.problem_statement.length) ||
      (context?.changes?.has("domain") &&
        context?.correctiveAction?.domain.length) ||
      (context?.changes?.has("source") &&
        context?.correctiveAction?.source.length) ||
      (context?.changes?.has("priority") &&
        context?.correctiveAction?.priority.length) ||
      (context?.changes?.has("root_cause") &&
        context?.correctiveAction?.root_cause.length)) &&
    context.changesHappened === true
  );
};

const noReportOrReportDraftOrPending = (context, event) => {
  return (
    !context?.correctiveAction?.report ||
    context?.correctiveAction?.report?.report_status_type.name === "DRAFT" ||
    context?.correctiveAction?.report?.report_status_type.name === "PENDING"
  );
};

const validToken = require("../../../commons/guards").validToken;

const accessDeniedError = require("../../../commons/guards").accessDeniedError;

export {
  validChangeSet,
  validToken,
  noReportOrReportDraftOrPending,
  accessDeniedError,
};
