import StepsContainer from "../../../container";
import GenericSelectMenu from "../../../GenericSelectMenu";

import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import { useTranslation } from "react-i18next";

export default function Step4() {
  const [createReportState, sendToCreateReport] = useCreateReportMachine();
  const { t } = useTranslation();

  return (
    <>
      <StepsContainer>
        <div id="step-4-lists-container">
          <GenericSelectMenu
            data={createReportState.context?.targetTypes}
            selectedItem={createReportState.context?.form?.target}
            onItemSelected={(element) => {
              sendToCreateReport("TARGET_SELECTED", { data: element });
            }}
            noSelectionText={t(
              "createReport.steps.step4.form.placeholder.target",
            )}
            label={t("createReport.steps.step4.form.label.target")}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.target."
          />
        </div>
        <div>
          <GenericSelectMenu
            data={createReportState.context?.categories}
            selectedItem={createReportState.context?.form?.category}
            onItemSelected={(element) => {
              sendToCreateReport("CATEGORY_SELECTED", { data: element });
            }}
            noSelectionText={t(
              "createReport.steps.step4.form.placeholder.category",
            )}
            label={t("createReport.steps.step4.form.label.category")}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.category."
          />
        </div>
        <div>
          <GenericSelectMenu
            //data={createReportState.context?.domains}
            data={createReportState.context?.restrictedDomains}
            selectedItem={createReportState.context?.form?.domain}
            onItemSelected={(element) => {
              sendToCreateReport("DOMAIN_SELECTED", { data: element });
            }}
            noSelectionText={t(
              "createReport.steps.step4.form.placeholder.domain",
            )}
            label={t("createReport.steps.step4.form.label.domain")}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.domain."
          />
        </div>
        <div>
          <GenericSelectMenu
            data={createReportState.context?.locations}
            selectedItem={createReportState.context?.form?.location}
            onItemSelected={(element) => {
              sendToCreateReport("LOCATION_SELECTED", { data: element });
            }}
            noSelectionText={t(
              "createReport.steps.step4.form.placeholder.location",
            )}
            label={t("createReport.steps.step4.form.label.location")}
            useNameForKey={true}
            useEntireItemForValue={true}
            i18nPrefixKey="shared.type.location."
          />
        </div>
      </StepsContainer>
    </>
  );
}
