import StepsContainer from "../../../container";
import { useRequalifyReportMachine } from "../../../../../context/RequalifyReportMachineContext";
import { useTranslation } from "react-i18next";

export default function Step3() {
  const [state, send] = useRequalifyReportMachine();
  const { t } = useTranslation();

  return (
    <StepsContainer>
      <div>
        <label
          htmlFor="requalification_reason"
          className="block text-sm font-medium leading-6 text-gray-600"
        >
          {t("requalifyEvent.view.label.reason")}
        </label>
        <div className="mt-2">
          <input
            autoFocus
            type="text"
            name="requalification_reason"
            id="requalification_reason"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              send("REASON_CHANGED", {
                data: e.target.value,
              });
            }}
            value={state.context.changeSet.requalification_reason || ""}
            tabIndex={1}
          />
        </div>
      </div>
    </StepsContainer>
  );
}
