import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEditCorrectionsMachine } from "../../../context/EditCorrectionMachineContext";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { useReviewCorrectionsMachine } from "../../../context/ReviewCorrectionsMachineContext";
import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import NewCorrectionPanel from "../edit-corrections/NewCorrectionPanel";
import { FlagIcon } from "@heroicons/react/20/solid";
import { FlagIcon as FlagIconOutline } from "@heroicons/react/24/outline";
import TimeAgo from "../../ui/shared/TimeAgo";
import FlagSectionPanel from "../flag-section/FlagSectionPanel";
import ReviewCorrectionsPanel from "../review-corrections/ReviewCorrectionsPanel";
import SectionLock from "../shared/SectionLock";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ImmediateActions() {
  const [state, send] = useViewEditReportMachine();
  const [editCorrectionsState, editCorrectionSend] =
    useEditCorrectionsMachine();
  const [flagSectionForReviewState, flagSectionForReviewSend] =
    useFlagSectionForReviewMachine();
  const [reviewCorrectionsState, reviewCorrectionsSend] =
    useReviewCorrectionsMachine();
  const { t } = useTranslation();
  const sectionLocked =
    state.context?.report?.event?.criticity?.immediate_actions_required ===
      false && state.context?.report?.corrections?.length > 0;

  useEffect(() => {
    editCorrectionSend("LOAD", {
      data: {
        reportId: state?.context?.report?.id,
        corrections: state?.context?.report?.corrections,
        authentication: {
          token: state?.context?.authentication?.token,
        },
      },
    });
    return () => {
      editCorrectionSend("UNLOAD");
    };
  }, []);

  return (
    <>
      {(state.context?.report?.event?.criticity?.immediate_actions_required ===
        true ||
        sectionLocked) && (
        <SectionLock locked={sectionLocked}>
          <div
            className={classNames(
              "mt-12 overflow-hidden rounded-lg bg-white shadow",
              state.matches("processing.initialized.active.edit") ? "" : "",
            )}
          >
            <div className="border-t border-slate-200 bg-white  px-4 py-4 drop-shadow-md  sm:px-6">
              <div className="flex w-full items-center justify-between">
                <h2 className="py-2 text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.immediateActions")}
                </h2>
                {!state?.context?.report?.obfuscated &&
                  state?.context?.report?.user_actions?.some(
                    (action) => action.action_id === "submit",
                  ) && (
                    <>
                      <div className="flex">
                        {state.context?.report?.corrections?.length > 0 && (
                          <button
                            className="mr-3 flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50"
                            onClick={(e) => {
                              reviewCorrectionsSend("START", {
                                corrections:
                                  state?.context?.report?.corrections,
                                authentication: {
                                  token: state?.context?.authentication?.token,
                                },
                                qmsToolApiUrl: state?.context?.qmsToolApiUrl,
                                reportId: state?.context?.report?.id,
                              });
                            }}
                            disabled={
                              state.matches(
                                "processing.initialized.active.edit",
                              ) ||
                              !state?.context?.report?.user_actions?.some(
                                (action) => action.action_id === "submit",
                              )
                            }
                          >
                            {t("shared.button.edit")}
                          </button>
                        )}

                        <button
                          className=" flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                          onClick={(e) => {
                            editCorrectionSend("ADD_ITEM");
                          }}
                          disabled={
                            state.matches(
                              "processing.initialized.active.edit",
                            ) ||
                            !state?.context?.report?.user_actions?.some(
                              (action) => action.action_id === "submit",
                            )
                          }
                        >
                          {t("editReport.view.immediateActions.button.add")}
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div
              id="immediateActionsSection"
              className="bg-slate-50 px-4 py-4  sm:px-6 "
            >
              {(!state.context?.report?.corrections ||
                (state.context?.report?.corrections?.length === 0 &&
                  (!editCorrectionsState.context?.corrections ||
                    editCorrectionsState.context?.corrections?.length ===
                      0))) && (
                <>
                  <div className="mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                    <Trans
                      i18nKey={
                        "editReport.view.immediateActions.label.required"
                      }
                    ></Trans>
                  </div>
                </>
              )}
              {(state.context?.report?.corrections?.length > 0 ||
                editCorrectionsState.context?.corrections?.length > 0) && (
                <>
                  <ul className="ml-3 mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                    {editCorrectionsState.context?.corrections.map(
                      (item, index) => (
                        <li
                          key={index}
                          className={classNames(
                            "list-disc",
                            state.context?.report?.obfuscated ? "blur-sm" : "",
                          )}
                        >
                          {item.description}
                        </li>
                      ),
                    )}
                  </ul>
                </>
              )}
            </div>
            {state?.context?.report?.user_actions?.some(
              (action) => action.action_id === "corrections-request-review",
            ) &&
              !state.context?.report?.corrections_review_requested_at && (
                <div className="border-t border-gray-200 bg-slate-50  px-4 pb-3 pt-4 text-sm font-medium text-gray-500 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      {t(
                        "editReport.view.immediateActions.label.requestReview",
                      )}
                    </div>
                    <div>
                      <button
                        className=" flex items-center justify-center rounded-md   px-2.5 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-amber-300 hover:text-gray-700 hover:ring-amber-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                        onClick={(e) => {
                          flagSectionForReviewSend("START", {
                            data: {
                              action_url:
                                state?.context?.report?.user_actions?.find(
                                  (action) =>
                                    action.action_id ===
                                    "corrections-request-review",
                                )?.url,
                              authentication: {
                                token: state?.context?.authentication?.token,
                              },
                            },
                          });
                        }}
                      >
                        <FlagIcon className="h6 w-5"></FlagIcon>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {state.context?.report?.corrections_review_requested_at && (
              <div className=" bg-amber-300 p-6  text-sm  text-gray-600 sm:px-6 sm:pb-5 sm:pt-6">
                <div className="flex justify-between">
                  <div className="mb-6 flex">
                    <FlagIconOutline className="h6 mr-3 w-5"></FlagIconOutline>
                    <div>
                      {" "}
                      <Trans
                        i18nKey={`editReport.view.activity.REPORT_REVIEW_REQUESTED`}
                        components={{
                          span: <span className="font-medium text-gray-700" />,
                        }}
                        values={{
                          username:
                            state.context?.report
                              ?.corrections_review_requester_username,
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <TimeAgo
                      isoString={
                        state.context?.report?.corrections_review_requested_at
                      }
                    />
                  </div>
                </div>
                <div>
                  “{state.context?.report?.corrections_review_description}”
                </div>
              </div>
            )}
          </div>
        </SectionLock>
      )}
      <NewCorrectionPanel />
      <ReviewCorrectionsPanel />
      <FlagSectionPanel />
    </>
  );
}
