const validRequest = (context, event) => {
  return (
    event.corrections &&
    Array.isArray(event.corrections) &&
    event.corrections.length > 0 &&
    event.authentication &&
    event.authentication.token
  );
};

const notOnFirstPage = (context, event) => {
  return context?.index && context.index > 0;
};

const notOnLastPage = (context, event) => {
  return context?.index < context.corrections.length - 1;
};

const correctionIsTheLast = (context, event) => {
  return context?.index === context.corrections.length - 1;
};

const currentActorHasChanges = (context, event) => {
  return context?.corrections[context.index]?.ref?.state?.context
    ?.changesHappened;
};

const validToken = require("../../commons/guards").validToken;

export {
  validRequest,
  notOnFirstPage,
  notOnLastPage,
  correctionIsTheLast,
  currentActorHasChanges,
  validToken,
};
