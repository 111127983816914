const validRequest = (context, event) => {
  return (
    event?.data?.reportIdentifiers &&
    Array.isArray(event?.data?.reportIdentifiers) &&
    event?.data?.status &&
    event?.data?.status.length > 0 &&
    event.data?.viewReportActorRef
  );
};

// const notOnFirstPage = require("../../commons/guards").notOnFirstPage;
// const notOnLastPage = require("../../commons/guards").notOnLastPage;

const notOnFirstPage = (context, event) => {
  const condResult = Number.isInteger(context?.index) && context.index > 0;
  console.log("notOnFirstPage", condResult);
  return condResult;
};

const notOnLastPage = (context, event) => {
  const condResult = context?.index < context.reportIdentifiers.length - 1;
  console.log("notOnLastPage", condResult);
  return condResult;
};

const idExists = (context, event) => {
  return (
    context?.data?.reportIdentifiers.findIndex((id) => id === event?.data?.id) >
    -1
  );
};

export { validRequest, notOnFirstPage, notOnLastPage, idExists };
