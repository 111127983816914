const seqOfEventsEmpty = (context, event) => {
  return (
    context?.loadRequest?.sequenceOfEvents === undefined ||
    context?.loadRequest?.sequenceOfEvents === null
  );
};

const validChangeSet = (context, event) => {
  return (
    context?.changeSet?.item_occured_at &&
    context?.changeSet?.description &&
    context?.changeSet?.affected_entity &&
    context?.changeSet?.traceability_and_evidences
  );
};

const validToken = require("../../commons/guards").validToken;

export { seqOfEventsEmpty, validChangeSet, validToken };
