import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ListReportsMachine as parentMachine } from "../machines/list-reports/list-reports-machine";

const ListReportsMachineContext = createContext();

const filters = [
  {
    id: "status",
    getter: (report) => {
      return report?.status || "";
    },
    i18nKey: "reports.table.headers.status",
    i18nValuePrefixKey: "shared.type.reportStatus.",
    options: [],
  },
  {
    id: "type",
    getter: (report) => {
      return report?.event?.type || "";
    },
    i18nKey: "reports.table.headers.type",
    i18nValuePrefixKey: "",
    options: [],
  },
  {
    id: "criticity",
    getter: (report) => {
      return report?.event.criticity.name || "";
    },
    i18nKey: "reports.table.headers.criticity",
    i18nValuePrefixKey: "shared.type.criticity.tag.",
    options: [],
  },
  {
    id: "residence",
    getter: (report) => {
      return report.residence_one_key || "";
    },
    i18nKey: "reports.table.headers.residence",
    i18nValuePrefixKey: "",
    options: [],
  },
];

export function useListReportsMachine() {
  const context = useContext(ListReportsMachineContext);

  if (!context) {
    throw new Error(
      "useListReportsMachine must be used within a ListReportsMachineProvider",
    );
  }

  return context;
}

export function ListReportsMachineProvider({ children }) {
  const listReportsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
      filters: filters,
    },
  });

  return (
    <ListReportsMachineContext.Provider value={listReportsMachine}>
      {children}
    </ListReportsMachineContext.Provider>
  );
}
