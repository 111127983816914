import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function SortableTableHeader({
  headers,
  sortedBy,
  sortOrder,
  onHeaderClick,
}) {
  // Fonction t pour l'internationalisation (à remplacer par votre fonction d'internationalisation)
  const { t } = useTranslation();

  const handleClick = (event, header) => {
    event.preventDefault(); // Empêche la navigation par défaut
    onHeaderClick(header);
  };

  const getSortIcon = (headerKey) => {
    if (sortedBy !== headerKey) {
      return (
        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      );
    } else if (sortOrder === "asc") {
      return (
        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      );
    } else if (sortOrder === "desc") {
      return (
        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      );
    }
  };

  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header.key} scope="col" className={header.classes}>
            <a
              href="#"
              className="group inline-flex items-center"
              onClick={(e) => handleClick(e, header)}
            >
              {t(header.textId)}
              {getSortIcon(header.key)}
            </a>
          </th>
        ))}
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Select</span>{" "}
          {/* Le dernier th pour la sélection */}
        </th>
      </tr>
    </thead>
  );
}
