import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useCreateActionPlanMachine } from "../../../../context/action-plans/CreateActionPlanMachineContext";
import { useListActionPlansMachine } from "../../../../context/ListActionPlansMachineContext";
import { useAuth } from "../../../../context/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Loading from "./Loading";
import Error from "./Error";
import AllPlansAlreadyCreated from "./AllPlansAlreadyCreated";
import Step1 from "./steps/step1";
import ErrorCreatingActionPlan from "./ErrorCreatingActionPlan";
import Success from "./Success";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewActionPlanPanel() {
  const { t } = useTranslation();
  const [state, send] = useCreateActionPlanMachine();
  const [listActionPlansState, listActionPlansSend] =
    useListActionPlansMachine();
  const { keycloak } = useAuth();
  const navigate = useNavigate();

  const handleOnClose = () => {
    send("CLOSE");
    if (state.context?.changesHappened) {
      console.log("Changes happened, reload action plans");
      listActionPlansSend("RELOAD");
    }
  };

  useEffect(() => {
    console.log(
      "Token is refreshing : send token to create action plan machine",
    );
    send("UPDATE_BEARER_TOKEN", {
      data: keycloak.token,
    });
  }, [keycloak.token]);

  return (
    <Transition.Root show={state.matches("active")} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={handleOnClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-medium leading-6 text-white">
                            {t("createActionPlan.title.newActionPlan")}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              tabIndex={-1}
                              type="button"
                              className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                              disabled={!state.can({ type: "CLOSE" })}
                              onClick={handleOnClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            {t("createActionPlan.subtitle.newActionPlan")}
                          </p>
                        </div>
                        <div className="mt-2"></div>
                      </div>

                      {/* LOADING POSSIBLE ACTION PLANS */}
                      {state.matches({
                        active: { init: "fetch action plans to be created" },
                      }) && <Loading />}

                      {/* ALL PLANS ALREADY CREATED */}
                      {state.matches("active.all plans already created") && (
                        <AllPlansAlreadyCreated
                          show={state.matches(
                            "active.all plans already created",
                          )}
                        />
                      )}

                      {/* STEP 1 - SELECTION OF THE RESIDENCE */}
                      {state.matches({
                        active: "selecting residence's one key",
                      }) && (
                        <Step1
                          data={state.context?.actionPlansToBeCreated}
                          onItemSelected={(value) => {
                            send("ONE_KEY_SELECTED", { data: value });
                          }}
                        />
                      )}

                      {/* ERROR LOADING ACTIONS PLAN TO BE CREATED*/}
                      {state.matches({
                        active: { init: "error" },
                      }) && <Error />}

                      {/* ERROR CREATING ACTION PLAN */}
                      {state.matches({
                        active: "error",
                      }) && <ErrorCreatingActionPlan />}

                      {/* SUCCESS */}
                      {state.matches({
                        active: "success",
                      }) && <Success />}
                    </div>
                    {/* Bottom Action Bar */}
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {state.can({ type: "CLOSE" }) && (
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                          onClick={handleOnClose}
                        >
                          {t("shared.button.close")}
                        </button>
                      )}
                      {state.matches({
                        active: "selecting residence's one key",
                      }) && (
                        <button
                          onClick={() => send("CREATE")}
                          className={
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300"
                          }
                          disabled={!state.can({ type: "CREATE" })}
                        >
                          {t("shared.button.create")}
                        </button>
                      )}
                      {state.can({ type: "RETRY" }) && (
                        <button
                          onClick={() => send("RETRY")}
                          className={
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          }
                          s
                        >
                          {t("createReport.button.retry")}
                        </button>
                      )}
                      {state.matches({
                        active: { init: "fetch action plans to be created" },
                      }) && (
                        <button
                          type="button"
                          className={classNames(
                            "relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 ",
                            "cursor-not-allowed",
                          )}
                          disabled={true}
                        >
                          <svg
                            className={classNames(
                              "-ml-1 mr-1.5 h-5 w-5 text-white",
                              "animate-spin ",
                            )}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          {t("reports.status.loading")}
                        </button>
                      )}
                      {state.matches({
                        active: "success",
                      }) && (
                        <button
                          onClick={() => {
                            send("CLOSE");
                            navigate({
                              pathname: `/action-plans/${state.context?.actionPlan.id}`,
                              search: `?residence_one_key=${state.context?.actionPlan.residence_one_key}`,
                            });
                          }}
                          className={classNames(
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                          )}
                        >
                          {t("createActionPlan.button.viewActionPlan")}
                        </button>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
