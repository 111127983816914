const { actions, spawn } = require("xstate");
const { assign } = actions;
const {
  createFetchAgentMachine,
} = require("../../fetch-agent/fetch-agent-machine");

function compareValues(a, b, direction = "asc") {
  // Gérer les cas où les deux valeurs sont null
  if (a === null && b === null) {
    return 0;
  }

  // Considérer null comme inférieur à toute autre valeur
  if (a === null) return direction === "asc" ? -1 : 1;
  if (b === null) return direction === "asc" ? 1 : -1;

  // Comparaison pour les nombres
  if (typeof a === "number" && typeof b === "number") {
    return direction === "asc" ? a - b : b - a;
  }

  // Comparaison pour les dates
  else if (a instanceof Date && b instanceof Date) {
    return direction === "asc" ? a - b : b - a;
  }

  // Comparaison pour les chaînes de caractères
  else if (typeof a === "string" && typeof b === "string") {
    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  }

  // Ajout d'un cas de fallback si les types ne sont pas gérés
  // Cela peut être utile pour éviter des erreurs en cas de comparaison de types inattendus
  else {
    console.warn("Attempting to compare unsupported types", { a, b });
    return 0;
  }
}

function getNestedValue(obj, path) {
  return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
}

function updateFilterOptions(filters, records) {
  // Retourner une nouvelle copie de l'array des filtres
  return filters.map((filter) => {
    // Collecter toutes les valeurs uniques obtenues par la fonction getter pour le filtre actuel
    const uniqueValues = new Set();
    records.forEach((record) => {
      const value = filter.getter(record);
      if (value) {
        // Assurer que seules les valeurs non nulles et non vides sont ajoutées
        uniqueValues.add(value);
      }
    });

    // Construire un nouvel ensemble d'options basé sur les valeurs uniques trouvées et les options existantes
    let updatedOptions = filter.options.filter((option) =>
      uniqueValues.has(option.value),
    ); // Conserver les options existantes qui sont encore valides

    // Ajouter les nouvelles valeurs comme options, marquées comme unchecked
    uniqueValues.forEach((value) => {
      if (!updatedOptions.some((option) => option.value === value)) {
        updatedOptions.push({
          value: value,
          i18nPrefixKey: filter.i18nValuePrefixKey, // Utiliser le même préfixe i18n que les autres options
          checked: false, // Les nouvelles valeurs sont non cochées
        });
      }
    });

    console.log(uniqueValues);

    // Retourner un nouvel objet filtre avec les options mises à jour
    return {
      ...filter, // Copier toutes les propriétés existantes du filtre
      options: updatedOptions, // Utiliser le nouvel ensemble d'options
    };
  });
}

// function filterRecords(filters, records) {
//   return records.filter((record) => {
//     // Vérifier chaque record pour voir s'il satisfait toutes les conditions des filtres
//     return filters.every((filter) => {
//       // Vérifier si les options existent et récupérer seulement celles qui sont cochées
//       const checkedOptions = filter.options
//         ? filter.options.filter((option) => option.checked)
//         : [];

//       // Si aucune option n'est cochée ou si les options sont nulles ou un tableau vide, le filtre est valide
//       if (!filter.options || checkedOptions.length === 0) {
//         return true;
//       }

//       // Obtenir la valeur du record pour le filtre actuel
//       const recordValue = filter.getter(record);

//       // Vérifier si la valeur du record correspond à l'une des options cochées
//       return checkedOptions.some((option) => option.value === recordValue);
//     });
//   });
// }
const filterRecords = require("../../commons/actions").filterRecords;
const saveToken = require("../../commons/actions").saveToken;
const saveLoadRequest = assign({
  authentication: (context, event) => {
    return {
      ...context.authentication,
      token: event.data.authentication.token,
    };
  },
  id: (context, event) => {
    return parseInt(event.data.id);
  },
  residence_one_key: (context, event) => {
    return event.data.residence_one_key;
  },
});
const updateLastRefreshedTimestamp =
  require("../../commons/actions").updateLastRefreshedTimestamp;
const saveError = require("../../commons/actions").saveError;

const saveCorrectiveActions = assign({
  corrective_actions: (context, event) => {
    return event.data;
  },
});
const spawnFetchAgents = assign({
  todoActionsFetchAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=todo`,
        loadedEventType: "AGENT.TODO_DATA_LOADED",
        errorEventType: "AGENT.LOADING_ERROR",
      }),
    );
  },
  ongoingActionsFetchAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=ongoing`,
        loadedEventType: "AGENT.ONGOING_DATA_LOADED",
        errorEventType: "AGENT.LOADING_ERROR",
      }),
    );
  },
  completeActionsFetchAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=complete`,
        loadedEventType: "AGENT.COMPLETE_DATA_LOADED",
        errorEventType: "AGENT.LOADING_ERROR",
      }),
    );
  },
});
const saveTodoActions = assign({
  todoCorrectiveActions: (context, event) => {
    return event.data;
  },
});
const saveOngoingActions = assign({
  ongoingCorrectiveActions: (context, event) => {
    return event.data;
  },
});
const saveCompleteActions = assign({
  completeCorrectiveActions: (context, event) => {
    return event.data;
  },
});
const setTodoDataLoaded = assign({
  todoCorrectiveActionsLoaded: (context, event) => {
    return true;
  },
});
const setOngoingDataLoaded = assign({
  ongoingCorrectiveActionsLoaded: (context, event) => {
    return true;
  },
});
const setCompleteDataLoaded = assign({
  completeCorrectiveActionsLoaded: (context, event) => {
    return true;
  },
});
const unsetDataLoaded = assign({
  todoCorrectiveActionsLoaded: (context, event) => {
    return false;
  },
  ongoingCorrectiveActionsLoaded: (context, event) => {
    return false;
  },
  completeCorrectiveActionsLoaded: (context, event) => {
    return false;
  },
});
const sendTokenToFetchAgents = (context, event) => {
  context.todoActionsFetchAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
  context.ongoingActionsFetchAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
  context.completeActionsFetchAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
};
const sendLoadRequestToFetchAgents = (context, event) => {
  context.todoActionsFetchAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=todo`,
    },
  });
  context.ongoingActionsFetchAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=ongoing`,
    },
  });
  context.completeActionsFetchAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url: `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions?status=complete`,
    },
  });
};
const setViewToTodo = assign({
  view: "todo",
});
const setViewToOngoing = assign({
  view: "ongoing",
});
const setViewToComplete = assign({
  view: "complete",
});
const setCorrectiveActionsToFilteredTodoActions = assign({
  corrective_actions: (context, event) => {
    return context.filteredTodoCorrectiveActions;
  },
});
const setCorrectiveActionsToFilteredOngoingActions = assign({
  corrective_actions: (context, event) => {
    return context.filteredOngoingCorrectiveActions;
  },
});
const setCorrectiveActionsToFilteredCompleteActions = assign({
  corrective_actions: (context, event) => {
    return context.filteredCompleteCorrectiveActions;
  },
});
const combineAndSortCorrectiveActions = assign({
  corrective_actions: (context, event) => {
    return [
      ...context?.todoCorrectiveActions,
      ...context?.ongoingCorrectiveActions,
      ...context?.completeCorrectiveActions,
    ].sort((a, b) => (a.id > b.id ? -11 : 1));
  },
});
const sortCorrectiveActions = assign((context, event) => {
  let sortKey = event.sortKey || context.currentSortKey;
  let comparator;
  let sortDirection;
  const defaultComparator = (a, b, sortDirection) =>
    compareValues(
      getNestedValue(a, sortKey),
      getNestedValue(b, sortKey),
      sortDirection,
    );

  if (event.sortKey && context.currentSortKey === event.sortKey) {
    sortDirection = context.currentSortDirection === "asc" ? "desc" : "asc";
  } else if (event.sortKey && context.currentSortKey !== event.sortKey) {
    sortDirection = "asc";
  } else if (!event.sortKey) {
    sortDirection = context.currentSortDirection;
  }

  if (event.comparator) {
    comparator = event.comparator;
  } else if (context.currentSortKey === event.sortKey || !event.sortKey) {
    comparator = context.currentComparator || defaultComparator;
  } else {
    comparator = defaultComparator;
  }

  const sortedCorrectiveActions = [...context.corrective_actions].sort((a, b) =>
    comparator(a, b, sortDirection),
  );

  console.log("sortedCorrectiveActions", sortedCorrectiveActions);

  return {
    corrective_actions: sortedCorrectiveActions,
    currentSortKey: sortKey,
    currentSortDirection: sortDirection,
    currentComparator: comparator,
  };
});

const saveUniqueFiltersOptions = assign({
  filters: (context, event) => {
    console.log("context.filters", context.filters);
    const updatedFilters = updateFilterOptions(context?.filters, [
      ...context?.todoCorrectiveActions,
      ...context?.ongoingCorrectiveActions,
      ...context?.completeCorrectiveActions,
    ]);
    console.log("updatedFilters", updatedFilters);
    return updatedFilters;
  },
});

const filterTodoActions = assign({
  filteredTodoCorrectiveActions: (context, event) => {
    return filterRecords(context?.filters, context?.todoCorrectiveActions);
  },
});

const filterOngoingActions = assign({
  filteredOngoingCorrectiveActions: (context, event) => {
    return filterRecords(context?.filters, context?.ongoingCorrectiveActions);
  },
});

const filterCompleteActions = assign({
  filteredCompleteCorrectiveActions: (context, event) => {
    return filterRecords(context?.filters, context?.completeCorrectiveActions);
  },
});

const addValueToFilter = require("../../commons/actions").addValueToFilter;
const removeValueFromFilter =
  require("../../commons/actions").removeValueFromFilter;

export {
  spawnFetchAgents,
  saveLoadRequest,
  saveCorrectiveActions,
  updateLastRefreshedTimestamp,
  saveError,
  saveToken,
  saveTodoActions,
  saveOngoingActions,
  saveCompleteActions,
  setTodoDataLoaded,
  setOngoingDataLoaded,
  setCompleteDataLoaded,
  sendLoadRequestToFetchAgents,
  sendTokenToFetchAgents,
  unsetDataLoaded,
  setViewToTodo,
  setViewToOngoing,
  setViewToComplete,
  setCorrectiveActionsToFilteredTodoActions,
  setCorrectiveActionsToFilteredOngoingActions,
  setCorrectiveActionsToFilteredCompleteActions,
  sortCorrectiveActions,
  combineAndSortCorrectiveActions,
  saveUniqueFiltersOptions,
  filterTodoActions,
  filterOngoingActions,
  filterCompleteActions,
  addValueToFilter,
  removeValueFromFilter,
};
