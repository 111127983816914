import axios from "axios";

const defaultDeleteOptions = {
  method: "DELETE",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultPutOptions = {
  method: "Put",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const deleteCorrection = (context, event) => {
  try {
    const options = {
      ...defaultDeleteOptions,
      headers: {
        ...defaultDeleteOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/corrections/${context?.correction?.id}`,
      options,
    ).then((response) => response?.data?.corrections);
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCorrection = (context, event) => {
  try {
    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify({
        description: context?.correction?.description,
      }),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/corrections/${context?.correction?.id}`,
      options,
    ).then((response) => response?.data?.corrections);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { deleteCorrection, updateCorrection };
