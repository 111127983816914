import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { CreateReportMachine as parentMachine } from "../machines/create-report/create-report-machine";
export const CreateReportMachineContext = createContext();

export function useCreateReportMachine() {
  const context = useContext(CreateReportMachineContext);
  if (!context) {
    throw new Error(
      "useCreateReportMachine must be used within a CreateReportMachineProvider",
    );
  }
  return context;
}

export function CreateReportMachineProvider({ children }) {
  const createReportMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <CreateReportMachineContext.Provider value={createReportMachine}>
      {children}
    </CreateReportMachineContext.Provider>
  );
}
