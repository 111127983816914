import React, { useEffect } from "react";

import { useActor } from "@xstate/react";
import { useTranslation } from "react-i18next";
import DateTimePicker from "../DateTimePicker";
import { TextArea } from "../TextArea";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SeqOfEventDetail({ seq_of_events_detail, maxDate }) {
  const [state, send] = useActor(seq_of_events_detail.ref);
  const { t } = useTranslation();

  return (
    <>
      <div className="space-y-6 pb-5 pt-6">
        <div>
          <DateTimePicker
            label={t("editSeqOfEvents.view.label.item_occured_at")}
            onChange={(iso861DateStr) => {
              send({
                type: "EVENT_OCCURENCE_DATE_SELECTED",
                data: iso861DateStr,
              });
            }}
            initialDate={state.context?.seq_of_events_detail?.item_occured_at}
            max={maxDate}
          />
        </div>

        {/* What */}
        <div className="col-span-full">
          <TextArea
            id="event-description"
            name="event-description"
            rows={2}
            label={t("editSeqOfEvents.view.label.description")}
            //helpText="Veuillez décrire les circonstances de l'évènement"
            onChange={(e) => {
              send({
                type: "DESCRIPTION_CHANGED",
                data: e.target.value,
              });
            }}
            value={state.context.seq_of_events_detail.description}
          />
        </div>
        {/* Who */}
        <div>
          <label
            htmlFor="affected_entity"
            className="block text-sm font-medium leading-6 text-gray-600"
          >
            {t("editSeqOfEvents.view.label.affected_entity")}
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="affected_entity"
              id="affected_entity"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t(
                "editSeqOfEvents.view.label.affected_entity.placeholder",
              )}
              onChange={(e) => {
                send({
                  type: "AFFECTED_ENTITY_CHANGED",
                  data: e.target.value,
                });
              }}
              value={state.context.seq_of_events_detail.affected_entity}
            />
          </div>
        </div>
        {/* Traces and evidences */}
        <div className="col-span-full">
          <TextArea
            id="traces-and-evidences"
            name="traces-and-evidences"
            rows={5}
            label={t("editSeqOfEvents.view.label.traceability_and_evidences")}
            //helpText="Veuillez décrire les circonstances de l'évènement"
            onChange={(e) => {
              send({
                type: "TRACES_AND_EVIDENCES_CHANGED",
                data: e.target.value,
              });
            }}
            value={
              state.context.seq_of_events_detail.traceability_and_evidences
            }
          />
        </div>

        <div className="mt-6 flex justify-end">
          <button
            type="button"
            onClick={() => send("UPDATE")}
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0 sm:w-auto"
            disabled={!state.can("UPDATE")}
          >
            {!state.matches("update.updating") && (
              <>{t("editReport.view.action.save")}</>
            )}
            {state.matches("update.updating") && (
              <>
                <svg
                  className={classNames(
                    "-ml-1 mr-3  h-5 w-5 text-white",
                    "animate-spin ",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {t("shared.button.saving")}
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
