import StepsContainer from "../../container";
import Info from "../../Info";
import { useTranslation } from "react-i18next";
import { useReviewFiveWhysMachine } from "../../../../context/ReviewFiveWhysMachineContext";
import Switch from "../../shared/Toggle";

export default function Answer5() {
  const { t } = useTranslation();
  const [state, send] = useReviewFiveWhysMachine();
  return (
    <>
      <StepsContainer>
        <Info>
          <h2 className="font-medium">
            {t("editFiveWhys.steps.answer5.info.header")}
          </h2>
          <div className="mt-3">
            {t("editFiveWhys.steps.answer5.info.subHeader")}
          </div>
        </Info>
        <div className="space-y-6 pb-5 pt-6">
          <div>
            {/* ANSWER 2 */}
            <label
              htmlFor="answer_why_4"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("editFiveWhys.steps.form.previousAnswer.label")}
            </label>
            <div className="mt-2 block w-full rounded-md border-0 bg-gray-100 px-3 py-3 text-sm text-gray-500">
              <div>{state?.context?.changeSet?.answer_why_4}</div>
            </div>
          </div>

          <div>
            <label
              htmlFor="answer_why_5"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("editFiveWhys.steps.form.why.label")}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="answer_why_5"
                id="answer_why_5"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  send("ANSWER_CHANGED", {
                    data: e.target.value,
                  });
                }}
                value={state?.context?.changeSet?.answer_why_5}
              />
            </div>
          </div>
          {state.can("OTHER_POSSIBLE_CAUSES_CHANGED") && (
            <div>
              <label
                htmlFor="other_possible_causes"
                className="block text-sm font-medium leading-6 text-gray-600"
              >
                {t("editFiveWhys.steps.form.why.otherPossibleCauses.label")}
              </label>

              <div className="mt-2">
                <input
                  type="text"
                  name="other_possible_causes"
                  id="other_possible_causes"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => {
                    send("OTHER_POSSIBLE_CAUSES_CHANGED", {
                      data: e.target.value,
                    });
                  }}
                  value={state?.context?.changeSet?.other_possible_causes}
                />
              </div>
            </div>
          )}
        </div>
      </StepsContainer>
    </>
  );
}
