import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ViewEfficiencyMetricsMachine as parentMachine } from "../machines/view-efficiency-metrics/view-efficiency-metrics-machine";

const ViewEfficiencyMetricsMachineContext = createContext();

export function useViewEfficiencyMetricsMachine() {
  const context = useContext(ViewEfficiencyMetricsMachineContext);

  if (!context) {
    throw new Error(
      "useViewEfficiencyMetricsMachine must be used within a ListReportsMachineProvider",
    );
  }

  return context;
}

export function ViewEfficiencyMetricsMachineProvider({ children }) {
  const viewEfficiencyMetricsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ViewEfficiencyMetricsMachineContext.Provider
      value={viewEfficiencyMetricsMachine}
    >
      {children}
    </ViewEfficiencyMetricsMachineContext.Provider>
  );
}
