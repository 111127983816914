const { actions } = require("xstate");
const { assign } = actions;
const {
  flagChanges,
  unflagChanges,
  saveToken,
} = require("../../../commons/actions");

const saveStartRequest = assign({
  authentication: (context, event) => {
    return event.data?.authentication;
  },
  actionPlanId: (context, event) => {
    return event.data?.actionPlanId;
  },
});

const saveDescription = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      description: event.data,
    };
  },
});

const saveStatus = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      status: event.data,
    };
  },
});

const saveDomain = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      domain: event.data,
    };
  },
});

const saveProblemStatement = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      problemStatement: event.data,
    };
  },
});

const saveCorrectiveActionSource = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      correctiveActionSource: event.data,
    };
  },
});

const saveCorrectiveActionPriority = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      correctiveActionPriority: event.data,
    };
  },
});

const saveRootCause = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      rootCause: event.data,
    };
  },
});

const saveHandledBy = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      handledBy: event.data,
    };
  },
});

const saveHandlerType = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      handlerType: event.data,
    };
  },
});

const saveExpirationDate = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      expiredAt: event.data,
    };
  },
});

const resetChangeSet = assign({
  changeSet: (context, event) => {
    return undefined;
  },
});

export {
  flagChanges,
  unflagChanges,
  saveToken,
  saveStartRequest,
  saveDescription,
  saveCorrectiveActionSource,
  saveCorrectiveActionPriority,
  saveStatus,
  resetChangeSet,
  saveDomain,
  saveProblemStatement,
  saveRootCause,
  saveHandledBy,
  saveHandlerType,
  saveExpirationDate,
};
