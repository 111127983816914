const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const ListActionPlansMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBsCWsAuBaAhgYw1QHsA7LAB2RxNgDpUJkwBiAGQHkBBAEQG0AGALqJQ5IrFSFSIkAA9EAJn4BOWsvUBmDQBYFG9QHZ+GgKwAaEAE9EBg7QCMKjbeUA2DQoAcbgwF9fFmiYuATEZJTUdMhEOBCoJFDMEKRg9CQAbkQA1qlB2PhS4VQ0tNGx8VAI8Zl4OIUCgg0yYhKFMvII2p78DiZ9ntq2nvYjehbWCAr2GrR62vZGTgaD9v6B6PmhpBTFUTFxCcxgAE7HRMe0ERgAZucAtqUbIYU7kaX7FVUZRLX1Qk1IEAtSRhdo2dwOVzaZQKVz2Ab2PSucaIRHaWjLZSmBQGewmZRddRrEB5Z5hV4lMoQSDMABKAFEODwAaJxCDpICOjjPGptNp+HilHCNAsUZNBhjXCZtBp+K4lDiDJ4-AESU8CuSIpT9jSmXwhM02W1OTYDKouiLPJ4ofZBsYxQoFCZaPx+d1lLZTK5rdpiaSNdstXtYjSAKoABW4nAAKvSAPoAIXpnAZtLj0fYAGl6QA5FlAo2gk2dGWzZTGKVKa0ubRi7R9WgDZWjBFwlXrYIBopvKk0gDK7Fp0fzwONoA6jgUs0WSjluKVBmRVkQ9edTYMU3sUK08z96q23ZKJzOxzp9OjtIAmiPCxzx4hhjMNN4TFooX0vGLTLyTNNnNiX2UPdOwPCk6GPc5aDwAALMA8CyAACCDjgQjBLHIFgb1aIt7wQEYTB6RxjFdedlV0L9+CnZ85WVK03A0VwpWAzYXiDWhkKg2D4KQ05zlQ9DMPsYRAVHHC5FRfR0W9fhpVnfQCIUCiqO6b0lW8dxGJMfxVRIIhqXgQF-VAoNDWwu9xLwxTlwQLBf1oZwlVcSjTE8EwnWYslA12ehGDAUz2RIMEECcsVlB5Ql8V0KYPVWVUjNY7yqQqfyxws6F0QFeVvStK0COUULVAI+xlFfTwnUouUFA8rswPeEMIBSsSOhMJyXT5SsFjCwYlwmBi7GKjw8TNAUVNcarjO85DGvMidJwcfhiJWBdyOssrZm0KVoTNP9liquL9wSt4OJguDEOQ-iMOmwLi0RcteQW591BMRcNAdLd7LCgZXBhEVVK0-aQMOo9eIufA8DgWAEOpEhUEgK6gvw+w1A3fgDAY1w0eir8oVoeVKIUflbAJhjxqB8CQdoWATnSE4eJPeGbv5Z0RlMZzXIJ+VsakhUCdRjcZTG7SgA */
    predictableActionArguments: true,

    id: "list-action-plans",

    context: {
      actionPlans: [],
      qmsToolApiUrl: undefined,
      authentication: {
        token: undefined,
      },
      loaded_at: undefined,
    },

    states: {
      idle: {
        on: {
          LOAD: {
            target: "loading",
            actions: "saveLoadRequest",
            cond: "validLoadRequest",
          },
        },
      },

      loading: {
        invoke: {
          src: "fetchActionPlans",

          onDone: {
            target: "loaded",
            actions: ["saveActionPlans", "updateLastRefreshedTimestamp"],
          },

          onError: "error",
        },
      },

      loaded: {
        on: {
          RELOAD: "loading",

          LOAD: {
            target: "loading",
            cond: "validLoadRequest",
            actions: "saveLoadRequest",
          },

          UPDATE_BEARER_TOKEN: {
            target: "loaded",
            internal: true,
            cond: "validToken",
            actions: "saveToken",
          },

          SORT: {
            target: "loaded",
            internal: true,
            actions: "sortActionPlans",
            cond: "actionPlansNotEmpty",
          },
        },

        entry: "sortActionPlans",
      },
      error: {
        on: {
          RETRY: "loading",
        },

        entry: "saveError",

        states: {
          "check error type": {
            always: [
              {
                target: "access denied",
                cond: "accessDeniedError",
              },
              "server error",
            ],
          },

          "access denied": {},
          "server error": {},
        },

        initial: "check error type",
      },
    },

    initial: "idle",
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { ListActionPlansMachine };
