const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const EditCorrectionsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5SQJYBcC0BjA9gJzzCzRRwDtYA6FCAGzAGIAZAeQEEARAbQAYBdRKAAOOWOlJlBIAB6IelAGwKA7AE4ArDzVaALACYAzAYAc6gDQgAnogCM65Yps29xmwuM7TCkwF8fF1ExcAiISciosAAsiAGsAAggAQzREhl4BJBARMTDJTNkEDBtjBwU9dRtlT1VjU1UdBQtrBAMdHUobI303ZT1lBRsdPwCIdGx8QmIJCOiseKSUtJsM4VFxcikC4oUOrVqdVR5vHh0TJsRW9s7WvR6+gfVh8FGgidDpyjAAWyE0SwYAKoAOVYnHSUmy6zyoAKGAMh0oqkOHjsTlUhnOLVcHXU7gU6j0CkOp2MCiegXGISm4U+Pz+zHY3H4ELWuU2iCKpMoPC0SOKVU07mUmM8DgMPA0FXUxhcOmUj38zzGwUmuSo31+-04HAA+gBJAAqAFEALLgzKQtn5RB6PSUEoHUk6dQuga1TG4nYnXGVPQnOzKAzkl6U1UfMg4NBxDX04GgpkrLKsiTslrKBwkqoGdStZw8cxWC7YuzuEyDF3KE7B5VvakUSgRqMx-7x82rHIp60ISrqRGnNQ2VTKQbFHMikqUcUaAx2U48AzeZTV15UtUNyPRulaji6w2mttJjsbLsuvsGAdDkemVoipSKIwmeyGdM1IaKikq940xIQCBxdDfJQP6jGQUD-mg3wMBwRoAMoAMIAEp6gACgaeosECOpwQAEmwQIAOJGgmLJHtCMgctmOyqM4Gjyt49GNIWCAyjYlCGBUNjenoBwqMuoZfvWwHgYBwEoKBwlfAwiFGmwxr6saZrMhaybHjCtg8MYij2J4C6yoSSiYh4BiIriOiDJ49jqNRfGfnWVBCQBXxAb+YlgY5UmsDBRoHpanZqQgZlXDw9wLpUGk8DYHp3t6Ay9P69hBu+Ia2WuDkQU5WCEMkrkSQwEDkGA1BkAAbjgMSFZlYDJGA-F1j5KlkQUZk7IG0r1BKsozpi7WUJWKg1MYSJlNZSU1quHxpYBlXZeJ7lgAQ+CUEItDJAAZvgGVZRBtW5PVpGpsYxnOjK+jaJ4g6Mc0nFWZODQqPmShEhpNm1qlv4SZ8C14AwCFGgaCEAJp7VCqbcdyKgKBK+gSqoCinAYmJOKcbH5qdtxWTUS6jSuYbfu9jnOSBbnpYCyEcLJRo6gAQjJv0ITqBosAA0kaQLA1a-kYDoJz2vKo6qCYWjo4jaKUE+i5yvYI2KhGEBwFIH6vdMJEg12GCaPIJQVHUgu9HYmIYJrtrUVoGPGBF5SJSMY24-WND0CrHPkQFxiGTsGs8oFWjyqoL3jTSUSxAkySJI7fnO7avWcfUhKw0SngaZiLiscNyjmyUg7pn7tvqluYeqc7RQNIiAycdxgr9DUIo89c3QqPcnTZwJVCNpumr541NoRR0c4GHohyDOKnTV1cD4C2Z-QnKYTd2YTEkd6mXMErz2tWbrwtMfidrqG0-S1AG+bGDPb1-gTomzelC9dn63KaGoSjphpgb5pigaqPaEP4jU-XzsfE34+lSg00SAX2+FffynFESBlqASZEHUCzNAFppFQMoXDtV3lbJUONm5zwJvNPA+BwHO3MuDSeMcYZwwRkxJGrFJT6AqGZeorQ-B+CAA */
    predictableActionArguments: true,
    id: "edit-corrections",
    context: {
      corrections: [],
    },
    states: {
      idle: {
        on: {
          LOAD: {
            target: "check data",
            actions: "saveLoadRequest",
          },
        },
      },

      "check data": {
        always: [
          {
            target: "empty",
            cond: "noCorrections",
          },
          "not empty",
        ],
      },

      empty: {
        on: {
          UNLOAD: {
            target: "idle",
            actions: "resetLoadRequest",
          },

          LOAD: {
            target: "check data",
            actions: "saveLoadRequest",
          },

          ADD_ITEM: "add item.adding item",
        },
      },

      "not empty": {
        on: {
          UNLOAD: {
            target: "idle",
            actions: "resetLoadRequest",
          },

          LOAD: {
            target: "check data",
            actions: "saveLoadRequest",
          },

          ADD_ITEM: "add item.adding item",
        },
      },

      "add item": {
        states: {
          "adding item": {
            on: {
              DESCRIPTION_CHANGED: {
                target: "adding item",
                actions: "saveDescription",
                internal: true,
              },

              CREATE_ITEM: {
                target: "creating item",
                cond: "validChangeSet",
              },

              CLOSE: {
                target: "#edit-corrections.check data",
                actions: "unflagChanges",
              },

              UPDATE_BEARER_TOKEN: {
                target: "adding item",
                internal: true,
                cond: "validToken",
                actions: "saveToken",
              },
            },
          },

          "creating item": {
            invoke: {
              src: "createCorrection",
              id: "create-correction",

              onDone: {
                target: "adding item",
                actions: [
                  "saveReturnedCorrections",
                  "resetChangeSet",
                  "flagChanges",
                ],
              },

              onError: "error",
            },
          },

          error: {
            on: {
              RETRY: "creating item",
            },
          },
        },

        initial: "adding item",
      },
    },

    initial: "idle",
  },
  {
    actions: actions,
    guards: guards,
    services: services,
  },
);

export { EditCorrectionsMachine };
