import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { EditCorrectionsMachine as parentMachine } from "../machines/edit-corrections/edit-corrections-machine";

const EditCorrectionsMachineContext = createContext();

export function useEditCorrectionsMachine() {
  const context = useContext(EditCorrectionsMachineContext);

  if (!context) {
    throw new Error(
      "useEditCorrectionsMachine must be used within a EditCorrectionsMachineContext",
    );
  }

  return context;
}

export function EditCorrectionsMachineProvider({ children }) {
  const editCorrectionsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <EditCorrectionsMachineContext.Provider value={editCorrectionsMachine}>
      {children}
    </EditCorrectionsMachineContext.Provider>
  );
}
