import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GenericSelectMenu({
  data,
  selectedItem,
  noSelectionText,
  onItemSelected,
  label,
  useNameForKey = false,
  useNameForValue = true,
  useEntireItemForValue = false,
  disabled = false,
  i18nPrefixKey = "",
  autoFocus = false,
}) {
  const [selected, setSelected] = useState(selectedItem || null);
  const { t } = useTranslation();

  const handleChange = (element) => {
    setSelected(element);
    onItemSelected(element);
  };

  return (
    <Listbox value={selected} onChange={handleChange} disabled={disabled}>
      {({ open }) => (
        <>
          {label ? (
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-600">
              {label}
            </Listbox.Label>
          ) : null}

          <div className={classNames("relative", label ? "mt-2" : "")}>
            <Listbox.Button
              tabIndex={0}
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 disabled:text-gray-300"
              autoFocus={autoFocus}
            >
              <span
                className={classNames(
                  "block truncate ",
                  disabled
                    ? "text-gray-300"
                    : !selected
                    ? "text-gray-500"
                    : selected
                    ? "text-gray-900"
                    : "",
                )}
              >
                {/* {selected?.name || selected || noSelectionText} */}
                {selected?.name
                  ? i18nPrefixKey
                    ? t(i18nPrefixKey + selected.name)
                    : selected.name
                  : selected
                  ? i18nPrefixKey
                    ? t(i18nPrefixKey + selected)
                    : selected
                  : noSelectionText}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map((element) => (
                  <Listbox.Option
                    key={useNameForKey ? element.name : element}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={
                      useEntireItemForValue
                        ? element
                        : useNameForValue
                        ? element.name
                        : element
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          {useNameForValue
                            ? i18nPrefixKey
                              ? t(i18nPrefixKey + element.name)
                              : element.name
                            : i18nPrefixKey
                            ? t(i18nPrefixKey + element)
                            : element}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
