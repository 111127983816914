const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const createReviewCorrectionMachine = ({
  correction,
  token,
  qmsToolApiUrl,
  reportId,
}) =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QCcwDcCWYDuBaAxgPbKr4AuGhAdgHSQYVVQDEAIgKIDKAwgEoCSABQAq-APIA5APrcAEgEEJAcXasA2gAYAuolAAHQrAaUqukAA9EuAMwaATDQAsANmvWAnPccaNt584AaEABPRABWGjCNR3cARgB2Zzt46MdY9wBfDKDUTBwCYlIKajoIYyZmAFVBVnlhdk0dJBADI2LTZssEXHjYml7E5xcfAA5Y53Sg0IR3BxGku2jYxbGxsKyc9Cw8IhIwchNS8pYOABl2esazVuNqMy7YjXcaWfjrFztrWNjHX+spqyLF6xEa9WZxJLuaygjYgXLbAp7A4lACueggAEMyJBmFdmjd2vcrB4Iss7CN3N4wtYwo87ADuiMIs4RotofEwslkiDYfD8rsioc0ZjsTRhViMBUINQwDRJWhCABrWXi7GIwXUPH6Qy3DqgLq4BLxGjWIaxMKvWI02YjBnxIF2cnxFI0ibOMLOXlbfmFfbtMXorEqwOMFhgEjEGh6AA2WIAZsQALYBkVgdV+kxalo6wmdcLPMYaH4jNKJRzWOxhBnOXw0DT2sbOTyg5zxL15Ha+5G0VWy8PIYjMXgXXgATSzBJMRIQywii0cIx8Lvi5fmDJixrG7nd3yi0Tc7YRAozJQgYGjYFFZ4voeY0qosvlStl18vaeP3YnOaneYQC+cdarGE5YeI8lKOAydjfJE7iLu4K7QrBNaetkcLep2SL+q+V7npekphhGyBRrGZAJsgybYe+XbtF+bQ-vqiD2hEvy0t48QjIWlIMhSNAcSsFLzPW9htqhfIYRqtCUXQhFDiO47aNc353L+8TuMy6SVuWlYgt8DK4ECcSguMqlhKZbzWIePqYYc9C3tUtT1FIABC7DyMOvBSMIYgANLsBItG6tOhoJCafF2O425vIsbx6Y6GgmoMvhUvaEyOFkqFUIQZ7wM0Ynpt2il0cpDHdH8NBkhSVI0nSem2MaTZqcs1i9CM7x2JZ4knpJuHYhAhWBb+vwjDQ7o0kWJbzI4nyQWMI0eq14zfDWHppaJ6H5f6tn4f1uYlYadYRRpYQrt4ppJLaISAn0lZMapswsqa7wdRtQohpAO30RYgKfCa4yUtC5qzC48TcaZ-TpDWCTJBWnLPR+-q9h9xVfd0tjWP0N1MjSglNgy0IARyFYw-adiOG8cPUa9qYphKTBI3qKP6WDt1Y2EOPuOuK51ok5q2E80LAesa0di9qJvdJA7IPT06fHMbMcnEIJY1a67wU4YzfN4VJFuaFPWaePVgNLv5fMNzquAkS7UqCVaXd0jzDaT4xfBuIKpHrEk0FJ2HbfiSkM10Z39IkXwpPW1sctWzzfKCGj+GZzVsx7XVe4bEvEMbJWPPWLxxMkLinTSIN2xxI3NeF3wghWGjUulGRAA */
      predictableActionArguments: true,

      id: "review-correction",

      context: {
        correction: { id: correction.id, description: correction.description },
        authentication: { token: token },
        qmsToolApiUrl: qmsToolApiUrl,
        reportId: reportId,
      },

      states: {
        deleted: {
          entry: "sendDeleteEventToParent",
          type: "final",
        },

        editing: {
          on: {
            DESCRIPTION_CHANGED: {
              target: "editing",
              internal: true,
              actions: ["saveDescription", "flagChanges"],
            },

            UPDATE: {
              target: "update",
              cond: "validChangedDescription",
            },

            DELETE: "delete",

            UPDATE_BEARER_TOKEN: {
              target: "editing",
              internal: true,
              cond: "validToken",
              actions: "saveToken",
            },
          },
        },

        updated: {
          entry: "sendUpdateEventToParent",

          always: "editing",
        },

        update: {
          initial: "updating",

          states: {
            updating: {
              invoke: {
                src: "updateCorrection",
                id: "update-correction",
                onDone: {
                  target: "#review-correction.updated",
                  actions: "unflagChanges",
                },
                onError: "error",
              },
            },

            error: {
              on: {
                RETRY: "updating",
              },
            },
          },
        },

        delete: {
          states: {
            deleting: {
              invoke: {
                src: "deleteCorrection",
                id: "delete-correction",
                onDone: "#review-correction.deleted",
                onError: "error",
              },
            },

            error: {
              on: {
                RETRY: "deleting",
              },
            },
          },

          initial: "deleting",
        },
      },

      initial: "editing",
    },
    { actions: actions, guards: guards, services: services },
  );

export { createReviewCorrectionMachine };
