import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ReviewCorrectionsMachine as parentMachine } from "../machines/review-corrections/review-corrections-machine";

const ReviewCorrectionsMachineContext = createContext();

export function useReviewCorrectionsMachine() {
  const context = useContext(ReviewCorrectionsMachineContext);

  if (!context) {
    throw new Error(
      "useReviewCorrectionsMachine must be used within a ReviewCorrectionsMachineProvider",
    );
  }

  return context;
}

export function ReviewCorrectionsMachineProvider({ children }) {
  const reviewCorrectionsMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ReviewCorrectionsMachineContext.Provider value={reviewCorrectionsMachine}>
      {children}
    </ReviewCorrectionsMachineContext.Provider>
  );
}
