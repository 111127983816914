import { useTranslation } from "react-i18next";
import { useViewEditActionPlanMachine } from "../../../context/ViewEditActionPlanMachineContext";
import { type } from "@testing-library/user-event/dist/type";

function getActiveFilterOptions(filters) {
  let activeOptions = [];
  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      filter.options.forEach((option) => {
        if (option.checked) {
          activeOptions.push({
            filterId: filter.id,
            value: option.value,
            i18nPrefixKey: option.i18nPrefixKey,
          });
        }
      });
    });
  }
  return activeOptions;
}

export default function ActiveFilters({ state, send }) {
  const { t } = useTranslation();
  // const [state, send] = useViewEditActionPlanMachine();

  const activeFilters = getActiveFilterOptions(state.context.filters);

  return (
    <>
      {activeFilters && activeFilters.length > 0 && (
        <div className="-mx-4 mt-6 overflow-auto  bg-white ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
          <div className="mx-auto  px-4 py-3  sm:flex sm:items-center sm:px-6">
            <h3 className="text-sm font-medium text-gray-500">
              {t("action-plan.corrective_actions.activeFilters.label")}
              <span className="sr-only">, active</span>
            </h3>

            <div
              aria-hidden="true"
              className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
            />

            <div className="mt-2 sm:ml-4 sm:mt-0">
              <div className="-m-1 flex flex-wrap items-center">
                {activeFilters.map((activeFilter) => (
                  <span
                    key={activeFilter.value}
                    className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-gray-100 py-1.5 pl-3 pr-2 text-xs font-medium text-gray-600"
                  >
                    <span className="uppercase">
                      {t(`${activeFilter.i18nPrefixKey}${activeFilter.value}`)}
                    </span>
                    <button
                      type="button"
                      className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                      onClick={(event) => {
                        send({
                          type: "REMOVE_VALUE_FROM_FILTER",
                          data: {
                            filterId: activeFilter.filterId,
                            value: activeFilter.value,
                          },
                        });
                      }}
                    >
                      <span className="sr-only">
                        Remove filter for {activeFilter.label}
                      </span>
                      <svg
                        className="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeWidth="1.5"
                          d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
