const { actions, sendParent } = require("xstate");
const { assign } = actions;

const saveDescription = assign({
  correction: (context, event) => {
    return {
      ...context.correction,
      description: event.data,
    };
  },
});

const sendDeleteEventToParent = sendParent((context) => ({
  type: "CORRECTION.DELETE",
  id: context.correction.id,
}));
const sendUpdateEventToParent = sendParent((context) => ({
  type: "CORRECTION.UPDATE",
  correction: context.correction,
}));

const flagChanges = require("../../commons/actions").flagChanges;
const unflagChanges = require("../../commons/actions").unflagChanges;
const saveToken = require("../../commons/actions").saveToken;

export {
  saveDescription,
  sendDeleteEventToParent,
  sendUpdateEventToParent,
  flagChanges,
  unflagChanges,
  saveToken,
};
