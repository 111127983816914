import { useState } from "react";
import PeriodOptions from "./PeriodOptions";
import RangeOptions from "./RangeOptions";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

export default function SearchOptions({ advancedSearchAgentRef, close }) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();

  return (
    <>
      <div className="w-80 divide-y divide-gray-200    py-3">
        <div className="mb-3  px-3">
          <nav className="-mb-px flex space-x-8">
            <a
              key={"period"}
              href="#"
              className={classNames(
                advancedSearchAgentRef?.state?.matches("period")
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium",
              )}
              onClick={() => {
                advancedSearchAgentRef.send("VIEW_PERIOD_OPTIONS");
              }}
            >
              {t("shared.searchWidget.options.period")}
            </a>
            <a
              key={"range"}
              href="#"
              className={classNames(
                advancedSearchAgentRef?.state?.matches("range")
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium",
              )}
              onClick={() => {
                advancedSearchAgentRef.send("VIEW_RANGE_OPTIONS");
              }}
            >
              {t("shared.searchWidget.options.range")}
            </a>
          </nav>
        </div>
        <div className="px-6 py-3">
          {advancedSearchAgentRef?.state?.matches("period") && (
            <PeriodOptions
              advancedSearchAgentRef={advancedSearchAgentRef}
              forceUpdate={forceUpdate}
            />
          )}
          {advancedSearchAgentRef?.state?.matches("range") && (
            <RangeOptions
              advancedSearchAgentRef={advancedSearchAgentRef}
              forceUpdate={forceUpdate}
            />
          )}
        </div>
        <div className="flex justify-end px-3 pt-3">
          <button
            className={classNames(
              "mr-3 flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white",
            )}
            onClick={() => {
              advancedSearchAgentRef.send("CLEAR");
              forceUpdate();
            }}
            disabled={!advancedSearchAgentRef?.state?.can("CLEAR")}
          >
            {t("shared.searchWidget.button.clear")}
          </button>

          <button
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white",
            )}
            onClick={() => {
              advancedSearchAgentRef.send("APPLY");

              forceUpdate();
              close();
            }}
            disabled={!advancedSearchAgentRef?.state?.can("APPLY")}
          >
            {t("shared.searchWidget.button.apply")}
          </button>
        </div>
      </div>
    </>
  );
}
