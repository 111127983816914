import { useTranslation } from "react-i18next";

export default function Cancel() {
  const { t } = useTranslation();
  return (
    <>
      <div className="relative -mt-6 flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
        <h1 className="text-center text-xl font-medium text-slate-600">
          {t("editFiveWhys.cancel.header")}
        </h1>
        <div className="mt-4">
          <p className="text-center text-sm text-gray-500">
            {t("editFiveWhys.cancel.subHeader")}
          </p>
        </div>
      </div>
    </>
  );
}
