import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { AuthContextProvider } from "./context/AuthContext";
import { CreateReportMachineProvider } from "./context/CreateReportMachineContext";
import { CreateActionPlanMachineProvider } from "./context/action-plans/CreateActionPlanMachineContext";
import { CreateCorrectiveActionMachineProvider } from "./context/action-plans/CreateCorrectiveActionMachineContext";
import { EventNavigatorMachineProvider } from "./context/EventNavigatorMachineContext";

import { ViewEditReportMachineProvider } from "./context/ViewEditReportMachineContext";
import { ViewEfficiencyMetricsMachineProvider } from "./context/ViewEfficiencyMetricsMachineContext";
import { ViewEditActionPlanMachineProvider } from "./context/ViewEditActionPlanMachineContext";
import "./i18n";

import App from "./App";

import { ListReportsMachineProvider } from "./context/ListReportsMachineContext";
import { ListActionPlansMachineProvider } from "./context/ListActionPlansMachineContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ListActionPlansMachineProvider>
    <ListReportsMachineProvider>
      <ViewEditReportMachineProvider>
        <EventNavigatorMachineProvider>
          <CreateReportMachineProvider>
            <CreateActionPlanMachineProvider>
              <CreateCorrectiveActionMachineProvider>
                <AuthContextProvider>
                  <ViewEfficiencyMetricsMachineProvider>
                    <ViewEditActionPlanMachineProvider>
                      <App />
                    </ViewEditActionPlanMachineProvider>
                  </ViewEfficiencyMetricsMachineProvider>
                </AuthContextProvider>
              </CreateCorrectiveActionMachineProvider>
            </CreateActionPlanMachineProvider>
          </CreateReportMachineProvider>
        </EventNavigatorMachineProvider>
      </ViewEditReportMachineProvider>
    </ListReportsMachineProvider>
  </ListActionPlansMachineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
