const tokenReady = (context, event) => {
  return (
    context?.authentication?.token !== undefined &&
    context?.authentication?.token !== null
  );
};

const validToken = (context, event) => {
  return true;
};

const validLoadRequest = (context, event) => {
  return (
    event?.data?.authentication?.token !== undefined &&
    event?.data?.authentication?.token !== null &&
    event?.data?.authentication?.token !== ""
  );
};

const noChanges = (context, event) => {
  return !context.changeSet;
};

const changesHappened = (context, event) => {
  return context.changesHappened;
};

const accessDeniedError = (context, event) => {
  return (
    context.error?.response?.status === 403 ||
    context.error?.response?.status === 401
  );
};

const badRequestError = (context, event) => {
  return context.error?.response?.status === 400;
};

const notFoundError = (context, event) => {
  return context.error?.response?.status === 404;
};

const validSelectedConsequenceTypeObject = (context, event) => {
  console.log("validSelectedConsequenceTypeObject", event);
  return (
    event?.data !== undefined && event?.data !== null && event?.data !== ""
  );
};

export {
  tokenReady,
  validToken,
  validLoadRequest,
  noChanges,
  accessDeniedError,
  notFoundError,
  validSelectedConsequenceTypeObject,
  badRequestError,
  changesHappened,
};
