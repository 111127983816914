const validLoadRequest = (context, event) => {
  return (
    event?.data?.authentication?.token !== undefined &&
    event?.data?.authentication?.token !== null &&
    event?.data?.authentication?.token !== "" &&
    event?.data?.id !== undefined &&
    event?.data?.id !== null &&
    event?.data?.id !== "" &&
    event?.data?.residence_one_key !== undefined &&
    event?.data?.residence_one_key !== null &&
    event?.data?.residence_one_key !== ""
  );
};
const validToken = require("../../commons/guards").validToken;

const allDataLoaded = (context, event) => {
  return (
    context?.todoCorrectiveActionsLoaded === true &&
    context?.ongoingCorrectiveActionsLoaded === true &&
    context?.completeCorrectiveActionsLoaded === true
  );
};

const todoViewActive = (context, event) => {
  return context?.view === "todo";
};

const ongoingViewActive = (context, event) => {
  return context?.view === "ongoing";
};

const completeViewActive = (context, event) => {
  return context?.view === "complete";
};
const correctiveActionsNotEmpty = (context, event) => {
  return context?.corrective_actions?.length > 0;
};

export {
  validLoadRequest,
  validToken,
  allDataLoaded,
  todoViewActive,
  ongoingViewActive,
  completeViewActive,
  correctiveActionsNotEmpty,
};
