const compareValues = (a, b, direction = "asc") => {
  // When both values are null or undefined
  if ((a === null && b === null) || (a === undefined && b === undefined)) {
    return 0;
  }

  // Considérer null comme inférieur à toute autre valeur
  if (a === null) return direction === "asc" ? -1 : 1;
  if (b === null) return direction === "asc" ? 1 : -1;

  // Comparaison pour les nombres
  if (typeof a === "number" && typeof b === "number") {
    return direction === "asc" ? a - b : b - a;
  }

  // Comparaison pour les dates
  else if (a instanceof Date && b instanceof Date) {
    return direction === "asc" ? a - b : b - a;
  }

  // Comparaison pour les chaînes de caractères
  else if (typeof a === "string" && typeof b === "string") {
    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  }

  // Ajout d'un cas de fallback si les types ne sont pas gérés
  // Cela peut être utile pour éviter des erreurs en cas de comparaison de types inattendus
  else {
    console.warn("Attempting to compare unsupported types", { a, b });
    return 0;
  }
};

const getNestedValue = (obj, path) => {
  return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
};

export { compareValues, getNestedValue };
