import axios from "axios";

const defaultPutOptions = {
  method: "Put",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const updateFiveWhys = (context, event) => {
  try {
    const dataSet = {};

    if (context.changeSet.problem_statement) {
      dataSet.problem_statement = context.changeSet.problem_statement;
    }
    if (context.changeSet.answer_why_1) {
      dataSet.answer_why_1 = context.changeSet.answer_why_1;
    }
    if (context.changeSet.answer_why_2) {
      dataSet.answer_why_2 = context.changeSet.answer_why_2;
    }
    if (context.changeSet.answer_why_3) {
      dataSet.answer_why_3 = context.changeSet.answer_why_3;
    }
    if (context.changeSet.answer_why_4) {
      dataSet.answer_why_4 = context.changeSet.answer_why_4;
    }
    if (context.changeSet.answer_why_5) {
      dataSet.answer_why_5 = context.changeSet.answer_why_5;
    }
    if (context.changeSet.final_why_index) {
      dataSet.final_why_index = context.changeSet.final_why_index;
    }
    if (context.changeSet.final_answer) {
      dataSet.final_answer = context.changeSet.final_answer;
    }

    dataSet.other_possible_causes = context.changeSet.other_possible_causes
      ? context.changeSet.other_possible_causes
      : null;

    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify(dataSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/five-whys`,
      options,
    ).then((response) => response?.data?.five_whys);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { updateFiveWhys };
