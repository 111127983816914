export default function StepsContainer({ children }) {
  return (
    <>
      <div className="flex flex-1 flex-col justify-between">
        <div className="divide-y divide-gray-200 px-4 pb-96 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">{children}</div>
        </div>
      </div>
    </>
  );
}
