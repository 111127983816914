// import { useCreateReportMachine } from "../../../../context/CreateReportMachineContext";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CriticityBadge({ value, obfuscated = false }) {
  // const [createReportState, createReportSend] = useCreateReportMachine();
  const { t } = useTranslation();

  const criticityTypeStyles = {
    LIMITED_RISK_SURVEILLANCE_ZONE: {
      class: "bg-blue-100 text-blue-700",
      label: t("shared.type.criticity.tag.LIMITED_RISK_SURVEILLANCE_ZONE"),
    },
    MODERATE_RISK_PLANNED_CORRECTIONS: {
      class: "bg-indigo-100 text-blue-700",
      label: t("shared.type.criticity.tag.MODERATE_RISK_PLANNED_CORRECTIONS"),
    },
    SIGNIFICANT_RISK_CORRECTIONS_WITHIN_24H: {
      class: "bg-purple-100 text-purple-700",
      label: t(
        "shared.type.criticity.tag.SIGNIFICANT_RISK_CORRECTIONS_WITHIN_24H",
      ),
    },
    CRITICAL_RISK_URGENT_CORRECTIVE_ACTIONS: {
      class: "bg-pink-100 text-pink-700",
      label: t(
        "shared.type.criticity.tag.CRITICAL_RISK_URGENT_CORRECTIVE_ACTIONS",
      ),
    },
    OBFUSCATED: {
      class: "bg-gray-100 text-gray-700",
      label: t("shared.type.criticity.tag.OBFUSCATED"),
    },
  };

  const currentStyle = criticityTypeStyles[value] || "";

  return (
    <>
      <span
        className={classNames(
          "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1 text-xs font-medium",
          currentStyle.class,
        )}
      >
        <span className={classNames(obfuscated ? "blur-sm" : "")}>
          {currentStyle.label}
        </span>
      </span>
    </>
  );
}
