const {
  validToken,
  tokenReady,
  accessDeniedError,

  validSelectedConsequenceTypeObject,
} = require("../../commons/guards");

const validChosenResidence = (context, event) => {
  return (
    context?.report?.residence_one_key !== undefined &&
    context?.report?.residence_one_key !== null &&
    context?.report?.residence_one_key !== ""
  );
  // return true;
};
const validSelectedResidenceAndConsequence = (context, event) => {
  return (
    validConsequenceTypeEventKey(context, event) &&
    validChosenResidence(context, event)
  );
  // return true;
};

const validConsequenceTypeEventKey = (context, event) => {
  return (
    context?.report?.event?.consequence_type !== undefined &&
    context?.report?.event?.consequence_type !== null &&
    context?.report?.event?.consequence_type !== ""
  );
};
const validCriticityType = (context, event) => {
  return context?.form?.computedCriticityType;
};

const allRequiredFieldsAreValid = (context, event) => {
  return true;
};

const validReceivedEventDescription = (context, event) => {
  return true;
};

const validEventOccurenceDate = (context, event) => {
  return (
    event?.data !== undefined && event?.data !== null && event?.data !== ""
  );
};

const validDate = (context, event) => {
  console.log("validDate", event);
  return event?.data !== undefined && event?.data !== null;
};

const validEventDomain = (context, event) => {
  return true;
};

const forcedCriticity = (context, event) => {
  if (context?.report?.event?.consequence_type) {
    const consequenceType = context.consequenceTypes.find(
      (consequenceType) =>
        consequenceType.name === context.report.event.consequence_type,
    );
    if (
      consequenceType?.forced_severity &&
      consequenceType?.forced_probability
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const computableCrititicy = (context, event) => {
  if (context?.report?.event?.consequence_type) {
    const consequenceType = context.consequenceTypes.find(
      (consequenceType) =>
        consequenceType.name === context.report.event.consequence_type,
    );
    if (
      consequenceType?.forced_severity == null &&
      consequenceType?.forced_probability == null
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const validSelectedSeverity = (context, event) => {
  return true;
};

const validSelectedProbability = (context, event) => {
  return true;
};

const validEventLocation = (context, event) => {
  return true;
};

const validEventTarget = (context, event) => {
  return true;
};

const validEventCategory = (context, event) => {
  return true;
};

const validEventPeriodOfTheDay = (context, event) => {
  return true;
};
const validResidence = (context, event) => {
  return true;
};

const editReportMachineRef = (context, event) => {
  return (
    context?.editReportMachineRef !== undefined &&
    context?.editReportMachineRef !== null
  );
};

const validEventDescription = (context, event) => {
  return (
    context?.report?.event?.description !== undefined &&
    context?.report?.event?.description !== null &&
    context?.report?.event?.description !== ""
  );
};

const validEventDateAndDescription = (context, event) => {
  return (
    context?.report?.event?.event_occured_at !== undefined &&
    context?.report?.event?.event_occured_at !== null &&
    context?.report?.event?.event_occured_at !== "" &&
    context?.report?.event?.description !== undefined &&
    context?.report?.event?.description !== null &&
    context?.report?.event?.description !== ""
  );
};

const validEventContextFormData = (context, event) => {
  return (
    // occurence date
    context?.report?.event?.event_occured_at !== undefined &&
    context?.report?.event?.event_occured_at !== null &&
    context?.report?.event?.event_occured_at !== "" &&
    // location
    context?.report?.event?.location !== undefined &&
    context?.report?.event?.location !== null &&
    context?.report?.event?.location !== ""
  );
};

const userHasAffiliatedResidences = (context, event) => {
  return (
    context?.residences !== undefined &&
    context?.residences !== null &&
    context?.residences.length > 0
  );
};

export {
  validSelectedConsequenceTypeObject,
  validChosenResidence,
  validConsequenceTypeEventKey,
  validSelectedResidenceAndConsequence,
  validEventDateAndDescription,
  validCriticityType,
  allRequiredFieldsAreValid,
  validToken,
  validReceivedEventDescription,
  validEventDescription,
  validEventOccurenceDate,
  validDate,
  validEventContextFormData,
  tokenReady,
  forcedCriticity,
  computableCrititicy,
  validSelectedSeverity,
  validSelectedProbability,
  validEventLocation,
  validEventTarget,
  validEventCategory,
  validEventDomain,
  validEventPeriodOfTheDay,
  validResidence,
  editReportMachineRef,
  userHasAffiliatedResidences,
  accessDeniedError,
};
