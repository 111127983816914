const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const ReviewCorrectionsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCcwDcCWYDuBaAxgPbKr4AuGhAdrAHQYQA2YAxAMoAqAggEocDaABgC6iUAAdCsDBWpiQAD0S4AHAGZaAVgCcAFm0AmFQYO6AbCs2CVAGhABPZQEYDg2mu2CjKgOy+zPlYqAL7BdqiYOATEpLI0tACG5BhorAByAKIAGgIi8pLScfJKCE4+ZrSC2k7VTmauaroGmmZ2jgjNbpo+PWpenpq+3aHh6Fh4RCRgydR0SRSpLAAKPBkAagCSAPIAqmxCokggBTKUVMXKaibuZT5N5YIuZZptiM0VBmb6eoJBempmEYgCLjaJTGbxeYpVgAYS2PFWMI42zStAAIhkADIZDgZA75KSnORHEqqOq0Hx6XROXQed49V4ITRWWhGT4uHyCRoGK5AkFRSaxM5zZKLOEIjJIlG0HZLNFcXH4o4nIokxBlCoqMzapzMgyU2kmRkeJys7TM+6aT4GOqAsLAsYCmLTOIihawzFbNh4vLKwmq0AlOo+WgqazqHwGQyBWwOdWhsPqAKBarWbo+PmOibOiFu6EsDHYxW+iT+s4XUqRrRWUz1apmNTlAyMq26SqDExmOqeAKZyLZ8GuxKi1iy+W4gD6ACEMrwMjwJxwtgBpDJpJWlwrltWlJwqWgNu49FRh6mPRlfDRHqqCR7eXyhe1UQgQODyfkDoWzAlb4mB5Ram2BrUrShgtAycYILgfQaNo2hmIYXiUt0TiCLofagoKLrCvQTBgD+RLnDuuBlFogg9Lelg1HuEHtLgNoVAEHhwWYFrWBm9ofmCX6QiOBEBooyimBSuiRh4fRwS41S6IyuptuanxXGoYaeDaBiPsEQA */
    predictableActionArguments: true,
    id: "review-corrections",
    context: {
      corrections: [],
      index: null,
      authentication: { token: null },
      reportId: null,
      qmsToolApiUrl: null,
    },
    states: {
      idle: {
        on: {
          START: {
            target: "active",
            cond: "validRequest",
            actions: [
              "saveCorrections",
              "setIndexOnFirstPage",
              "saveAuthentication",
              "saveReportId",
              "saveApiUrl",
              "unflagChanges",
            ],
          },
        },
      },

      active: {
        on: {
          NEXT: {
            target: "active",
            internal: true,
            actions: ["incrementIndex", "notifyCurrentIndex"],
            cond: "notOnLastPage",
          },

          PREVIOUS: {
            target: "active",
            internal: true,
            cond: "notOnFirstPage",
            actions: ["decrementIndex", "notifyCurrentIndex"],
          },

          "CORRECTION.DELETE": {
            target: "active",
            internal: true,
            actions: ["adaptIndex", "flagChanges", "removeCorrection"],
          },

          "CORRECTION.UPDATE": {
            target: "active",
            internal: true,
            actions: ["updateCorrection", "flagChanges"],
          },

          CLOSE: {
            target: "idle",
            actions: ["resetCorrections", "resetPageIndex"],
          },

          DELETE: {
            target: "active",

            actions: "sendDeleteToCurrentActor",
          },

          UPDATE_BEARER_TOKEN: {
            target: "active",
            internal: true,
            cond: "validToken",
            actions: ["saveToken", "sendTokenToSpawnedActors"],
          },
        },
      },
    },

    initial: "idle",
  },
  { actions: actions, guards: guards, services: services },
);

export { ReviewCorrectionsMachine };
