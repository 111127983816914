import Layout from "./Layout";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEffect } from "react";

export default function ReportDetails() {
  const [state, send] = useViewEditReportMachine();

  const value = state.value;

  useEffect(() => {
    console.log("value changed to: ", state.value);
  }, [state]);

  return (
    <div className="xl:mt-3">
      <Layout></Layout>
    </div>
  );
}
