import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { ReviewFiveWhysMachine as parentMachine } from "../machines/review-five-whys/review-five-whys-machine";

const ReviewFiveWhysMachineContext = createContext();

export function useReviewFiveWhysMachine() {
  const context = useContext(ReviewFiveWhysMachineContext);

  if (!context) {
    throw new Error(
      "useReviewFiveWhysMachine must be used within a ReviewFiveWhysMachineProvider",
    );
  }

  return context;
}

export function ReviewFiveWhysMachineProvider({ children }) {
  const reviewFiveWhysMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <ReviewFiveWhysMachineContext.Provider value={reviewFiveWhysMachine}>
      {children}
    </ReviewFiveWhysMachineContext.Provider>
  );
}
