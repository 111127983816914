import axios from "axios";

const defaultPostOptions = {
  method: "Post",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const createSeqOfEventsItemDetail = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
      data: JSON.stringify(context?.changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.loadRequest.reportId}/sequence-of-events`,
      options,
    ).then((response) => response?.data?.sequence_of_events);
  } catch (error) {
    return Promise.reject(error);
  }
};

const removeSeqOfEventsItemDetail = (context, event) => {};

export { createSeqOfEventsItemDetail, removeSeqOfEventsItemDetail };
