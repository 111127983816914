import React from "react";
import { useTranslation } from "react-i18next";

import { useCreateReportMachine } from "../../../../context/CreateReportMachineContext";
import {
  ArrowSmallRightIcon,
  ArrowSmallDownIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventTypeFlag() {
  const { t } = useTranslation();

  const [createReportState, createReportSend] = useCreateReportMachine();
  const eventType =
    createReportState?.context?.form?.computedCriticityType?.type;
  const stepIdentificationOfTheCriticity = createReportState.matches({
    processing: {
      initialized: "active.identification of the criticity",
    },
  });
  const computingCriticityType =
    createReportState.matches({
      processing: {
        initialized: "active.selection of the consequence.compute criticity",
      },
    }) ||
    createReportState.matches({
      processing: {
        initialized:
          "active.identification of the criticity.computable criticity.compute criticity",
      },
    });

  return (
    <div className="grid grid-cols-3 ">
      <div
        className={classNames(
          "col-start-3 col-end-4  justify-self-center rounded transition-all duration-1000",
          !eventType
            ? "bg-gray-600"
            : eventType === "SENTINEL"
            ? "bg-red-600"
            : eventType === "SAFETY" 
            ? "bg-orange-400"
            : "bg-gray-400",
        )}
      >
        <div
          className={"block px-6 py-1 text-xs font-medium leading-6 text-white"}
        >
          {computingCriticityType ? (
            // Replace this with whatever you want to render when computing is true
            <svg
              className=" h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : !eventType ? (
            stepIdentificationOfTheCriticity ? (
              <ArrowSmallDownIcon className="h-6 w-6 text-white" />
            ) : (
              <ArrowSmallRightIcon className="h-6 w-6 text-white" />
            )
          ) : (
            t("shared.type.event." + eventType).toUpperCase()
          )}
        </div>
      </div>
    </div>
  );
}
