import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useCreateReportMachine } from "../../../context/CreateReportMachineContext";
import { useListReportsMachine } from "../../../context/ListReportsMachineContext";

import { useNavigate } from "react-router-dom";

import { XMarkIcon, BanknotesIcon } from "@heroicons/react/24/outline";

import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import Step6 from "./steps/step6";

import Success from "./steps/Success";
import Error from "./steps/Error";
import Cancel from "./steps/Cancel";
import Crumbread from "./steps/Crumbread";
import EventTypeFlag from "./steps/EventTypeFlag";
import Loading from "./steps/Loading";
import AccessDenied from "./AccessDenied";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewReportPanel() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const [listReportsState, listReportsSend] = useListReportsMachine();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Period changed");
  }, [createReportState.context?.report?.event?.period_of_the_day]);

  const handleOnClose = () => {
    createReportSend("ABANDON_REQUESTED");
  };

  const computingCriticity =
    createReportState.matches({
      processing: {
        initialized: "active.selection of the consequence.compute criticity",
      },
    }) ||
    createReportState.matches({
      processing: {
        initialized:
          "active.identification of the criticity.computable criticity.compute criticity",
      },
    });

  return (
    <Transition.Root
      show={createReportState.matches({
        processing: { initialized: "active" },
      })}
      as={Fragment}
    >
      <Dialog as="div" className="relative z-[100]" onClose={handleOnClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-medium leading-6 text-white">
                            {t("createReport.title.newReport")}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              tabIndex={-1}
                              type="button"
                              className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                              disabled={
                                !createReportState.matches({
                                  processing: {
                                    initialized:
                                      "active.selection of the consequence",
                                  },
                                }) &&
                                !createReportState.matches({
                                  processing: {
                                    initialized:
                                      "active.report actions summary",
                                  },
                                })
                              }
                              onClick={() => {
                                if (
                                  createReportState.matches({
                                    processing: {
                                      initialized:
                                        "active.report actions summary",
                                    },
                                  })
                                ) {
                                  createReportSend("CLOSE");
                                }
                                createReportSend("ABANDON_REQUESTED");
                              }}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            {t("createReport.subtitle.newReport")}
                          </p>
                        </div>
                        <div className="mt-2"></div>
                      </div>
                      {!createReportState.matches({
                        processing: { initialized: "active.confirm abandon" },
                      }) &&
                        !createReportState.matches({
                          processing: {
                            initialized: "active.report actions summary",
                          },
                        }) &&
                        !createReportState.matches({
                          processing: {
                            initialized: "active.create report.creating report",
                          },
                        }) &&
                        !createReportState.matches({
                          processing: {
                            initialized: "active.report actions summary",
                          },
                        }) && (
                          <div className="-mt-4">
                            <EventTypeFlag />
                          </div>
                        )}

                      {/* CONFIRM ABANDON */}
                      {createReportState.matches({
                        processing: { initialized: "active.confirm abandon" },
                      }) && <Cancel />}
                      {/* STEP 1 */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.selection of the consequence",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={1} />
                          <div className="mt-4">
                            <Step1 />
                          </div>
                        </div>
                      )}
                      {/* STEP 2 */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.identification of the criticity",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={2} />
                          <div className="mt-4">
                            <Step2 />
                          </div>
                        </div>
                      )}
                      {/* STEP 3 */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.event description",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={3} />
                          <div className="mt-4">
                            <Step3 />
                          </div>
                        </div>
                      )}
                      {/* STEP 4 */}
                      {createReportState.matches({
                        processing: { initialized: "active.event context" },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={4} />
                          <div className="mt-4">
                            <Step4 />
                          </div>
                        </div>
                      )}
                      {/* STEP 5 */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.external communication",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={5} />
                          <div className="mt-4">
                            <Step5 />
                          </div>
                        </div>
                      )}
                      {/* STEP 6 */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.confirm creation",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={6} />
                          <div className="mt-4">
                            <Step6 />
                          </div>
                        </div>
                      )}
                      {/* Success */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.report actions summary",
                        },
                      }) && (
                        <div className="mt-6">
                          <div className="mt-4">
                            <Success />
                          </div>
                        </div>
                      )}
                      {/* Server Error */}
                      {createReportState.matches({
                        processing: {
                          initialized:
                            "active.create report.error.server error",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={6} />
                          <div className="mt-4">
                            <Error />
                          </div>
                        </div>
                      )}
                      {/* Server Error */}
                      {createReportState.matches({
                        processing: {
                          initialized:
                            "active.create report.error.access denied",
                        },
                      }) && (
                        <div className="mt-6">
                          <Crumbread currentStep={6} />
                          <div className="mt-4">
                            <AccessDenied />
                          </div>
                        </div>
                      )}

                      {/* Loading */}
                      {createReportState.matches({
                        processing: {
                          initialized: "active.create report.creating report",
                        },
                      }) && (
                        <div className="mt-6">
                          <Loading />
                        </div>
                      )}
                    </div>

                    {/* Bottom Action Bar */}
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {createReportState.can({ type: "ABANDON" }) && (
                        // && createReportState.matches({ processing: { initialized: 'active.selection of the consequence' } })
                        <button
                          onClick={() => createReportSend("ABANDON")}
                          className="ml-4 rounded-md px-3 py-2 text-sm font-medium text-red-600 ring-1 ring-inset ring-red-300 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        >
                          {t("shared.button.confirmAbandon")}
                        </button>
                      )}
                      {createReportState.can({ type: "ABANDON_REQUESTED" }) && (
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                          onClick={() => {
                            createReportSend("ABANDON_REQUESTED");
                          }}
                        >
                          {t("shared.button.cancel")}
                        </button>
                      )}
                      {createReportState.can({ type: "CANCEL_ABANDON" }) && (
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                          onClick={() => {
                            createReportSend("CANCEL_ABANDON");
                          }}
                        >
                          {t("shared.button.continue")}
                        </button>
                      )}
                      {createReportState.can({ type: "PREVIOUS" }) && (
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                          onClick={() => {
                            console.log("PREVIOUS");
                            createReportSend("PREVIOUS");
                          }}
                        >
                          {t("shared.button.back")}
                        </button>
                      )}
                      {!createReportState.matches({
                        processing: { initialized: "active.confirm abandon" },
                      }) &&
                        !createReportState.matches({
                          processing: { initialized: "idle" },
                        }) &&
                        !createReportState.matches({
                          processing: {
                            initialized: "active.confirm creation",
                          },
                        }) &&
                        !createReportState.matches({
                          processing: {
                            initialized: "active.report actions summary",
                          },
                        }) && (
                          <button
                            type="button"
                            onClick={() => createReportSend("NEXT")}
                            className={classNames(
                              createReportState.can({ type: "NEXT" }) &&
                                !computingCriticity
                                ? "bg-indigo-600 hover:bg-indigo-500"
                                : "cursor-not-allowed bg-indigo-300",
                              "ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                            )}
                            disabled={
                              !createReportState.can({ type: "NEXT" }) ||
                              computingCriticity
                            }
                          >
                            {t("shared.button.next")}
                          </button>
                        )}
                      {createReportState.can({ type: "CREATE" }) && (
                        <button
                          onClick={() => createReportSend("CREATE")}
                          className={
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          }
                        >
                          {t("shared.button.create")}
                        </button>
                      )}
                      {createReportState.can({ type: "CLOSE" }) && (
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                          onClick={() => {
                            createReportSend("CLOSE");
                          }}
                        >
                          {t("shared.button.close")}
                        </button>
                      )}
                      {createReportState.can({ type: "VIEW_REPORT" }) && (
                        <button
                          onClick={() => {
                            createReportSend("VIEW_REPORT");
                            listReportsSend("RELOAD");
                            navigate(
                              `/reports/${createReportState.context.result.id}`,
                            );
                          }}
                          className={
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          }
                        >
                          {t("createReport.button.viewReport")}
                        </button>
                      )}

                      {createReportState.can({ type: "RETRY" }) && (
                        <button
                          onClick={() => createReportSend("RETRY")}
                          className={
                            "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          }
                        >
                          {t("createReport.button.retry")}
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
