import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { useReviewFiveWhysMachine } from "../../../context/ReviewFiveWhysMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";

import { XMarkIcon } from "@heroicons/react/24/outline";
import Cancel from "./Cancel";
import ProblemDefinition from "./steps/ProblemDefinition";
import Answer1 from "./steps/Answer1";
import Answer2 from "./steps/Answer2";
import Answer3 from "./steps/Answer3";
import Answer4 from "./steps/Answer4";
import Answer5 from "./steps/Answer5";
import Summary from "./steps/Summary";
import Loading from "./steps/Loading";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ReviewFiveWhysPanel() {
  const [state, send] = useReviewFiveWhysMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();

  const { t } = useTranslation();
  const { keycloak } = useAuth();

  const handleOnClose = () => {
    send("ABANDON_REQUESTED");
  };

  useEffect(() => {
    if (state.matches("active.success")) {
      viewEditReportSend("LOAD_REPORT", { data: state?.context?.reportId });
    }
  }, [state]);

  useEffect(() => {
    send("UPDATE_BEARER_TOKEN", { data: keycloak.token });
  }, [keycloak.token]);

  return (
    <>
      <Transition.Root
        show={state.matches("active") || state.matches("confirm abandon")}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-[100]" onClose={handleOnClose}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-medium leading-6 text-white">
                              {t("editFiveWhys.view.title")}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                tabIndex={-1}
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                                disabled={!state.can("ABANDON_REQUESTED")}
                                onClick={() => send("ABANDON_REQUESTED")}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {t("editFiveWhys.view.subtitle")}
                            </p>
                          </div>
                          <div className="mt-2"></div>
                        </div>
                        {/* CONFIRM ABANDON */}
                        {state.matches("confirm abandon") && <Cancel />}
                        {/* PROBLEM STATEMENT */}
                        {state.matches("active.define problem") && (
                          <ProblemDefinition />
                        )}
                        {/* ANSWER 1 */}
                        {state.matches("active.why 1") && <Answer1 />}
                        {/* ANSWER 2 */}
                        {state.matches("active.why 2") && <Answer2 />}
                        {/* ANSWER 3 */}
                        {state.matches("active.why 3") && <Answer3 />}
                        {/* ANSWER 4 */}
                        {state.matches("active.why 4") && <Answer4 />}
                        {/* ANSWER 5 */}
                        {state.matches("active.why 5") && <Answer5 />}
                        {/* SUMMARY */}
                        {state.matches("active.summary") && <Summary />}
                        {/* LOADING */}
                        {state.matches("active.creating") && <Loading />}
                      </div>

                      {/* Bottom Action Bar */}
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {state.can({ type: "ABANDON" }) && (
                          // && createReportState.matches({ processing: { initialized: 'active.selection of the consequence' } })
                          <button
                            onClick={() => send("ABANDON")}
                            className="ml-4 rounded-md px-3 py-2 text-sm font-medium text-red-600 ring-1 ring-inset ring-red-300 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                          >
                            {t("shared.button.confirmAbandon")}
                          </button>
                        )}
                        {state.can({
                          type: "ABANDON_REQUESTED",
                        }) &&
                          state.matches("active.define problem") && (
                            <button
                              type="button"
                              className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                              onClick={() => {
                                send("ABANDON_REQUESTED");
                              }}
                            >
                              {t("shared.button.cancel")}
                            </button>
                          )}
                        {state.can({ type: "CANCEL_ABANDON" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={() => {
                              send("CANCEL_ABANDON");
                            }}
                          >
                            {t("shared.button.continue")}
                          </button>
                        )}
                        {state.can({ type: "PREVIOUS" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={() => {
                              send("PREVIOUS");
                            }}
                          >
                            {t("shared.button.back")}
                          </button>
                        )}
                        {state.can("REVIEW") && (
                          <button
                            type="button"
                            onClick={() => send("REVIEW")}
                            className={classNames(
                              "ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500",
                            )}
                            disabled={!state.can("REVIEW")}
                          >
                            {t("shared.button.review")}
                          </button>
                        )}
                        {state.can("CONFIRM") && (
                          <button
                            type="button"
                            onClick={() => send("CONFIRM")}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("CONFIRM")}
                          >
                            {t("shared.button.confirm")}
                          </button>
                        )}
                        {state.can("UPDATE") && (
                          <button
                            type="button"
                            onClick={() => send("UPDATE")}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("UPDATE")}
                          >
                            {t("shared.button.update")}
                          </button>
                        )}
                        {state.can("CLOSE") && (
                          <button
                            type="button"
                            onClick={() => {
                              send("CLOSE");
                            }}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("CLOSE")}
                          >
                            {t("shared.button.close")}
                          </button>
                        )}
                        {!state.matches("active.summary") &&
                          !state.matches("confirm abandon") &&
                          !state.can("CONFIRM") && (
                            <button
                              type="button"
                              onClick={() => send("NEXT")}
                              className={classNames(
                                "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                              )}
                              disabled={!state.can("NEXT")}
                            >
                              {t("shared.button.next")}
                            </button>
                          )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
