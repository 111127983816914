import jwtDecode from "jwt-decode";

const { validToken } = require("../../../commons/guards");

const validStartRequest = (context, event) => {
  console.log("validStartRequest", event.data);
  return (
    event?.data?.authentication &&
    event.data?.authentication?.token &&
    event.data?.authentication?.token.length > 0
  );
};

const userCanAddANewActionPlan = (context, event) => {
  return event?.data?.length > 0;
};

const validResidenceOneKey = (context, event) => {
  return event.data?.residence_one_key;
};

const validOneKey = (context, event) => {
  return context?.createRequest?.residenceOneKey;
};

export {
  validStartRequest,
  userCanAddANewActionPlan,
  validResidenceOneKey,
  validToken,
  validOneKey,
};
