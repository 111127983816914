import {
  CalendarDaysIcon,
  UserCircleIcon,
  BuildingOffice2Icon,
  Bars4Icon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { useCreateReportMachine } from "../../../../context/CreateReportMachineContext";
import DateTimeFormatter from "../../DateTimeFormatter";
import { useAuth } from "../../../../context/AuthContext";
import {
  MoonIcon,
  SunIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import CriticityBadge from "../../CriticityBadge";
import GenericBadge from "../../shared/GenericBadge";
import { Trans, useTranslation } from "react-i18next";
import PeriodOfTheDayBadge from "../../PeriodOfTheDayBadge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Summary() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { keycloak, state, send, profile } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <div className="lg:col-start-3 lg:row-end-1">
        <div className="rounded-lg bg-gray-50 pb-6 shadow-sm ring-1 ring-gray-900/5">
          <dl className="flex flex-wrap">
            {/* Submitter header */}
            <div className="mt-6 px-6">
              <p className="w-full text-sm font-medium leading-6 text-gray-600">
                {t("createReport.steps.step6.summary.sectionHeader.submitter")}
              </p>
            </div>
            {/* Submitter */}
            <div className="flex w-full flex-none gap-x-4  px-6 pt-6">
              <div className="flex flex-auto justify-between">
                <div>
                  <div className="flex w-full flex-none gap-x-4   text-sm font-semibold leading-6 text-gray-900">
                    <dt className="flex-none">
                      <UserCircleIcon
                        className="h-6 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-500">
                      {profile.firstName} {profile.lastName}
                    </dd>
                  </div>
                </div>
                <div>
                  <div className="flex w-full flex-none gap-x-4   text-sm font-semibold leading-6 text-gray-900">
                    <dt>
                      <BuildingOffice2Icon
                        className="h-6 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-500">
                      {createReportState?.context?.report?.residence_one_key}
                    </dd>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <div className="flex flex-auto justify-between">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t(
                    "createReport.steps.step6.summary.sectionHeader.criticity",
                  )}
                </p>
                <CriticityBadge
                  value={
                    createReportState?.context?.form?.computedCriticityType
                      ?.name
                  }
                />
              </div>
            </div>
            <div className="flex w-full flex-none gap-x-4 px-6 pt-6">
              <div className="flex w-full flex-none gap-x-4 text-sm leading-6 text-gray-600">
                <dt className="flex-none">
                  <ExclamationTriangleIcon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>

                <dd className="">
                  {createReportState?.context?.form?.computedCriticityType
                    .action_deadline_in_hours === 0 && (
                    <>
                      <Trans
                        i18nKey="createReport.steps.step6.summary.criticity.warn.actionDeadlineZeroHours"
                        components={{
                          span: <span className="font-semibold text-red-700" />,
                        }}
                      />
                    </>
                  )}
                  {createReportState?.context?.form?.computedCriticityType
                    ?.action_deadline_in_hours > 0 &&
                    createReportState?.context?.form?.computedCriticityType
                      ?.action_deadline_in_hours <= 24 && (
                      <>
                        <Trans
                          i18nKey="createReport.steps.step6.summary.criticity.warn.actionDeadlineWithin24Hours"
                          components={{
                            span: (
                              <span className="font-semibold text-purple-700" />
                            ),
                          }}
                          values={{
                            hours:
                              createReportState?.context?.form
                                ?.computedCriticityType
                                ?.action_deadline_in_hours,
                          }}
                        />
                      </>
                    )}
                  {createReportState?.context?.form?.computedCriticityType
                    .action_deadline_in_hours > 24 && (
                    <>
                      <Trans
                        i18nKey="createReport.steps.step6.summary.criticity.warn.actionDealineOver24Hours"
                        components={{
                          span: (
                            <span className="font-semibold text-blue-700" />
                          ),
                        }}
                        values={{
                          days:
                            createReportState?.context?.form
                              ?.computedCriticityType
                              ?.action_deadline_in_hours / 24,
                        }}
                      />
                    </>
                  )}
                </dd>
              </div>
            </div>
            {/* Required actions */}

            {(createReportState?.context?.form?.computedCriticityType
              ?.action_plan_required ||
              createReportState?.context?.form?.computedCriticityType
                ?.seq_of_events_required ||
              createReportState?.context?.form?.computedCriticityType
                ?.root_cause_analysis_required) && (
              <div className="flex w-full flex-none gap-x-4 px-6 pt-6">
                <div className="flex w-full flex-none gap-x-4 pb-6 text-sm leading-6 text-gray-600">
                  <dt className="flex-none">
                    <ListBulletIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="">
                    <div>
                      <p className=" ">
                        {t(
                          "createReport.steps.step6.summary.criticity.warn.label.tasks",
                        )}
                      </p>
                      <ul className="mt-1.5 list-disc pl-4">
                        {createReportState?.context?.form?.computedCriticityType
                          ?.seq_of_events_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.sequenceOfEvents",
                            )}
                          </li>
                        )}
                        {createReportState?.context?.form?.computedCriticityType
                          ?.root_cause_analysis_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.rootCauseAnalysis",
                            )}
                          </li>
                        )}
                        {createReportState?.context?.form?.computedCriticityType
                          ?.action_plan_required && (
                          <li>
                            {t(
                              "createReport.steps.step6.summary.criticity.warn.task.actionPlan",
                            )}
                          </li>
                        )}
                      </ul>
                    </div>
                  </dd>
                </div>
              </div>
            )}

            {/* Event section header */}
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <p className="text-sm font-medium leading-6 text-gray-600">
                {t("createReport.steps.step6.summary.sectionHeader.event")}
              </p>
            </div>
            {/* Date and time  */}
            <div className="flex flex-auto  px-6 pt-6">
              <div className="flex flex-auto justify-between">
                <div className="flex flex-none gap-x-4 ">
                  <dt className="flex-none">
                    <CalendarDaysIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    <time
                      dateTime={
                        createReportState?.context?.report?.event
                          ?.event_occured_at
                      }
                    >
                      <DateTimeFormatter
                        isoString={
                          createReportState?.context?.report?.event
                            ?.event_occured_at
                        }
                      />
                    </time>
                  </dd>
                </div>
                <PeriodOfTheDayBadge
                  periodOfTheDay={
                    createReportState?.context?.report?.event?.period_of_the_day
                  }
                />
              </div>
            </div>
            {/* Description */}
            <div className="flex w-full flex-none gap-x-4 px-6 pt-6">
              <div className="flex w-full flex-none gap-x-4 text-sm leading-6 text-gray-600">
                <dt className="flex-none">
                  <Bars4Icon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd className="">
                  {createReportState?.context?.report?.event?.description}
                </dd>
              </div>
            </div>
            {/* Target section  */}
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <div className="flex flex-auto justify-between gap-x-4">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t("createReport.steps.step6.summary.sectionHeader.target")}
                </p>
                <GenericBadge
                  value={t(
                    "shared.type.target." +
                      createReportState?.context?.report?.event?.target,
                  )}
                />
              </div>
            </div>

            {/* Location section  */}
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <div className="flex flex-auto justify-between gap-x-4">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t("createReport.steps.step6.summary.sectionHeader.location")}
                </p>
                <GenericBadge
                  value={t(
                    "shared.type.location." +
                      createReportState?.context?.report?.event?.location,
                  )}
                />
              </div>
            </div>

            {/* Category section  */}
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <div className="flex flex-auto justify-between gap-x-4">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t("createReport.steps.step6.summary.sectionHeader.category")}
                </p>
                <GenericBadge
                  value={t(
                    "shared.type.category." +
                      createReportState?.context?.report?.event?.category,
                  )}
                />
              </div>
            </div>
            {/* Domain section  */}
            <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
              <div className="flex flex-auto justify-between gap-x-4">
                <p className="text-sm font-medium leading-6 text-gray-600">
                  {t("createReport.steps.step6.summary.sectionHeader.domain")}
                </p>
                <GenericBadge
                  value={t(
                    "shared.type.domain." +
                      createReportState?.context?.report?.event?.domain,
                  )}
                />
              </div>
            </div>

            {/* Communication to authorities */}
            {createReportState?.context?.report?.event
              ?.authority_informed_at && (
              <>
                <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    {t(
                      "createReport.steps.step6.summary.sectionHeader.authorityInformed",
                    )}
                  </p>
                </div>
                {/* Date and time  */}
                <div className="flex flex-auto  px-6 pt-6">
                  <div className="flex flex-auto justify-between">
                    <div className="flex flex-none gap-x-4 ">
                      <dt className="flex-none">
                        <CalendarDaysIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time
                          dateTime={
                            createReportState?.context?.report?.event
                              ?.authority_informed_at
                          }
                        >
                          <DateTimeFormatter
                            isoString={
                              createReportState?.context?.report?.event
                                ?.authority_informed_at
                            }
                          />
                        </time>
                      </dd>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Communication to family */}
            {createReportState?.context?.report?.event?.family_informed_at && (
              <>
                <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    {t(
                      "createReport.steps.step6.summary.sectionHeader.familyInformed",
                    )}
                  </p>
                </div>
                {/* Date and time  */}
                <div className="flex flex-auto  px-6 pt-6">
                  <div className="flex flex-auto justify-between">
                    <div className="flex flex-none gap-x-4 ">
                      <dt className="flex-none">
                        <CalendarDaysIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time
                          dateTime={
                            createReportState?.context?.report?.event
                              ?.family_informed_at
                          }
                        >
                          <DateTimeFormatter
                            isoString={
                              createReportState?.context?.report?.event
                                ?.family_informed_at
                            }
                          />
                        </time>
                      </dd>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Communication to regional direction */}
            {createReportState?.context?.report?.event
              ?.regional_direction_informed_at && (
              <>
                <div className="mt-6 w-full border-t border-gray-900/5 px-6 pt-6">
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    {t(
                      "createReport.steps.step6.summary.sectionHeader.regionalDirectionInformed",
                    )}
                  </p>
                </div>
                {/* Date and time  */}
                <div className="flex flex-auto  px-6 pt-6">
                  <div className="flex flex-auto justify-between">
                    <div className="flex flex-none gap-x-4 ">
                      <dt className="flex-none">
                        <CalendarDaysIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time
                          dateTime={
                            createReportState?.context?.report?.event
                              ?.regional_direction_informed_at
                          }
                        >
                          <DateTimeFormatter
                            isoString={
                              createReportState?.context?.report?.event
                                ?.regional_direction_informed_at
                            }
                          />
                        </time>
                      </dd>
                    </div>
                  </div>
                </div>
              </>
            )}
          </dl>
        </div>
      </div>
    </>
  );
}
