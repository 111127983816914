import axios from "axios";

const defaultPostOptions = {
  method: "POST",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const createCorrectiveAction = (context, event) => {
  const options = {
    ...defaultPostOptions,
    headers: {
      ...defaultPostOptions.headers,
      Authorization: `Bearer ${context.authentication.token}`,
    },
    data: JSON.stringify({
      //   action_plan_id: context?.changeSet?.actionPlanId,
      description: context?.changeSet?.description,
      expired_at: context?.changeSet?.expiredAt,
      problem_statement: context?.changeSet?.problemStatement,
      domain: context?.changeSet?.domain,
      source: context?.changeSet?.correctiveActionSource,
      priority: context?.changeSet?.correctiveActionPriority,
      root_cause: context?.changeSet?.rootCause,
      handler_type: context?.changeSet?.handlerType,
      //   due_date: context?.changeSet?.dueDate,
      status: context?.changeSet?.status,
      //   title: context?.changeSet?.title,
    }),
  };
  return axios(
    `${context.qmsToolApiUrl}action-plans/${context.actionPlanId}/corrective-actions`,
    options,
  ).then((response) => response.data);
};

export { createCorrectiveAction };
