import axios from "axios";

const defaultDeleteOptions = {
  method: "DELETE",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultPutOptions = {
  method: "Put",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const deleteCorrectiveAction = (context, event) => {
  try {
    const options = {
      ...defaultDeleteOptions,
      headers: {
        ...defaultDeleteOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/corrective-actions/${context?.correctiveAction?.id}`,
      options,
    ).then((response) => response?.data?.corrective_actions);
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCorrectiveAction = (context, event) => {
  try {
    const changeSet = {};

    if (context?.changes?.has("description")) {
      changeSet.description = context?.correctiveAction?.description;
    }
    if (context?.changes?.has("handler_type")) {
      changeSet.handler_type = context?.correctiveAction?.handler_type;
    }
    if (context?.changes?.has("expired_at")) {
      changeSet.expired_at = context?.correctiveAction?.expired_at;
    }
    if (context?.changes?.has("deferral_reason")) {
      changeSet.deferral_reason = context?.correctiveAction?.deferral_reason;
    }
    if (context?.changes?.has("status")) {
      changeSet.status = context?.correctiveAction?.status;
    }

    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify(changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/corrective-actions/${context?.correctiveAction?.id}`,
      options,
    ).then((response) => response?.data?.corrective_actions);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { deleteCorrectiveAction, updateCorrectiveAction };
