import axios from "axios";

const defaultDeleteOptions = {
  method: "DELETE",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultPutOptions = {
  method: "Put",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const deleteSeqOfEventsDetail = (context, event) => {
  try {
    const options = {
      ...defaultDeleteOptions,
      headers: {
        ...defaultDeleteOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/sequence-of-events/${context?.seq_of_events_detail?.id}`,
      options,
    ).then(
      (response) =>
        response?.data?.sequence_of_events?.sequence_of_events_items_details,
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSeqOfEventsDetail = (context, event) => {
  try {
    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify({
        description: context?.seq_of_events_detail?.description,
        item_occured_at: context?.seq_of_events_detail?.item_occured_at,
        affected_entity: context?.seq_of_events_detail?.affected_entity,
        traceability_and_evidences:
          context?.seq_of_events_detail?.traceability_and_evidences,
      }),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.reportId}/sequence-of-events/${context?.seq_of_events_detail?.id}`,
      options,
    ).then(
      (response) =>
        response?.data?.sequence_of_events?.sequence_of_events_items_details,
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export { deleteSeqOfEventsDetail, updateSeqOfEventsDetail };
