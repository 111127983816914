import axios from "axios";
const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const fetchData = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.url}`, options).then((response) => response.data);
};

export { fetchData };
