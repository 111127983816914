import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useTranslation, Trans } from "react-i18next";
import { useEditCorrectiveActionsMachine } from "../../../context/EditCorrectiveActionsMachineContext";
import { useReviewCorrectiveActionsMachine } from "../../../context/ReviewCorrectiveActionsMachineContext";
import { useEffect } from "react";
import NewCorrectiveActionPanel from "../edit-corrective-actions/NewCorrectiveActionPanel";
import { FlagIcon } from "@heroicons/react/20/solid";
import { FlagIcon as FlagIconOutline } from "@heroicons/react/24/outline";
import TimeAgo from "../shared/TimeAgo";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { createReviewCorrectiveActionMachine } from "../../../machines/review-corrective-action/review-corrective-action-machine";

import DateTimeFormatter from "../DateTimeFormatter";
import ReviewCorrectiveActionsPanel from "../review-corrective-actions/ReviewCorrectiveActionsPanel";
import SectionLock from "../shared/SectionLock";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statuses = {
  COMPLETE: "text-green-700 bg-green-50 ring-green-600/20",
  ONGOING: "text-purple-700 bg-purple-50 ring-purple-600/20",
  TODO: "text-yellow-700 bg-yellow-100  ring-yellow-700/10",
};

export default function ActionPlan() {
  const [state, send] = useViewEditReportMachine();
  const [stateCorrectiveActions, sendCorrectiveActions] =
    useEditCorrectiveActionsMachine();
  const [flagSectionForReviewState, flagSectionForReviewSend] =
    useFlagSectionForReviewMachine();
  const [reviewCorrectiveActions, reviewCorrectiveActionsSend] =
    useReviewCorrectiveActionsMachine();
  const { t } = useTranslation();
  const sectionLocked =
    state.context?.report?.event?.criticity?.action_plan_required === false &&
    state.context?.report?.corrective_actions &&
    state.context?.report?.corrective_actions?.length > 0;

  useEffect(() => {
    sendCorrectiveActions("LOAD", {
      data: {
        reportId: state?.context?.report?.id,
        corrective_actions: state?.context?.report?.corrective_actions,
        authentication: {
          token: state?.context?.authentication?.token,
        },
      },
    });
    return () => {
      sendCorrectiveActions("UNLOAD");
    };
  }, []);

  useEffect(() => {
    console.log(
      "stateCorrectiveActions.context?.corrective_actions",
      stateCorrectiveActions.context?.corrective_actions,
    );
  }, [stateCorrectiveActions.context]);

  return (
    <>
      {(state.context?.report?.event?.criticity?.action_plan_required ===
        true ||
        sectionLocked) && (
        <>
          <SectionLock locked={sectionLocked}>
            <div
              className={classNames(
                "mt-12 overflow-hidden rounded-lg bg-white shadow",
                state.matches("processing.initialized.active.edit") ? "" : "",
              )}
            >
              <div className="border-t border-slate-200 bg-white  px-4 py-4 drop-shadow-md  sm:px-6">
                <div className="flex w-full items-center justify-between">
                  <h2 className="py-2 text-sm font-medium text-gray-600">
                    <div>
                      <Trans
                        i18nKey={"editReport.view.sectionHeader.actionPlan"}
                        components={{
                          span: (
                            <span className="ml-3 text-sm  leading-6 text-gray-500" />
                          ),
                        }}
                        values={{
                          residence_one_key:
                            state.context?.report?.residence_one_key,
                        }}
                      ></Trans>
                    </div>
                  </h2>

                  {!state?.context?.report?.obfuscated &&
                    state?.context?.report?.user_actions?.some(
                      (action) => action.action_id === "submit",
                    ) && (
                      <>
                        <div className="flex">
                          {state.context?.report?.corrective_actions?.length >
                            0 && (
                            <button
                              className="mr-3 flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50"
                              onClick={(e) => {
                                reviewCorrectiveActionsSend("START", {
                                  corrective_actions:
                                    state?.context?.report?.corrective_actions,
                                  authentication: {
                                    token:
                                      state?.context?.authentication?.token,
                                  },
                                  qmsToolApiUrl: state?.context?.qmsToolApiUrl,
                                  reportId: state?.context?.report?.id,
                                  spawnActorFunction:
                                    createReviewCorrectiveActionMachine,
                                });
                              }}
                              disabled={
                                state.matches(
                                  "processing.initialized.active.edit",
                                ) ||
                                !state?.context?.report?.user_actions?.some(
                                  (action) => action.action_id === "submit",
                                )
                              }
                            >
                              {t("shared.button.edit")}
                            </button>
                          )}
                          <button
                            className=" flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                            onClick={(e) => {
                              // Send here the event to add a new item to the list
                              sendCorrectiveActions("ADD_ITEM");
                            }}
                            // Disabled when the report is in edit mode
                            disabled={
                              state.matches(
                                "processing.initialized.active.edit",
                              ) || state.context?.report?.obfuscated
                            }
                          >
                            {t("editReport.view.actionPlan.button.add")}
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </div>

              <div
                id="fiveWhysSection"
                className="bg-slate-50 px-4 py-4  sm:px-6 "
              >
                {(!state.context?.report?.corrective_actions ||
                  (state.context?.report?.corrective_actions?.length === 0 &&
                    (!stateCorrectiveActions.context?.corrective_actions ||
                      stateCorrectiveActions.context?.corrective_actions
                        ?.length === 0))) && (
                  <>
                    <div className="mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                      <Trans
                        i18nKey={"editReport.view.actionPlan.label.required"}
                      ></Trans>
                    </div>
                  </>
                )}
                {(state.context?.report?.corrective_actions?.length > 0 ||
                  stateCorrectiveActions.context?.corrective_actions?.length >
                    0) && (
                  <div className="mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                    <>
                      <div className="">
                        <div className=" flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    {/* <th
                                    scope="col"
                                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                                  >
                                    {t(
                                      "editReport.view.actionPlan.table.headers.createdBy",
                                    )}
                                  </th>
                                  <th
                                    scope="col"
                                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-900 sm:pl-0"
                                  >
                                    Created at
                                  </th> */}
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap px-2 py-3.5 pl-4 text-left text-xs font-normal text-gray-900 sm:pl-0"
                                    >
                                      {t(
                                        "reviewActionPlan.correctiveAction.label.status",
                                      )}
                                    </th>
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-900 sm:pl-0"
                                    >
                                      {t(
                                        "createCorrectiveAction.step1.label.expiredAt",
                                      )}
                                    </th>
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                                    >
                                      {t(
                                        "createCorrectiveAction.step1.label.handledBy",
                                      )}
                                    </th>
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                                    >
                                      {t(
                                        "editCorrections.view.label.description",
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {stateCorrectiveActions.context?.corrective_actions.map(
                                    (item) => (
                                      <tr key={item.id}>
                                        {/* <td
                                        className={classNames(
                                          "whitespace-nowrap px-2 py-2 text-xs text-gray-500",
                                          state?.context?.report?.obfuscated
                                            ? "blur-sm"
                                            : "",
                                        )}
                                      >
                                        {item.created_by_username}
                                      </td>
                                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs text-gray-500 sm:pl-0">
                                        <DateTimeFormatter
                                          isoString={item?.created_at}
                                          obfuscated={
                                            state?.context?.report?.obfuscated
                                          }
                                        />
                                      </td> */}
                                        <td
                                          className={classNames(
                                            "whitespace-nowrap px-2 py-2 pl-4 text-xs text-gray-500 print:whitespace-pre-wrap sm:pl-0",
                                            state?.context?.report?.obfuscated
                                              ? "blur-sm"
                                              : "",
                                          )}
                                        >
                                          {t(
                                            "shared.type.correctiveActionStatus." +
                                              item.status,
                                          )}
                                        </td>
                                        <td
                                          className={classNames(
                                            "whitespace-nowrap px-2 py-2 text-xs text-gray-500 print:whitespace-pre-wrap",
                                            state?.context?.report?.obfuscated
                                              ? "blur-sm"
                                              : "",
                                          )}
                                        >
                                          <DateTimeFormatter
                                            isoString={item?.expired_at}
                                            obfuscated={
                                              state?.context?.report?.obfuscated
                                            }
                                          />
                                        </td>
                                        <td
                                          className={classNames(
                                            "whitespace-nowrap px-2 py-2 text-xs text-gray-500 print:whitespace-pre-wrap",
                                            state?.context?.report?.obfuscated
                                              ? "blur-sm"
                                              : "",
                                          )}
                                        >
                                          {item?.handler_type?.name
                                            ? t(
                                                `shared.type.correctiveAction.managerTypes.${item.handler_type.name}`,
                                              )
                                            : ""}
                                        </td>
                                        <td
                                          className={classNames(
                                            "whitespace-nowrap px-2 py-2 text-xs text-gray-500 print:whitespace-pre-wrap",
                                            state?.context?.report?.obfuscated
                                              ? "blur-sm"
                                              : "",
                                          )}
                                        >
                                          {item.description}
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </div>
              {state?.context?.report?.user_actions?.some(
                (action) =>
                  action.action_id === "corrective-actions-request-review",
              ) &&
                !state.context?.report
                  ?.corrective_actions_review_requested_at && (
                  <div className="border-t border-gray-200 bg-slate-50  px-4 pb-3 pt-4 text-sm font-medium text-gray-500 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div>
                        {t(
                          "editReport.view.immediateActions.label.requestReview",
                        )}
                      </div>
                      <div>
                        <button
                          className=" flex items-center justify-center rounded-md   px-2.5 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-amber-300 hover:text-gray-700 hover:ring-amber-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                          onClick={(e) => {
                            flagSectionForReviewSend("START", {
                              data: {
                                action_url:
                                  state?.context?.report?.user_actions?.find(
                                    (action) =>
                                      action.action_id ===
                                      "corrective-actions-request-review",
                                  )?.url,
                                authentication: {
                                  token: state?.context?.authentication?.token,
                                },
                              },
                            });
                          }}
                        >
                          <FlagIcon className="h6 w-5"></FlagIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {state.context?.report
                ?.corrective_actions_review_requested_at && (
                <div className=" bg-amber-300 p-6  text-sm  text-gray-600 sm:px-6 sm:pb-5 sm:pt-6">
                  <div className="flex justify-between">
                    <div className="mb-6 flex">
                      <FlagIconOutline className="h6 mr-3 w-5"></FlagIconOutline>
                      <div>
                        {" "}
                        <Trans
                          i18nKey={`editReport.view.activity.REPORT_REVIEW_REQUESTED`}
                          components={{
                            span: (
                              <span className="font-medium text-gray-700" />
                            ),
                          }}
                          values={{
                            username:
                              state.context?.report
                                ?.corrective_actions_review_requester_username,
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <TimeAgo
                        isoString={
                          state.context?.report
                            ?.corrective_actions_review_requested_at
                        }
                      />
                    </div>
                  </div>
                  <div>
                    “
                    {
                      state.context?.report
                        ?.corrective_actions_review_description
                    }
                    ”
                  </div>
                </div>
              )}
            </div>
            <ReviewCorrectiveActionsPanel />
            <NewCorrectiveActionPanel />
          </SectionLock>
        </>
      )}
    </>
  );
}
