const { actions, spawn, send } = require("xstate");
const { assign } = actions;

const compareValues = require("../../commons/functions").compareValues;
const getNestedValue = require("../../commons/functions").getNestedValue;
const {
  createFetchAgentMachine,
} = require("../../fetch-agent/fetch-agent-machine");
const {
  createAdvancedSearchAgentMachine,
} = require("../../advanced-search/advanced-search-machine");

const saveToken = require("../../commons/actions").saveToken;
const saveLoadRequest = require("../../commons/actions").saveLoadRequest;

const saveSearchValue = assign({
  searchValue: (context, event) => {
    console.log("event.data.searchValue", event.data.searchValue);
    return event.data.searchValue ? event.data.searchValue : null;
  },
});

const spawnAdvancedSearchAgent = assign({
  advancedSearchAgentRef: (context, event) => {
    return spawn(
      createAdvancedSearchAgentMachine({
        periodSelectedEventType: "ADVANCED_SEARCH.PERIOD_SELECTED",
        rangeSelectedEventType: "ADVANCED_SEARCH.RANGE_SELECTED",
        clearedEventType: "ADVANCED_SEARCH.CLEARED",
        applyEventType: "ADVANCED_SEARCH.APPLY",
      }),
    );
  },
});

const spawnFetchAgents = assign({
  inactiveReportsAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: context.qmsToolApiUrl + "reports?active=false&view=minimal",
        loadedEventType: "AGENT.INACTIVE_DATA_LOADED",
        errorEventType: "AGENT.LOADING_ERROR",
      }),
    );
  },
  activeReportsAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: context.qmsToolApiUrl + "reports?active=true&view=minimal",
        loadedEventType: "AGENT.ACTIVE_DATA_LOADED",
        errorEventType: "AGENT.LOADING_ERROR",
      }),
    );
  },
});

const saveInactiveReports = assign({
  inactiveReports: (context, event) => {
    return event.data;
  },
});

const saveActiveReports = assign({
  activeReports: (context, event) => {
    return event.data;
  },
});

const setReportsToFilteredActiveReports = assign({
  reports: (context, event) => {
    return context.filteredActiveReports;
  },
});

const setReportsToFilteredInactiveReports = assign({
  reports: (context, event) => {
    return context.filteredInactiveReports;
  },
});

const sendLoadRequestToFetchAgents = (context, event) => {
  let searchValue = context?.searchValue
    ? `&search_value=${context.searchValue}`
    : "";
  const advancedSearch = context?.advancedSearch;
  if (advancedSearch?.startDate && advancedSearch?.endDate) {
    searchValue += `&start_date=${advancedSearch.startDate}&end_date=${advancedSearch.endDate}`;
  } else if (advancedSearch?.month || advancedSearch?.year) {
    if (advancedSearch?.month) {
      searchValue += `&month=${advancedSearch.month}`;
    }
    if (advancedSearch?.year) {
      searchValue += `&year=${advancedSearch.year}`;
    }
  }

  context.inactiveReportsAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url:
        context.qmsToolApiUrl +
        "reports?active=false&view=minimal" +
        searchValue,
    },
  });
  context.activeReportsAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url:
        context.qmsToolApiUrl +
        "reports?active=true&view=minimal" +
        searchValue,
    },
  });
};

const sendOpenToSearchAgent = (context, event) => {
  context.advancedSearchAgentRef.send("OPEN");
};

const sendCloseToSearchAgent = (context, event) => {
  context.advancedSearchAgentRef.send("CLOSE");
};

const setActiveDataLoaded = assign({
  activeReportsLoaded: (context, event) => {
    return true;
  },
});

const setInactiveDataLoaded = assign({
  inactiveReportsLoaded: (context, event) => {
    return true;
  },
});

const unsetDataLoaded = assign({
  activeReportsLoaded: (context, event) => {
    return false;
  },
  inactiveReportsLoaded: (context, event) => {
    return false;
  },
});

const sendTokenToFetchAgents = (context, event) => {
  context.inactiveReportsAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
  context.activeReportsAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
};

const combineAndSortReports = assign({
  reports: (context, event) => {
    return [...context.activeReports, ...context.inactiveReports].sort(
      (a, b) => (a.id > b.id ? -11 : 1),
    );
  },
});

const updateLastRefreshedTimestamp =
  require("../../commons/actions").updateLastRefreshedTimestamp;

const saveError = require("../../commons/actions").saveError;

const setViewToActive = assign({
  view: "active",
});

const setViewToInactive = assign({
  view: "inactive",
});

const sortReports = assign((context, event) => {
  let sortKey = event.sortKey || context.currentSortKey;
  let comparator;
  let sortDirection;
  const defaultComparator = (a, b, sortDirection) =>
    compareValues(
      getNestedValue(a, sortKey),
      getNestedValue(b, sortKey),
      sortDirection,
    );

  if (event.sortKey && context.currentSortKey === event.sortKey) {
    sortDirection = context.currentSortDirection === "asc" ? "desc" : "asc";
  } else if (event.sortKey && context.currentSortKey !== event.sortKey) {
    sortDirection = "asc";
  } else if (!event.sortKey) {
    sortDirection = context.currentSortDirection;
  }

  if (event.comparator) {
    comparator = event.comparator;
  } else if (context.currentSortKey === event.sortKey || !event.sortKey) {
    comparator = context.currentComparator || defaultComparator;
  } else {
    comparator = defaultComparator;
  }

  const sortedReports = [...context.reports].sort((a, b) =>
    comparator(a, b, sortDirection),
  );

  return {
    reports: sortedReports,
    currentSortKey: sortKey,
    currentSortDirection: sortDirection,
    currentComparator: comparator,
  };
});

function updateFilterOptions(filters, records) {
  // Retourner une nouvelle copie de l'array des filtres
  return filters.map((filter) => {
    // Collecter toutes les valeurs uniques obtenues par la fonction getter pour le filtre actuel
    const uniqueValues = new Set();
    records.forEach((record) => {
      const value = filter.getter(record);
      if (value) {
        // Assurer que seules les valeurs non nulles et non vides sont ajoutées
        uniqueValues.add(value);
      }
    });

    // Construire un nouvel ensemble d'options basé sur les valeurs uniques trouvées et les options existantes
    let updatedOptions = filter.options.filter((option) =>
      uniqueValues.has(option.value),
    ); // Conserver les options existantes qui sont encore valides

    // Ajouter les nouvelles valeurs comme options, marquées comme unchecked
    uniqueValues.forEach((value) => {
      if (!updatedOptions.some((option) => option.value === value)) {
        updatedOptions.push({
          value: value,
          i18nPrefixKey: filter.i18nValuePrefixKey, // Utiliser le même préfixe i18n que les autres options
          checked: false, // Les nouvelles valeurs sont non cochées
        });
      }
    });

    updatedOptions.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    // Retourner un nouvel objet filtre avec les options mises à jour
    return {
      ...filter, // Copier toutes les propriétés existantes du filtre
      options: updatedOptions, // Utiliser le nouvel ensemble d'options
    };
  });
}

const saveUniqueFiltersOptions = assign({
  filters: (context, event) => {
    const updatedFilters = updateFilterOptions(context?.filters, [
      ...context.activeReports,
      ...context.inactiveReports,
    ]);
    return updatedFilters;
  },
});

const filterRecords = require("../../commons/actions").filterRecords;

const filterActiveReports = assign({
  filteredActiveReports: (context, event) => {
    return filterRecords(context?.filters, context?.activeReports);
  },
});

const filterInactiveReports = assign({
  filteredInactiveReports: (context, event) => {
    return filterRecords(context?.filters, context?.inactiveReports);
  },
});

const saveAdvancedSearchOptions = assign({
  advancedSearch: (context, event) => {
    return {
      ...context.advancedSearch,
      ...event.data,
    };
  },
});

const clearAdvancedSearchOptions = assign({
  advancedSearch: (context, event) => {
    return {
      ...context.advancedSearch,
      month: null,
      year: null,
      startDate: null,
      endDate: null,
    };
  },
});

const addValueToFilter = require("../../commons/actions").addValueToFilter;
const removeValueFromFilter =
  require("../../commons/actions").removeValueFromFilter;

export {
  saveLoadRequest,
  spawnFetchAgents,
  spawnAdvancedSearchAgent,
  saveInactiveReports,
  saveActiveReports,
  combineAndSortReports,
  updateLastRefreshedTimestamp,
  sendOpenToSearchAgent,
  sendCloseToSearchAgent,
  sendLoadRequestToFetchAgents,
  sendTokenToFetchAgents,
  saveError,
  saveToken,
  setActiveDataLoaded,
  setInactiveDataLoaded,
  unsetDataLoaded,
  setReportsToFilteredActiveReports,
  setReportsToFilteredInactiveReports,
  setViewToActive,
  setViewToInactive,
  sortReports,
  saveUniqueFiltersOptions,
  addValueToFilter,
  removeValueFromFilter,
  filterActiveReports,
  filterInactiveReports,
  saveSearchValue,
  clearAdvancedSearchOptions,
  saveAdvancedSearchOptions,
};
