import { FunnelIcon } from "@heroicons/react/20/solid";
import {
  XMarkIcon,
  FunnelIcon as FunnelIconOutline,
} from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu } from "@headlessui/react";
import { useViewEditActionPlanMachine } from "../../../context/ViewEditActionPlanMachineContext";
import { t } from "i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Filters({ state, send }) {
  const [open, setOpen] = useState(false);
  //const [state, send] = useViewEditActionPlanMachine();
  return (
    <>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    {t("action-plan.corrective_actions.activeFilters.label")}
                  </h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {state?.context?.filters &&
                    state?.context?.filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.name}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                <span className="font-medium text-gray-900">
                                  {t(section.i18nKey)}
                                </span>
                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "-rotate-180" : "rotate-0",
                                      "h-5 w-5 transform",
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onClick={(event) => {
                                        send({
                                          type: !option.checked
                                            ? "ADD_VALUE_TO_FILTER"
                                            : "REMOVE_VALUE_FROM_FILTER",
                                          data: {
                                            filterId: section.id,
                                            value: event.target.value,
                                          },
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-500"
                                    >
                                      {t(
                                        `${option.i18nPrefixKey}${option.value}`,
                                      )}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex h-full justify-between">
        <div className="my-auto">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white sm:px-3 sm:py-3 md:hidden ",
            )}
          >
            <FunnelIcon className="h-4 w-4 sm:h-5 sm:w-5"></FunnelIcon>
          </button>
        </div>
        <div className="hidden md:block">
          <div className="flow-root">
            <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
              <FunnelIconOutline className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />

              {state?.context?.filters &&
                state?.context?.filters.map((section, sectionIdx) => (
                  <Popover
                    key={section.name}
                    className="relative inline-block px-4 text-left"
                  >
                    <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-500 hover:text-gray-700">
                      <span>{t(section.i18nKey)}</span>
                      {/* {sectionIdx === 0 ? (
                      <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                        1
                      </span>
                    ) : null} */}
                      <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                        {
                          section.options.filter((option) => option.checked)
                            .length
                        }
                      </span>

                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                defaultChecked={option.checked}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onClick={(event) => {
                                  send({
                                    type: !option.checked
                                      ? "ADD_VALUE_TO_FILTER"
                                      : "REMOVE_VALUE_FROM_FILTER",
                                    data: {
                                      filterId: section.id,
                                      value: event.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium  text-gray-500"
                              >
                                {t(`${option.i18nPrefixKey}${option.value}`)}
                              </label>
                            </div>
                          ))}
                        </form>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                ))}
            </Popover.Group>
          </div>
        </div>
      </div>
    </>
  );
}
