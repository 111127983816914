import React from "react";
import StepsContainer from "../container";
import { useTranslation } from "react-i18next";

export default function Error() {
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
          <h1 className="text-center text-xl font-medium text-red-600">
            {t("editCorrectiveAction.delete.error.header")}
          </h1>
          <div className="mt-4">
            <p className="text-center text-sm text-gray-500">
              {t("editCorrectiveAction.delete.error.subHeader")}
            </p>
            <p className="mt-20 text-center text-sm text-gray-500">
              {t("editCorrectiveAction.delete.error.help.tip")}
            </p>
          </div>
        </div>
      </StepsContainer>
    </>
  );
}
