import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { EditFiveWhysMachine as parentMachine } from "../machines/edit-five-whys/edit-five-whys-machine";

const EditFiveWhysMachineContext = createContext();

export function useEditFiveWhysMachine() {
  const context = useContext(EditFiveWhysMachineContext);

  if (!context) {
    throw new Error(
      "useEditFiveWhysMachine must be used within a EditFiveWhysMachineProvider",
    );
  }

  return context;
}

export function EditFiveWhysMachineProvider({ children }) {
  const editFiveWhysMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <EditFiveWhysMachineContext.Provider value={editFiveWhysMachine}>
      {children}
    </EditFiveWhysMachineContext.Provider>
  );
}
