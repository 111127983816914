import { MinusSmallIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DateTimeFormatter({
  isoString,
  obfuscated = false,
  ...props
}) {
  const date = isoString ? (
    new Date(isoString).toLocaleString()
  ) : (
    <MinusSmallIcon className="h-6 w-6 text-gray-300" />
  );

  return (
    <span className={classNames(obfuscated ? "blur-sm" : "")}>{date}</span>
  );
}
