import GenericSelectMenu from "../../../GenericSelectMenu";
import StepsContainer from "../../../container";
import Info from "../../../Info";
import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import { Trans, useTranslation } from "react-i18next";

export default function Step2() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { t } = useTranslation();

  return (
    <StepsContainer>
      {createReportState.context?.form?.consequenceType.forced_severity ? (
        <div>
          <Info>
            <Trans
              i18nKey="createReport.steps.step2.form.info"
              components={{ span: <span className="font-semibold" /> }}
            />
          </Info>
        </div>
      ) : null}
      <div>
        <GenericSelectMenu
          data={createReportState.context?.severityTypes}
          selectedItem={createReportState.context?.form?.severityType}
          noSelectionText={t(
            "createReport.steps.step2.form.placeholder.severity",
          )}
          onItemSelected={(element) => {
            createReportSend("SEVERITY_SELECTED", { data: element });
          }}
          label={t("createReport.steps.step2.form.label.severity")}
          useNameForKey={true}
          useEntireItemForValue={true}
          disabled={
            createReportState.context?.form?.consequenceType.forced_severity
              ? true
              : false
          }
          i18nPrefixKey="shared.type.severity."
        />
      </div>
      <div>
        <GenericSelectMenu
          data={createReportState.context?.probabilityTypes}
          selectedItem={createReportState.context?.form?.probabilityType}
          noSelectionText={t(
            "createReport.steps.step2.form.placeholder.probability",
          )}
          onItemSelected={(element) => {
            createReportSend("PROBABILITY_SELECTED", { data: element });
          }}
          label={t("createReport.steps.step2.form.label.probability")}
          useNameForKey={true}
          useEntireItemForValue={true}
          disabled={
            createReportState.context?.form?.consequenceType.forced_severity
              ? true
              : false
          }
          i18nPrefixKey="shared.type.probability."
        />
      </div>
    </StepsContainer>
  );
}
