import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";

import StepsContainer from "../container";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FlagSectionPanel() {
  const [state, send] = useFlagSectionForReviewMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();
  const { t } = useTranslation();
  const { keycloak } = useAuth();

  const handleClose = () => {
    send("CLOSE");
    if (state?.context?.changesHappened) {
      viewEditReportSend("LOAD_REPORT", {
        data: viewEditReportState.context?.report?.id,
      });
    }
  };

  useEffect(() => {
    console.log("Token refreshed -> send to FlagSectionForReview Machine");
    send("UPDATE_BEARER_TOKEN", { data: keycloak.token });
  }, [keycloak.token]);

  return (
    <>
      <Transition.Root show={state.matches("active")} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={handleClose}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-amber-400 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-medium leading-6 text-gray-700">
                              {t("flagSectionForReview.view.title")}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                tabIndex={-1}
                                type="button"
                                className="relative rounded-md  text-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-amber-100"
                                disabled={!state.can("CLOSE")}
                                onClick={handleClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-gray-600">
                              {t("flagSectionForReview.view.subtitle")}
                            </p>
                          </div>
                          <div className="mt-2"></div>
                        </div>

                        {/* Main content */}
                        {state.matches("active.edit description") && (
                          <StepsContainer>
                            {/* Description */}
                            <div>
                              <label
                                htmlFor="affected_entity"
                                className="block text-sm font-medium leading-6 text-gray-600"
                              >
                                {t(
                                  "flagSectionForReview.view.label.description",
                                )}
                              </label>
                              <div className="mt-2">
                                <input
                                  autoFocus
                                  type="text"
                                  name="review_description"
                                  id="review_description"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => {
                                    send("DESCRIPTION_CHANGED", {
                                      data: e.target.value,
                                    });
                                  }}
                                  tabIndex={1}
                                />
                              </div>
                            </div>
                          </StepsContainer>
                        )}

                        {/* Success content */}
                        {state.matches("active.success") && (
                          <StepsContainer>
                            <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
                              <h1 className="text-center text-xl font-medium text-slate-600">
                                {t("flagSectionForReview.success.header")}
                              </h1>
                              <div className="mt-4">
                                <p className="text-center text-sm text-gray-500">
                                  {t("flagSectionForReview.success.subHeader")}
                                </p>
                              </div>
                            </div>
                          </StepsContainer>
                        )}
                        {/* Error content */}

                        {/* Loading content */}

                        {/* Abandon content */}
                        {/* {state.matches("confirm abandon") && <Cancel />} */}
                      </div>

                      {/* Bottom Action Bar */}
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {state.can({ type: "CLOSE" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={handleClose}
                            tabIndex={3}
                          >
                            {t("shared.button.close")}
                          </button>
                        )}
                        {state.matches("active.edit description") && (
                          <button
                            onClick={() => send("FLAG_FOR_REVIEW")}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-amber-300 px-3 py-2 text-sm font-medium text-gray-600 shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:bg-amber-100 disabled:text-amber-200",
                            )}
                            disabled={!state.can("FLAG_FOR_REVIEW")}
                            tabIndex={2}
                          >
                            {t("shared.button.create")}
                          </button>
                        )}
                        {state.can({ type: "RETRY" }) && (
                          <button
                            onClick={() => send("RETRY")}
                            className={
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            }
                          >
                            {t("createReport.button.retry")}
                          </button>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
