import axios from "axios";
import { read, utils, writeFile, writeFileAsync } from "xlsx";
import moment from "moment-timezone";

const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const fetchCorrectiveActions = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(
    `${context.qmsToolApiUrl}action-plans/${context.id}/corrective-actions`,
    options,
  ).then((response) => response.data);
};

const exportCorrectiveActions = async (context, event) => {
  return new Promise((resolve, reject) => {
    try {
      const transformedCorrectiveActions = context.corrective_actions.map(
        (action) => {
          const {
            id,
            domain,
            priority,
            source,
            problem_statement,
            root_cause,
            description,
            handled_by,
            expired_at,
            deferral_reason,
            completed_at,
            created_at,
            status,
            report,
          } = action;
          return {
            action_id: id,
            action_domain: report?.event?.domain?.name || domain?.name,
            action_report_reference: report?.reference,
            action_source: source?.name,
            action_priority: priority?.name,
            action_problem_statement:
              problem_statement ||
              report?.five_whys?.problem_statement ||
              report?.event?.description,
            action_root_cause: root_cause
              ? root_cause
              : report?.five_whys?.final_answer || "",
            action_description: description,
            action_handled_by: handled_by,
            action_expired_at: new Date(expired_at).toLocaleString(),
            action_deferral_reason: deferral_reason,
            action_completed_at: new Date(completed_at).toLocaleString(),
            action_created_at: new Date(created_at).toLocaleString(),
            action_status: status,
          };
        },
      );

      const worksheet = utils.json_to_sheet(transformedCorrectiveActions, {
        UTC: true,
      });
      const workbook = utils.book_new();
      utils.book_append_sheet(
        workbook,
        worksheet,
        `${context?.residence_one_key} Corrective Actions`,
      );

      const DOMAIN_INDEX = 1;
      const SOURCE_INDEX = 3;
      const PRIORITY_INDEX = 4;
      const PROBLEM_STATEMENT_INDEX_INDEX = 5;
      const ROOT_CAUSE_INDEX = 6;
      const DESCRIPTION_INDEX = 7;
      const HANDLED_BY_INDEX = 8;
      const EXPIRATION_DATE_INDEX = 9;
      const DEFERRAL_REASON_INDEX = 10;
      const COMPLETED_AT_INDEX = 11;
      const CREATED_AT = 12;
      const STATUS_INDEX = 13;

      /* create !cols array if it does not exist */
      if (!worksheet["!cols"]) worksheet["!cols"] = [];

      if (!worksheet["!cols"][DOMAIN_INDEX])
        worksheet["!cols"][DOMAIN_INDEX] = { wch: 25 };

      if (!worksheet["!cols"][SOURCE_INDEX])
        worksheet["!cols"][SOURCE_INDEX] = { wch: 25 };

      if (!worksheet["!cols"][PRIORITY_INDEX])
        worksheet["!cols"][PRIORITY_INDEX] = { wch: 25 };

      if (!worksheet["!cols"][PROBLEM_STATEMENT_INDEX_INDEX])
        worksheet["!cols"][PROBLEM_STATEMENT_INDEX_INDEX] = {
          wch: 50,
          wrapText: true,
        };

      if (!worksheet["!cols"][ROOT_CAUSE_INDEX])
        worksheet["!cols"][ROOT_CAUSE_INDEX] = { wch: 50 };

      if (!worksheet["!cols"][DESCRIPTION_INDEX])
        worksheet["!cols"][DESCRIPTION_INDEX] = { wch: 50 };

      if (!worksheet["!cols"][HANDLED_BY_INDEX])
        worksheet["!cols"][HANDLED_BY_INDEX] = { wch: 25 };

      if (!worksheet["!cols"][EXPIRATION_DATE_INDEX])
        worksheet["!cols"][EXPIRATION_DATE_INDEX] = { wch: 25 };

      if (!worksheet["!cols"][DEFERRAL_REASON_INDEX])
        worksheet["!cols"][DEFERRAL_REASON_INDEX] = { wch: 50, wrapText: true };

      if (!worksheet["!cols"][STATUS_INDEX])
        worksheet["!cols"][STATUS_INDEX] = { wch: 25 };

      const range = utils.decode_range(worksheet["!ref"]);

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const cellRef = utils.encode_cell({
          c: EXPIRATION_DATE_INDEX,
          r: rowNum,
        }); // Column 'H', change 6 to the index of your column
        if (worksheet[cellRef]) {
          worksheet[cellRef].z = "yyyy-mm-dd hh:mm:ss"; // Set format for the cell
        }
      }

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const cellRef = utils.encode_cell({ c: COMPLETED_AT_INDEX, r: rowNum }); // Column 'H', change 6 to the index of your column
        if (worksheet[cellRef]) {
          worksheet[cellRef].z = "yyyy-mm-dd hh:mm:ss"; // Set format for the cell
        }
      }

      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const cellRef = utils.encode_cell({ c: CREATED_AT, r: rowNum }); // Column 'H', change 6 to the index of your column
        if (worksheet[cellRef]) {
          worksheet[cellRef].z = "yyyy-mm-dd hh:mm:ss"; // Set format for the cell
        }
      }
      writeFile(
        workbook,
        `corrective_actions_${moment(context?.loaded_at).format(
          "YYYY_MM_DD_HH_mm_ss",
        )}.xlsx`,
        {
          compression: true,
        },
      );
      return resolve();
    } catch (error) {
      return reject(error);
    }
  });
};

export { fetchCorrectiveActions, exportCorrectiveActions };
