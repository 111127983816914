const validRequest = (context, event) => {
  return (
    event.corrective_actions &&
    Array.isArray(event.corrective_actions) &&
    event.corrective_actions.length > 0 &&
    event.authentication &&
    event.authentication.token &&
    event.qmsToolApiUrl &&
    event.spawnActorFunction &&
    typeof event.spawnActorFunction === "function"
  );
};
const notOnFirstPage = (context, event) => {
  return context?.index && context.index > 0;
};

const notOnLastPage = (context, event) => {
  return context?.index < context.corrective_actions.length - 1;
};

const correctiveActionIdExists = (context, event) => {
  return (
    context?.corrective_actions.findIndex(
      (ca) => ca.id === event?.correctiveAction?.id,
    ) > -1
  );
};

const currentActorCanUpdate = (context, event) => {
  return context?.corrective_actions[context?.index]?.ref.state.can("UPDATE");
};

const currentActorCanDelete = (context, event) => {
  return context?.corrective_actions[context?.index]?.ref.state.can("DELETE");
};

const validToken = require("../../commons/guards").validToken;

export {
  validRequest,
  notOnFirstPage,
  notOnLastPage,
  validToken,
  correctiveActionIdExists,
  currentActorCanUpdate,
  currentActorCanDelete,
};
