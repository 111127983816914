import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";

import { CreateCorrectiveActionMachine as parentMachine } from "../../machines/action-plans/create-corrective-action/create-corrective-action-machine";

export const CreateCorrectiveActionMachineContext = createContext();

export function useCreateCorrectiveActionMachine() {
  const context = useContext(CreateCorrectiveActionMachineContext);
  if (!context) {
    throw new Error(
      "useCreateCorrectiveActionMachine must be used within a CreateCorrectiveActionMachineProvider",
    );
  }
  return context;
}

export function CreateCorrectiveActionMachineProvider({ children }) {
  const createCorrectiveActionMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <CreateCorrectiveActionMachineContext.Provider
      value={createCorrectiveActionMachine}
    >
      {children}
    </CreateCorrectiveActionMachineContext.Provider>
  );
}
