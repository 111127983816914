import DateTimePicker from "../../../DateTimePicker";
import StepsContainer from "../../../container";
import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import Info from "../../../Info";
import { Trans, useTranslation } from "react-i18next";

export default function Step5() {
  const [createReportState, sendToCreateReport] = useCreateReportMachine();
  const min = createReportState?.context?.report?.event?.event_occured_at;
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div>
          <Info>
            <Trans
              i18nKey="createReport.steps.step5.form.info"
              components={{ span: <span className="font-semibold" /> }}
            />
          </Info>
        </div>
        <div>
          <DateTimePicker
            label={t("createReport.steps.step5.form.label.authorityInformed")}
            initialDate={
              createReportState.context?.report?.event?.authority_informed_at
            }
            onChange={(iso861DateStr) => {
              sendToCreateReport("AUTHORITY_INFORMED_DATE_SELECTED", {
                data: iso861DateStr,
              });
            }}
            min={min}
          />
        </div>
        <div>
          <DateTimePicker
            label={t("createReport.steps.step5.form.label.familyInformed")}
            initialDate={
              createReportState.context?.report?.event?.family_informed_at
            }
            onChange={(iso861DateStr) => {
              sendToCreateReport("FAMILY_INFORMED_DATE_SELECTED", {
                data: iso861DateStr,
              });
            }}
            min={min}
          />
        </div>
        <div>
          <DateTimePicker
            label={t(
              "createReport.steps.step5.form.label.generalPractitionerInformed",
            )}
            initialDate={
              createReportState.context?.report?.event
                ?.general_practitioner_informed_at
            }
            onChange={(iso861DateStr) => {
              sendToCreateReport(
                "GENERAL_PRACTITIONER_INFORMED_DATE_SELECTED",
                {
                  data: iso861DateStr,
                },
              );
            }}
            min={min}
          />
        </div>
      </StepsContainer>
    </>
  );
}
