const validChangeSet = (context, event) => {
  return (
    ((context?.changes?.has("description") &&
      context?.correctiveAction?.description.length) ||
      context?.changes?.has("handler_type") ||
      (context?.changes?.has("expired_at") &&
        context?.changes?.has("deferral_reason") &&
        context?.correctiveAction?.deferral_reason) ||
      (context?.changes?.has("status") &&
        ["TODO", "ONGOING", "COMPLETE"].includes(
          context?.correctiveAction?.status,
        ))) &&
    context.changesHappened === true
  );
};

const accessDeniedError = require("../../commons/guards").accessDeniedError;

const validToken = require("../../commons/guards").validToken;

export { validChangeSet, validToken, accessDeniedError };
