import StepsContainer from "../../container";
import { useCreateReportMachine } from "../../../../context/CreateReportMachineContext";
import { Trans, useTranslation } from "react-i18next";

export default function Success() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
          <h1 className="text-center text-xl font-medium text-slate-600">
            {t("createReport.steps.success.header")}
          </h1>
          <div className="mt-4">
            <p className="text-center text-sm text-gray-500">
              <Trans
                i18nKey={"createReport.steps.success.subHeader"}
                components={{ span: <span className="text-blue-600" /> }}
                values={{
                  reference: createReportState?.context?.result?.reference,
                }}
              />
            </p>
          </div>
        </div>
      </StepsContainer>
    </>
  );
}
