const { actions } = require("xstate");
const { assign } = actions;

const saveLoadRequest = assign({
  loadRequest: (context, event) => {
    return event.data;
  },
  sequence_of_events_items_details: (context, event) => {
    return event.data?.sequenceOfEvents?.sequence_of_events_items_details || [];
  },
  authentication: (context, event) => {
    return event.data.authentication;
  },
  event_occured_at: (context, event) => {
    return event.data?.event_occured_at;
  },
});
const saveAddItemRequest = assign({
  addItemRequest: (context, event) => {
    return event.data;
  },
});
const saveRemoveRequestItem = assign({
  removeRequestItem: (context, event) => {
    return event.data;
  },
});

const resetAddItemRequest = assign({
  addItemRequest: (context, event) => {
    return undefined;
  },
});

const resetRemoveRequestItem = assign({
  resetAddItemRequest: (context, event) => {
    return undefined;
  },
});

const saveReturnedItemsDetails = assign({
  sequenceOfEvents: (context, event) => {
    return event.data;
  },
  sequence_of_events_items_details: (context, event) => {
    return event.data.sequence_of_events_items_details;
  },
});

const resetLoadRequest = assign({
  loadRequest: (context, event) => {
    return undefined;
  },
  sequence_of_events_items_details: (context, event) => {
    return [];
  },
  event_occured_at: (context, event) => {
    return undefined;
  },
});

const saveEventOccurenceDate = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      item_occured_at: event.data,
    };
  },
});

const setInitialOccurenceDate = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      item_occured_at: context?.event_occured_at,
    };
  },
});

const saveDescription = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      description: event.data,
    };
  },
});

const saveAffectedEntity = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      affected_entity: event.data,
    };
  },
});

const saveTracesAndEvidences = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      traceability_and_evidences: event.data,
    };
  },
});

const resetChangeSet = require("../../commons/actions/index").resetChangeSet;
const flagChanges = require("../../commons/actions/index").flagChanges;
const unflagChanges = require("../../commons/actions/index").unflagChanges;
const saveError = require("../../commons/actions/index").saveError;
const saveToken = require("../../commons/actions/index").saveToken;

export {
  saveLoadRequest,
  saveAddItemRequest,
  saveRemoveRequestItem,
  resetAddItemRequest,
  resetRemoveRequestItem,
  saveError,
  saveReturnedItemsDetails,
  saveEventOccurenceDate,
  saveDescription,
  saveAffectedEntity,
  saveTracesAndEvidences,
  resetLoadRequest,
  resetChangeSet,
  setInitialOccurenceDate,
  flagChanges,
  unflagChanges,
  saveToken,
};
