import Layout from "../edit-report/Layout";
import EventNavigator from "./EventNavigator";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEventNavigatorMachine } from "../../../context/EventNavigatorMachineContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ReportsNavigator() {
  const [state, send] = useViewEditReportMachine();
  const [eventNavigatorState, eventNavigatorSend] = useEventNavigatorMachine();
  const navigate = useNavigate();

  useEffect(() => {
    if (eventNavigatorState.matches("idle")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <EventNavigator />

      {state.matches("processing.initialized.loading") ? (
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="flex flex-row gap-2">
              <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700"></div>
              <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.3s]"></div>
              <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.5s]"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="xl:mt-3">
          <Layout showBreadcrumb={false}></Layout>
        </div>
      )}
    </>
  );
}
