import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEditFiveWhysMachine } from "../../../context/EditFiveWhysMachineContext";
import { useTranslation, Trans } from "react-i18next";
import { FlagIcon } from "@heroicons/react/20/solid";
import { FlagIcon as FlagIconOutline } from "@heroicons/react/24/outline";
import TimeAgo from "../shared/TimeAgo";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { useReviewFiveWhysMachine } from "../../../context/ReviewFiveWhysMachineContext";
import Statement from "../Statement";
import SectionLock from "../shared/SectionLock";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FiveWhys() {
  const [state, send] = useViewEditReportMachine();
  const [stateFiveWhys, sendFiveWhys] = useEditFiveWhysMachine();
  const [flagSectionForReviewState, flagSectionForReviewSend] =
    useFlagSectionForReviewMachine();
  const [reviewFiveWhysState, reviewFiveWhysSend] = useReviewFiveWhysMachine();
  const { t } = useTranslation();
  const sectionLocked =
    state.context?.report?.event?.criticity?.root_cause_analysis_required ===
      false && state.context?.report?.five_whys;
  return (
    <>
      {/* Five Whys */}
      {(state.context?.report?.event?.criticity
        ?.root_cause_analysis_required === true ||
        sectionLocked) && (
        <>
          <SectionLock locked={sectionLocked}>
            <div
              className={classNames(
                "mt-12 overflow-hidden rounded-lg bg-white shadow",
                state.matches("processing.initialized.active.edit") ? "" : "",
              )}
            >
              <div className="border-t border-slate-200 bg-white  px-4 py-4 drop-shadow-md  sm:px-6">
                <div className="flex w-full items-center justify-between">
                  <h2 className="py-2 text-sm font-medium text-gray-600">
                    {t("editReport.view.sectionHeader.rootCauseAnalysis")}
                  </h2>
                  {state.context?.report?.five_whys &&
                    state?.context?.report?.user_actions?.some(
                      (action) => action.action_id === "submit",
                    ) && (
                      <button
                        className=" flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                        onClick={(e) => {
                          reviewFiveWhysSend("START", {
                            data: {
                              reportId: state?.context?.report?.id,
                              changeSet: {
                                ...state?.context?.report?.five_whys,
                              },
                              authentication: state?.context?.authentication,
                            },
                          });
                        }}
                        // Disabled when the report is in edit mode
                        disabled={state.matches(
                          "processing.initialized.active.edit",
                        )}
                      >
                        {t("shared.button.review")}
                      </button>
                    )}
                  {!state.context?.report?.five_whys && (
                    <button
                      className=" flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                      onClick={(e) => {
                        sendFiveWhys("START", {
                          data: {
                            reportId: state?.context?.report?.id,
                            problem_statement:
                              state?.context?.report?.event?.description,
                            authentication: state?.context?.authentication,
                          },
                        });
                      }}
                      // Disabled when the report is in edit mode
                      disabled={state.matches(
                        "processing.initialized.active.edit",
                      )}
                    >
                      {t("editReport.view.fiveWhys.button.start")}
                    </button>
                  )}
                </div>
              </div>

              {/* Five Whys */}
              <div
                id="fiveWhysSection"
                className="bg-slate-50 px-4 py-4  sm:px-6 "
              >
                {!state.context?.report?.five_whys && (
                  <>
                    <div className="mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                      <Trans
                        i18nKey={"editReport.view.fiveWhys.label.required"}
                      ></Trans>
                    </div>
                  </>
                )}
                {state.context?.report?.five_whys && (
                  <>
                    <div className="py-4">
                      <div className=" sm:grid sm:grid-cols-3 sm:gap-4 ">
                        <div className="py-3 text-sm font-medium text-gray-600">
                          {t("editFiveWhys.steps.form.problem.label")}
                        </div>
                        <div className="text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          <Statement
                            value={
                              state.context?.report?.five_whys.problem_statement
                            }
                            obfuscated={state.context?.report?.obfuscated}
                          />
                        </div>
                      </div>
                      <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                        <div className="py-3 text-sm font-medium text-gray-600">
                          {t("editFiveWhys.steps.form.why.label")}
                        </div>
                        <div className="text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          <Statement
                            value={
                              state.context?.report?.five_whys.answer_why_1
                            }
                            obfuscated={state.context?.report?.obfuscated}
                          />
                          {state.context?.report?.five_whys.answer_why_2 &&
                            state.context?.report?.five_whys.final_why_index !==
                              2 && (
                              <div className="mt-3">
                                <Statement
                                  active={
                                    state.context?.report?.five_whys
                                      .final_why_index === 2
                                  }
                                  value={
                                    state.context?.report?.five_whys
                                      .answer_why_2
                                  }
                                  obfuscated={state.context?.report?.obfuscated}
                                />
                              </div>
                            )}
                          {state.context?.report?.five_whys.answer_why_3 &&
                            state.context?.report?.five_whys.final_why_index !==
                              3 && (
                              <div className="mt-3">
                                <Statement
                                  active={
                                    state.context?.report?.five_whys
                                      .final_why_index === 3
                                  }
                                  value={
                                    state.context?.report?.five_whys
                                      .answer_why_3
                                  }
                                  obfuscated={state.context?.report?.obfuscated}
                                />
                              </div>
                            )}
                          {state.context?.report?.five_whys.answer_why_4 &&
                            state.context?.report?.five_whys.final_why_index !==
                              4 && (
                              <div className="mt-3">
                                <Statement
                                  active={
                                    state.context?.report?.five_whys
                                      .final_why_index === 4
                                  }
                                  value={
                                    state.context?.report?.five_whys
                                      .answer_why_4
                                  }
                                  obfuscated={state.context?.report?.obfuscated}
                                />
                              </div>
                            )}
                          {state.context?.report?.five_whys.answer_why_5 &&
                            state.context?.report?.five_whys.final_why_index !==
                              5 && (
                              <div className="mt-3">
                                <Statement
                                  active={
                                    state.context?.report?.five_whys
                                      .final_why_index === 5
                                  }
                                  value={
                                    state.context?.report?.five_whys
                                      .answer_why_5
                                  }
                                  obfuscated={state.context?.report?.obfuscated}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                        <div className="py-3 text-sm font-medium text-gray-600">
                          {t("editFiveWhys.steps.form.countermeasure.label")}
                        </div>
                        <div className="text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          <Statement
                            active={true}
                            value={
                              state.context?.report?.five_whys.final_answer
                            }
                            obfuscated={state.context?.report?.obfuscated}
                          />
                        </div>
                      </div>
                      {state.context?.report?.five_whys.other_possible_causes &&
                        state.context?.report?.five_whys
                          .other_possible_causes !== "" && (
                          <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-4 ">
                            <div className="py-3 text-sm font-medium text-gray-600">
                              {t(
                                "editFiveWhys.steps.form.why.otherPossibleCauses.label",
                              )}
                            </div>
                            <div className="text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                              <Statement
                                value={
                                  state.context?.report?.five_whys
                                    .other_possible_causes
                                }
                                obfuscated={state.context?.report?.obfuscated}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </>
                )}
              </div>
              {state?.context?.report?.user_actions?.some(
                (action) => action.action_id === "five-whys-request-review",
              ) &&
                !state.context?.report?.five_whys?.review_requested_at && (
                  <div className="border-t border-gray-200 bg-slate-50  px-4 pb-3 pt-4 text-sm font-medium text-gray-500 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div>
                        {t(
                          "editReport.view.immediateActions.label.requestReview",
                        )}
                      </div>
                      <div>
                        <button
                          className=" flex items-center justify-center rounded-md   px-2.5 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-amber-300 hover:text-gray-700 hover:ring-amber-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                          onClick={(e) => {
                            flagSectionForReviewSend("START", {
                              data: {
                                action_url:
                                  state?.context?.report?.user_actions?.find(
                                    (action) =>
                                      action.action_id ===
                                      "five-whys-request-review",
                                  )?.url,
                                authentication: {
                                  token: state?.context?.authentication?.token,
                                },
                              },
                            });
                          }}
                        >
                          <FlagIcon className="h6 w-5"></FlagIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {state.context?.report?.five_whys?.review_requested_at && (
                <div className=" bg-amber-300 p-6  text-sm  text-gray-600 sm:px-6 sm:pb-5 sm:pt-6">
                  <div className="flex justify-between">
                    <div className="mb-6 flex">
                      <FlagIconOutline className="h6 mr-3 w-5"></FlagIconOutline>
                      <div>
                        {" "}
                        <Trans
                          i18nKey={`editReport.view.activity.REPORT_REVIEW_REQUESTED`}
                          components={{
                            span: (
                              <span className="font-medium text-gray-700" />
                            ),
                          }}
                          values={{
                            username:
                              state.context?.report?.five_whys
                                ?.review_requester_username,
                          }}
                        />
                      </div>
                    </div>
                    <div className="">
                      <TimeAgo
                        isoString={
                          state.context?.report?.five_whys?.review_requested_at
                        }
                      />
                    </div>
                  </div>
                  <div>
                    “{state.context?.report?.five_whys?.review_description}”
                  </div>
                </div>
              )}
            </div>
          </SectionLock>
        </>
      )}
    </>
  );
}
