import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";

import { CreateActionPlanMachine as parentMachine } from "../../machines/action-plans/create-action-plan/create-action-plan-machine";
export const CreateActionPlanMachineContext = createContext();

export function useCreateActionPlanMachine() {
  const context = useContext(CreateActionPlanMachineContext);
  if (!context) {
    throw new Error(
      "useCreateActionPlanMachine must be used within a CreateActionPlanMachineProvider",
    );
  }
  return context;
}

export function CreateActionPlanMachineProvider({ children }) {
  const createActionPlanMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <CreateActionPlanMachineContext.Provider value={createActionPlanMachine}>
      {children}
    </CreateActionPlanMachineContext.Provider>
  );
}
