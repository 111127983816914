import { MinusSmallIcon } from "@heroicons/react/24/outline";

export default function ShortDate({ isoString, locale }) {
  const formatDate = (date, locale) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat(locale, options).format(date);
  };

  const date = isoString ? new Date(isoString) : null;
  const output = date ? (
    formatDate(date, locale)
  ) : (
    <MinusSmallIcon className="h-6 w-6 text-gray-300" />
  );

  return <>{output}</>;
}
