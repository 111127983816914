const { actions } = require("xstate");
const { assign } = actions;

const saveLoadRequest = assign({
  loadRequest: (context, event) => {
    return event.data;
  },
  corrective_actions: (context, event) => {
    return event.data?.corrective_actions || [];
  },
  authentication: (context, event) => {
    return event.data.authentication;
  },
});

const resetLoadRequest = assign({
  loadRequest: (context, event) => {
    return undefined;
  },
  corrective_actions: (context, event) => {
    return [];
  },
});

const saveDescription = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      description: event.data,
    };
  },
});

const saveHandledBy = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      handled_by: event.data,
    };
  },
});

const saveHandlerType = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      handler_type: event.data,
    };
  },
});

const saveExpirationDate = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      expired_at: event.data,
    };
  },
});

const resetChangeSet = require("../../commons/actions/index").resetChangeSet;

const flagChanges = require("../../commons/actions/index").flagChanges;
const unflagChanges = require("../../commons/actions/index").unflagChanges;
const saveToken = require("../../commons/actions/index").saveToken;

const saveReturnedCorrectiveActions = assign({
  corrective_actions: (context, event) => {
    return event.data;
  },
});

export {
  saveLoadRequest,
  resetLoadRequest,
  flagChanges,
  unflagChanges,
  resetChangeSet,
  saveDescription,
  saveReturnedCorrectiveActions,
  saveToken,
  saveHandledBy,
  saveHandlerType,
  saveExpirationDate,
};
