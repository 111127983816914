import StepsContainer from "../../container";
import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation();
  return (
    <StepsContainer>
      <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
        <h1 className="text-center text-xl font-medium text-slate-600">
          {t("createCorrectiveAction.creating.header")}
        </h1>
        <div className="mt-4">
          <p className="text-center text-sm text-gray-500">
            <span className=" text-blue-600">
              <svg
                className="inline-block h-5 w-5 animate-spin text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <span className="ml-1.5 ">
                {t("createCorrectiveAction.creating.subHeader")}
              </span>
            </span>
          </p>
        </div>
        <div className="mt-12"></div>
      </div>
    </StepsContainer>
  );
}
