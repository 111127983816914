import { useTranslation } from "react-i18next";

export default function Success() {
  const { t } = useTranslation();
  return (
    <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
      <h1 className="text-center text-xl font-medium text-slate-600">
        {t("createCorrectiveAction.creating.success.header")}
      </h1>
      <div className="mt-4">
        <p className="text-center text-sm text-gray-500">
          <span className=" text-blue-600">
            {t("createCorrectiveAction.creating.success.subHeader")}
          </span>
        </p>
      </div>
      <div className="mt-12"></div>
    </div>
  );
}
