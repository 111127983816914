function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function GenericBadge({ value, obfuscated = false }) {
  return (
    <>
      <span
        className={
          "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium uppercase text-gray-500 ring-1 ring-inset ring-gray-500/10"
        }
      >
        <span className={classNames(obfuscated ? "blur-sm" : "")}>{value}</span>
      </span>
    </>
  );
}
