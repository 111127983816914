import { useTranslation } from "react-i18next";
import List from "../../ui/list-action-plans/List";

export default function ActionPlans() {
  const { t } = useTranslation();
  return (
    <div>
      <List />
    </div>
  );
}
