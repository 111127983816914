import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventTypeBadge({ value }) {
  const { t } = useTranslation();

  const eventTypeStyles = {
    SENTINEL: {
      class: "bg-red-500 text-white",
      label: t("shared.type.event.SENTINEL"),
    },
    SAFETY: {
      class: "bg-orange-400 text-white",
      label: t("shared.type.event.SAFETY"),
    },
    UNCLASSIFIED: {
      class: "bg-gray-400 text-white",
      label: t("shared.type.event.UNCLASSIFIED"),
    }
  };

  const currentStyle = eventTypeStyles[value] || "";

  return (
    <>
      <span
        className={classNames(
          "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1 text-xs font-medium",
          currentStyle.class,
        )}
      >
        {currentStyle.label.toUpperCase()}
      </span>
    </>
  );
}
