import React, { useEffect, useState } from "react";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DateTimePicker = ({
  label,
  initialDate,
  onChange,
  min,
  autofocus,
  tabindex,
  max,
}) => {
  const maxDate = max
    ? moment(max).format(moment.HTML5_FMT.DATETIME_LOCAL)
    : null;

  const minDate = min
    ? moment(min).format(moment.HTML5_FMT.DATETIME_LOCAL)
    : null;

  const [selectedDate, setSelectedDate] = useState(
    initialDate
      ? moment(initialDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
      : "",
  );

  const handleDateChange = (event) => {
    if (event.target.value === "") {
      setSelectedDate("");
      if (onChange) {
        onChange("");
      }
      return;
    } else {
      let localDate = moment(event.target.value);

      if (maxDate && localDate.isAfter(maxDate)) {
        localDate = maxDate;
      }

      //setSelectedDate(event.target.value);
      setSelectedDate(
        moment(localDate).format(moment.HTML5_FMT.DATETIME_LOCAL),
      );
      if (onChange) {
        onChange(moment(localDate).toISOString());
      }
    }
  };

  useEffect(() => {
    setSelectedDate(
      initialDate
        ? moment(initialDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
        : "",
    );
  }, [initialDate]);

  return (
    <div className="w-full">
      {label && (
        <label className="block text-sm font-medium leading-6 text-gray-600">
          {label}
        </label>
      )}
      <div className={classNames("relative", label ? "mt-2" : "")}>
        <input
          autoFocus={autofocus}
          type="datetime-local"
          className={classNames(
            selectedDate ? "text-gray-700" : "text-gray-500",
            "w-full rounded-md border-0 bg-white py-2 pl-3 text-left  text-sm leading-5 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2  focus:ring-blue-600",
          )}
          onChange={handleDateChange}
          value={selectedDate}
          min={minDate}
          max={maxDate}
          tabIndex={tabindex}
        ></input>
      </div>
    </div>
  );
};

export default DateTimePicker;
