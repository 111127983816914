import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
export default function NoResults() {
  const { t } = useTranslation();
  return (
    <div className="px-6 py-14 text-center text-sm sm:px-14">
      <ExclamationCircleIcon
        type="outline"
        name="exclamation-circle"
        className="mx-auto h-6 w-6 text-gray-400"
      />
      <p className="mt-4 font-semibold text-gray-900">
        {t("shared.noResults.title")}
      </p>
      <p className="mt-2 text-gray-500">{t("shared.noResults.subtitle")}</p>
    </div>
  );
}
