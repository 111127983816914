import StepsContainer from "../../../components/ui/container";
import { useRequalifyReportMachine } from "../../../context/RequalifyReportMachineContext";
import { Trans, useTranslation } from "react-i18next";

export default function Success() {
  const [state, send] = useRequalifyReportMachine();
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
          <h1 className="text-center text-xl font-medium text-slate-600">
            {t("requalifyEvent.steps.success.header")}
          </h1>
          <div className="mt-4">
            <p className="text-center text-sm text-gray-500">
              {t("requalifyEvent.steps.success.subHeader")}
            </p>
          </div>
        </div>
      </StepsContainer>
    </>
  );
}
