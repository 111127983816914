import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useEventNavigatorMachine } from "../../../context/EventNavigatorMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const defaultBadgeClass =
  "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1.5 text-md font-medium bg-indigo-500 text-white shadow-md";

const classes = {
  URGENT:
    "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1.5 text-md font-medium bg-red-500 text-white shadow-md",
  DRAFT: defaultBadgeClass,

  SUBMITTED: defaultBadgeClass,
  PENDING: defaultBadgeClass,
  TO_REVIEW: defaultBadgeClass,
  VALIDATED: defaultBadgeClass,
};

export default function EventNavigator() {
  const { t } = useTranslation();
  const [state, send] = useEventNavigatorMachine();

  const refViewEdit = useViewEditReportMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();

  useEffect(() => {
    return () => {
      viewEditReportSend("CLOSE");
    };
  }, []);

  return (
    <>
      {state.matches("active") && (
        <div>
          <div className="-mt-6 pb-4 sm:mt-0 sm:pb-6 ">
            <div className="flex items-center">
              <div className={classes[state.context?.status]}>
                {t(
                  `dashboard.efficiency.widgets.reports.${state?.context?.status.toLowerCase()}`,
                )}
              </div>
              <div
                aria-hidden="true"
                className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
              />
              <div className="ml-3 flex items-center">
                <button
                  type="button"
                  onClick={() =>
                    send({
                      type: "PREVIOUS",
                      data: {
                        viewReportActorRef: refViewEdit,
                      },
                    })
                  }
                  className={classNames(
                    "rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:text-gray-300",
                  )}
                  disabled={
                    !state.can("PREVIOUS") ||
                    viewEditReportState.matches(
                      "processing.initialized.loading",
                    )
                  }
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() =>
                    send({
                      type: "NEXT",
                      data: {
                        viewReportActorRef: refViewEdit,
                      },
                    })
                  }
                  className={classNames(
                    "ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:text-gray-300",
                  )}
                  disabled={
                    !state.can("NEXT") ||
                    viewEditReportState.matches(
                      "processing.initialized.loading",
                    )
                  }
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
                <span className="text-medium ml-4 text-xs text-gray-900 ">
                  <div className="inline-block w-2 text-center">
                    {state.context.index + 1}
                  </div>
                  <div className="ml-1 inline-block w-2 text-center text-gray-500">
                    /
                  </div>
                  <div className="inline-block w-2 text-center">
                    {state.context.reportIdentifiers.length}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
