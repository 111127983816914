import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { BoltIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function KeyboardShortcutsPanel() {
  const { t } = useTranslation();
  return (
    <Popover className="relative flex w-full justify-center ">
      <Popover.Button className="inline-flex items-center  gap-x-1 rounded-full px-2 text-xs leading-6  text-indigo-200  hover:text-amber-400 focus-visible:outline focus-visible:outline-offset-4 focus-visible:outline-amber-400 ">
        <span>{t("shared.keyboardShortcutsPanel.button.label")}</span>
        <BoltIcon className="h-5 w-5 " aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-11 w-full max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
            <div className="bg-gray-900 p-4">
              <ul className="grid grid-cols-1 gap-4 text-xs text-gray-400 ">
                <li className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      <ArrowLongLeftIcon className="w-4" />
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.previous")}</div>
                </li>
                <li className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      <ArrowLongRightIcon className="w-4" />
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.next")}</div>
                </li>
                <li className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      S
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.save")}</div>
                </li>

                <li className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Del
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.delete")}</div>
                </li>
                <li className="flex items-center justify-between">
                  <div>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex h-6 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Backspace
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.delete")}</div>
                </li>
                <li className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Ctrl
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      Shift
                    </span>
                    <span className="px-1">{"+"}</span>
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      R
                    </span>
                  </div>
                  <div>{t("shared.keyboardShortcutsPanel.label.retry")}</div>
                </li>
              </ul>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
