import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TimeAgo = ({ isoString }) => {
  const { t } = useTranslation();

  const timeAgo = (date) => {
    const now = new Date();
    const diffInMilliseconds = now - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 7),
    );
    const diffInMonths = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 30),
    );
    const diffInYears = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 365),
    );
    if (diffInSeconds < 60) {
      return t("shared.component.timeAgo.sec", { count: diffInSeconds });
    }
    if (diffInMinutes < 60) {
      return t("shared.component.timeAgo.min", { count: diffInMinutes });
    }
    if (diffInHours < 24) {
      const roundedHours = Math.round(diffInMinutes / 60);
      return t("shared.component.timeAgo.hour", { count: roundedHours });
    }
    if (diffInDays < 7) {
      return t("shared.component.timeAgo.day", { count: diffInDays });
    }
    if (diffInWeeks < 4) {
      return t("shared.component.timeAgo.week", { count: diffInWeeks });
    }
    if (diffInMonths < 12) {
      return t("shared.component.timeAgo.month", { count: diffInMonths });
    }
    if (diffInYears >= 1) {
      return t("shared.component.timeAgo.year", { count: diffInYears });
    }
    // You can add more conditions for years, etc.
    return date.toString();
  };

  return <>{timeAgo(new Date(isoString))}</>;
};

export default TimeAgo;
