import StepsContainer from "../container";
import { useTranslation } from "react-i18next";

export default function BadRequest() {
  const { t } = useTranslation();
  return (
    <>
      <StepsContainer>
        <div className="relative flex h-full flex-1 flex-col items-center justify-center px-4 sm:px-6">
          <h1 className="text-center text-xl font-medium text-red-600">
            {t("shared.components.badRequestError.title")}
          </h1>
          <div className="mt-4">
            <p className="text-center text-sm text-gray-500">
              {t("shared.components.badRequestError.description")}
            </p>
            <p className="mt-20 text-center text-sm text-gray-500">
              {t(
                "shared.components.badRequestError.action.contact-support.label",
              )}
            </p>
          </div>
        </div>
      </StepsContainer>
    </>
  );
}
