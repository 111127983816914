import { useState, useEffect } from "react";

const Crumbread = ({ currentStep }) => {
  const initialSteps = [
    { name: "Step 1", status: "upcoming" },
    { name: "Step 2", status: "upcoming" },
    { name: "Step 3", status: "upcoming" },
    { name: "Step 4", status: "upcoming" },
    { name: "Step 5", status: "upcoming" },
    { name: "Step 6", status: "upcoming" },
  ];
  const [steps, setSteps] = useState(initialSteps);

  useEffect(() => {
    const updatedSteps = steps.map((step, index) => {
      if (index < currentStep - 1) {
        return { ...step, status: "complete" };
      } else if (index === currentStep - 1) {
        return { ...step, status: "current" };
      } else {
        return { ...step, status: "upcoming" };
      }
    });
    setSteps(updatedSteps);
  }, [currentStep]);

  return (
    <nav
      className="flex scale-75 items-center justify-center "
      aria-label="Progress"
    >
      {/* <p className="text-sm text-zinc-400 font-medium">
        Step {steps.findIndex((step) => step.status === 'current') + 1} of {steps.length}
      </p>       */}
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-blue-200 " />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-blue-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Crumbread;
