import axios from "axios";

const defaultPostOptions = {
  method: "POST",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const flagSectionForReview = (context, event) => {
  let url = context.qmsToolApiUrl;
  if (context.action_url.startsWith("/")) {
    url += context.action_url.substring(1);
  } else {
    url += context.action_url;
  }

  const options = {
    ...defaultPostOptions,
    headers: {
      ...defaultPostOptions.headers,
      Authorization: `Bearer ${context.authentication.token}`,
    },
    data: JSON.stringify({
      review_description: context.description,
    }),
  };
  return axios(url, options).then((response) => response.data);
};

export { flagSectionForReview };
