const { actions } = require("xstate");
const { assign } = actions;

const {
  saveAuthentication,
  saveReportId,
  saveApiUrl,
  saveError,
  resetChangeSet,
} = require("../../commons/actions");

const saveConsequenceTypes = assign({
  consequenceTypes: (context, event) => {
    return event.consequenceTypes;
  },
});
const saveSeverityTypes = assign({
  severityTypes: (context, event) => {
    return event.severityTypes;
  },
});

const saveProbabilityTypes = assign({
  probabilityTypes: (context, event) => {
    return event.probabilityTypes;
  },
});

const saveSelectedConsequenceType = assign({
  changeSet: (context, event) => {
    const consequence = context.consequenceTypes.find(
      (consequenceType) => consequenceType.name === event.data,
    );
    const severity =
      consequence?.forced_severity &&
      context?.severityTypes.find(
        (severityType) =>
          severityType.associated_value === consequence?.forced_severity,
      );

    const probability =
      consequence?.forced_probability &&
      context?.probabilityTypes.find(
        (probabilityType) =>
          probabilityType.associated_value === consequence?.forced_probability,
      );

    return {
      ...context.changeSet,
      consequence: consequence,
      severity: severity,
      probability: probability,
    };
  },
});

const saveInitialChangeSet = assign({
  changeSet: (context, event) => {
    console.log("saveInitialChangeSet", event.changeSet);

    const returnValue = {
      ...event.changeSet,
    };
    const severity =
      event?.changeSet?.severity &&
      event?.severityTypes.find(
        (severityType) =>
          severityType.associated_value === event.changeSet.severity,
      );
    const probability =
      event?.changeSet?.probability &&
      event?.probabilityTypes.find(
        (probabilityType) =>
          probabilityType.associated_value === event.changeSet.probability,
      );

    if (severity) {
      returnValue.severity = severity;
    }
    if (probability) {
      returnValue.probability = probability;
    }
    if (!event?.changeSet?.criticity?.type) {
      returnValue.criticity.type = event.changeSet.type;
    }

    // If the event has already been requalified, then reset the requalification_reason

    if (event.changeSet.requalification_reason) {
      returnValue.requalification_reason = undefined;
    }

    return returnValue;
  },
});

const saveSelectedProbability = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      probability: event.data,
    };
  },
});

const saveSelectedSeverity = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      severity: event.data,
    };
  },
});

const saveComputedCriticityType = assign({
  changeSet: (context, event) => {
    console.log("before change", context.changeSet);
    return {
      ...context.changeSet,
      criticity: event.data,
    };
  },
});

const saveRequalificationReason = assign({
  changeSet: (context, event) => {
    return {
      ...context.changeSet,
      requalification_reason: event.data,
    };
  },
});

export {
  saveError,
  saveAuthentication,
  saveReportId,
  saveApiUrl,
  saveInitialChangeSet,
  saveConsequenceTypes,
  saveSelectedConsequenceType,
  saveComputedCriticityType,
  saveSeverityTypes,
  saveProbabilityTypes,
  saveSelectedSeverity,
  saveSelectedProbability,
  saveRequalificationReason,
  resetChangeSet,
};
