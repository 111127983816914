const validRequest = (context, event) => {
  return (
    event.seq_of_events &&
    Array.isArray(event.seq_of_events) &&
    event.seq_of_events.length > 0 &&
    event.authentication &&
    event.authentication.token
  );
};

const notOnFirstPage = (context, event) => {
  return context?.index && context.index > 0;
};

const notOnLastPage = (context, event) => {
  return context?.index < context.seq_of_events.length - 1;
};

const correctionIsTheLast = (context, event) => {
  return context?.index === context.seq_of_events.length - 1;
};

const currentActorHasChanges = (context, event) => {
  return context?.seq_of_events[context.index]?.ref?.state?.context
    ?.changesHappened;
};
const validToken = require("../../commons/guards/index").validToken;

export {
  validRequest,
  notOnFirstPage,
  notOnLastPage,
  correctionIsTheLast,
  currentActorHasChanges,
  validToken,
};
