import DateTimeFormatter from "../DateTimeFormatter";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useEditSeqOfEventsMachine } from "../../../context/EditSeqOfEventsMachineContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventsList({ obfuscated = false }) {
  const { t } = useTranslation();
  const [state, send] = useEditSeqOfEventsMachine();

  return (
    <div className="">
      <div className=" flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-900 sm:pl-0"
                  >
                    {t(
                      "editReport.view.seqOfEvents.table.headers.item_occured_at",
                    )}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                  >
                    {t("editReport.view.seqOfEvents.table.headers.description")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                  >
                    {t(
                      "editReport.view.seqOfEvents.table.headers.affected_entity",
                    )}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                  >
                    {t(
                      "editReport.view.seqOfEvents.table.headers.traceability_and_evidences",
                    )}
                  </th>
                  {/* <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-normal text-gray-900"
                  ></th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {state.context?.sequence_of_events_items_details.map((item) => (
                  <tr key={item.id}>
                    <td className="print:whitespace-pre-wrap whitespace-nowrap py-2 pl-4 pr-3 text-xs text-gray-500 sm:pl-0">
                      <DateTimeFormatter
                        isoString={item?.item_occured_at}
                        obfuscated={obfuscated}
                      />
                    </td>
                    <td
                      className={classNames(
                        "print:whitespace-pre-wrap whitespace-nowrap px-2 py-2 text-xs text-gray-500",
                        obfuscated ? "blur-sm" : "",
                      )}
                    >
                      {item.description}
                    </td>
                    <td
                      className={classNames(
                        "print:whitespace-pre-wrap whitespace-nowrap px-2 py-2 text-xs text-gray-500",
                        obfuscated ? "blur-sm" : "",
                      )}
                    >
                      {item.affected_entity}
                    </td>
                    <td
                      className={classNames(
                        "print:whitespace-pre-wrap whitespace-nowrap px-2 py-2 text-xs text-gray-500",
                        obfuscated ? "blur-sm" : "",
                      )}
                    >
                      {item.traceability_and_evidences}
                    </td>

                    {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-xs font-medium sm:pr-0">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900 disabled:cursor-none disabled:text-gray-500"
                        aria-disabled="true"
                        disabled={true}
                      >
                        Edit<span className="sr-only">, {item.id}</span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
