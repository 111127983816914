import axios from "axios";

const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const fetchEfficiencyMetrics = async (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  const response = await axios(
    `${context.qmsToolApiUrl}dashboard/efficiency-tracker`,
    options,
  );
  return response.data;
};

export { fetchEfficiencyMetrics };
