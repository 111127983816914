import React, { Fragment, useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./RootLayout";
import { useAuth } from "./context/AuthContext";
import { useCreateReportMachine } from "./context/CreateReportMachineContext";
import { useViewEditReportMachine } from "./context/ViewEditReportMachineContext";
import Dashboard from "./components/routes/dashboard";
import Reports from "./components/routes/reports";
import ReportDetails from "./components/ui/edit-report/ReportDetails";
import ActionPlans from "./components/routes/action-plans";
import ActionPlan from "./components/ui/edit-action-plan/ActionPlan";
import { ReviewCorrectiveActionsMachineProvider } from "./context/ReviewCorrectiveActionsMachineContext";
import ReportsNavigator from "./components/ui/reports-navigator/ReportsNavigator";

function App() {
  const { state } = useAuth();
  const [createReportState, createReportSend] = useCreateReportMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();

  const router = createBrowserRouter([
    {
      path: "/",
      element:
        (state.matches("dashboard") &&
          (createReportState.matches({ processing: { initialized: "idle" } }) ||
            createReportState.matches({
              processing: { initialized: "active" },
            }) ||
            createReportState.matches({
              processing: { initialized: "error" },
            })) &&
          viewEditReportState.matches({
            processing: { initialized: "idle" },
          })) ||
        viewEditReportState.matches({
          processing: { initialized: "active" },
        }) ||
        viewEditReportState.matches({
          processing: { initialized: "loading" },
        }) ||
        viewEditReportState.matches({
          processing: { initialized: "error" },
        }) ||
        state.matches("tokenExpiring") ? (
          <RootLayout />
        ) : (
          <div className="flex h-screen">
            <div className="m-auto">
              <div className="flex flex-row gap-2">
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700"></div>
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.3s]"></div>
                <div className="h-4 w-4 animate-bounce rounded-full bg-blue-700 [animation-delay:-.5s]"></div>
              </div>
            </div>
          </div>
        ),
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "/reports",
          element: <Reports />,
        },
        {
          path: "/reports/:id",
          element: <ReportDetails />,
        },
        {
          path: "/reports/navigator",
          element: <ReportsNavigator />,
        },
        {
          path: "/action-plans",
          element: <ActionPlans />,
        },
        {
          path: "/action-plans/:id",
          element: (
            <ReviewCorrectiveActionsMachineProvider>
              <ActionPlan />
            </ReviewCorrectiveActionsMachineProvider>
          ),
        },
      ],
      errorElement: <div>Oops, something went wrong</div>,
    },
    ,
  ]);

  return <RouterProvider router={router} />;
}

export default App;
