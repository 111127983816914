const validProblemStatement = (context, event) => {
  console.log("validProblemStatement", context?.changeSet?.problem_statement);

  return (
    context?.changeSet?.problem_statement !== undefined &&
    context?.changeSet?.problem_statement !== null &&
    context?.changeSet?.problem_statement !== ""
  );
};

const validAnswer1 = (context, event) => {
  return (
    context?.changeSet?.answer_why_1 !== undefined &&
    context?.changeSet?.answer_why_1 !== null &&
    context?.changeSet?.answer_why_1 !== ""
  );
};

const validAnswer2 = (context, event) => {
  return (
    context?.changeSet?.answer_why_2 !== undefined &&
    context?.changeSet?.answer_why_2 !== null &&
    context?.changeSet?.answer_why_2 !== ""
  );
};

const validAnswer3 = (context, event) => {
  return (
    context?.changeSet?.answer_why_3 !== undefined &&
    context?.changeSet?.answer_why_3 !== null &&
    context?.changeSet?.answer_why_3 !== ""
  );
};

const validAnswer4 = (context, event) => {
  return (
    context?.changeSet?.answer_why_4 !== undefined &&
    context?.changeSet?.answer_why_4 !== null &&
    context?.changeSet?.answer_why_4 !== ""
  );
};

const validAnswer5 = (context, event) => {
  return (
    context?.changeSet?.answer_why_5 !== undefined &&
    context?.changeSet?.answer_why_5 !== null &&
    context?.changeSet?.answer_why_5 !== ""
  );
};

const validCreateRequest = (context, event) => {
  return (
    event?.data?.reportId !== undefined &&
    event?.data?.reportId !== null &&
    event?.data?.reportId !== "" &&
    event?.data?.authentication?.token !== undefined &&
    event?.data?.authentication?.token !== null &&
    event?.data?.authentication?.token !== ""
  );
};

const answer2MarkedFinal = (context, event) => {
  return (
    context?.changeSet?.final_why_index === 2 &&
    context?.changeSet?.final_answer === context?.changeSet?.answer_why_2
  );
};

const answer3MarkedFinal = (context, event) => {
  return (
    context?.changeSet?.final_why_index === 3 &&
    context?.changeSet?.final_answer === context?.changeSet?.answer_why_3
  );
};

const answer4MarkedFinal = (context, event) => {
  return (
    context?.changeSet?.final_why_index === 4 &&
    context?.changeSet?.final_answer === context?.changeSet?.answer_why_4
  );
};

const validToken = require("../../commons/guards/index").validToken;

export {
  validProblemStatement,
  validAnswer1,
  validAnswer2,
  validAnswer3,
  validAnswer4,
  validAnswer5,
  validCreateRequest,
  answer2MarkedFinal,
  answer3MarkedFinal,
  answer4MarkedFinal,
  validToken,
};
