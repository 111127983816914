const { actions, spawn, send, sendTo } = require("xstate");
const { assign, log } = actions;

const saveReportIdentifiers = assign({
  reportIdentifiers: (context, event) => {
    return event?.data?.reportIdentifiers || [];
  },
});

const resetReportIdentifiers = assign({
  reportIdentifiers: (context, event) => {
    return [];
  },
});

const saveStatus = assign({
  status: (context, event) => {
    return event?.data?.status;
  },
});

const sendLoadEventToViewReportMachine = (context, event) => {
  const [state, send] = event.data.viewReportActorRef;
  send({
    type: "LOAD_REPORT",
    data: context.reportIdentifiers[context.index],
  });
};

const setIndexOnFirstPage =
  require("../../commons/actions").setIndexOnFirstPage;
const incrementIndex = require("../../commons/actions").incrementIndex;
const decrementIndex = require("../../commons/actions").decrementIndex;
const resetPageIndex = require("../../commons/actions").resetPageIndex;

export {
  saveReportIdentifiers,
  setIndexOnFirstPage,
  incrementIndex,
  decrementIndex,
  saveStatus,
  resetPageIndex,
  resetReportIdentifiers,
  sendLoadEventToViewReportMachine,
};
