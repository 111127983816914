import { createMachine } from "xstate";
const { actions } = require("xstate");
const { assign } = actions;

const authMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgOgDYHsoBLAOwGIBBAVQBUAJAUQDkaBJAYQpoYBEBtAAwBdRKAAO+WEXRF8JUSAAeiAOwBOABzYAzADZ1AVl0CBBlRoEAmADQgAnoktXsl7QIAsujSoMm-2gF8A2zQsbAhkWEwAI3xkACcIbFJkAGMZADcwMhoAeQBpZgB9BgANAAVWACVWJgBxQREkEAkpGTkFZQR9d2x3SwMnc0tNAw01WwcEAzVegTVfdw1tNVcVDyCQjBwIqNiEiDIAGVy63NpGhVbpWXlmrvc1AR1dXQBGXQN+t21vA0nHM5XB4vD4-CZdJsQKEdpEYnFEth0PgANZgEgMRRiIjxUhQMhVBgAMUJAGU6Jdmtd2ndQF1LG8VNgVOpXG83u5tG9LBp9ACENp3Ey1G9eSzBU9xhyoTDwnD9ojkWiMVicXjjqdzjRKeJJDcOvdEAY3tgvm8FiMDIKNI8bPZAZYXG5PH9wQJAsFods5XsEUk0plsopYOhkOgwNhkAAzcPxAAUvhMAEoyLLdvCDpH0kQsjqWnqaZ1EG9TNpsCCZkYnkN3PzBo75r5fm9tDzLDLvemFUkUtmspR2GwAGpcBh56m3IsIdkqR2WIWWcxebSCsx1pym2YrFTuR42tQdsJdv3YeJgKNnqJ4mio9E5ArFQkkhjk3jjguTw0IDTc7CfEbeGooxLLo-KCsKor6DuJgtqYBiHrCvqZgGObZHkhRMCUFTVLUDTCFcH4GnSiAaAypqzvOMwMh4xr8v0ujYOyAFGAMxggghPoZoqt4qtiuIkFA4RgKkZ4ALbougZDBqG4aRjGYDxiWyapp28onkq6KYnxeJCSJYDiSQ6Dvm0n7Ed0zxtju5o7hovK6HaUyvGof4LG8Yz7moPgelsR5qZmGm8WqAnYKk+CoIZeIAAQQPgADu5DGfqtJKI4BgGOWIorronhPF8Ez2tMvKMW5IrzJ8uhuO2nppn5iJnhecCYNePFkESFCsEcVCEolhZfu5f4CKKviaF4pigQVSxaMYAxPBaFjZUEnokPgEBwAoMIESZREpQgAC0yzMoyAwWAM7IrhoYHpQMIychVPj6MaHEEMQyUTttXQ2gxnLsrBxqMt4-LcmWox6EYgo8vNHHHgcm1JVOu3aOlKhHWMVh-ed-KWO8fQjD95reCykLVapSHccqWlBVAsO9WZv6We41mTa8DmILuZY2k2yzZZoi5PYQYXoNTpk7YjjqMu8GjGpLPLrCogOWI6Ki-PZbgVaYIzeV6vmk-6fZgEL73FtyjqkU833GJW41TPRxVuGVsF6EKUO1T2JAoVkBvJV0JYrsynJLAsywrojdazBlTY8lRFiazVOunuel5NQJN7Kp7U5GM86yvK8iOtk82h0VjxUjKsvwDJ4O7O3HAUU-xgmheFMgCdFcWvYRXuIE8dYsjoWOkbj2f6FXXFJDXqp17pYkSWnX6is5EqeduQ20QVny9D4VoM8s7L6FVQRAA */
    id: "auth",
    initial: "login",
    predictableActionArguments: true,
    context: {
      counter: 0,
      tokenExpiresIn: 0,
    },
    states: {
      login: {
        on: {
          AUTHENTICATED: "dashboard",
        },
      },

      dashboard: {
        on: {
          LOGOUT: "logout",
        },

        states: {
          active: {
            after: {
              5000: "inactive",
            },

            on: {
              TOKEN_EXPIRING: "refreshingToken",
            },
          },

          inactive: {
            on: {
              ACTIVATE: "active",
              TOKEN_EXPIRING: "tokenExpiring",
            },
          },

          refreshingToken: {
            on: {
              TOKEN_REFRESHED: "active",

              FAILURE: {
                target: "refreshingToken",
                internal: true,
              },
            },
          },

          tokenExpiring: {
            on: {
              LOGOUT: "#auth.logout",
              REFRESH: "refreshingToken",
            },

            states: {
              "counting down": {
                always: "decrement",
              },

              decrement: {
                after: {
                  1000: "counting down",
                },

                entry: "decrementCounter",
              },
            },

            initial: "counting down",
            entry: "saveTokenExpirationDelay",
          },
        },

        initial: "active",
      },

      logout: {
        type: "final",
      },
    },
  },
  {
    actions: {
      decrementCounter: assign({
        tokenExpiresIn: (context, event) => {
          return context.tokenExpiresIn - 1;
        },
      }),
      saveTokenExpirationDelay: assign({
        tokenExpiresIn: (context, event) => event.data,
      }),
    },
  }
);

export default authMachine;
