import axios from "axios";

const defaultDeleteOptions = {
  method: "DELETE",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultPutOptions = {
  method: "Put",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const deleteCorrectiveAction = (context, event) => {
  try {
    const url = !context?.correctiveAction?.report
      ? `${context.qmsToolApiUrl}action-plans/${context?.correctiveAction.action_plan_id}/corrective-actions/${context?.correctiveAction?.id}`
      : `${context.qmsToolApiUrl}reports/${context?.correctiveAction.report_id}/corrective-actions/${context?.correctiveAction?.id}`;
    const options = {
      ...defaultDeleteOptions,
      headers: {
        ...defaultDeleteOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
    };
    return axios(
      // `${context.qmsToolApiUrl}action-plans/${context?.correctiveAction.action_plan_id}/corrective-actions/${context?.correctiveAction?.id}`,
      url,
      options,
    ).then((response) => response?.data?.corrective_actions);
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCorrectiveAction = (context, event) => {
  try {
    const changeSet = {};

    console.log(`All changes : ${context.changes}`);

    if (context?.changes?.has("description")) {
      changeSet.description = context?.correctiveAction?.description;
    }
    if (context?.changes?.has("handler_type")) {
      changeSet.handler_type = context?.correctiveAction?.handler_type;
    }
    if (context?.changes?.has("expired_at")) {
      changeSet.expired_at = context?.correctiveAction?.expired_at;
    }
    if (context?.changes?.has("deferral_reason")) {
      changeSet.deferral_reason = context?.correctiveAction?.deferral_reason;
    }
    if (context?.changes?.has("status")) {
      changeSet.status = context?.correctiveAction?.status;
    }
    if (context?.changes?.has("domain")) {
      changeSet.domain = context?.correctiveAction?.domain;
    }
    if (context?.changes?.has("source")) {
      changeSet.source = context?.correctiveAction?.source;
    }
    if (context?.changes?.has("priority")) {
      changeSet.priority = context?.correctiveAction?.priority;
    }

    if (context?.changes?.has("problem_statement")) {
      changeSet.problem_statement =
        context?.correctiveAction?.problem_statement;
    }
    if (context?.changes?.has("root_cause")) {
      changeSet.root_cause = context?.correctiveAction?.root_cause;
    }

    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context?.authentication?.token}`,
      },
      data: JSON.stringify(changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}action-plans/${context?.correctiveAction?.action_plan_id}/corrective-actions/${context?.correctiveAction?.id}`,
      options,
    ).then((response) => response?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { deleteCorrectiveAction, updateCorrectiveAction };
