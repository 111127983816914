import { createContext, useContext } from "react";
import { useMachine } from "@xstate/react";
import { EventNavigatorMachine as parentMachine } from "../machines/event-navigator/event-navigator-machine";

const EventNavigatorMachineContext = createContext();

export function useEventNavigatorMachine() {
  const context = useContext(EventNavigatorMachineContext);

  if (!context) {
    throw new Error(
      "useEventNavigatorMachine must be used within a EventNavigatorMachineProvider",
    );
  }

  return context;
}

export function EventNavigatorMachineProvider({ children }) {
  const eventNavigatorMachine = useMachine(parentMachine, {
    context: {
      ...parentMachine.context,
      qmsToolApiUrl:
        process.env.REACT_APP_QMSTOOL_API_URL || "http://localhost:3000",
    },
  });

  return (
    <EventNavigatorMachineContext.Provider value={eventNavigatorMachine}>
      {children}
    </EventNavigatorMachineContext.Provider>
  );
}
