const noCorrections = (context, event) => {
  return (
    !context?.corrections ||
    !Array.isArray(context.corrections) ||
    context?.corrections?.length === 0
  );
};

const validChangeSet = (context, event) => {
  return context?.changeSet?.description;
};

const validToken = require("../../commons/guards/index").validToken;

export { noCorrections, validChangeSet, validToken };
