import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useEditSeqOfEventsMachine } from "../../../context/EditSeqOfEventsMachineContext";

import { Trans, useTranslation } from "react-i18next";
import { FlagIcon } from "@heroicons/react/20/solid";
import { FlagIcon as FlagIconOutline } from "@heroicons/react/24/outline";
import TimeAgo from "../shared/TimeAgo";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { useReviewSeqOfEventsMachine } from "../../../context/ReviewSeqOfEventsMachineContext";
import EventsList from "./EventsList";
import { useEffect } from "react";

import NewItemPanel from "../edit-seq-of-events/NewItemPanel";
import ReviewSeqOfEventsPanel from "../review-seq-of-events/ReviewSeqOfEventsPanel";
import SectionLock from "../shared/SectionLock";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SequenceOfEvents({ data }) {
  const [state, send] = useViewEditReportMachine();
  const [stateSeqOfEvents, sendSeqOfEvents] = useEditSeqOfEventsMachine();
  const [flagSectionForReviewState, flagSectionForReviewSend] =
    useFlagSectionForReviewMachine();
  const [reviewSeqOfEventsState, reviewSeqOfEventsSend] =
    useReviewSeqOfEventsMachine();

  const { t } = useTranslation();
  const sectionLocked =
    state.context?.report?.event?.criticity?.seq_of_events_required === false &&
    state.context?.report?.sequence_of_events?.sequence_of_events_items_details
      ?.length > 0;

  useEffect(() => {
    sendSeqOfEvents("LOAD", {
      data: {
        reportId: state?.context?.report?.id,
        sequenceOfEvents: state?.context?.report?.sequence_of_events,
        event_occured_at: state?.context?.report?.event?.event_occured_at,
        authentication: {
          token: state?.context?.authentication?.token,
        },
      },
    });
    return () => {
      sendSeqOfEvents("UNLOAD");
    };
  }, []);

  return (
    <>
      {/* Sequence of Events */}
      {(state.context?.report?.event?.criticity?.seq_of_events_required ===
        true ||
        sectionLocked) && (
        <SectionLock locked={sectionLocked}>
          <div
            className={classNames(
              "mt-12 overflow-hidden rounded-lg bg-white shadow",
              state.matches("processing.initialized.active.edit") ? "" : "",
            )}
          >
            <div className="border-t border-slate-200 bg-white  px-4 py-4 drop-shadow-md  sm:px-6">
              <div className="flex w-full items-center justify-between">
                <h2 className="py-2 text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.sequenceOfEvents")}
                </h2>
                {!state?.context?.report?.obfuscated &&
                  state?.context?.report?.user_actions?.some(
                    (action) => action.action_id === "submit",
                  ) && (
                    <>
                      <div className="flex">
                        {state.context?.report?.sequence_of_events
                          ?.sequence_of_events_items_details?.length > 0 && (
                          <button
                            className="mr-3 flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50"
                            onClick={(e) => {
                              reviewSeqOfEventsSend("START", {
                                seq_of_events:
                                  state?.context?.report?.sequence_of_events
                                    ?.sequence_of_events_items_details,
                                authentication: {
                                  token: state?.context?.authentication?.token,
                                },
                                qmsToolApiUrl: state?.context?.qmsToolApiUrl,
                                reportId: state?.context?.report?.id,
                                maxDate:
                                  state?.context?.report?.event
                                    ?.event_occured_at,
                              });
                            }}
                            disabled={
                              state.matches(
                                "processing.initialized.active.edit",
                              ) ||
                              !state?.context?.report?.user_actions?.some(
                                (action) => action.action_id === "submit",
                              )
                            }
                          >
                            {t("shared.button.edit")}
                          </button>
                        )}
                        <button
                          className=" flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                          onClick={(e) => {
                            sendSeqOfEvents("ADD_ITEM");
                          }}
                          // Disabled when the report is in edit mode
                          disabled={
                            state.matches(
                              "processing.initialized.active.edit",
                            ) || state.context?.report?.obfuscated
                          }
                        >
                          {t("editReport.view.seqOfEvents.button.add")}
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </div>
            {/* Sequence of Event details */}
            <div
              id="seqOfEventsSection"
              className="bg-slate-50 px-4  py-4  sm:px-6"
            >
              {!state.context?.report?.sequence_of_events
                ?.sequence_of_events_items_details?.length > 0 &&
                !stateSeqOfEvents.context?.sequenceOfEvents && (
                  <>
                    <div className="mt-1 text-sm leading-6 text-gray-500  sm:mt-0">
                      <Trans
                        i18nKey={"editReport.view.seqOfEvents.label.required"}
                      ></Trans>
                    </div>
                  </>
                )}
              {(state.context?.report?.sequence_of_events
                ?.sequence_of_events_items_details?.length > 0 ||
                stateSeqOfEvents.context?.sequenceOfEvents) && (
                <>
                  <EventsList obfuscated={state.context?.report?.obfuscated} />
                </>
              )}
            </div>
            {state?.context?.report?.user_actions?.some(
              (action) =>
                action.action_id === "sequence-of-events-request-review",
            ) &&
              !state.context?.report?.sequence_of_events
                ?.review_requested_at && (
                <div className="border-t border-gray-200 bg-slate-50  px-4 pb-3 pt-4 text-sm font-medium text-gray-500 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      {t(
                        "editReport.view.immediateActions.label.requestReview",
                      )}
                    </div>
                    <div>
                      <button
                        className=" flex items-center justify-center rounded-md   px-2.5 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-amber-300 hover:text-gray-700 hover:ring-amber-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                        onClick={(e) => {
                          flagSectionForReviewSend("START", {
                            data: {
                              action_url:
                                state?.context?.report?.user_actions?.find(
                                  (action) =>
                                    action.action_id ===
                                    "sequence-of-events-request-review",
                                )?.url,
                              authentication: {
                                token: state?.context?.authentication?.token,
                              },
                            },
                          });
                        }}
                      >
                        <FlagIcon className="h6 w-5"></FlagIcon>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {state.context?.report?.sequence_of_events?.review_requested_at && (
              <div className=" bg-amber-300 p-6  text-sm  text-gray-600 sm:px-6 sm:pb-5 sm:pt-6">
                <div className="flex justify-between">
                  <div className="mb-6 flex">
                    <FlagIconOutline className="h6 mr-3 w-5"></FlagIconOutline>
                    <div>
                      {" "}
                      <Trans
                        i18nKey={`editReport.view.activity.REPORT_REVIEW_REQUESTED`}
                        components={{
                          span: <span className="font-medium text-gray-700" />,
                        }}
                        values={{
                          username:
                            state.context?.report?.sequence_of_events
                              ?.review_requester_username,
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <TimeAgo
                      isoString={
                        state.context?.report?.sequence_of_events
                          ?.review_requested_at
                      }
                    />
                  </div>
                </div>
                <div>
                  “
                  {
                    state.context?.report?.sequence_of_events
                      ?.review_description
                  }
                  ”
                </div>
              </div>
            )}
          </div>
        </SectionLock>
      )}
      <ReviewSeqOfEventsPanel />
      <NewItemPanel />
    </>
  );
}
