import StepsContainer from "../../../container";
import Summary from "../Summary";

export default function Step6() {
  return (
    <>
      <StepsContainer>
        <div className="space-y-6">
          <Summary />
        </div>
      </StepsContainer>
    </>
  );
}
