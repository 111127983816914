const { actions, sendParent } = require("xstate");
const { assign } = actions;

const saveToken = require("../../commons/actions").saveToken;
const saveError = require("../../commons/actions").saveError;
const updateLastRefreshedTimestamp =
  require("../../commons/actions").updateLastRefreshedTimestamp;

const saveData = assign({
  data: (context, event) => event.data,
});

const sendErrorEventTypeToParent = sendParent((context) => ({
  type: context.errorEventType,
  error: context.error,
}));

const sendLoadedEventTypeToParent = sendParent((context) => ({
  type: context.loadedEventType,
  data: context.data,
}));

const saveLoadRequest = assign({
  authentication: (context, event) => {
    return {
      ...context.authentication,
      token: event.data.token,
    };
  },
  url: (context, event) => {
    return event.data.url;
  },
});

export {
  updateLastRefreshedTimestamp,
  saveError,
  saveToken,
  saveData,
  sendErrorEventTypeToParent,
  sendLoadedEventTypeToParent,
  saveLoadRequest,
};
