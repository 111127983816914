import React from "react";
import { useAuth } from "./context/AuthContext";
import LogoutModal from "./components/ui/LogoutModal";
import Navigation from "./components/navigation/Navigation";
import { Outlet } from "react-router-dom";
import DebugPanel from "./components/ui/debug/DebugPanel";
import NewReportPanel from "./components/ui/create-report/NewReportPanel";
import NewActionPlanPanel from "./components/ui/action-plans/create-action-plan/NewActionPlanPanel";
import NewCorrectiveActionPanel from "./components/ui/action-plans/create-corrective-action/NewCorrectiveActionPanel";

function RootLayout() {
  const { keycloak, state, send } = useAuth();

  return (
    <>
      <div>
        <Navigation>
          <Outlet />
        </Navigation>
        <LogoutModal />
        <NewReportPanel />
        <NewActionPlanPanel />
        <NewCorrectiveActionPanel />
        {/* <DebugPanel /> */}
      </div>
    </>
  );
}

export default RootLayout;
