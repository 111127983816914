import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { useRequalifyReportMachine } from "../../../context/RequalifyReportMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";

import { XMarkIcon } from "@heroicons/react/24/outline";
import Cancel from "./Cancel";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import EventTypeFlag from "./steps/EventTypeFlag";
import AccessDenied from "./AccessDenied";
import Error from "./Error";
import BadRequest from "./BadRequest";
import Success from "./Success";
import Loading from "./Loading";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RequalifyReportPanel() {
  const [state, send] = useRequalifyReportMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();

  const { t } = useTranslation();
  const { keycloak } = useAuth();

  const handleOnClose = () => {
    send("ABANDON_REQUESTED");
  };

  useEffect(() => {
    if (state.matches("active.success")) {
      viewEditReportSend("LOAD_REPORT", { data: state?.context?.reportId });
    }
  }, [state]);

  useEffect(() => {
    send("UPDATE_BEARER_TOKEN", { data: keycloak.token });
  }, [keycloak.token]);

  return (
    <>
      <Transition.Root
        show={
          state.matches("active") ||
          state.matches("confirm abandon") ||
          state.matches("success")
        }
        as={Fragment}
      >
        <Dialog as="div" className="relative z-[100]" onClose={handleOnClose}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-medium leading-6 text-white">
                              {t("requalifyEvent.view.title")}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                tabIndex={-1}
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                                disabled={!state.can("ABANDON_REQUESTED")}
                                onClick={() => send("ABANDON_REQUESTED")}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {t("requalifyEvent.view.subtitle")}
                            </p>
                          </div>
                          <div className="mt-2"></div>
                        </div>
                        {!state.matches("active.confirm abandon") &&
                          !state.matches(
                            "active.confirmation of the requlification",
                          ) &&
                          !state.matches("active.error") &&
                          !state.matches(
                            "active.requalify event.event requalified",
                          ) && (
                            <div className="-mt-4">
                              <EventTypeFlag />
                            </div>
                          )}
                        {/* CONFIRM ABANDON */}
                        {state.matches("active.confirm abandon") && <Cancel />}
                        {/* SELECTION OF THE CONSEQUENCE */}
                        {state.matches(
                          "active.selection of the consequence",
                        ) && <Step1 />}
                        {/* IDENTIFICATION OF THE CRITICITY */}
                        {state.matches(
                          "active.identification of the criticity",
                        ) && <Step2 />}
                        {/* REASON OF THE REQUaLIFICATION */}
                        {state.matches(
                          "active.reason of the requalification",
                        ) && <Step3 />}
                        {/* ACCESS DENIED */}
                        {state.matches("active.error.access denied") && (
                          <AccessDenied />
                        )}
                        {/* ERROR */}
                        {state.matches("active.error.server error") && (
                          <Error />
                        )}
                        {/* BAD REQUEST */}
                        {state.matches("active.error.bad request") && (
                          <BadRequest />
                        )}
                        {/* SUCCESS */}
                        {state.matches(
                          "active.requalify event.event requalified",
                        ) && <Success />}

                        {/* LOADING */}
                        {state.matches(
                          "active.requalify event.requalifying event",
                        ) && <Loading />}
                      </div>

                      {/* Bottom Action Bar */}
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {state.can({ type: "ABANDON" }) && (
                          <button
                            onClick={() => send("ABANDON")}
                            className="ml-4 rounded-md px-3 py-2 text-sm font-medium text-red-600 ring-1 ring-inset ring-red-300 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                          >
                            {t("shared.button.confirmAbandon")}
                          </button>
                        )}
                        {state.can({
                          type: "ABANDON_REQUESTED",
                        }) &&
                          state.matches(
                            "active.selection of the consequence",
                          ) && (
                            <button
                              type="button"
                              className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                              onClick={() => {
                                send("ABANDON_REQUESTED");
                              }}
                            >
                              {t("shared.button.cancel")}
                            </button>
                          )}
                        {state.can({ type: "CANCEL_ABANDON" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={() => {
                              send("CANCEL_ABANDON");
                            }}
                          >
                            {t("shared.button.continue")}
                          </button>
                        )}
                        {state.can({ type: "PREVIOUS" }) && (
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                            onClick={() => {
                              send("PREVIOUS");
                            }}
                          >
                            {t("shared.button.back")}
                          </button>
                        )}
                        {state.matches(
                          "active.reason of the requalification",
                        ) && (
                          <button
                            type="button"
                            onClick={() => send("CONFIRM")}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("CONFIRM")}
                          >
                            {t("shared.button.confirm")}
                          </button>
                        )}
                        {state.can("CLOSE") && (
                          <button
                            type="button"
                            onClick={() => {
                              send("CLOSE");
                            }}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("CLOSE")}
                          >
                            {t("shared.button.close")}
                          </button>
                        )}
                        {!state.matches("active.confirm abandon") &&
                          !state.matches(
                            "active.reason of the requalification",
                          ) &&
                          !state.matches("active.error") &&
                          !state.matches(
                            "active.requalify event.event requalified",
                          ) && (
                            <button
                              type="button"
                              onClick={() => send("NEXT")}
                              className={classNames(
                                "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                              )}
                              disabled={!state.can("NEXT")}
                            >
                              {t("shared.button.next")}
                            </button>
                          )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
