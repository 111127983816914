import axios from "axios";

const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};
const defaultPutOptions = {
  method: "PUT",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultPostOptions = {
  method: "Post",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const fetchReport = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };

  return axios(`${context.qmsToolApiUrl}reports/${event.data}`, options).then(
    (response) => response.data,
  );
};

const saveReport = (context, event) => {
  try {
    const options = {
      ...defaultPutOptions,
      headers: {
        ...defaultPutOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
      data: JSON.stringify(context?.changeSet),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${
        context?.report?.id
      }/event?timestamp=${Date.now()}`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitReport = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.report?.id}/submit`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const validateReport = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.report?.id}/validate`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const closeReport = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.report?.id}/close`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const markReportAsSent = (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context?.report?.id}/mark-as-sent`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchReport,
  saveReport,
  submitReport,
  validateReport,
  closeReport,
  markReportAsSent,
};
