import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useEditCorrectiveActionsMachine } from "../../../context/EditCorrectiveActionsMachineContext";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useCreateReportMachine } from "../../../context/CreateReportMachineContext";
import GenericSelectMenu from "../GenericSelectMenu";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DateTimePicker from "../DateTimePicker";
import GenericText from "../GenericText";
import Loading from "./Loading";
import Error from "./Error";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewCorrectiveActionPanel() {
  const [state, send] = useEditCorrectiveActionsMachine();
  const [viewEditReportState, viewEditReportSend] = useViewEditReportMachine();
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { keycloak } = useAuth();
  const { t } = useTranslation();

  const handleClose = () => {
    send("CLOSE");
    if (state?.context?.changesHappened) {
      viewEditReportSend("LOAD_REPORT", {
        data: viewEditReportState.context?.report?.id,
      });
    }
  };

  useEffect(() => {
    console.log("Token refreshed -> send to EditCorrectiveActions Machine");
    send("UPDATE_BEARER_TOKEN", { data: keycloak.token });
  }, [keycloak.token]);

  useEffect(() => {
    console.log("State.context", state.context);
    console.log("State.value", state.value);
  }, [state]);

  return (
    <>
      <Transition.Root show={state.matches("add item")} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={handleClose}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-medium leading-6 text-white">
                              {t("editCorrectiveActions.view.title")}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                tabIndex={-1}
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white disabled:text-indigo-950"
                                disabled={!state.can("CLOSE")}
                                onClick={handleClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {t("editCorrectiveActions.view.subtitle")}
                            </p>
                          </div>
                          <div className="mt-2"></div>
                        </div>

                        {/* Main content */}
                        {state.matches("add item.adding item") && (
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                {/* Description */}
                                <div>
                                  <label
                                    htmlFor="affected_entity"
                                    className="block text-sm font-medium leading-6 text-gray-600"
                                  >
                                    {t(
                                      "editCorrectiveActions.view.label.description",
                                    )}
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="affected_entity"
                                      id="affected_entity"
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      onChange={(e) => {
                                        send("DESCRIPTION_CHANGED", {
                                          data: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <GenericSelectMenu
                                    data={
                                      createReportState.context
                                        ?.correctiveActionManagerTypes
                                    }
                                    selectedItem={
                                      state.context?.changeSet?.handler_type
                                    }
                                    onItemSelected={(value) => {
                                      send({
                                        type: "HANDLER_TYPE_CHANGED",
                                        data: value,
                                      });
                                    }}
                                    label={t(
                                      "createCorrectiveAction.step1.label.handledBy",
                                    )}
                                    noSelectionText={t(
                                      "createCorrectiveAction.step1.placeholder.handledBy",
                                    )}
                                    useNameForKey={true}
                                    useEntireItemForValue={true}
                                    i18nPrefixKey="shared.type.correctiveAction.managerTypes."
                                  />
                                </div>

                                {/* Deadline */}
                                <DateTimePicker
                                  label={t(
                                    "createCorrectiveAction.step1.label.expiredAt",
                                  )}
                                  initialDate={null}
                                  onChange={(iso861DateStr) => {
                                    send("EXPIRATION_DATE_SELECTED", {
                                      data: iso861DateStr,
                                    });
                                  }}
                                  min={new Date()}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Success content */}

                        {/* Error content */}
                        {state.matches("add item.error") && <Error />}

                        {/* Loading content */}
                        {state.matches("add item.create item") && <Loading />}

                        {/* Abandon content */}
                        {/* {state.matches("confirm abandon") && <Cancel />} */}
                      </div>

                      {/* Bottom Action Bar */}
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                          onClick={handleClose}
                          disabled={!state.can("CLOSE")}
                        >
                          {t("shared.button.close")}
                        </button>

                        {state.matches("add item.adding item") && (
                          <button
                            onClick={() => send("CREATE_ITEM")}
                            className={classNames(
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-indigo-300",
                            )}
                            disabled={!state.can("CREATE_ITEM")}
                          >
                            {t("shared.button.create")}
                          </button>
                        )}
                        {state.can({ type: "RETRY" }) && (
                          <button
                            onClick={() => send("RETRY")}
                            className={
                              "ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            }
                            s
                          >
                            {t("createReport.button.retry")}
                          </button>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
