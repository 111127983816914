import React from "react";
import { useCallback } from "react";

const GenericText = ({
  label,
  placeholder,
  onChange,
  tabIndex = null,
  value = null,
  disabled = false,
}) => {
  const handleChange = useCallback(
    (event) => {
      event.persist();
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  return (
    <div className="flex flex-col">
      <label
        htmlFor="affected_entity"
        className="block text-sm font-medium leading-6 text-gray-600"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="affected_entity"
          id="affected_entity"
          className="block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 
ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:text-gray-300"
          placeholder={placeholder}
          onChange={handleChange}
          tabIndex={tabIndex}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default GenericText;
