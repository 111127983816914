import { useCreateReportMachine } from "../../../../../context/CreateReportMachineContext";
import GenericSelectMenu from "../../../GenericSelectMenu";
import StepsContainer from "../../../container";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Combobox, Label } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Step1() {
  const [createReportState, createReportSend] = useCreateReportMachine();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [selectedResidenceOneKey, setSelectedResidenceOneKey] = useState(null);
  const handleChange = (value) => {
    setSelectedResidenceOneKey(value);
    createReportSend("RESIDENCE_SELECTED", { data: value });
  };
  const filteredResidenceOneKeys =
    query === ""
      ? createReportState.context?.residences
      : createReportState.context?.residences.filter((residenceOneKey) => {
          return residenceOneKey.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <StepsContainer>
        <div>
          <Combobox
            as="div"
            value={selectedResidenceOneKey}
            onChange={handleChange}
          >
            <Label className="block text-sm font-medium leading-6 text-gray-700">
              {t("createActionPlan.steps.step1.form.label.residence_one_key")}
            </Label>
            <div className="relative mt-2">
              <Combobox.Input
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(residence) => residence}
                placeholder={t(
                  "createActionPlan.steps.step1.form.placeholder.residence_one_key",
                )}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
              {filteredResidenceOneKeys.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredResidenceOneKeys.map((residence) => (
                    <Combobox.Option
                      key={residence}
                      value={residence}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold",
                            )}
                          >
                            {residence}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                active ? "text-white" : "text-indigo-600",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        </div>
        <div>
          <GenericSelectMenu
            data={createReportState.context?.consequenceTypes.filter(
              (type) => type.used_for_report_creation,
            )}
            selectedItem={
              createReportState.context?.report?.event?.consequence_type
            }
            noSelectionText={t(
              "createReport.steps.step1.form.placeholder.consequence",
            )}
            onItemSelected={(element) => {
              createReportSend("CONSEQUENCE_SELECTED", { data: element });
            }}
            label={t("createReport.steps.step1.form.label.consequence")}
            useNameForKey={true}
            useNameForValue={true}
            i18nPrefixKey="shared.type.consequence."
          />
        </div>
      </StepsContainer>
    </>
  );
}
