const validChangedDescription = (context, event) => {
  return (
    context?.correction?.description &&
    context.correction.description.length > 0 &&
    context.changesHappened === true
  );
};

const validToken = require("../../commons/guards").validToken;

export { validChangedDescription, validToken };
